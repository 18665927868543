import React from 'react';
import modelEditor from 'http/modelEditor';
import GraphView from '../../molecules/GraphView/GraphView';
import withNavShell from '../../molecules/withNavShell';

class ProductsMaster extends React.Component {
    state = {
        model: {
            nodes: [],
            edges: [],
        },
        modelId: null,
    };

    componentDidMount = () => {
        this.readModel();
    };

    readModel = async () => {
        const modelId = this.props.match.params.modelId;
        const model = await modelEditor.getModel(modelId);
        this.setState({ model });
        this.props.updateNavShell();
    };

    render = () => {
        const { modelId } = this.props.match.params;
        const { model } = this.state;
        const { nodes, edges } = model;

        return (
            <GraphView
                editor
                modelId={modelId}
                style={{
                    width: '100%',
                    height: 'calc(100vh - 58px)',
                }}
                graph={{
                    nodes: nodes,
                    edges: edges,
                }}
            />
        );
    };
}

export default withNavShell(ProductsMaster);
