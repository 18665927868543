import React from 'react';
import { useHistory } from 'react-router-dom';
import { Header, Icon, Table } from 'semantic-ui-react';
import accounts from 'http/accounts';
import AutoStamp from 'atoms/AutoStamp';
import AccountsTable from '../AccountsTable';
import TooltipIcon from '../TooltipIcon';

const LoginsMaster = () => {
    const history = useHistory();

    const renderLoginCells = login => (
        <>
            <Table.Cell>
                <Header size='tiny'>
                    <Icon name={login.segment === 'privat' ? 'user' : 'building'} />
                    <Header.Content>
                        {login.loginEmail}
                    </Header.Content>
                </Header>
            </Table.Cell>
            <Table.Cell>{login.phone}</Table.Cell>
            <Table.Cell textAlign='center'>
                {login.isAdmin && <TooltipIcon icon='star' color='yellow' content='Administrator' />}
                {login.wantsNews && <TooltipIcon icon='newspaper' content='Tilmeldt nyhedsbrev' />}
                {login.wantsStatusMails && <TooltipIcon icon='mail' content='Tilmeldt driftsmails' />}
            </Table.Cell>
            <Table.Cell>
                <AutoStamp stamp={login.createdAt} />
            </Table.Cell>
            <Table.Cell>
                <AutoStamp stamp={login.latestLogin} />
            </Table.Cell>
        </>
    );

    const getLoginLink = loginID => `/accounts/logins/${loginID}`;

    return (
        <AccountsTable
            columns={[
                { title: 'Email' },
                { title: 'Telefon' },
                { title: 'Badges', textAlign: 'center' },
                { title: 'Oprettet d.', sortProperty: 'createdAt' },
                { title: 'Sidste login d.', sortProperty: 'latestLogin' },
            ]}
            onRowClicked={login => history.push(getLoginLink(login._id))}
            onRowCtrlClicked={login => window.open(getLoginLink(login._id), '_blank')}
            renderRowCells={renderLoginCells}
            fetchTotalPages={accounts.countLogins}
            fetchPage={({ page, ...other }) => accounts.getLogins({ page: page - 1, ...other })}
            defaultSort='createdAt'
            searchPlaceholder='Søg på email eller telefonnummer...'
            search
        />
    );
}

export default LoginsMaster;