import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProductsMaster from 'model-editor/pages/ProductsMaster/ProductsMaster';
import ProductsView from 'model-editor/pages/ProductsView/ProductsView';
import ResourceTemplatesDetail from 'model-editor/pages/ResourceTemplates/Detail';
import ProductsMetadata from 'model-editor/pages/ProductsMetadata/ProductsMetadata';
import NodesView from 'model-editor/pages/NodesView/NodesView';
import ProductsCreateNode from 'model-editor/pages/ProductsCreateNode/ProductsCreateNode';
import ProductsCreateSection from 'model-editor/pages/ProductsCreateSection/ProductsCreateSection';
import ProductsSectionMaster from 'model-editor/pages/ProductsSectionMaster/ProductsSectionMaster';
import ProductsSectionView from 'model-editor/pages/ProductsSectionView/ProductsSectionView';
import ProductExport from 'model-editor/pages/ProductExport/ProductExport';
import ModelMaster from 'model-editor/pages/ModelMaster/ModelMaster';
import ProductDetail from 'model-editor/pages/ProductDetail';
import ExecutionDetail from 'model-editor/pages/ExecutionDetail';
import ReportTemplatesDetail from 'model-editor/pages/ReportTemplates/Detail';

import ModelEditorSearch from './ModelEditorSearch';

const withModelShell = Component => {
    return ({ match, ...props }) => {
        return <>
            <ModelEditorSearch modelId={match?.params?.modelId} />
            <Component match={match} {...props} />
        </>;
    };
};

const modelDetailRoutes = [
    ['/export', ProductExport],
    ['/metadata', ProductsMetadata],
    ['/node/:nodeId', ProductsView],
    ['/nodes', NodesView],
    ['/create-node', ProductsCreateNode],
    ['/sections/:sectionSlug', ProductsSectionView],
    ['/sections', ProductsSectionMaster],
    ['/create-section', ProductsCreateSection],
    ['/', ProductsMaster],
].map(([path, component]) => {
    return <Route
        key={path}
        path={`/model-editor/:modelId${path}`}
        component={withModelShell(component)}
    />;
});

const ModelEditorMaster = () => {
    return (
        <Switch>
            <Route path='/model-editor/report-templates/:templateID' component={ReportTemplatesDetail} />
            <Route path='/model-editor/resource-templates/:slug' component={ResourceTemplatesDetail} />
            <Route path='/model-editor/products/:productId' component={ProductDetail} />
            <Route path='/model-editor/execution/:exeid' component={ExecutionDetail} />
            <Route path='/model-editor' component={ModelMaster} exact />
            {modelDetailRoutes}
        </Switch>
    );
};

export default ModelEditorMaster;