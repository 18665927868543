import React, { Component } from 'react';
import OnetimePaymentForm from './OnetimePaymentForm';
import { Modal, Button, Message, Loader, Dimmer } from 'semantic-ui-react';

export default class OnetimePaymentCreateModal extends Component {
    render () {
        const { open, onClose, error, working } = this.props;
        return (
            <Modal dimmer='blurring' open={open} onClose={onClose}>
                <Modal.Header>Opret enkeltkøb</Modal.Header>
                <Modal.Content>
                    <OnetimePaymentForm {...this.props}/>
                    <Dimmer inverted active={working}>
                        <Loader inline size='massive' active={working}/>
                    </Dimmer>
                </Modal.Content>
                <Modal.Actions>
                    { error && <Message color='orange' style={{textAlign: 'center'}}>{error}</Message> }
                    <Button color='black' onClick={onClose}>Annuller</Button>
                    <Button
                        positive
                        icon='plus'
                        labelPosition='right'
                        content='Opret'
                        onClick={this.props.submitForm}
                    />
                </Modal.Actions>
            </Modal>
        );
    }
}