import React from 'react';
let interval = null;

class Spinner extends React.Component {
    componentDidMount () {
        const canvas = this.refs.canvas;
        const ctx = canvas.getContext('2d');
        const {pointDirection, drawCircle} = this;
        const {size} = this.props;
        const colorOne = this.props.colorOne || '#68ed5e';
        const colorTwo = this.props.colorTwo || '#b2e8ae';
        const sizeAsNum = Number(size);

        let counter = 0;
        let moveBy = 0;
        const amountOfDots = 2 + Math.floor(Math.random() * 5);

        interval = setInterval(() => {
            ctx.clearRect(0, 0, sizeAsNum, sizeAsNum);
            for (let i = 0; i < amountOfDots; i++) {
                ctx.fillStyle = i % 2 === 0? colorOne : colorTwo;
                const {x, y} = pointDirection(sizeAsNum/2, sizeAsNum/2, (360/amountOfDots) * i + (moveBy*4), (Math.sin(counter)*(sizeAsNum/20)) + sizeAsNum/3);
                drawCircle(ctx, x, y, (sizeAsNum / 320) * 8);
            }
            for (let i = 0; i < amountOfDots; i++) {
                ctx.fillStyle = i % 2 !== 0? colorOne : colorTwo;
                const {x, y} = pointDirection(sizeAsNum/2, sizeAsNum/2, (360/amountOfDots) * i + (moveBy*8), (Math.sin(counter)*(sizeAsNum/40)) * -1 + sizeAsNum/6);
                drawCircle(ctx, x, y, (sizeAsNum / 320) * 4);
            }
            counter += 0.03;
            moveBy += Math.abs(Math.sin(counter));
        }, 16);
    }

    drawCircle (ctx, x, y, size) {
        ctx.beginPath();
        ctx.arc(x, y, size, 0, 2 * Math.PI);
        ctx.fill();
    }

    componentWillUnmount () {
        clearInterval(interval);
    }

    pointDirection(x, y, direction, stepSize) {
        return {
            x: x - stepSize * Math.cos(direction * Math.PI / 180),
            y: y - stepSize * Math.sin(direction * Math.PI / 180)
        };
    }

    render () {
        const {size} = this.props;
        return <canvas height={size} width={size} ref='canvas'/>;
    }
}

export default Spinner;