const businessForms = {
    AKTIESELSKAB: 'Aktieselskab',
    AMTSKOMMUNE: 'Amtskommune',
    ANDELSSELSKAB_MED_BEGRÆNSET_ANSVAR: 'Andelsselskab med begrænset ansvar',
    ANDELSSELSKAB: 'Andelsselskab',
    ANDEN_UDENLANDSK_VIRKSOMHED: 'Anden udenlandsk virksomhed',
    ANPARTSSELSKAB: 'Anpartsselskab',
    DØDSBO: 'Dødsbo',
    ENHED_UNDER_OPRETTELSE_I_ERHVERVS_OG_SELSKABSSTYRELSEN: 'Enhed under oprettelse i Erhvervs- og Selskabsstyrelsen',
    ENKELTMANDSVIRKSOMHED: 'Enkeltmandsvirksomhed',
    ERHVERVSDRIVENDE_FOND: 'Erhvervsdrivende fond',
    EUROPÆISK_ØKONOMISK_FIRMAGRUPPE: 'Europæisk Økonomisk Firmagruppe',
    FAST_FORRETNINGSSTED_AF_EUROPÆISK_ØKONOMISK_FIRMA: 'Fast forretningssted af Europæisk økonomisk Firma',
    FILIAL_AF_ANDEN_UDENLANDSK_VIRKSOMHEDSFORM: 'Filial af anden udenlandsk virksomhedsform',
    FILIAL_AF_SE_SELSKAB: 'Filial af SE-selskab',
    FILIAL_AF_UDENLANDSK_AKTIESELSKAB_KOMMANDITAKTIESELSKAB: 'Filial af udenlandsk aktieselskab, kommanditaktieselskab',
    FILIAL_AF_UDENLANDSK_ANPARTSSELSKAB_ELLER_SELSKAB: 'Filial af udenlandsk anpartsselskab eller selskab',
    FILIAL_AF_UDENLANDSK_VIRKSOMHED_MED_BEGRÆNSET_ANSVAR: 'Filial af udenlandsk virksomhed med begrænset ansvar',
    FOLKEKIRKELIGE_INSTITUTIONER: 'Folkekirkelige Institutioner',
    FONDE_OG_ANDRE_SELVEJENDE_INSTITUTIONER: 'Fonde og andre selvejende institutioner',
    FORENING_ELLER_SELSKAB_MED_BEGRÆNSET_ANSVAR: 'Forening eller selskab med begrænset ansvar',
    FORENING_MED_BEGRÆNSET_ANSVAR: 'Forening med begrænset ansvar',
    FORENING: 'Forening',
    FRIVILLIG_FORENING: 'Frivillig forening',
    GRØNLANDSK_AFDELING_AF_UDENLANDSK_SELSKAB_ELLER_VIRKSOMHED: 'Grønlandsk afdeling af udenlandsk selskab eller virksomhed',
    INTERESSENTSKAB: 'Interessentskab',
    IVÆRKSÆTTERSELSKAB: 'Iværksætterselskab',
    KOMMANDITAKTIESELSKAB_PARTNERSELSKAB: 'Kommanditaktieselskab / Partnerselskab',
    KOMMANDITSELSKAB: 'Kommanditselskab',
    MEDARBEJDERINVESTERINGSSELSKAB: 'Medarbejderinvesteringsselskab',
    PARTREDERI: 'Partrederi',
    PERSONLIGT_EJET_MINDRE_VIRKSOMHED: 'Personligt ejet Mindre Virksomhed',
    PRIMÆRKOMMUNE: 'Primærkommune',
    REGION: 'Region',
    SE_SELSKAB: 'SE-Selskab',
    SELSKAB_MED_BEGRÆNSET_ANSVAR: 'Selskab med begrænset ansvar',
    SELVSTÆNDIG_OFFENTLIG_VIRKSOMHED: 'Selvstændig offentlig virksomhed',
    STATSLIG_ADMINISTRATIV_ENHED: 'Statslig administrativ enhed',
    SÆRLIG_FINANSIEL_VIRKSOMHED: 'Særlig finansiel virksomhed',
    UOPLYST_VIRKSOMHEDSFORM: 'Uoplyst virksomhedsform',
    ØVRIGE_VIRKSOMHEDSFORMER: 'Øvrige virksomhedsformer',
};

export default Object.values(businessForms);