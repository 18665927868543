import React, { Component } from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';

class NodeDataCvrOverview extends Component {
    state = {
        useAccountantCvrData: false
    };

    componentDidMount = () => {
        this.setState({
            useAccountantCvrData: this.props.node?.data?.useAccountantCvrData || false,
        });
    };

    getData () {
        return {
            useAccountantCvrData: this.state.useAccountantCvrData,
        };
    }

    render () {
        const { useAccountantCvrData } = this.state;

        return (
            <Segment>
                <Checkbox
                    toggle
                    checked={useAccountantCvrData}
                    onClick={() => this.setState({ useAccountantCvrData: !useAccountantCvrData })}
                    label='Hent rådgiverens CVR data?'
                />
            </Segment>
        );
    }
}

export default NodeDataCvrOverview;