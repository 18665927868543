import React, { Component } from 'react';
import { List, Header, Segment, Button } from 'semantic-ui-react';
import ActivityItem from './ActivityItem';
import { translateActivity } from './activityTypes';

const ACTIVITIES_PER_LOAD = 10;

export default class ActivityList extends Component {
    state = {
        displayAmount: ACTIVITIES_PER_LOAD
    };

    loadMore = () => {
        const { displayAmount } = this.state;
        this.setState({
            displayAmount: displayAmount + ACTIVITIES_PER_LOAD
        });
    };

    renderActivity = activity => {
        const translatedActivity = translateActivity(activity);
        return <ActivityItem key={activity._id} {...translatedActivity} />;
    };

    renderActivities = () => {
        const { activities } = this.props;
        const stopIdx = Math.min(this.state.displayAmount, activities.length);
        const out = [];
        for (let i = 0; i < stopIdx; i++) {
            out.push(this.renderActivity(activities[i]));
        }
        return out;
    };

    renderLoadMoreButton = () => {
        const { activities } = this.props;
        if (this.state.displayAmount >= activities.length) {
            return null;
        }
        return <Segment basic textAlign='center'>
            <Button
                primary
                content='Vis flere'
                onClick={this.loadMore}
            />
        </Segment>;
    };

    render () {
        const { activities } = this.props;
        if (!activities || activities.length === 0) {
            return (
                <Header color='grey'>
                    <i>Der er ikke registreret nogen aktiviteter endnu</i>
                </Header>
            );
        }
        return <>
            <List relaxed divided size='large'>
                {this.renderActivities()}
            </List>
            {this.renderLoadMoreButton()}
        </>;
    }
}