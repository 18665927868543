import React, { useState } from 'react';
import { Transition, Button, Table } from 'semantic-ui-react';

function CalculationDocumentation ({ alwaysOpen, documentation, buttonText }) {
    const [open, setOpen] = useState(alwaysOpen);

    const helpTable = (
        <Table striped basic='very'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Signatur</Table.HeaderCell>
                    <Table.HeaderCell>Antal argumenter</Table.HeaderCell>
                    <Table.HeaderCell>Beskrivelse</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    documentation.map(doc => {
                        const paramsNeeded = doc.paramsNeeded ? doc.paramsNeeded : 'Varierende';
                        return (
                            <Table.Row key={doc.signature}>
                                <Table.Cell><b>{doc.signature}</b></Table.Cell>
                                <Table.Cell>{paramsNeeded}</Table.Cell>
                                <Table.Cell>{doc.description}</Table.Cell>
                            </Table.Row>
                        );
                    })
                }
            </Table.Body>
        </Table>
    );

    if (alwaysOpen) {
        return helpTable;
    }

    return (
        <div>
            <Transition.Group animation='fade up' duration={500}>
                { open && helpTable }
            </Transition.Group>
            <Button
                onClick={() => setOpen(!open)}
                positive={!open}
                content={open ? 'Skjul' : (buttonText || 'Vis dokumentation')}
            />
        </div>
    );
}

export default CalculationDocumentation;