import React from 'react';
import { Icon, List } from 'semantic-ui-react';

import styles from './ActionItem.module.css';

function ActionItem({ onClick, content, icon, disabled, loading }) {
    const cn = disabled ? styles.disabledActionItem : styles.actionItem;
    const iconToUse = loading ? 'spinner' : icon;
    return <List.Item onClick={onClick}>
        <span className={cn}>
            <Icon name={iconToUse} loading={loading} />
            {content}
        </span>
    </List.Item>
}

export default ActionItem;
