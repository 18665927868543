import React from 'react';
import './NodesView.css';
import NodeList from '../../molecules/NodeList/NodeList';
import withNavShell from '../../molecules/withNavShell';

class NodesView extends React.Component {
    componentDidMount () {
        this.props.updateNavShell();
    }

    render() {
        const { modelId } = this.props.match.params;

        return (
            <div className='nodes-view'>
                <NodeList modelId={modelId} />
            </div>
        );
    }
}


export default withNavShell(NodesView);
