import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { getAppContentContainer } from 'shared/appContentContainer';
import { createLineBuffer } from './lineBuffer';
import { analyzeText } from './analyzeText';
import SuggestionsMenu from './SuggestionsMenu';

const colors = {
    standard:   'black',
    identifier: 'purple',
    inactive:   '#ed3507',
    active:     '#0b9e15',
};

const AugmentationLayer = ({ menuOpen, setMenuOpen, onAutocomplete, text, caretIndex, syntaxConfiguration }) => {
    const [caretCoordinates, setCaretCoordinates] = useState(null);
    const caretRef = useRef();
    const menuRef = useRef();

    const textRenderResult = useMemo(() => {
        const colorizedElements = [];
        const { colorChangeIndices, autocomplete } = analyzeText({
            text,
            colors,
            caretIndex,
            syntaxConfiguration,
        });
        const caretMarker = <span ref={caretRef} />;

        let lineBuffer = createLineBuffer(colors.standard);

        [...text].forEach((char, charIdx) => {
            if (charIdx === caretIndex) {
                lineBuffer.writeCaretMarker(caretMarker);
            }

            if (char === '\n') {
                colorizedElements.push(lineBuffer.toElement());
                lineBuffer = createLineBuffer(colors.standard);
                return;
            }

            const colorChange = colorChangeIndices[charIdx];
            if (colorChange) {
                lineBuffer.changeColor(colorChange);
            }
            
            lineBuffer.write(char);
        });

        if (caretIndex === text.length) {
            lineBuffer.writeCaretMarker(caretMarker);
        }

        colorizedElements.push(lineBuffer.toElement());

        return {
            colorizedElements,
            autocomplete,
        };
    }, [text, caretIndex, syntaxConfiguration]);

    useLayoutEffect(() => {
        if (textRenderResult.autocomplete.suggestions.length === 0) {
            setMenuOpen(false);
        }        

        if (!caretRef.current) {
            setCaretCoordinates(null);
            return;
        }

        const rect = caretRef.current.getBoundingClientRect();
        if (!rect) {
            setCaretCoordinates(null);
            return;
        }
        
        setCaretCoordinates([rect.left, rect.top + rect.height]);
    }, [textRenderResult, setMenuOpen]);

    const renderSuggestionMenu = () => {
        const { autocomplete } = textRenderResult;

        if (!menuOpen) {
            return null;
        }
        
        if (autocomplete.suggestions.length === 0) {
            return null;
        }

        if (!caretCoordinates) {
            return null;
        }

        const appContainer = getAppContentContainer();
        const [caretX, caretY] = caretCoordinates;
        const menuWidth = menuRef.current?.offsetWidth || 0;
        const overshoot = menuWidth - Math.min(appContainer.clientWidth - caretX, menuWidth);
        const correctedX = caretX - overshoot;

        return (
            <div
                children={
                    <SuggestionsMenu
                        ref={menuRef}
                        items={autocomplete.suggestions}
                        query={autocomplete.query}
                        setMenuOpen={setMenuOpen}
                        onSelection={idx => onAutocomplete({
                            query: autocomplete.query,
                            ...autocomplete.suggestions[idx],
                        })}
                    />
                }
                style={{
                    position: 'fixed',
                    left: correctedX,
                    top: caretY,
                    zIndex: 1,
                }}
            />
        );
    };
 
    return <>
        {textRenderResult.colorizedElements}
        {renderSuggestionMenu()}
    </>;
};

export default AugmentationLayer;