import React, { Component } from 'react';
import {
    Container,
    Loader,
    Menu
} from 'semantic-ui-react';
import ProductAccess from './ProductAccess';
import Subscriptions from './Subscriptions';
import OnetimePayments from './OnetimePayments';
import AddonProducts from './AddonProducts';

class PaymentAdministration extends Component {
    constructor (props) {
        super(props);

        this.tabs = {
            access: {
                header: 'Produktadgange',
                render: () => <ProductAccess {...props} />,
            },
            subscriptions: {
                header: 'Abonnementer',
                render: () => <Subscriptions {...props} />,
            },
            onetimePayments: {
                header: 'Enkeltkøb',
                render: () => <OnetimePayments {...props} />,
            },
            addonProducts: {
                header: 'Revisorpakken',
                render: () => <AddonProducts {...props} />,
            },
        };

        this.state = {
            activeTab: 'access',
        };
    }

    renderSubscriptions = () => {
        return <h1>Hello</h1>;
    };
    
    renderOnetimePayments = () => {
        return <h1>Hello</h1>;
    };

    renderContent = () => {
        const { loading, activeTab } = this.state;

        if (loading) {
            return null;
        }

        // get selected tab
        const tab = this.tabs[activeTab];

        // run the tabs render method
        return tab.render();
    };

    renderMenu = () => {
        const { activeTab } = this.state;

        return (
            <Menu secondary pointing>
                {Object.entries(this.tabs).map(([id, tab]) => {
                    return (
                        <Menu.Item
                            key={id}
                            content={tab.header}
                            active={id === activeTab}
                            onClick={() => this.setState({ activeTab: id })}
                        />
                    );
                })}
            </Menu>
        );
    };

    render () {
        const { loading, working } = this.state;
        return (
            <Container>
                <Loader active={loading || working} />
                {this.renderMenu()}
                {this.renderContent()}
            </Container>
        );
    }
}

export default PaymentAdministration;