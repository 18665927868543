import React from 'react';
import { Accordion, Button, Container, Header, Icon, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import modelEditor from 'http/modelEditor';
import NodeMetadata, { nodeCategories } from '../../nodeMetadata';
import NavItem from "../../../atoms/NavItem/NavItem";
import TextInput from "../../../atoms/TextInput/TextInput";
import { comparators } from 'shared/utility';
import styles from './NodeList.module.css';

export default class NodeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
            modelId: null,
            filterBy: '',
            taglistActiveIdx: -1,
        }

        this.sortOptions = {
            name: {
                name: 'Navn',
                compareFn: comparators.LEXICOGRAPHICAL,
                getProperty: x => x.name,
            },
            type: {
                name: 'Type',
                compareFn: comparators.LEXICOGRAPHICAL,
                getProperty: x => x.type,
            },
            level: {
                name: 'Niveau',
                compareFn: comparators.DEFAULT,
                getProperty: x => x.level,
            }
        };
    }

    async componentDidMount() {
        const modelId = this.props.modelId;
        if (modelId) {
            await this.readNodeList(modelId);
        }
    }

    componentDidUpdate = async props => {
        if (this.props.modelId !== props.modelId) {
            await this.readNodeList(this.props.modelId);
        }
    }

    async readNodeList(modelId) {
        const nodes = await modelEditor.getModelNodes(modelId);
        const { compareFn, getProperty } = this.sortOptions.name;
        this.setState({
            nodes: this.customSort(nodes, compareFn, getProperty),
            modelId: modelId,
        })
    }

    customSort(array, compareFn, getProperty) {
        return [...array].sort((a, b) => compareFn(...[a, b].map(x => getProperty(x))))
    }

    onSortTypeChanged = event => {
        const property = event.target.value;
        const { nodes } = this.state;
        const { compareFn, getProperty } = this.sortOptions[property];
        this.setState({
            nodes: this.customSort(nodes, compareFn, getProperty)
        });
    }

    getNodeTypeIcon = nodeType => {
        const meta = NodeMetadata(nodeType);
        const category = nodeCategories[meta.category];
        return category.icon;
    }

    filterNodes (nodes, filterBy) {
        if (!filterBy) return nodes;
        return nodes.filter(n => {
            // Filters on either the name of the node or the XBRL tag
            return new RegExp(filterBy, 'gi').test(n.name+"|"+n.tag);
        });
    }

    render() {
        const { nodes, modelId, filterBy, taglistActiveIdx } = this.state;
        const { nodeId } = this.props;
        const hasProductNode = nodes.filter(n => n.type === 'Product').length > 0;
        const filteredNodes = this.filterNodes(nodes, filterBy);

        return (
            <Container className={styles.nodeList}><Segment inverted className={styles.mainSegment}>
                <div className={styles.headerSection}>
                    <Header inverted as="h1">Noder</Header>
                    <Button.Group>
                        <Button
                            content='Opret node'
                            color='green'
                            as={Link}
                            to={{
                                pathname: `/model-editor/${modelId}/create-node`,
                                state: { hasProductNode: hasProductNode },
                            }}
                        />
                    </Button.Group>
                </div>

                <div className={styles.sortItems}>
                    <label>Sortér efter</label>
                    <select className={styles.sortSelect} onChange={this.onSortTypeChanged}>
                        {
                            Object.entries(this.sortOptions).map(([key, sortOption]) => <option value={key}>{sortOption.name}</option>)
                        }
                    </select>
                </div>

                <TextInput autoFocus placeholder='Filtrer' onChange={e => this.setState({filterBy: e.target.value})} name={'filter'}/>

                <hr/>
                <ul>
                    <NavItem to={`/model-editor/${modelId}`} label="Alle" name="apps" active={!nodeId}/>
                    {
                        filteredNodes.map(node => {
                            return <NavItem
                                to={`/model-editor/${modelId}/node/${node.id}`}
                                label={`${node.name} (${node.type})`}
                                key={ node.id }
                                name={this.getNodeTypeIcon(node.type)}
                                active={node.id === nodeId}
                                data={ NodeMetadata(node.type).color }
                            />
                        })
                    }
                </ul>

                <Accordion styled fluid className={styles.taglist}>
                    <Accordion.Title
                        active={taglistActiveIdx === 0}
                        index={0}
                        onClick={ () => this.setState({taglistActiveIdx: taglistActiveIdx === 0 ? -1 : 0}) }
                    >
                        <Icon name='dropdown' />
                        Anvendte XBRL-tags i modellen
                    </Accordion.Title>
                    <Accordion.Content active={taglistActiveIdx === 0}>
                    <ul>
                    {
                        this.customSort(nodes.filter(n => Boolean(n.tag)), comparators.LEXICOGRAPHICAL, x => x.tag).map(node => {
                            const tag = node.tag;
                            return <li key={tag}>{tag}</li>
                        })
                    }
                    </ul>
                    </Accordion.Content>
                </Accordion>
            </Segment></Container>
        );
    }
}
