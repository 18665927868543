import React from 'react';
import { Dropdown, Header, Segment } from 'semantic-ui-react';

export const vsoOutput = [
    'aaretsSamledeOverfoersler',
    'hensatTilHaevning',
    'indskudskonto',
    'kapitalafkast',
    'kapitalafkastgrundlag',
    'mellemregningskonto',
    'muligHaevning',
    'muligOpsparing',
    'opsparetOverskud',
    'opsparetOverskud.1987',
    'opsparetOverskud.1991',
    'opsparetOverskud.1992',
    'opsparetOverskud.1999',
    'opsparetOverskud.2001',
    'opsparetOverskud.2005',
    'opsparetOverskud.2007',
    'opsparetOverskud.2014',
    'opsparetOverskud.2015',
    'opsparetOverskud.2016',
    'overfoertFraOpsparetOverskud',
    'overskud',
    'overskudFoerRenter',
    'personligbeskattetIndkomst',
    'rentekorrektion',
    'rentekorrektionForaeldrekoeb',
    'virksomhedensResultat',
    'virksomhedsbeskattetIndkomst'
];

class NodeDataTaxCalc extends React.Component {
    state = { property: '' };

    componentDidMount = () => {
        const { property } = this.props.node.data;
        if (property) {
            this.setState({ property });
        }
    };

    getData = () => {
        return this.state;
    };

    render = () => {
        return (
            <Segment secondary>
                <Header>Vælg værdi at trække ud</Header>
                <Dropdown
                    fluid
                    search
                    selection
                    options={vsoOutput.map(o => ({
                        text: o,
                        value: o,
                    }))}
                    onChange={(_, { value }) => this.setState({ property: value })}
                    value={this.state.property}
                />
            </Segment>
            
        );
    };

}

export default NodeDataTaxCalc;