import React from 'react';

export const taxonomyDatalistID = 'taxonomyDatalist';

const TaxonomyDatalist = ({ taxonomy }) => {
    return (
        <datalist id={taxonomyDatalistID}>
            {Object.values(taxonomy || {}).map(({ name, namespace }) => {
                const title = namespace;
                return (
                    <option key={`${namespace}:${name}`} value={name}>
                        {title}
                    </option>
                );
            })}
        </datalist>
    );
};

export default TaxonomyDatalist;