import React, { useState } from 'react';
import { Button, Form, Icon, Loader, Message, Modal, Pagination, Table } from 'semantic-ui-react';
import { truncString } from 'shared/truncString';
import useRestResource from 'shared/hooks/useRestResource';
import reportEditor from 'http/reportEditor';
import * as fileStorage from 'http/fileStorage';
import AutoStamp from 'atoms/AutoStamp';
import ColoredText from 'atoms/ColoredText';
import ValidationError from '../ValidationError';

const logoCache = {};

const listTemplateVersions = async templateID => {
    const versions = await reportEditor.getExportedTemplateVersions(templateID);

    await Promise.all(versions.map(async version => {
        const { logo } = version;
        if (!logo) {
            return;
        }

        logoCache[version.logo] ||= fileStorage.getFileDataAsBase64(logo);

        const { data } = await logoCache[logo];

        version.logoData = data;
    }));

    return versions;
};

const ExportHistory = ({ templateID, goBack }) => {
    const [page, setPage] = useState(0);
    const [versionToView, setVersionToView] = useState(-1);
    const exportHistory = useRestResource({
        fetcher: () => listTemplateVersions(templateID),
        args: [templateID],
    });

    if (exportHistory.loading) {
        return <Loader inline='centered' active />;
    }

    const pageSize = 10;
    const totalPages = Math.ceil(exportHistory.data.length / pageSize);
    const pagesToShow = exportHistory.data.slice(pageSize * page, pageSize * page + pageSize);

    const selectedVersion = versionToView !== -1 && exportHistory.data.find(({ version }) => {
        return version === versionToView;
    });

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Version</Table.HeaderCell>
                        <Table.HeaderCell>Publication time</Table.HeaderCell>
                        <Table.HeaderCell>Publishing user</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {pagesToShow.map(({ version, publishTimestamp, displayName, description, logoData }) => {
                        return <Table.Row>
                            <Table.Cell>{version}</Table.Cell>
                            <Table.Cell>
                                <AutoStamp stamp={publishTimestamp} lang='en' />
                            </Table.Cell>
                            <Table.Cell verticalAlign='center'> 
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {logoData && (
                                        <img
                                            alt='logo'
                                            src={`data:image/png;base64,${logoData}`}
                                            style={{
                                                marginRight: '0.5em',
                                                objectFit: 'cover',
                                                width: '16px',
                                                height: '16px',
                                                borderRadius: '50%',
                                                border: '1px solid lightgray'
                                            }}
                                        />
                                    )}
                                    {displayName}
                                </div>
                            </Table.Cell>
                            <Table.Cell>
                                <ColoredText
                                    onClick={() => setVersionToView(version)}
                                    content={truncString(description, 50)}
                                    link
                                />
                            </Table.Cell>
                        </Table.Row>;
                    })}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='4' textAlign='center'>
                            <Pagination
                                activePage={page + 1}
                                totalPages={totalPages}
                                onPageChange={(_, { activePage }) => setPage(activePage - 1)}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            <Button basic content='Go back' icon='arrow left' onClick={() => goBack()} />
            <Modal open={selectedVersion} onClose={() => setVersionToView(-1)}>
                <Modal.Header>
                    Version #{versionToView}
                </Modal.Header>
                <Modal.Content>
                    <Form style={{ width: '100%', borderSpacing: '0px' }}>
                        <Form.Field>
                            <label>Publishing user</label>
                            {selectedVersion?.displayName}
                        </Form.Field>
                        <Form.Field>
                            <label>Publication time</label>
                            <AutoStamp stamp={selectedVersion?.publishTimestamp} lang='en' />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <div style={{ whiteSpace: 'pre-line' }}>
                                {selectedVersion?.description}
                            </div>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setVersionToView(-1)}
                        content='Close'
                        color='black'
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

const Export = ({ changesMade, templateID, ...props }) => {
    const [didExport, setDidExport] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [viewingHistory, setViewingHistory] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const [description, setDescription] = useState('');
    const nextVersion = useRestResource({
        fetcher: () => reportEditor.nextTemplateVersion(templateID),
        args: [templateID],
    });

    
    const doExport = async () => {
        setIsExporting(true);
        
        try {
            await reportEditor.exportTemplate(templateID, description);
            setDidExport(true);
        } catch (e) {
            if (!e.data) throw e; 
            setValidationError(e.data);
        }
        
        setIsExporting(false);
    };
    
    if (viewingHistory) {
        return <ExportHistory templateID={templateID} goBack={() => setViewingHistory(false)} />;
    }
    
    if (nextVersion.error) {
        return <Message error content='Failed to determine next version' />;
    }
    
    const descriptionTooShort = description.length < 3;
    const exportDisabled = nextVersion.loading || descriptionTooShort || changesMade || didExport || isExporting;

    return (
        <Form style={{ width: '100%', borderSpacing: '0px' }}>
            <Form.Field>
                <label>Version description</label>
                <textarea
                    disabled={isExporting || didExport}
                    defaultValue={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder='Input a small description of what is new in this version'
                    style={{
                        resize: 'vertical',
                        height: '32px',
                    }}
                />
            </Form.Field>
            {
                changesMade &&
                <Form.Field>
                    <Icon color='orange' name='info circle' />
                    You must save before you can publish the template
                </Form.Field>
            }
            <Form.Field>
                <Button
                    primary
                    loading={isExporting || nextVersion.loading}
                    content={
                        didExport
                            ? 'Version published!'
                            : `Publish version #${nextVersion.data}`
                    }
                    disabled={exportDisabled}
                    icon={didExport ? 'check' : 'upload'}
                    onClick={() => doExport()}
                />
                &nbsp;
                <Button
                    icon='list'
                    content='See publication history'
                    basic
                    onClick={() => setViewingHistory(true)}
                />
            </Form.Field>
            {
                validationError &&
                <ValidationError
                    {...props}
                    error={validationError}
                />
            }
        </Form>
    );
};

export default Export;
