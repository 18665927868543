import React from 'react';
import RuleManager from '../../molecules/RuleManager/RuleManager';
import './NodeDataRuleSwitch.css';

class NodeDataRuleSwitch extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            rules: props.node.data.rules || [],
        };
    }

    getData () {
        return { rules: this.state.rules };
    }

    ruleChanged (data) {
        this.setState({
            rules: data
        });
    }

    getSelectedNodes = () => {
        const { nodes } = this.props;
        const edges = this.props.node.edges;
        return edges.map(e => nodes.find(n => n.id === e));
    };

    render () {
        const { modelId } = this.props;
        const { rules } = this.state;
        
        const nodes = this.getSelectedNodes();
        if (nodes.length === 0) {
            return null; 
        }

        return (
            <div className='ruleContainer'>
                <RuleManager
                    disabled={false}
                    rules={rules}
                    deliverData={this.ruleChanged.bind(this)}
                    modelId={modelId}
                    nodes={nodes}
                />
            </div>
        );
    }
}

export default NodeDataRuleSwitch;