import React, { Component } from 'react';

function repeat (times, func) {
    const out = [];
    for (let i = 0; i < times; i++) {
        out.push(func(i));
    }
    return out;
}

class SpaceSpan extends Component {
    getSpaces = () => {
        const { amount, sizeInSpaces } = this.props;
        return (
            <React.Fragment>
                { 
                    repeat(amount, () => {
                        return repeat(sizeInSpaces, () => <React.Fragment>&nbsp;</React.Fragment>);
                    }) 
                }
            </React.Fragment>
        );
    };

    render () {
        return <span>{this.getSpaces()}</span>;
    }
}

export default SpaceSpan;