import React from 'react'
import { toast } from 'react-toastify';
import { Message, Form, Container, Button, Header } from "semantic-ui-react";
import modelEditor from 'http/modelEditor';
import NodeMetadata from '../../nodeMetadata';
import withNavShell from '../../molecules/withNavShell';
import TextInput from "../../../atoms/TextInput/TextInput";
import NodeTypeSelector from '../../atoms/NodeTypeSelector';
import StickyFooter from '../../../atoms/StickyFooter';
import './ProductsCreateNode.css'

class ProductsCreate extends React.Component {
    state = { nodeType: this.getDefaultNodeType() };

    componentDidMount = () => this.props.updateNavShell();

    createNode = async () => {
        this.setState({ error: null });
        const { modelId } = this.props.match.params;
        const nodeData = NodeMetadata(this.state.nodeType);
        const name = this.nodeName.getValue().trim();
        if (!name) {
            return toast.error('Nodenavn må ikke være tomt');
        }

        if (['æ', 'ø', 'å'].some(c => name.toLowerCase().includes(c))) {
            return toast.error('Nodenavn må ikke indeholde æ, ø eller å');
        }

        if (name.includes(' ')) {
            return toast.error('Nodenavn må ikke indeholde mellemrum');
        }

        const node = {
            id: nodeData.staticId || '',
            name: name,
            tag: this.nodeTag.getValue().trim(),
            type: nodeData.type,
            dataType: nodeData.dataType
        };
        
        try {
            const createdNode = await modelEditor.createNode(modelId, node);
            this.props.history.push(`/model-editor/${modelId}/node/${createdNode.id}`);
        } catch(e) {
            return toast.error(e.message);
        }
    };

    getDefaultNodeType () {
        return localStorage.getItem('lastSelectedNodeType') || '';
    }

    nodeTypeChanged = nodeType => {
        localStorage.setItem('lastSelectedNodeType', nodeType);
        this.setState({ nodeType });
    };

    renderErrorField = () => {
        const { error } = this.state;
        if (!error) {
            return;
        }
        return (
            <Form.Field>
                <Message negative>
                    <Message.Header>Fejl under oprettelsen af noden</Message.Header>
                    <p>{error}</p>
                </Message>
            </Form.Field>
        );
    };

    render () {
        return (
            <Container>
                <Form style={{ width: '100%' }}>
                    <Header size='large'>Opret node</Header>
                    <Form.Field>
                        <label>
                            Navn
                            <TextInput
                                ref={r => this.nodeName = r}
                                size='large'
                            />
                        </label>
                    </Form.Field>
                    <Form.Field>
                        <label>
                            XBRL Tag
                            <TextInput
                                ref={r => this.nodeTag = r}
                                size='large'
                            />
                        </label>
                    </Form.Field>
                    <Form.Field>
                        <label>Nodetype</label>
                        <NodeTypeSelector
                            selected={this.state.nodeType}
                            className='cardselected'
                            onChange={this.nodeTypeChanged}
                        />
                    </Form.Field>
                    <Form.Field>
                        {this.renderErrorField()}
                    </Form.Field>
                </Form>
                <StickyFooter>
                    <Button
                        onClick={this.createNode}
                        labelPosition='left'
                        icon='plus'
                        size='huge'
                        content='Opret node'
                        floated='right'
                        primary
                    />
                </StickyFooter>
            </Container>
        );
    }
}

export default withNavShell(ProductsCreate);
