import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Icon, Input, Label, Loader, Pagination, Segment, Table } from 'semantic-ui-react';
import CSV from 'shared/CSV'
import cns from 'shared/cns'

import styles from './AccountsTable.module.css';

const AccountsTable = forwardRef(({
    columns,                // which columns the table contains
    fetchPage,              // handler for fetching a given page
    fetchTotalPages,        // handler for fetching the total amount of available pages
    renderRowCells,         // handler for rendering the cells of a given row
    onRowClicked,           // handler for when a row is clicked
    onRowCtrlClicked,       // handler for when a row is clicked w/ control key pressed
    defaultSort,            // property name that should be sorted by (by default)
    search,                 // whether to render a search field or not
    searchPlaceholder,      // which placeholder text to show in the filter input field
    loading,                // whether the parent component is loading or not
    maxPageSize = 20,       // how many rows to show pr. page
    usePagination = true,   // whether page navigation should be displayed
    advancedSearch,         // is table being used in advanced search context
    limit = 50              // number of rows with payment data injected in them
}, ref) => {
    const [working, setWorking] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [direction, setDirection] = useState(-1);
    const [rows, setRows] = useState([]);
    const [sortBy, setSortBy] = useState(defaultSort);
    const [filterTimeout, setFilterTimeout] = useState(null);
    const [filter, setFilter] = useState('');

    const filterRef = useRef();
    const tableRef = useRef();

    const getFilterInput = () => {
        return filterRef.current?.inputRef?.current;
    };

    useImperativeHandle(ref, () => ({
        downloadAsCSV() {
            const cvs = new CSV([
                'company name',
                'email',
                'roles',
                'firstname',
                'lastname',
                'display name',
                'cvr',
                'created at',
                'owner wants newsletters',
                'owner wants status mails',
                'phone',
                'erp',
                'active products'
            ]);

            const toYesNo = bool => bool ? 'yes' : 'no';

            const getPhones = reportingEntity => {
                let phone = '';
                if(!reportingEntity.logins) {
                    return phone;
                }

                if(reportingEntity.logins.length === 1) {
                    phone = reportingEntity.logins[0].phone;
                } else {
                    phone = reportingEntity.logins.filter(login => login.schemaLogin.phone).map(login => `${login.schemaLogin.loginEmail} ${login.schemaLogin.phone || '-'}`).join(' | ');
                }
                return phone;
            }

            for(const reportingEntity of rows) {
                const { email, roles, companyName, firstname, lastname, cvr, createdAt, erp, subscriptions } = reportingEntity;

                let wantsNewsletters = false, wantsStatusMails = false;
                let login = (reportingEntity.logins || []).find(login => login.expanded.isOwner);
                if(login) {
                    wantsNewsletters = login.schemaLogin.wantsNews;
                    wantsStatusMails = login.schemaLogin.wantsStatusMails;
                }

                cvs.addToRow('company name', companyName || '');
                cvs.addToRow('email', email);
                cvs.addToRow('roles', roles.join(' '));
                cvs.addToRow('firstname', firstname || '');
                cvs.addToRow('lastname', lastname || '');
                cvs.addToRow('display name', reportingEntity.getDisplayName());
                cvs.addToRow('cvr', cvr || '');
                cvs.addToRow('created at', createdAt);
                cvs.addToRow('owner wants newsletters', toYesNo(wantsNewsletters));
                cvs.addToRow('owner wants status mails', toYesNo(wantsStatusMails));
                cvs.addToRow('phone', getPhones(reportingEntity));
                cvs.addToRow('erp', erp);
                cvs.addToRow('active products', (subscriptions || []).join(' '));
                cvs.newLine();
            }
            cvs.download('users');
        }
    }));

    useEffect(() => {
        const doFetchTotalPages = async () => {
            setWorking(true);
            const totalPages = await fetchTotalPages();

            setTotalPages(totalPages);
            setWorking(false);
        };

        doFetchTotalPages();
    }, [fetchTotalPages]);

    useEffect(() => {
        const doFetchRows = async () => {
            setWorking(true);

            // fetch current page rows
            const rows = await fetchPage({
                page,
                filter,
                direction,
                sortBy,
            });
            
            // update state
            if(usePagination) {
                setRows([...rows].splice(0, maxPageSize));
            } else {
                setRows([...rows]);
            }
            setWorking(false);
        };

        // exit early if no pages to fetch
        if (totalPages === 0) {
            return;
        }

        // exit early if parent is loading
        if (loading) {
            return;
        }

        doFetchRows();
    }, [page, filter, direction, sortBy, totalPages, fetchPage, maxPageSize, loading, usePagination]);

    useEffect(() => {
        if (working) {
            return;
        }

        // scroll to top of table
        tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [working]);

    useEffect(() => {
        const filterInput = getFilterInput();

        if(!filterInput) return;

        // reset
        filterInput.value = filter;
        filterInput.focus();
    }, [filter]);

    const doUpdateFilter = filter => {
        if (filter.length <= 2) {
            setFilter('');
            return;
        }

        setPage(1);
        setFilter(filter);
    };

    const handleFilterChange = (_, { value }) => {
        // clear current timeout (if any)
        window.clearTimeout(filterTimeout);

        const newFilterTimeout = window.setTimeout(() => {
            doUpdateFilter(value?.trim());
        }, 1000);

        setFilterTimeout(newFilterTimeout);
    };

    const handleFilterEnterPressed = ({ keyCode }) => {
        if (keyCode !== 13) {
            return;
        }

        // clear current search timeout (if any)
        window.clearTimeout(filterTimeout);

        doUpdateFilter(getFilterInput().value?.trim());
    };

    const handleRowClicked = (e, row, rowIdx) => {
        if (e.ctrlKey) {
            onRowCtrlClicked(row, rowIdx);
            return;
        }

        onRowClicked(row, rowIdx);
    };

    const renderLoader = () => (
        <Table.Row>
            <Table.Cell colSpan={columns.length} textAlign='center'>
                <Loader active inline />
                <br />
                Indlæser...
            </Table.Cell>
        </Table.Row>
    );

    const renderHeaderCells = () => {
        return columns.map(({ title, sortProperty, className, ...otherProps }) => {
            const rowClassName = (
                sortProperty ?
                styles.sortable :
                undefined
            );

            const combinedClassNames = cns(rowClassName, className);

            const handleCellClicked = () => {
                if (!sortProperty) {
                    return;
                }

                if (sortProperty === sortBy) {
                    // sort column didn't change => change direction
                    setDirection(direction * -1);
                    return;
                }

                // sort column changed =>
                // reset direction & set new column
                setDirection(-1);
                setSortBy(sortProperty);
            };

            const cellContent = (
                <span>
                    {
                        sortProperty &&
                        sortProperty === sortBy &&
                        <Icon
                            name={
                                direction === -1 ?
                                'caret down' :
                                'caret up'
                            }
                        />
                    }
                    {title}
                </span>
            );

            return <Table.HeaderCell
                onClick={handleCellClicked}
                className={combinedClassNames}
                content={cellContent}
                {...otherProps}
            />
        });
    };

    const renderRows = () => {
        return rows.slice(0, limit).map((row, rowIdx) => {
            const className = cns(
                working && styles.disabled,
                rowIdx % 2 !== 0 && styles.uneven,
                styles.row,
            );

            return (
                <Table.Row
                    className={className}
                    onClick={e => handleRowClicked(e, row, rowIdx)}
                    children={renderRowCells(row, rowIdx)}
                />
            );
        });
    };

    const renderFooter = () => {
        return (
            <Table.Footer fullWidth className={cns(styles.sticky, styles.bottom)}>
                <Table.Row>
                    <Table.HeaderCell colSpan={columns.length} textAlign='center'>
                        {
                            filter ?
                            <span>Viser {rows.length} resultat{rows.length !== 1 && 'er'}</span> :
                            <Pagination
                                disabled={working}
                                defaultActivePage={page}
                                totalPages={Math.ceil(totalPages / maxPageSize)}
                                onPageChange={(_, { activePage }) => setPage(activePage)}
                            />
                        }
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        );
    };

    return (
        <div ref={tableRef}>
            {
                search && 
                <Segment basic textAlign='center'>
                    <Input
                        icon={
                            <Icon
                                name='x'
                                link={filter}
                                disabled={!filter}
                                onClick={() => setFilter('')}
                            />
                        }
                        label={
                            <Label
                                color='grey'
                                content={<Icon name='search' fitted />}
                            />
                        }
                        onChange={handleFilterChange}
                        onKeyDown={handleFilterEnterPressed}
                        className={styles.filterInput}
                        ref={filterRef}
                        defaultValue={filter}
                        placeholder={searchPlaceholder}
                        autoFocus
                    />
                </Segment>
            }
            <Table>
                <Table.Header className={cns(styles.sticky, styles.top, styles.above)}>
                    {renderHeaderCells()}
                </Table.Header>
                <Table.Body>
                    {
                        working && rows.length === 0 ?
                        renderLoader() :
                        renderRows()
                    }
                    {
                        advancedSearch && <Segment fluid>
                            <p>{`Fundet konti i alt: ${rows.length}`}</p>
                        </Segment>
                    }
                </Table.Body>
                {usePagination && renderFooter()}
            </Table>
        </div>
    );
});

export default AccountsTable;