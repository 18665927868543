import { Client } from '../client';
import { API_LOCATION } from '../../config';

const client = new Client(`${API_LOCATION}/model-editor/api/models`);

function getModels () {
    return client.getResource('');
}

function getModel (modelID) {
    return client.getResource(`/${modelID}`);
}

function createModel(modelName, modelId) {
    return client.postResource('', {
        modelName,
        modelId,
    });
}

function cloneModel(oldID, newModelId, newModelName) {
    return client.postResource(`/${oldID}/clone`, {
        newModelId,
        newModelName,
    });
}


function deleteModel(modelID, modelName) {
    return client.deleteResource(`/${modelID}`, {
        modelName,
    });
}

function getModelNodes (modelID) {
    return client.getResource(`/${modelID}/nodes`);
}

async function getModelNodesMap (modelID) {
    const modelNodes = await getModelNodes(modelID);
    const nodesByID = {};
    for (let node of modelNodes) {
        nodesByID[node.id] = node;
    }
    return nodesByID;
}

function getNodeByID (modelID, nodeID) {
    return client.getResource(`/${modelID}/nodes/${nodeID}`);
}

function createNode (modelID, node) {
    return client.postResource(`/${modelID}/nodes`, node);
}

function updateNode (modelID, nodeID, node) {
    return client.putResource(`/${modelID}/nodes/${nodeID}`, node)
}

function deleteNode (modelID, nodeID) {
    return client.deleteResource(`/${modelID}/nodes/${nodeID}`);
}

function getModelTags (modelID) {
    return client.getResource(`/${modelID}/tags`);
}

function importModel (id, name, rawModel) {
    return client.postResource('/import', {
        newModelID: id,
        newModelName: name,
        raw: rawModel,
    });
}

function replaceModel (id, newModelData) {
    return client.putResource(`/${id}`, newModelData);
}

function getModelDiff (id, newModelData) {
    return client.postResource(`/${id}/diff`, newModelData);
}

function exportModel (modelID) {
    return client.postResource(`/${modelID}/export`);
}

function getFieldsByNodeID (modelID, nodeID) {
    return client.getResource(`/${modelID}/nodes/${nodeID}/fields`);
}

function getSections (modelID) {
    return client.getResource(`/${modelID}/sections`);
}

function getSectionBySlug (modelID, slug) {
    return client.getResource(`/${modelID}/sections/${slug}`);
}

function createSection (modelID, slug, title, icon) {
    return client.postResource(`/${modelID}/sections`, { slug, title, icon });
}

function updateSection (modelID, slug, section) {
    return client.putResource(`/${modelID}/sections/${slug}`, section);
}

function swapSections (modelID, sectionOne, sectionTwo) {
    return client.postResource(`/${modelID}/sections/swap`, {
        sectionOne,
        sectionTwo,
    });
}

function deleteSection (modelID, slug) {
    return client.deleteResource(`/${modelID}/sections/${slug}`);
}

function getFieldIds (modelID) {
    return client.getResource(`/${modelID}/field-ids`);
}

function getModelMetadata (modelID) {
    return client.getResource(`/${modelID}/metadata`);
}

function patchModelMetadata (modelID, data) {
    return client.patchResource(`/${modelID}/metadata`, data);
}

async function getNodeNeighbours (modelID, nodeID) {
    const { leftNodes, rightNodes } = await client.getResource(`/${modelID}/nodes/${nodeID}/neighbours`);
    return {
        leftNodes: new Set(leftNodes),
        rightNodes: new Set(rightNodes),
    };
}

function updateNodeNeighbours (modelID, nodeID, leftNodes, rightNodes) {
    return client.putResource(`/${modelID}/nodes/${nodeID}/neighbours`, {
        leftNodes: [...leftNodes],
        rightNodes: [...rightNodes],
    });
}

function search (modelID, query) {
    return client.postResource(`/${modelID}/search`, { query });
}

function cloneNode (modelID, nodeID) {
    return client.postResource(`/${modelID}/nodes/copy/${nodeID}`);
}

function setNodeLinks (modelID, nodeID, links = []) {
    return client.putResource(`/${modelID}/nodes/${nodeID}/edges`, { links })
}

function getViewStatus(modelID, nodeID) {
    return client.getResource(`/${modelID}/nodes/${nodeID}/views-status`);
}

const modelsClient = {
    getModels,
    getModel,
    createModel,
    cloneModel,
    deleteModel,
    getModelNodes,
    getModelNodesMap,
    getModelMetadata,
    patchModelMetadata,
    getNodeByID,
    createNode,
    updateNode,
    deleteNode,
    getModelTags,
    importModel,
    replaceModel,
    getModelDiff,
    exportModel,
    getFieldsByNodeID,
    getSections,
    getSectionBySlug,
    createSection,
    updateSection,
    swapSections,
    deleteSection,
    getFieldIds,
    getNodeNeighbours,
    updateNodeNeighbours,
    search,
    cloneNode,
    setNodeLinks,
    getViewStatus,
};

export default modelsClient;