import React, { Component } from 'react';
import { List, Icon, Grid, Header, } from 'semantic-ui-react';
import AutoStamp from 'atoms/AutoStamp';
import styles from './ActivityItem.module.css';

class ActivityItem extends Component {
    state = {
        detailOpen: false
    };

    hasDetail = () => {
        return !!this.props.renderData;
    };

    toggleDetailOpen = () => {
        if (!this.hasDetail()) {
            return;
        }
        const detailOpen = !this.state.detailOpen;
        this.setState({ detailOpen });
    };

    renderData = () => {
        const { detailOpen } = this.state;
        const { data, renderData } = this.props;
        if (!this.hasDetail() || !detailOpen) {
            return null;
        }
        return (
            <div className={styles.activityData}>
                {renderData(data)}
            </div>
        );
    };

    renderFoldIndicator = () => {
        const { detailOpen } = this.state;
        return (
            <Icon
                size='big'
                disabled={!this.hasDetail()}
                name={detailOpen ? 'chevron down' : 'chevron left'}
                color={this.hasDetail() ? 'black' : 'grey'}
            />
        )
    };

    render () {
        const { title, icon, timestamp } = this.props;
        return (
            <List.Item onClick={this.toggleDetailOpen}>
                <Grid verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width='1'>
                            <Icon
                                name={icon}
                                size='large'
                            />
                        </Grid.Column>
                        <Grid.Column width='14'>
                            <Header size='small'>
                                {title}
                            </Header>
                            <AutoStamp stamp={timestamp} />
                        </Grid.Column>
                        <Grid.Column width='1'textAlign='right'>
                            {this.renderFoldIndicator()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {this.renderData()}
            </List.Item>
        );
    }
}

export default ActivityItem;