import React from 'react';

class DateQuestion extends React.Component {

    constructor () {
        super();
        this.pad = this.pad.bind(this);
        this.epochToDate = this.epochToDate.bind(this);
    }

    getData () {
        const {minDate, maxDate} = this;
        let toReturn = {};
        if (minDate) {
            toReturn.minDate = minDate.valueAsNumber;
        }
        if (maxDate) {
            toReturn.maxDate = maxDate.valueAsNumber;
        }
        return toReturn; 
    }

    pad (num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    epochToDate (epoch) {
        const {pad} = this;
        const parsedDate = new Date(epoch);
		return '' + parsedDate.getFullYear() + '-' + pad(parsedDate.getUTCMonth() + 1, 2) + '-' + pad(parsedDate.getUTCDate(), 2);
    }

    render () {
        const {minDate, maxDate} = this.props.node.data;
        const {epochToDate} = this;
        return (
            <section>
                <label>Minimumsdato</label>
                <input type="date" defaultValue={epochToDate(minDate)} ref={(r) => this.minDate = r}/>
                <br/>
                <label>Maximumsdato</label>
                <input type="date" defaultValue={epochToDate(maxDate)} ref={(r) => this.maxDate = r}/>
            </section>
        )
    }
}

export default DateQuestion;