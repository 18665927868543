export const createEventHub = () => {
    const eventHooks = {};

    return {
        register(namespace, handler) {
            eventHooks[namespace] = eventHooks[namespace] || new Set();
            eventHooks[namespace].add(handler);
            return () => this.unregister(namespace, handler);
        },
        unregister(namespace, handler) {
            const hooks = eventHooks[namespace] || new Set();
            hooks.delete(handler);
        },
        emitEvent(namespace, newValue) {
            const hooks = eventHooks[namespace] || new Set();
            for (let hook of [...hooks]) {
                hook(newValue);
            }
        },
    };
};