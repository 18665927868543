import React from 'react';
import { List } from 'semantic-ui-react';
import styles from './DataList.module.css';

export const renderDataAsList = listToRender => {
    return data => {
        const listItems = listToRender.map(({ id, title }, i) => {
            const dataLookup = data[id];
            return <List.Item key={id + i}>
                <List.Icon
                    name='info circle'
                    className={styles.listIcon}
                />
                <List.Content>
                    <List.Header>{title}</List.Header>
                    <List.Description>{dataLookup}</List.Description>
                </List.Content>
            </List.Item>;
        });
        return <List animated size='small'>
            {listItems}
        </List>;
    };
};