import React, { Component } from 'react';
import ExecutionView from '../../molecules/ExecutionView/ExecutionView';
import product2graph from './product2graph';
import './ExecutionDetail.css';
import http from '../../../http/client';
import { Message, Loader } from 'semantic-ui-react';

class ExecutionDetail extends Component {
    state = {
        execution: {},
        graph: {},
        loading: true,
        error: false
    };

    requestResource = async url => {
        const { success, data, message } = await http.get(url);
        if (!success) throw new Error(message);
        return data;
    };

    getExecution = async () => {
        const { exeid } = this.props.match.params;
        return this.requestResource(`/product-engine/api/executions/${exeid}`);
    };

    getModel = async modelId => {
        return this.requestResource(`/model-editor/api/models/${modelId}`);
    };

    getProduct = async productId => {
        return this.requestResource(`/product-engine/api/${productId}`);
    };

    async componentDidMount () {
        try {
            const execution = await this.getExecution();
            const product = await this.getProduct(execution.productId);
            const model = await this.getModel(execution.productId);
            const graph = product2graph(product, model);
            this.setState({
                execution,
                graph,
                loading: false
            });
        } catch (error) {
            console.log(error);
            this.setState({ error });
        }
    }

    render () {
        const { graph, execution, error, loading } = this.state;
        console.log(graph, execution);

        let content = null;
        if (error) {
            content = <Message
                error
                content='Kunne ikke hente execution...'
                icon='exclamation'
            />;
        } else if (!loading) {
            content = <ExecutionView
                graph={graph}
                execution={execution}
            />;
        }

        return (
            <div className='execution-page'>
                <Loader active={loading && !error}/>
                {content}
            </div>
        );
    }
}

export default ExecutionDetail;