import { SPLIT_OTHER_EXTERNAL_EXPENSES } from 'config';

// TODO:
//
// All the hardcoded tags in this file should
// be directly extracted from a relevant model.
//
// The "SPLIT_OTHER_EXTERNAL_EXPENSES" flag is used
// during the development of splitting OtherExternalExpenses
// into multiple custom sub tags.

export const customXbrlTags = [
	'DigitalRevisorSalesExpenses',
	'DigitalRevisorCarExpenses',
	'DigitalRevisorDirectExpenses',
];

const getXBRLTagDict = () => {
	const otherExternalExpensesTags = [];

	if (SPLIT_OTHER_EXTERNAL_EXPENSES) {
		otherExternalExpensesTags.push(...customXbrlTags);
	} else {
		otherExternalExpensesTags.push('OtherExternalExpenses');
	}

    return {
        total: [
            'RawMaterialsAndConsumables',
            'DepositsShorttermLiabilitiesOtherThanProvisions',
            'RawMaterialsAndConsumablesUsed',
            'DevelopmentProjectsInProgressAndPrepaymentsForIntangibleAssets',
            'LongtermDebtToOtherCreditInstitutions',
            'ChangeInInventoriesOfFinishedGoodsWorkInProgressAndGoodsForResale',
            'PostemploymentBenefitExpense',
            'KontantHaevet',
            'LandAndBuildings',
            'OtherEmployeeExpense',
            'OtherLongtermInvestments',
            'ShorttermTradePayables',
            'VatAndDutiesPayables',
            'LongtermMortgageDebt',
            'PrivateAndele',
            'OtherShorttermPayables',
            'WorkInProgress',
            'WorkPerformedByEntityAndCapitalised',
            'CashAndCashEquivalents',
            'ShorttermDeferredIncome',
            'GainsLossesFromCurrentValueAdjustmentsOfInvestmentProperty',
            'OtherOperatingIncome',
            'CostOfSales',
            'AaretsIndskud',
            'Kontant haevet',
            'ContractWorkInProgress',
            'Livestock',
            'ShorttermDebtToOtherCreditInstitutions',
            'EmployeeExpensesTransferredToAssets',
            'Private andele',
            'ManufacturedGoodsAndGoodsForResale',
            'OtherInterestIncome',
            'WritedownsOfCurrentAssetsThatExceedNormalWritedowns',
            'None',
            'FixturesFittingsToolsAndEquipment',
            'LeaseholdImprovements',
            'PlantAndMachinery',
            'Aarets Indskud',
            'Egenkapital, primo',
            'RetainedEarnings',
            'LongtermDebtToBanks',
            'OtherOperatingExpenses',
            'SocialSecurityContributions',
            'ShorttermDebtToBanks',
            'InvestmentProperty',
            'PrepaymentsForGoods',
            'WagesAndSalaries',
            'EgenkapitalPrimo',
            'DepositsLongtermInvestmentsAndReceivables',
            'Goodwill',
            'AssetsHeldForSaleInventories',
            'WritedownsOfCurrentAssetsOtherThanCurrentFinancialAssets',
            'CompletedDevelopmentProjects',
            'ShorttermTradeReceivables',
            'VatAndDutiesReceivables',
            'OtherInterestExpenses',
            'Revenue',
            'OtherShorttermReceivables',
            'AdministrativeExpenses',
            'DepreciationAmortisationExpenseAndImpairmentLossesOfPropertyPlantAndEquipmentAndIntangibleAssetsRecognisedInProfitOrLoss',
            'DeferredIncomeAssets',
            'DebtReliefOrSimilarFinanceIncome',
            'IncomeFromOtherLongtermInvestmentsAndReceivables',
            'ImpairmentOfFinancialAssets',
            'AdjustmentsForDeferredTax',
            'ContributedCapital',
            'ProvisionsForDeferredTax',
            'InterestExpenseAssignedToGroupEnterprises',
            'ShorttermReceivablesFromAssociates',
            'InterestIncomeFromGroupEnterprises',
            'IncomeFromInvestmentsInGroupEnterprises',
            'ProposedDividendRecognisedInEquity',
            'ShorttermPayablesToShareholdersAndManagement',
            'InterestIncomeFromAssociates',
            'AdjustmentsForCurrentTaxOfPriorPeriod',
            'ReserveForNetRevaluationAccordingToEquityMethod',
            'RestOfOtherReserves',
            'ShorttermPayablesToGroupEnterprises',
            'IncomeFromInvestmentsInAssociates',
            'LongtermInvestmentsInAssociates',
            'ShorttermTaxReceivables',
            'CurrentDeferredTaxAssets',
            'ShorttermReceivablesFromOwnersAndManagement',
            'ShorttermPayablesToAssociates',
            'CurrentTaxExpense',
            'ShorttermReceivablesFromGroupEnterprises',
            'ShorttermTaxReceivablesFromGroupEnterprises',
            'ReserveForDevelopmentExpenditure',
            'OtherLongtermPayables',
            'InterestExpenseAssignedToAssociates',
            'ReserveForEntrepreneurialCompany',
            'ShorttermPartOfLongtermLiabilitiesOtherThanProvisions',
            'SharePremium',
            'ShorttermTaxPayables',
            'LongtermInvestmentsInGroupEnterprises',
            'OtherShorttermInvestments',
            'ShorttermTaxPayablesToGroupEnterprises',
            'OtherProvisions',
            'PropertyCost',
			...otherExternalExpensesTags,
        ],
        common: [
            'RawMaterialsAndConsumables',
            'DepositsShorttermLiabilitiesOtherThanProvisions',
            'RawMaterialsAndConsumablesUsed',
            'DevelopmentProjectsInProgressAndPrepaymentsForIntangibleAssets',
            'LongtermDebtToOtherCreditInstitutions',
            'ChangeInInventoriesOfFinishedGoodsWorkInProgressAndGoodsForResale',
            'PostemploymentBenefitExpense',
            'LandAndBuildings',
            'OtherEmployeeExpense',
            'OtherLongtermInvestments',
            'ShorttermTradePayables',
            'VatAndDutiesPayables',
            'LongtermMortgageDebt',
            'OtherShorttermPayables',
            'WorkInProgress',
            'WorkPerformedByEntityAndCapitalised',
            'CashAndCashEquivalents',
            'ShorttermDeferredIncome',
            'GainsLossesFromCurrentValueAdjustmentsOfInvestmentProperty',
            'OtherOperatingIncome',
            'CostOfSales',
            'ContractWorkInProgress',
            'Livestock',
            'ShorttermDebtToOtherCreditInstitutions',
            'EmployeeExpensesTransferredToAssets',
            'ManufacturedGoodsAndGoodsForResale',
            'OtherInterestIncome',
            'WritedownsOfCurrentAssetsThatExceedNormalWritedowns',
            'None',
            'FixturesFittingsToolsAndEquipment',
            'LeaseholdImprovements',
            'PlantAndMachinery',
            'RetainedEarnings',
            'LongtermDebtToBanks',
            'OtherOperatingExpenses',
            'SocialSecurityContributions',
            'ShorttermDebtToBanks',
            'InvestmentProperty',
            'PrepaymentsForGoods',
            'WagesAndSalaries',
            'DepositsLongtermInvestmentsAndReceivables',
            'Goodwill',
            'AssetsHeldForSaleInventories',
            'WritedownsOfCurrentAssetsOtherThanCurrentFinancialAssets',
            'CompletedDevelopmentProjects',
            'ShorttermTradeReceivables',
            'VatAndDutiesReceivables',
            'OtherInterestExpenses',
            'Revenue',
            'OtherShorttermReceivables',
            'AdministrativeExpenses',
            'DepreciationAmortisationExpenseAndImpairmentLossesOfPropertyPlantAndEquipmentAndIntangibleAssetsRecognisedInProfitOrLoss',
            'DeferredIncomeAssets',
            'PropertyCost',
			...otherExternalExpensesTags,
        ],
        onlyA: [
            'KontantHaevet',
            'PrivateAndele',
            'AaretsIndskud',
            'Kontant haevet',
            'Private andele',
            'Aarets Indskud',
            'Egenkapital, primo',
            'EgenkapitalPrimo',
        ],
        onlyB: [
            'DebtReliefOrSimilarFinanceIncome',
            'IncomeFromOtherLongtermInvestmentsAndReceivables',
            'ImpairmentOfFinancialAssets',
            'AdjustmentsForDeferredTax',
            'ContributedCapital',
            'ProvisionsForDeferredTax',
            'InterestExpenseAssignedToGroupEnterprises',
            'ShorttermReceivablesFromAssociates',
            'InterestIncomeFromGroupEnterprises',
            'IncomeFromInvestmentsInGroupEnterprises',
            'ProposedDividendRecognisedInEquity',
            'ShorttermPayablesToShareholdersAndManagement',
            'InterestIncomeFromAssociates',
            'AdjustmentsForCurrentTaxOfPriorPeriod',
            'ReserveForNetRevaluationAccordingToEquityMethod',
            'RestOfOtherReserves',
            'ShorttermPayablesToGroupEnterprises',
            'IncomeFromInvestmentsInAssociates',
            'LongtermInvestmentsInAssociates',
            'ShorttermTaxReceivables',
            'CurrentDeferredTaxAssets',
            'ShorttermReceivablesFromOwnersAndManagement',
            'ShorttermPayablesToAssociates',
            'CurrentTaxExpense',
            'ShorttermReceivablesFromGroupEnterprises',
            'ShorttermTaxReceivablesFromGroupEnterprises',
            'ReserveForDevelopmentExpenditure',
            'OtherLongtermPayables',
            'InterestExpenseAssignedToAssociates',
            'ReserveForEntrepreneurialCompany',
            'ShorttermPartOfLongtermLiabilitiesOtherThanProvisions',
            'SharePremium',
            'ShorttermTaxPayables',
            'LongtermInvestmentsInGroupEnterprises',
            'OtherShorttermInvestments',
            'ShorttermTaxPayablesToGroupEnterprises',
            'OtherProvisions',
        ],
    };
};

const tagDicts = {
    xbrl: getXBRLTagDict(),
    revcode: {
        total: [
            'AfskrivningerErhvervedeImmaterielleAktiver',
            'EjFradragsberettigedeOmkostninger',
            'Repraesentation + Salgsfremmende udgifter',
            'FremmedArbejder',
            'Fremmed arbejder',
            'Afskrivninger Faerdiggjorte udviklingprojekter',
            'Repræsentation + Salgsfremmende udgifter',
            'EjFradragRenter',
            'Afskrivninger Erhvervede immaterielle aktiver',
            'Afskrivninger Indretning lejede lokaler',
            'AfskrivningerFaerdiggjorteUdviklingprojekter',
            'AfskrivningerIndretningLejedeLokaler',
            'Laaneomkostninger',
            'RepraesentationSalgsfremmendeUdgifter',
            'TabPaaDebitorerKonstateret',
            'Afskrivninger Produktionsanlaeg og maskiner',
            'AfskrivningerGoodwill',
            'AfskrivningerGrundeBygninger',
            'Ej fradragsberettigede omkostninger',
            'Låneomkostninger m.v.',
            'Laaneomkostninger_mv',
            'Tab/gevinst anlægsaktiver',
            'TabGevinstAnlægsaktiver',
            'Bøder og andre gebyrer',
            'BoederogAndreGebyrer',
            'RenteudgiftSkat',
            'Repraesentation',
            'Smaaanskaffelser',
            'Afskrivninger Grunde og bygninger',
            'Afskrivninger Udviklingprojekter',
            'VirksomhedensKontingentTilArbejdsgiver',
            'Afskrivninger Andre anlaeg, driftsmateriel og inventar',
            'Nedskrivning omsaetningsaktiver',
            'NedskrivningOmsaetningsaktiver',
            'SalgsfremmendeUdgifter',
            'Afskrivninger Goodwill',
            'AfskrivningerUdviklingprojekter',
            'AfskrivningerProduktionsanlaegMaskiner',
            'AfskrivningerAndreAnlaegDriftsmaterielInventar',
            'Salgsfremmende udgifter',
            'TabPaaDebitorerHensat',
            'EjSkatAssocieredeVirk',
            'TilgangPaaProjekterUnderUdviklingIAaret',
            'EjSkatTilknyttedeVirk',
            'EjSkattepligtigeRenter',
            'HensatTilTabPaaDebitorer',
            'none',
        ],
        common: [
            'AfskrivningerErhvervedeImmaterielleAktiver',
            'EjFradragsberettigedeOmkostninger',
            'Repraesentation + Salgsfremmende udgifter',
            'EjFradragRenter',
            'AfskrivningerFaerdiggjorteUdviklingprojekter',
            'AfskrivningerIndretningLejedeLokaler',
            'Laaneomkostninger',
            'RepraesentationSalgsfremmendeUdgifter',
            'TabPaaDebitorerKonstateret',
            'AfskrivningerGoodwill',
            'AfskrivningerGrundeBygninger',
            'Ej fradragsberettigede omkostninger',
            'Låneomkostninger m.v.',
            'Laaneomkostninger_mv',
            'Tab/gevinst anlægsaktiver',
            'TabGevinstAnlægsaktiver',
            'Bøder og andre gebyrer',
            'BoederogAndreGebyrer',
            'RenteudgiftSkat',
            'Repraesentation',
            'Smaaanskaffelser',
            'NedskrivningOmsaetningsaktiver',
            'AfskrivningerUdviklingprojekter',
            'AfskrivningerProduktionsanlaegMaskiner',
            'AfskrivningerAndreAnlaegDriftsmaterielInventar',
            'TabPaaDebitorerHensat',
            'none',
        ],
        onlyA: [
            'FremmedArbejder',
            'Fremmed arbejder',
            'Afskrivninger Faerdiggjorte udviklingprojekter',
            'Repræsentation + Salgsfremmende udgifter',
            'Afskrivninger Erhvervede immaterielle aktiver',
            'Afskrivninger Indretning lejede lokaler',
            'Afskrivninger Produktionsanlaeg og maskiner',
            'Afskrivninger Grunde og bygninger',
            'Afskrivninger Udviklingprojekter',
            'VirksomhedensKontingentTilArbejdsgiver',
            'Afskrivninger Andre anlaeg, driftsmateriel og inventar',
            'Nedskrivning omsaetningsaktiver',
            'SalgsfremmendeUdgifter',
            'Afskrivninger Goodwill',
            'Salgsfremmende udgifter',
        ],
        onlyB: [
            'EjSkatAssocieredeVirk',
            'TilgangPaaProjekterUnderUdviklingIAaret',
            'EjSkatTilknyttedeVirk',
            'EjSkattepligtigeRenter',
            'HensatTilTabPaaDebitorer',
        ],
    },
};

export default tagDicts;