import React from 'react';
import { Icon, Label, Popup } from 'semantic-ui-react';

// wrapper for rendering tooltips
const Tooltip = ({ trigger, content }) => (
    <Popup
        trigger={trigger}
        content={content}
        position='top center'
        inverted
    />
);

// wrapper for rendering an icon w/ a tooltip
const TooltipIcon = ({ content, icon, color, text, size }) => (
    <Tooltip
        trigger={
            <Label
                basic
                circular
                children={
                    <>
                        <Icon
                            name={icon}
                            color={color}
                            size={size}
                            fitted={!text}
                        />
                        {text}
                    </>
                    
                }
            />
        }
        content={content}
    />
);

export default TooltipIcon;