import React from 'react';
import PropTypes from "prop-types";
import './Page.css'
import { Segment, Loader } from 'semantic-ui-react';

class Page extends React.Component {

    static propTypes = {
        children: PropTypes.node.isRequired,
        ready: PropTypes.bool
    };

    static defaultProps = {
        ready: true
    }

    render() {
        if (this.props.ready) {
            return <div className="page">
                {this.props.children}
            </div>
        } else {
            return <div className="page">
                <Segment textAlign='center' basic>
                    <Loader inline active size='massive' />
                </Segment>
            </div>
        }
    }

}

export default Page;