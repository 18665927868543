import React from 'react';
import AugmentedTextarea from 'molecules/AugmentedTextarea';
import model, { userContextSuggestions } from './model';

const CodeTextarea = ({ onChange, defaultValue, suggestions, calculationFunctions, style, containerStyle, placeholder }) => {
    const autocompleteConfiguration = [
        [model.variableNamespaces.activeYear, suggestions],
        [model.variableNamespaces.lastYear, suggestions],
        [model.variableNamespaces.userContext, userContextSuggestions],
    ];

    return (
        <AugmentedTextarea
            defaultValue={defaultValue}
            onChange={onChange}
            style={style}
            placeholder={placeholder}
            containerStyle={containerStyle}
            syntaxConfiguration={[
                ...autocompleteConfiguration.map(([namespace, relevantSuggestions]) => ({
                    pattern: namespace.pattern,
                    prefix: namespace.operatorPrefix,
                    autocompleteItems: relevantSuggestions,
                })),
                {
                    prefix: '',
                    pattern: /([a-z_]+)\b/gi,
                    autocompleteItems: calculationFunctions.map(func => ({ label: func.signature, icon: 'cube' })),
                    colorOverwrites: {
                        active: '#2003fc',
                    },
                },
                {
                    prefix: '',
                    pattern: /([0-9]+)\b/gi,
                    colorOverwrites: {
                        standard: '#1fbff0',
                    },
                },
                {
                    prefix: '',
                    pattern: /(-|\+|>|<|=|\*|\/|\(|\)|,|;|&|\|)/gi,
                    colorOverwrites: {
                        standard: '#3c2752',
                    },
                },
            ]}
        />
    );
};

export default CodeTextarea;