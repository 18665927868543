import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { appContentContainerID } from 'shared/appContentContainer';
import { StoreProvider, withStore } from './store';
import { Manager as LoginManager, EnsureLoggedIn } from './shared/LoginManager';
import NavShell from './molecules/NavShell/NavShell';
import ErrorBoundary from './molecules/ErrorBoundary/ErrorBoundary';

// pages
import WelcomePage from './pages/Welcome';
import Accounts from './pages/Accounts';
import Login from './pages/Login';
import Overview from './pages/Overview';
import TasksMaster from './pages/Tasks/master';
import TasksDetail from './pages/Tasks/detail';
import Email from './pages/Email';
import Notification from './pages/Notification';
import Payment from './pages/Payment';
import Development from './pages/Development';
import MachineLearning from './pages/MachineLearning';
import Taxonomy from './pages/Taxonomy';
import SaasMetrics from './pages/SaasMetrics/index.jsx';
import Hubspot from './pages/Hubspot/index.jsx';
import ModelEditorMaster from './model-editor/pages/Master';

const App = () => {
    return (
        <LoginManager>
            <div className='App' id={appContentContainerID}>
                <NavShell>
                    <div className='App-intro'>
                        <Switch>
                            <Route path='/login' component={Login}/>
                            <ErrorBoundary>
                                <EnsureLoggedIn requiredRole='admin'>
                                    <Route exact path='/' component={WelcomePage} />
                                    <Route path='/accounts' component={Accounts} />
                                    <Route path='/model-editor' component={ModelEditorMaster} />
                                    <Route path='/tasks/:id' component={TasksDetail} />
                                    <Route exact path='/tasks' component={TasksMaster} />
                                    <Route path='/overview' component={Overview} />
                                    <Route path='/payment' component={Payment} />
                                    <Route path='/emails' component={Email} />
                                    <Route path='/notifications' component={Notification} />
                                    <Route path='/development' component={Development} />
                                    <Route path='/machine-learning' component={MachineLearning} />
                                    <Route path='/taxonomy' component={Taxonomy} />
                                    <Route path='/saas-metrics' component={SaasMetrics} />
                                    <Route path='/hubspot' component={Hubspot} />
                                </EnsureLoggedIn>
                            </ErrorBoundary>
                        </Switch>
                    </div>
                </NavShell>
            </div>
        </LoginManager>
    );
}

const AppWithStore = withStore(App);
const AppWrapper = () => {
    return (
        <StoreProvider>
            <AppWithStore />
        </StoreProvider>
    );
};

export default AppWrapper;
