import uuid from 'uuid/v4';
import formatPeriod from './formatPeriod';

const interpretPeriods = instance => {
    const periodsSeen = new Set();
    const periods = [];

    const instantsSeen = new Set();
    const instants = [];

    for (const { period } of instance.contexts) {
        const formatted = formatPeriod(period);

        if (period.instant) {
            if (instantsSeen.has(formatted)) continue;
            instants.push({ date: new Date(period.instant), label: formatted });
            instantsSeen.add(formatted);
            continue;
        }

        if (periodsSeen.has(formatted)) continue;

        periods.push({
            startDate: new Date(period.startDate),
            endDate: new Date(period.endDate),
            label: formatted,
        });
        periodsSeen.add(formatted);
    }

    const [currentYear, lastYear] = periods.sort((a, b) => b.endDate - a.endDate);

    const periodLabels = {
        [currentYear.label]: 'indeværende år',
        [lastYear?.label]: 'sidste år',
    };

    for (const { date, label } of instants) {
        if (date.getTime() === currentYear.endDate.getTime())   periodLabels[label] = 'ultimo';
        if (date.getTime() === currentYear.startDate.getTime()) periodLabels[label] = 'primo';
        if (date.getTime() === lastYear?.endDate?.getTime())    periodLabels[label] = 'ultimo, sidste år';
        if (date.getTime() === lastYear?.startDate?.getTime())  periodLabels[label] = 'primo, sidste år';
    }

    return {
        periodLabels,
        currentYear,
        lastYear,
    };
};

const compareContexts = (instance, refA, refB) => {
    const [adate, bdate] = [refA, refB].map(ref => {
        const { period } = instance.contexts.find(ctx => ctx.id === ref);
        return new Date(period.instant ? period.instant : period.endDate);
    });
    return bdate.getTime() - adate.getTime();
};

const compareUnits = (instance, refA, refB) => {
    const [unitA, unitB] = [refA, refB].map(ref => {
        const unit = instance.units.find(unit => unit.id === ref);
        return unit?.measure || '';
    });

    return unitA.localeCompare(unitB);
};

const formatInstance = instance => {
    instance.fiscalData.sort((a, b) => {
        if (a.unitRef !== b.unitRef)       return compareUnits(instance, a.unitRef, b.unitRef);
        if (a.namespace !== b.namespace)   return a.namespace.localeCompare(b.namespace);
        if (a.tag !== b.tag)               return a.tag.localeCompare(b.tag);
        if (a.contextRef !== b.contextRef) return compareContexts(instance, a.contextRef, b.contextRef);
    
        return 0;
    });
    
    const { periodLabels, currentYear, lastYear } = interpretPeriods(instance);
    
    const groups = {};
    const groupedFiscalValues = new Set();

    for (const fiscalValue of instance.fiscalData) {
        fiscalValue.id = uuid();

        const context = instance.contexts.find(ctx => ctx.id === fiscalValue.contextRef);
        if (!context.scenario) {
            continue;
        }
    
        const { scenario } = context;
        if (scenario.memberKind !== 'typedMember') {
            continue;
        }
    
        groupedFiscalValues.add(fiscalValue.id);
        groups[scenario.dimensionTag] = groups[scenario.dimensionTag] || {
            members: {},
            tags: new Set(),
            dimensionNamespace: scenario.dimensionNamespace,
            dimensionTag: scenario.dimensionTag,
        };
        const { tags, members } = groups[scenario.dimensionTag];
        
        tags.add(`${fiscalValue.namespace}:${fiscalValue.tag}`);

        members[scenario.data.value] = members[scenario.data.value] || [];
        members[scenario.data.value].push({
            value: fiscalValue,
            context,
        });
    }

    return {
        instance,
        groups,
        groupedFiscalValues,
        periodLabels,
        currentYear,
        lastYear,
    };
};

export default formatInstance;