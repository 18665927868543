import React, { Component } from 'react';
import { Loader, Message, Segment, Progress, Icon, Header } from 'semantic-ui-react';
import productEngine from 'http/productEngine';

class ProductStates extends Component {
    state = {
        loading: true,
        states: [],
        error: false,
        maxStep: null,
    };
    /*
        See copies of this module here:
            - app: "src/design/molecules/AppLauncher/userStatuses.js"
            - product-engine: "model/product_states.go"
            - rest-api: "src/util/productStatuses.js"
    */
    statusesMap = {
        not_started: {
            id: 'not_started',
            displayText: 'Ikke påbegyndt',
            color: 'grey',
            icon: 'exclamation circle',
            step: 0,
        },
        started: { // need tooptip?
            id: 'started',
            displayText: 'Påbegyndt',
            color: 'yellow',
            icon: 'cog',
            step: 1,
        },
        sent_to_accountant: { // historisk status - bruges ikke længere
            id: 'sent_to_accountant',
            displayText: 'Sendt til rådgiver',
            color: 'yellow',
            icon: 'calculator',
            tooltip: 'Rapporten godkendes af din rådgiver',
            step: 2,
        },
        erp_data_fetched: { 
            id: 'erp_data_fetched',
            displayText: 'Tal hentet',
            color: 'yellow',
            icon: 'calculator',
            tooltip: 'Bogføringsdata hentet',
            step: 2,
        },
        signature_flow: {  // historical. penneo
            id: 'signature_flow',
            displayText: 'Underskrives',
            color: 'yellow',
            icon: 'pencil alternate',
            tooltip: 'Rapporten underskrives af virksomhedens interessenter',
            step: 3,
        },
        visma_signature_flow: {
            id: 'visma_signature_flow',
            displayText: 'Underskrives',
            color: 'yellow',
            icon: 'pencil alternate',
            tooltip: 'Rapporten underskrives af virksomhedens interessenter',
            step: 3,
        },
        completed: { // historical. do not use anymore
            id: 'completed',
            displayText: 'Påbegyndt',
            color: 'green',
            icon: 'check',
            step: 4,
        },
        done: {
            id: 'done',
            displayText: 'Underskrevet',
            color: 'green',
            icon: 'check',
            step: 4,
        },
        reported: {
            id: 'reported',
            displayText: 'Indberettet',
            color: 'green',
            icon: 'flag checkered',
            tooltip: 'Rapporten er modtaget og godkendt af Erhvervsstyrelsen',
            step: 5,
        },  
    };

    componentDidMount = async () => {
        const maxStep = this.props.roles.includes('erhverv') ? 5 : 2;
        await this.fetchProductStates();
        this.setState({ loading: false, maxStep });
    };

    fetchProductStates = async () => {
        const { userID } = this.props;
        try {
            const states = await productEngine.getUserProductStatuses(userID);
            this.setState({ states });
        } catch {
            this.setState({ error: true });
        }
    };

    renderProductStatus = ({ type, year, status }) => {
        const { displayText, step, icon, color } = this.statusesMap[status];
        const clampedStep = Math.min(step, this.state.maxStep);
        const pct = clampedStep / this.state.maxStep * 100;

        const content = <>
            <Header>{type} {year}</Header>
            <Progress
                progress='ratio'
                color={color}
                percent={pct}
                value={clampedStep}
                total={this.state.maxStep}
                label={<b>{displayText} <Icon name={icon} /></b>}
            />
        </>;
        return <Segment
            content={content}
        />;
    };

    render = () => {
        const { loading, states } = this.state;
        if (loading) {
            return <Segment basic textAlign='center'>
                <Loader inline active size='huge' />
            </Segment>;
        }

        if (states.length === 0) {
            return <Message
                info
                content={
                    <span>
                        <Icon name='info circle' />
                        Brugeren er ikke gået i gang med at bruge nogen produkter
                    </span>
                }
            />
        }

        return <Segment.Group stacked raised>
            {states.map(this.renderProductStatus)}
        </Segment.Group>;
    };
}

export default ProductStates;