import React, { Component } from 'react';
import { Input, Label, Icon } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import styles from './DatePicker.module.css';

class DatePicker extends Component {
    datepickerRef = React.createRef();

    compareDates = (d1, d2) => {
        return (
            d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getFullYear() === d2.getFullYear()
        );
    };

    onChange = date => {
        let cDate = date ? date.getTime() : null;
        this.props.onChange(cDate);
    };

    dateIconClicked = () => {
        return this.datepickerRef.current?.input?.select();
    };

    renderInputField = () => {
        const { leftAttachment, fluid } = this.props;
        return (
            <Input labelPosition='right' fluid={fluid}>
                { !!leftAttachment && leftAttachment }
                <input />
                <Label onClick={this.dateIconClicked}>
                    <Icon name='calendar alternate' fitted />
                </Label>
            </Input>
        );
    };

    getValue = () => {
        const { value } = this.props;

        // check if is valid date
        const asDate = new Date(value);
        if (isNaN(asDate.getTime())) {
            return undefined;
        }

        return asDate;
    };

    getDayClassName = date => {
        const value = this.getValue();
        if (value && this.compareDates(date, new Date(value))) {
            return styles.selected;
        }
    };

    render () {
        const { disabled, minDate, maxDate, placeholder, popperPlacement } = this.props;
        const value = this.getValue();
        return (
            <ReactDatePicker
                dayClassName={this.getDayClassName}
                customInput={this.renderInputField()}
                onChange={this.onChange}
                wrapperClassName={styles.wrapper}
                ref={this.datepickerRef}
                selected={value && value}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholder}
                dropdownMode='select'
                dateFormat='dd/MM/yyyy'
                popperPlacement={popperPlacement || 'bottom'}
                disabledKeyboardNavigation
                showYearDropdown
                fixedHeight
            />
        );
    }
}

export default DatePicker;
