import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/events/api`);

export function getEventsPageCount() {
    return client.getResource('/events');
}

export function getEventsPage(page) {
    return client.getResource(`/events/page/${page}`);
}