import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

const Suggestion = ({ content, icon, active, query, onClick, onHover }) => {
    const indexOfMatch = content.toLowerCase().indexOf(query.toLowerCase());

    const start = content.slice(0, indexOfMatch);
    const matchingPart = content.slice(indexOfMatch, indexOfMatch + query.length);
    const end = content.slice(indexOfMatch + query.length);

    return (
        <div
            onMouseDown={onClick}
            onMouseMove={onHover}
            title={content}
            children={
                <>
                    <Icon name={icon} style={{ color: active ? 'green' : 'gray' }} />
                    <span>{start}</span>
                    <span
                        children={matchingPart}
                        style={{
                            color: 'green',
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    />
                    <span>{end}</span>
                </>
            }
            style={{
                background: active ? 'white' : 'rgb(245, 245, 245)',
                opacity: active ? 1 : 0.95,
                cursor: 'pointer',
                padding: '0.25em',
                maxWidth: '400px',
                textOverflow: 'ellipsis',
                overflowX: 'hidden',
                whiteSpace: 'nowrap',
            }}
        />
    );
};

const SuggestionsMenu = React.forwardRef(({ setMenuOpen, items, query, onSelection }, ref) => {    
    const [activeItemIdx, setActiveItemIdx] = useState(0);

    useEffect(() => {
        setActiveItemIdx(0);
    }, [items]);

    useEffect(() => {
        const itemCount = items.length;
        const onKeyDown = e => {
            const actions = {
                ArrowUp:    () => setActiveItemIdx(activeItemIdx - 1 < 0 ? itemCount - 1 : activeItemIdx - 1),
                ArrowDown:  () => setActiveItemIdx((activeItemIdx + 1) % itemCount),
                Enter:      () => onSelection(activeItemIdx),
                ShiftLeft:  () => {/* no-op */},
                ShiftRight: () => {/* no-op */},
            };

            if (!(e.code in actions)) {
                setMenuOpen(false);
                return;
            }

            e.preventDefault();
            actions[e.code]();
        };

        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [setMenuOpen, onSelection, activeItemIdx, items]);

    return (
        <div
            ref={ref}
            style={{
                background: 'white',
                border: '1px solid lightgray',
            }}
            children={
                <div>
                    {items.map(({ label, icon }, idx) => {
                        const active = idx === activeItemIdx;
                        return (
                            <Suggestion
                                key={label}
                                content={label}
                                icon={icon}
                                active={active}
                                query={query}
                                onHover={() => setActiveItemIdx(idx)}
                                onClick={() => onSelection(idx)}
                            />
                        );
                    })}
                </div>
            }
        />
    );
});

export default SuggestionsMenu;