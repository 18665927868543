import React from 'react';
import { createPortal } from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const dndPortal = document.createElement('div');
document.body.appendChild(dndPortal);

function PortalAwareItem({ provided, snapshot, children }) {
    const usePortal = snapshot.isDragging;

    const child = (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            {children}
        </div>
    );

    if (!usePortal) {
        return child;
    }

    // if dragging - put the item in a portal
    return createPortal(child, dndPortal);
}

export const DragAndDropZone = ({ children, droppableId, ...other }) => {
    return (
        <DragDropContext {...other}>
            <Droppable droppableId={droppableId}>
                {provided => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export const DraggableItem = ({ id, index, children, isDragDisabled }) => {
    return (
        <Draggable
            key={id}
            draggableId={id}
            index={index}
            isDragDisabled={isDragDisabled}
        >
            {(provided, snapshot) => (
                <PortalAwareItem
                    provided={provided}
                    snapshot={snapshot}
                >
                    {children}
                </PortalAwareItem>
            )}
        </Draggable>
    );
};