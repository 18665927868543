import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/feature-flags/api`);

export function getAllFlags() {
    return client.getResource('/getAllFlags');
}

export function getFlagValue(flag) {
    return client.getResource(`/getFlagValue/${flag}`);
}

export function toggleFlag(flagName) {
    return client.postResource(`/toggleFlag/${flagName}`);
}