import React, {Component} from 'react';

class NodeDataStatus extends Component {
    getData () {
        if (!this.statusType) {
            return {};
        }
        return {
            statusType: this.statusType.value
        };
    }

    render () {
        const {statusType} = this.props.node.data;
        return (
            <select ref={s => this.statusType = s} defaultValue={statusType}>
                <option value=''>-</option>
                <option value='StatusKlasseA'>Klasse A</option>
                <option value='StatusKlasseAOprettelse'>Klasse A - Oprettelse</option>
            </select>
        );
    }
}

export default NodeDataStatus;