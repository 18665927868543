import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import payment from 'http/payment';
import TaxYearPurchaseMatrice from '../TaxYearPurchaseMatrice';

class PurchaseStats extends Component {
    state = {
        loading: true,
        billEntries: [],
    };

    componentDidMount = async () => {
        const billEntries = await payment.getAccountantBillEntries(this.props.uid);

        this.setState({
            loading: false,
            billEntries,
        });
    };

    render = () => {
        const { loading, billEntries } = this.state;
        if (loading) {
            return <Loader active inline='centered' />
        }

        return <TaxYearPurchaseMatrice purchases={billEntries} />;
    };
}

export default PurchaseStats;