import React from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';

class PaymentRow extends React.Component {
    checkboxClicked = () => {
        const { id, add, remove, checked } = this.props;
        if (checked) {
            remove(id);
        } else {
            add(id);
        }
    };

    render () {
        const { name, checked } = this.props;
        return (
            <Segment secondary={!checked}>
                <span>{name}</span>
                <Checkbox
                    style={{ float: 'right' }}
                    checked={checked}
                    onChange={this.checkboxClicked}
                    toggle
                />
            </Segment>
        );
    }
}

class OnetimePaymentEditor extends React.Component {
    render () {
        const {
            onetimePayments,
            pickedPayments,
            addOnetimePayment,
            removeOnetimePayment
        } = this.props;
        return (
            <Segment.Group>
                    {
                        onetimePayments.map(otp => {
                            const { name, id } = otp;
                            const isChecked = pickedPayments.includes(id);
                            return <PaymentRow 
                                checked={isChecked}
                                name={name}
                                id={id}
                                key={id}
                                add={addOnetimePayment}
                                remove={removeOnetimePayment}
                            />;
                        })
                    }
            </Segment.Group>
        );
    }
}

export default OnetimePaymentEditor;