import { Client } from './client';
import { API_LOCATION } from '../config';

const templatesClient = new Client(`${API_LOCATION}/report-editor/templates`);
const exportedTemplatesClient = new Client(`${API_LOCATION}/report-editor/exported-templates`);

class ReportEdtiorClient {
    testTemplate = ({ variables, userContext, template }) => {
        return templatesClient.postResource('/test', {
            variables,
            userContext,
            template,
        });
    };

    getTemplates = () => {
        return templatesClient.getResource('/');
    };

    getTemplate = (id) => {
        return templatesClient.getResource(`/${id}`);
    };

    createTemplate = (name) => {
        return templatesClient.postResource('/', {
            metadata: {
                name,
            },
        });
    };

    updateTemplate = (id, template) => {
        return templatesClient.putResource(`/${id}`, template);
    };

    deleteTemplate = (id) => {
        return templatesClient.deleteResource(`/${id}`);
    };
    
    copyTemplate = (id) => {
        return templatesClient.postResource(`/${id}/copy`);
    };

    exportTemplate = (id, description) => {
        return templatesClient.postResource(`/${id}/export`, {
            description,
        });
    };

    nextTemplateVersion = (id) => {
        return exportedTemplatesClient.getResource(`/${id}/next-version`);
    };

    getExportedTemplateVersions = (id) => {
        return exportedTemplatesClient.getResource(`/${id}/versions`);
    };
}

const reportEditor = new ReportEdtiorClient();

export default reportEditor;