const LOCALE_DK = 'da-DK';

function zeroPad (toPad, minDigits = 2) {
    toPad = toPad.toString();
    const zeroesNeeded = Math.max(minDigits - toPad.length, 0);
    return '0'.repeat(zeroesNeeded) + toPad;
}

function doFormatUnix(unix, locale, formattingOptions) {
    const asDate = new Date(unix);
    const date = asDate.toLocaleDateString(locale, formattingOptions);
    const time = asDate.toLocaleTimeString(locale);
    return `${date} ${time}`;
}

export function formatUnix (unix, locale = LOCALE_DK) {
    return doFormatUnix(unix, locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
}

export function formatUnixShort (unix, locale = LOCALE_DK) {
    return doFormatUnix(unix, locale, {
        month: 'short',
        day: 'numeric',
    });
}

export function localeDate (date, locale = LOCALE_DK) {
    return new Date(date).toLocaleDateString(locale);
}

export function localeTime (date, locale = LOCALE_DK) {
    let localeTime = new Date(date).toLocaleTimeString(locale);
    localeTime = localeTime.substring(0, localeTime.length - 3); // skip seconds
    if(localeTime[0] === '0') {
        localeTime = localeTime.substring(1, localeTime.length); // skip leading zero
    }
    return localeTime;
}

export function date2ISO8601 (date) {
    const day = zeroPad(date.getDate());
    const month = zeroPad(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

export function todayMidnight () {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
}

export function firstDayOfMonth () {
    const out = todayMidnight();
    out.setDate(1);
    return out;
}

function stampDiff (a, b, denom) {
    return Math.floor((b - a) / denom);
}

export function daysBetween (a, b) {
    return stampDiff(a, b, 1000 * 60 * 60 * 24);
}

export function hoursBetween (a, b) {
    return stampDiff(a, b, 1000 * 60 * 60);
}

export function minutesBetween (a, b) {
    return stampDiff(a, b, 1000 * 60);
}

export function secondsBetween (a, b) {
    return stampDiff(a, b, 1000);
}

export function stampDescription (stamp, lang = 'da') {
    const i18n = {
        siden: {
            da: 'siden',
            en: 'ago',
        },
        ligeNu: {
            da: 'Lige nu',
            en: 'Right now',
        },
        sekund: {
            da: 'sekund',
            en: 'second',
        },
        sekunder: {
            da: 'sekunder',
            en: 'seconds',
        },
        minut: {
            da: 'minut',
            en: 'minute'
        },
        minutter: {
            da: 'minutter',
            en: 'minutes'
        },
        time: {
            da: 'time',
            en: 'hour'
        },
        timer: {
            da: 'timer',
            en: 'hours'
        },
        dag: {
            da: 'dag',
            en: 'day'
        },
        dage: {
            da: 'dage',
            en: 'days'
        },
    };

    for (let [key, value] of Object.entries(i18n)) {
        i18n[key] = value[lang];
    }

    stamp = new Date(stamp).getTime();
    const now = Date.now();
    const since = (value, suffix) => `${value} ${suffix} ${i18n.siden}`;
    // Sekunder
    const secDiff = secondsBetween(stamp, now);
    if (secDiff === 0) {
        return i18n.ligeNu;
    }
    if (secDiff === 1) {
        return since(1, i18n.sekund);
    }
    if (secDiff <= 59) {
        return since(secDiff, i18n.sekunder);
    }
    // Minutter
    const minDiff = minutesBetween(stamp, now);
    if (minDiff === 1) {
        return since(1, i18n.minut);
    }
    if (minDiff <= 59) {
        return since(minDiff, i18n.minutter);
    }
    // Timer
    const hourDiff = hoursBetween(stamp, now);
    if (hourDiff === 1) {
        return since(1, i18n.time);
    }
    if (hourDiff <= 23) {
        return since(hourDiff, i18n.timer);
    }
    // Dage
    const dayDiff = daysBetween(stamp, now);
    if (dayDiff === 1) {
        return since(1, i18n.dag);
    }
    if (dayDiff <= 30) {
        return since(dayDiff, i18n.dage);
    }
    return formatUnix(stamp);
}

export const monthNamesDK = [
    'Januar',
    'Februar',
    'Marts',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'December',
];