import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MLTest from './MLTest';
import MappingTable from './MappingTable';

const MachineLearning = () => {
    return (
        <Switch>
            <Route path='/machine-learning/test' component={MLTest} />
            <Route path='/machine-learning/mapping' component={MappingTable} />
            <Redirect from='/machine-learning' to='/machine-learning/test' />
        </Switch>
    );
};

export default MachineLearning;