import React, { Component } from 'react';
import { Card, Header, Icon, Label, Loader, Segment, Table } from 'semantic-ui-react';
import payment from 'http/payment';
import productsHTTP from 'http/products';
import { formatNumber } from 'shared/formatNumber';
import { monthNamesDK } from 'shared/dateTimeFormatting';

const OverviewCard = ({ header, icon, value }) => (
    <Card color='green'>
        <Card.Content>
            <br />
            <Card.Header>
                <Header as='h3' icon textAlign='center'>
                    <Icon name={icon} style={{ fontSize: '2em' }} color='green' />
                    <Header.Content>{header}</Header.Content>
                </Header>
            </Card.Header>
        </Card.Content>
        <Card.Content textAlign='center'>
            <Header>{value}</Header>
        </Card.Content>
    </Card>
);

class Overview extends Component {
    state = {
        loading: true,
        totalSubscriptions: 0,
        products: [],
        subscriptionStats: {},
        renewalCalendar: [],
    };

    componentDidMount = async () => {
        await Promise.all([
            this.fetchProducts(),
            this.fetchRenewalCalendar(),
            this.countTotalSubscriptions(),
            this.countTotalSubscriptionsDistinct(),
        ]);

        this.setState({ loading: false });
    };

    fetchProducts = async () => {
        const resp = await productsHTTP.getActiveProducts();
        if (!resp.success) {
            throw new Error(resp.message);
        }
        this.setState({ products: resp.data });
    };

    fetchRenewalCalendar = async () => {
        const renewalCalendar = await payment.getRenewalCalendar();
        this.setState({ renewalCalendar });
    };
    
    countTotalSubscriptions = async () => {
        const totalSubscriptions = await payment.countSubscriptions();
        this.setState({ totalSubscriptions });
    };

    countTotalSubscriptionsDistinct = async () => {
        const subscriptionStats = await payment.countSubscriptionsDistinct();
        this.setState({ subscriptionStats });
    };

    renderHeader = ({ header, subheader, icon }) => (
        <Header as='h2'>
            <Icon name={icon} />
            <Header.Content>
                {header}
            {subheader && <Header.Subheader content={subheader} />}
            </Header.Content>
        </Header>
    );

    renderSubscriptionCards = () => {
        const { subscriptionStats, products } = this.state;
        const itemsPerRow = Math.min(5, Object.keys(subscriptionStats).length);

        const cards = Object.entries(subscriptionStats).map(([productID, count]) => {
            const product = products.find(p => p.id === productID);
            return (
                <OverviewCard
                    header={product.descriptiveName || product.name}
                    icon={product.icon}
                    value={formatNumber(count)}
                />
            );
        });

        return (
            <Card.Group itemsPerRow={itemsPerRow}>
                {cards}
            </Card.Group>
        );
    };
    
    renderSubscriptions = () => {
        return <Segment>
            {this.renderHeader({
                header: 'Abonnementer',
                icon: 'redo',
                subheader: (
                    <span>
                        <strong>{this.state.totalSubscriptions}</strong> i alt
                    </span>
                ),
            })}
            {this.renderSubscriptionCards()}
        </Segment>
    };

    renderRenewalCalendar = () => {
        const { renewalCalendar } = this.state;

        const monthHeadersAdded = new Set();

        return <Segment>
            {this.renderHeader({
                header: 'Fornyelseskalender',
                subheader: 'Fordeling af fornyelser i løbet af året',
                icon: 'redo',
            })}
            <Table>
                <Table.Body>
                    {renewalCalendar.map(({ day, month, productID, productName, icon, count }) => {
                        let header;

                        const monthName = monthNamesDK[month - 1];

                        // only add header, if not encountered before
                        if (!monthHeadersAdded.has(month)) {
                            header = (
                                <Table.Row active key={month}>
                                    <Table.Cell colspan='3'>
                                        <Header>
                                            <strong>{monthName}</strong>
                                        </Header>
                                    </Table.Cell>
                                </Table.Row>
                            );
                            monthHeadersAdded.add(month);
                        }

                        return <>
                            {header}
                            <Table.Row verticalAlign='middle' key={productID}>
                                <Table.Cell>
                                    <Label basic circular size='large'>
                                        <Icon name={icon} />
                                        {productName}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <Label basic circular size='large'>
                                        <Icon name={'calendar'} />
                                        {day}. {monthName.toLowerCase()}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <Label basic circular size='large'>
                                        <Icon name={'users'} />
                                        {count} gentegning{count !== 1 ? 'er' : ''}
                                    </Label>
                                </Table.Cell>
                            </Table.Row>
                        </>;
                    })}
                </Table.Body>
            </Table>
        </Segment>;
    };

    renderOverview = () => {        
        return <>
            {this.renderSubscriptions()}
            {this.renderRenewalCalendar()}
        </>;
    };

    render = () => {
        if (this.state.loading) {
            return <Loader
                active
                inline='centered'
                size='huge'
            />;
        }

        return this.renderOverview();
    };
}

export default Overview;