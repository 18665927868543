import React, { Component } from 'react';
import { List, Popup, Modal, Button } from 'semantic-ui-react';
import styles from './EventList.module.css';

const JSONView = (src) => {
    return <pre>{JSON.stringify(src, null, '    ')}</pre>;
};

const EVENTS = {
    ACTION_NOT_READY: 'ACTION_NOT_READY',
    SET_FACT: 'SET_FACT',
    ACTION_COMPLETED: 'ACTION_COMPLETED'
};

class EventList extends Component {
    state = { modal: false, selectedEvent: null };

    renderSetFactEvent = eventData => {
        if (eventData.length > 100) {
            return <i>Size of data is too big to show</i>;
        }
        return <span>{eventData}</span>;
    };

    renderActionNotReadyEvent = eventData => {
        const { name } = this.props.nodes.find(n => n.id === eventData);
        return (
            <span>
                Mangler fra noden: <i>{name}</i>
            </span>
        );
    };

    renderActionCompletedEvent = eventData => {
        const { actionName, timeTaken } = eventData;
        return (
            <div>
                <p>Type: {actionName}</p>
                <p>Time taken: {timeTaken} ms</p>
            </div>
        );
    };

    renderOverviewData = event => {
        const { eventType, eventData } = event;
        switch (eventType) {
            case EVENTS.SET_FACT:
                return this.renderSetFactEvent(eventData);
            case EVENTS.ACTION_NOT_READY:
                return this.renderActionNotReadyEvent(eventData);
            case EVENTS.ACTION_COMPLETED:
                return this.renderActionCompletedEvent(eventData);
            default:
                return eventData;
        }
    };

    hasModalContent = eventType => {
        return [EVENTS.SET_FACT, EVENTS.ACTION_COMPLETED].includes(eventType);
    };

    renderListItem = event => {
        const { icon, name, nodeId, eventType, eventData } = event;

        const onClick = () => {
            if (this.hasModalContent(eventType)) {
                this.setState({ modal: true, selectedEvent: event });
            }
        };
        const onMouseEnter = () => this.props.onMouseEnter(nodeId);
        const onMouseLeave = () => this.props.onMouseLeave(nodeId);

        const listHeader = <List.Header>{name} ({eventType})</List.Header>;
        const listHeaderPopup = (
            <Popup
                trigger={listHeader}
                position='right center'
            >
                {this.renderOverviewData(event)}
            </Popup>
        );

        return (
            <List.Item
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
                className={styles.eventElement}
            >
                <List.Icon
                    name={icon}
                    size='large'
                />
                <List.Content verticalAlign='middle'>
                    { eventData ? listHeaderPopup : listHeader }
                </List.Content>
            </List.Item>
        );
    };

    getModalJSON = event => {
        switch (event.eventType) {
            case EVENTS.SET_FACT:
                return JSON.parse(event.eventData);
            case EVENTS.ACTION_COMPLETED:
                return JSON.parse(event.eventData.actionResult);
            default:
                return {};
        }
    };

    renderResultModal = () => {
        const event = this.state.selectedEvent;
        if (!event) return null;

        const modalJSON = this.getModalJSON(event);

        return (
            <Modal open={this.state.modal}>
                <Modal.Header>
                    {event.name} ({event.eventType})
                </Modal.Header>
                <Modal.Content>
                    <JSONView
                        displayDataTypes={false}
                        src={modalJSON}
                        collapsed={true}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content='Luk'
                        onClick={() => this.setState({ modal: false })}
                    />
                </Modal.Actions>
            </Modal>
        );
    };

    render () {
        return (
            <div>
                { this.renderResultModal() }
                <List divided relaxed>
                    { this.props.events.map(this.renderListItem) }
                </List>
            </div>
        );
    }
}

export default EventList;