import React, { useState } from 'react';
import { Segment, Container, Header, Button, Form, Input, Table, Message } from 'semantic-ui-react';
import { alert } from 'shared/globalModal';
import { date2ISO8601 } from 'shared/dateTimeFormatting';
import { formatNumber } from 'shared/formatNumber';
import DatePicker from 'atoms/DatePicker';
import http from 'http/cvr';

function Sammenligningstal () {
    const [ cvr, setCvr ] = useState();
    const [ date, setDate ] = useState();
    const [ working, setWorking ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ sammenligningstal, setSammenligningstal ] = useState();

    const fetctSammenligningstal = async () => {
        if (!cvr) {
            return alert('Indtast et CVR-nummer');
        }
        if (!date) {
            return alert('Vælg en dato');
        }
        const isoDate = date2ISO8601(new Date(date));
        setWorking(true);
        setError(false);
        try {
            const resp = await http.getSammenligningstal(cvr, isoDate);
            setSammenligningstal(resp);
        } catch {
            setError(true);
        }
        setWorking(false);
    };

    const renderError = () => {
        return error && <Message
            error
            content='Sammenligningstal kunne ikke hentes'
            icon='warning'
        />
    };

    const renderSammenligningstal = () => {
        if (!sammenligningstal) {
            return;
        }

        const tagsJSX = Object.entries(sammenligningstal).map(([ tag, value ]) => {
            return <Table.Row>
                <Table.Cell>{tag}</Table.Cell>
                <Table.Cell textAlign='right'>{formatNumber(value)}</Table.Cell>
            </Table.Row>;
        });

        return <Table striped>
            <Table.Header>
                <Table.HeaderCell>XBRL-tag</Table.HeaderCell>
                <Table.HeaderCell>Værdi</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {tagsJSX}
            </Table.Body>
        </Table>
    };

    return <Container as={Segment}>
        <Header>Sammenligningstal</Header>
        <Form>
            <Form.Field disabled={working}>
                <label>CVR</label>
                <Input
                    defaultValue={cvr}
                    onChange={(_, { value }) => setCvr(value)}
                />
            </Form.Field>
            <Form.Field disabled={working}>
                <label>Dato</label>
                <DatePicker
                    value={date}
                    onChange={setDate}
                />
            </Form.Field>
            <Form.Field disabled={working}>
                <Button
                    content='Hent tal'
                    icon='download'
                    primary
                    loading={working}
                    onClick={fetctSammenligningstal}
                />
            </Form.Field>
        </Form>
        {renderSammenligningstal()}
        {renderError()}
    </Container>;
}

export default Sammenligningstal;