import React from 'react';
import { dataTypes } from 'model-editor/nodeMetadata';
import NumberQuestion from './NodeDataQuestionSubtypes/NumberQuestion';
import DateQuestion from './NodeDataQuestionSubtypes/DateQuestion';
import RadioQuestion from './NodeDataQuestionSubtypes/RadioQuestion';
import ToggleQuestion from './NodeDataQuestionSubtypes/ToggleQuestion';
import MultilineQuestion from './NodeDataQuestionSubtypes/MultilineQuestion';
import './NodeDataRuleSwitch.css';

class NodeDataQuestion extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            hasDefaultValue: false,
            defaultValue: '',
        };
        this.subQuestionData = {};
    }

    componentDidMount () {
        const { hasDefaultValue, defaultValue } = this.props.node.data;


        this.setState({
            hasDefaultValue,
            defaultValue,
        });
    }

    getData () {
        const { hasDefaultValue, defaultValue } = this.state;
        let subQuestionData = {};
        if (this.data) {
            subQuestionData = this.data.getData();
        }
        return {
            ...subQuestionData,
            hasDefaultValue,
            defaultValue
        };
    }

    getSubQuestionSection () {
        const {node, nodes} = this.props;
        const {dataType} = node;
        switch (dataType) {
            case dataTypes.NUMBER:
                return <NumberQuestion ref={data => this.data = data} node={node}nodes={nodes} />
            case dataTypes.DATE:
                return <DateQuestion ref={data => this.data = data} node={node} nodes={nodes}/>;
            case dataTypes.BOOLEAN:
                return <ToggleQuestion ref={data => this.data = data} node={node} nodes={nodes}/>;
            case dataTypes.ENUM_STRING:
                return <RadioQuestion ref={data => this.data = data} node={node} nodes={nodes}/>;
            case dataTypes.STRING:
                return <MultilineQuestion ref={data => this.data = data} node={node} nodes={nodes}/>;
            default:
                return null;
        }
    }

    defaultValueChanged (e) {
        let editorValue = e.target.value;
        this.setState({
            defaultValue: editorValue.toString()
        });
    }

    getDefaultValueSection () {
        const {defaultValue, hasDefaultValue} = this.state;
        if (!hasDefaultValue) {
            return null;
        }

        const {dataType} = this.props.node;
        switch (dataType) {
            case dataTypes.ENUM_STRING:
            case dataTypes.STRING:
                return (
                    <div>
                        <input defaultValue={defaultValue} onChange={e => this.defaultValueChanged(e)}/>
                    </div>
                );
            case dataTypes.NUMBER:
                return (
                    <div>
                        <input type='number' defaultValue={defaultValue} onChange={e => this.defaultValueChanged(e)}/>
                    </div>
                );
            case dataTypes.DATE:
                return (
                    <div>
                        <input type='date' defaultValue={defaultValue} onChange={e => this.defaultValueChanged(e)}/>
                    </div>
                );
            case dataTypes.BOOLEAN:
                return (
                    <div>
                        Sand
                        <select defaultValue={defaultValue} onChange={e => this.defaultValueChanged(e)}>
                            <option value=''>-</option>
                            <option value='true'>Sand</option>
                            <option value='false'>Falsk</option>
                        </select>
                    </div>
                );
            default:
                return null;
        }
    }

    render () {
        const {hasDefaultValue} = this.state;
        return (
            <section key={this.props.node.id}>
                {this.getSubQuestionSection()}
                <label>Standardværdi</label>
                &nbsp;
                <input
                    key={hasDefaultValue}
                    type='checkbox'
                    defaultChecked={hasDefaultValue}
                    onChange={e => this.setState({hasDefaultValue: e.target.checked})}
                />
                <br/>
                {this.getDefaultValueSection()}
                <br/>
            </section>
        );
    }
}

export default NodeDataQuestion;
