const erpSystems = [
    {
        id: 'dinero',
        name: 'Dinero',
    },
    {
        id: 'economic',
        name: 'E-conomic',
    },
    {
        id: 'billy',
        name: 'Billy',
    },
    // Uncomment when these ERP integrations are ready for live environment
    // {
    //     id: 'business-central',
    //     name: 'Business Central'
    // }
    {
        id: 'ofinda',
        name: 'Ofinda'
    },
    {
        id: 'uniconta',
        name: 'Uniconta',
    },
    {
        id: 'dynaccount',
        name: 'Dynaccount',
    },
    {
        id: 'xena',
        name: 'Xena',
    },
    {
        id: 'webfinance',
        name: 'Webfinance',
    },
    {
        id: 'manual',
        name: 'Manuel bogføring',
    },
];

export default erpSystems;