import { capitalize } from 'lodash';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
    Button,
    Checkbox,
    Form,
    Icon,
    Input,
    Label,
    Loader,
    Menu,
    Message,
    Popup,
    Segment,
    Table,
} from 'semantic-ui-react';

import taxonomyParser from 'http/taxonomyParser';
import * as fileStorage from 'http/fileStorage';
import { formatNumber } from 'shared/formatNumber';
import { localeDate } from 'shared/dateTimeFormatting';
import { downloadTextData, readAsText } from 'shared/files';
import { consumeQueryParam } from 'shared/queryParams';
import useRestResource from 'shared/hooks/useRestResource';
import FileuploadButton from 'atoms/FileuploadButton';

import parseInstance from './parseInstance';
import formatInstance from './formatInstance';
import formatPeriod from './formatPeriod';
import XBRLTester from './XBRLTester';
import { b64DecodeUnicode } from 'shared/base64';

const chopLongText = (str = '', maxSize = 50) => {
    const out = [];
    let idx = 0;
    while (idx < str.length) {
        out.push(str.substring(idx, idx + maxSize));
        idx += maxSize;
    }
    return (
        <div style={{ whiteSpace: 'pre' }}>
            {out.join('-\n')}
        </div>
    );
};

const doFetchTaxonomy = async taxonomyID => {
    if (!taxonomyID) {
        return null;
    }
    
    const availableTaxonomies = await taxonomyParser.getTaxonomyVersions();

    let taxonomyKnown = true;
    let taxonomyToUse = availableTaxonomies.find(taxonomy => taxonomy.id === taxonomyID);
    if (!taxonomyToUse) {
        taxonomyKnown = false;
        taxonomyToUse = availableTaxonomies.at(-1);
    }

    const [taxonomy, metadata] = await Promise.all([
        taxonomyParser.listTags(taxonomyToUse.id),
        taxonomyParser.getTaxonomyMetadata(taxonomyToUse.id),
    ]);
    
    return {
        taxonomy,
        metadata,
        taxonomyKnown,
    };
};

const htmlDecode = input => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
};

const Unit = ({ children }) => {
    if (!children) return null;

    return (
        <span
            children={children}
            style={{
                opacity: 0.9,
                fontSize: '10px',
                position: 'relative',
                top: '-3px',
                textDecoration: 'underline',
                textDecorationColor: 'gray',
                marginLeft: '0.35em',
            }}
        />
    );
};

const consumeFileIdsFromParams = () => {
    return [
        consumeQueryParam('xbrlFileID'),
        consumeQueryParam('supplementaryXbrlFileID'),
    ]
};

const Taxonomy = () => {
    const containerRef = useRef();
    const [activeTabIdx, setActiveTabIdx] = useState(0);
    const [rawInstance, setRawInstance] = useState('');
    const [rawSupplementaryInstance, setRawSupplementaryInstance] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [showTag, setShowTag] = useState(false);
    const [hideZeroValues, setHideZeroValues] = useState(false);
    const [viewingSupplementaryInstance, setViewingSupplementaryInstance] = useState(false);
    const [error, setError] = useState(null);
    
    const [xbrlFileID, supplementaryXbrlFileID] = consumeFileIdsFromParams();

    const parsedInstance = useMemo(() => {
        const instanceToView = viewingSupplementaryInstance ? rawSupplementaryInstance : rawInstance;
        if (!instanceToView) {
            return {};
        }

        const parsedInstance = parseInstance(instanceToView);

        return formatInstance(parsedInstance);
    }, [rawInstance, rawSupplementaryInstance, viewingSupplementaryInstance]);

    const {
        instance,
        groups,
        groupedFiscalValues,
        periodLabels,
        currentYear,
        lastYear,
    } = parsedInstance;

    useEffect(() => {
        if (!xbrlFileID) {
            return;
        }

        const doFetchXbrlFiles = async () => {
            try {
                
                const xbrlBase64 = await fileStorage.getFileDataAsBase64(xbrlFileID);
                const xbrl = b64DecodeUnicode(xbrlBase64.data);
                
                let supplementaryXbrl;
                if (supplementaryXbrlFileID) {
                    const supplementaryXbrlBase64 = await fileStorage.getFileDataAsBase64(supplementaryXbrlFileID);
                    supplementaryXbrl = b64DecodeUnicode(supplementaryXbrlBase64.data);
                }

                setRawInstance(xbrl);
                setRawSupplementaryInstance(supplementaryXbrl);
            } catch (e) {
                console.error('Error:', e);
                setError(true);
            }
        };

        doFetchXbrlFiles();
    }, [xbrlFileID, supplementaryXbrlFileID]);

    const taxonomy = useRestResource({
        fetcher: doFetchTaxonomy,
        args: [parsedInstance?.instance?.taxonomyID],
    });

    const instanceFileSelected = async file => {
        setError(null);
        try {
            const rawInstanceXML = await readAsText(file);
            setRawInstance(rawInstanceXML);
            setViewingSupplementaryInstance(false);
            setRawSupplementaryInstance('');
        } catch (e) {
            console.error(e);
            setError(e.message);
        }
    };

    const downloadXBRL = () => {
        const xbrlToDownload = viewingSupplementaryInstance ? rawSupplementaryInstance : rawInstance;
        downloadTextData('xbrl.xml', 'text/xml', xbrlToDownload);
    };

    const lookupLabel = tagName => {
        const tag = taxonomy.data.taxonomy.find(xbrlTag => xbrlTag.name === tagName);
        if (!tag) return '';

        return tag.label || '';
    };

    const lookupUnit = unitID => {
        if (!unitID) return '';

        const { units } = instance;
        const unit = units.find(unit => unit.id === unitID);
        if (!unit) return '';

        const measure = unit.measure;
        if (!measure) return '';

        return measure;
    };

    const formatMonetaryValue = (value, decimals) => {
        let amountOfDecimals = Number(decimals);
        if (isNaN(amountOfDecimals)) {
            amountOfDecimals = 0;
        }
        const choppedValue = value.substring(0, value.length - amountOfDecimals  * -1);
        return formatNumber(choppedValue);
    };

    const formatTextSegments = text => {
        const withoutCarriageReturn = text.replace(/\r/g, '');
        return withoutCarriageReturn
            .split('\n')
            .map(x => x.trim())
            .join('\n');
    };

    const formatValue = (value, decimals) => {
        if (decimals) {
            return formatMonetaryValue(value, decimals);
        }
        
        if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
            return localeDate(value);
        }

        const formattedText = formatTextSegments(value);

        return htmlDecode(formattedText);
    };

    const formatUnit = unitRef => {
        const unit = lookupUnit(unitRef);
        
        if (!unit || unit === 'pure') return '';

        return unit;
    };

    const formatValueAndUnit = ({ value = '', unitRef, decimals }) => ({
        formattedValue: formatValue(value, decimals),
        formattedUnit: formatUnit(unitRef),
    });

    const formatScenario = (ctx) => {
        const { scenario, period } = ctx;

        const getScenarioText = () => {
            if (!scenario) {
                return '';
            }

            const memberLabel = lookupLabel(scenario.data.memberTag);
            if (memberLabel) {
                return memberLabel;
            }

            const dimensionLabel = lookupLabel(scenario.dimensionTag);

            let extraText = '';
            if (scenario.data.value) {
                extraText = `: ${scenario.data.value}`;
            }

            return dimensionLabel + extraText;
        };
        
        const texts = [getScenarioText(), periodLabels[formatPeriod(period)]].filter(text => text);
        if (texts.length === 0) {
            return '';
        }

        texts[0] = capitalize(texts[0]);

        return texts.join(', ');
    };

    const getTaxonomyName = () => {
        const { taxonomyKnown, metadata } = taxonomy.data;
        
        if (!taxonomyKnown) {
            return [instance.taxonomyID, 'Ukendt'];
        }

        return [`${metadata.name} ∙ ${instance.taxonomyID}`];
    };

    const getTaxonomyEntrypoint = () => {
        const { taxonomyKnown, metadata } = taxonomy.data;

        if (!taxonomyKnown) {
            return [instance.entrypointUsed, 'Ukendt'];
        }

        const entrypointMetadata = metadata.entryPoints.find(entrypoint => {
            return entrypoint.path.endsWith(instance.entrypointUsed);
        });

        if (!entrypointMetadata) {
            return [instance.entrypointUsed, 'Ukendt'];
        }

        return [entrypointMetadata.name];
    };

    const renderOverview = () => {
        const [{ entity }] = instance.contexts;

        const taxonomyAndEntrypointLabels = [
            ['Taksonomi', getTaxonomyName()],
            ['Opstilling', getTaxonomyEntrypoint()],
        ].map(([label, [value, warning]]) => (
            <Form.Field>
                <label>{label}</label>
                {(
                    warning
                        ? <Label color='red' circular content={warning} />
                        : <Icon color='green' name='check circle' />
                )}
                &nbsp;{value}
            </Form.Field>
        ));

        return (
            <Form>
                {taxonomyAndEntrypointLabels}
                <Form.Field>
                    <label>CVR-nummer</label>
                    <span children={entity} />
                </Form.Field>
                <Form.Field>
                    <label>Regnskabsperiode</label>
                    <span children={currentYear.label} />
                </Form.Field>
                {
                    lastYear && 
                    <Form.Field>
                        <label>Forrig regnskabsperiode</label>
                        <span children={lastYear.label} />
                    </Form.Field>
                }
                
            </Form>
        );
    };

    const renderMember = (tags, memberAttributes) => {
        return (
            <Segment>
                {[...tags].map((namespaceAndTag, idx) => {
                    const tag = namespaceAndTag.split(':').at(-1);
                    const tagLabel = lookupLabel(tag);
                    const memberAttribute = memberAttributes.find(attr => attr.value.tag === tag);
                    const tagMeta = taxonomy.data.taxonomy.find(xbrlTag => xbrlTag.name === tag);
                    const memberValue = memberAttribute?.value?.value;

                    let valueToRender;
                    if (tagMeta.type === 'xbrli:monetaryItemType') {                        
                        valueToRender = formatMonetaryValue(memberValue);
                    } else {
                        valueToRender = memberValue || '<ingen værdi>';
                    }

                    return (
                        <div
                            style={{
                                marginBottom: idx < tags.size - 1 && '1em',
                            }}
                        >
                            <strong>{tagLabel}:</strong>
                            <br />
                            {valueToRender}
                        </div>
                    );
                })}
            </Segment>
        );
    };

    const renderGroups = () => {
        return (
            <Form>
                {Object.values(groups).map(
                    ({ members, tags, dimensionTag }) => {
                        const label = lookupLabel(dimensionTag);
                        return (
                            <Form.Field>
                                <label>{label}</label>
                                <Segment.Group>
                                    {Object.values(members).map(memberAttributes => {
                                        return renderMember(tags, memberAttributes);
                                    })}
                                </Segment.Group>
                            </Form.Field>
                        );
                    }
                )}
            </Form>
        );
    };

    const renderFiscalValues = (showTexts) => {
        return (
            <Table celled striped>
                <Table.Header
                    style={{ position: 'sticky', top: '50px', zIndex: 1 }}
                >
                    <Table.Row>
                        <Table.HeaderCell width='1'>Kategori</Table.HeaderCell>
                        <Table.HeaderCell width='2'>Tag</Table.HeaderCell>
                        <Table.HeaderCell>Kontekst</Table.HeaderCell>
                        <Table.HeaderCell>Værdi</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell colSpan='4'>
                            <Input
                                onChange={(_, { value }) => setSearchQuery(value)}
                                defaultValue={searchQuery}
                                icon='search'
                                iconPosition='left'
                                placeholder='Søg...'
                            />
                            &nbsp;
                            &nbsp;
                            <Checkbox
                                defaultChecked={showTag}
                                onChange={(_, { checked }) => setShowTag(checked)}
                                label='Vis tag i stedet for label?'
                                style={{ fontWeight: 'normal' }}
                            />
                            {
                                !showTexts &&
                                <Checkbox
                                    defaultChecked={hideZeroValues}
                                    onChange={(_, { checked }) => setHideZeroValues(checked)}
                                    label='Skjul 0-værdier?'
                                    style={{ fontWeight: 'normal', marginLeft: '1em' }}
                                />
                            }
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {instance.fiscalData.map(data => {
                        if (groupedFiscalValues.has(data.id)) {
                            return null;
                        }

                        const valueHasDecimals = !!data.decimals;
                        if (showTexts === valueHasDecimals) {
                            return null;
                        }

                        const { formattedUnit, formattedValue } = formatValueAndUnit(data);
                        if (formattedValue === '0' && hideZeroValues) {
                            return null;
                        }

                        const formattedScenario = formatScenario(instance.contexts.find(ctx => ctx.id === data.contextRef));
                        const label = lookupLabel(data.tag);

                        const trimmedQuery = searchQuery.trim();
                        if (trimmedQuery) {
                            const checks = [
                                data.namespace,
                                data.tag,
                                data.value,
                                formattedUnit,
                                formattedValue,
                                formattedScenario,
                                label,
                            ];

                            const foundMatch = checks.some(text => {
                                return text?.toLowerCase()?.includes(trimmedQuery.toLowerCase());
                            });

                            if (!foundMatch) {
                                return null;
                            }
                        }

                        return (
                            <Table.Row verticalAlign={showTexts ? 'top' : 'middle'} key={data.id}>
                                <Table.Cell width='1'>
                                    {data.namespace.toUpperCase()}
                                </Table.Cell>
                                <Table.Cell width='2'>
                                    {showTag && chopLongText(data.tag)}
                                    {!showTag && <Popup
                                        inverted
                                        position='top center'
                                        content={data.tag}
                                        trigger={<span>{label}</span>}
                                    />}
                                </Table.Cell>
                                <Table.Cell>
                                    {formattedScenario}
                                </Table.Cell>
                                <Table.Cell
                                    textAlign={valueHasDecimals ? 'right' : 'left'}
                                    style={{ whiteSpace: 'pre-wrap' }}
                                >
                                    <span dangerouslySetInnerHTML={{ __html: formattedValue }} />
                                    <Unit children={formattedUnit} />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    const renderXBRLTester = () => {
        return <XBRLTester xbrl={rawInstance} supplementaryXbrl={rawSupplementaryInstance} />;
    };

    const tabs = [
        {
            title: 'Overblik',
            icon: 'search',
            render: () => renderOverview(),
        },
        {
            title: 'Identifikationer',
            icon: 'users',
            render: () => renderGroups(),
        },
        {
            title: 'Tekster mm.',
            icon: 'font',
            render: () => renderFiscalValues(true),
        },
        {
            title: 'Regnskab',
            icon: 'calculator',
            render: () => renderFiscalValues(false),
        },
        {
            title: 'Test',
            icon: 'check square',
            render: () => renderXBRLTester(),
        },
    ];

    const renderLoader = () => (
        <Loader
            inline='centered'
            size='big'
            active
        />
    );

    const renderError = err => (
        <Message
            content={err}
            error
        />
    );

    const renderContent = () => {
        const instanceLoaded = Object.keys(parsedInstance).length > 0;

        if (error)            return renderError(error);
        if (taxonomy.error)   return renderError(taxonomy.error);
        if (taxonomy.loading) return renderLoader();
        if (!instanceLoaded)  return <span>Ingen XBRL-instans indlæst</span>;
        if (!taxonomy.data)   return renderLoader();

        return tabs[activeTabIdx].render();
    };

    return (
        <div style={{ margin: '1em' }} ref={containerRef}>
            <Menu
                attached='top'
                style={{ position: 'sticky', top: '0px', zIndex: 1 }}
            >
                {tabs.map((tab, tabIdx) => {
                    return (
                        <Menu.Item
                            icon={tab.icon}
                            content={tab.title}
                            key={tab.title}
                            active={tabIdx === activeTabIdx}
                            onClick={() => {
                                setActiveTabIdx(tabIdx);
                                containerRef.current.scrollIntoView();
                            }}
                        />
                        
                    );
                })}
                <Menu.Menu position='right'>
                    {
                        rawSupplementaryInstance &&
                        <Menu.Item>
                            <Checkbox
                                label='Vis supplerende instans'
                                defaultChecked={viewingSupplementaryInstance}
                                onChange={(_, { checked }) => setViewingSupplementaryInstance(checked)}
                            />
                        </Menu.Item>
                    }
                    {
                        (rawInstance || rawSupplementaryInstance) && (
                            <Menu.Item>
                                <Button
                                    primary
                                    content='Download XBRL'
                                    icon='download'
                                    onClick={downloadXBRL}
                                />
                            </Menu.Item>
                        )
                    }
                    <Menu.Item>
                        <FileuploadButton
                            accept={['xml']}
                            content='Upload XBRL'
                            icon='upload'
                            onChange={instanceFileSelected}
                        />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Segment attached='bottom'>
                {renderContent()}
            </Segment>
        </div>
    );
};

export default Taxonomy;
