import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/internal-tasks`);

export function listPipelines() {
    return client.getResource('/pipelines');
}

export function getPipeline(pipelineID) {
    return client.getResource(`/pipelines/${pipelineID}`);
}

export function moveTask(taskID, swimlaneID, index) {
    return client.postResource('/pipelines/move-task', {
        taskID,
        swimlaneID,
        index,
    });
}

export function getTask(taskID) {
    return client.getResource(`/tasks/${taskID}`);
}

export function flagTaskAsSeen(taskID) {
    return client.putResource(`/tasks/${taskID}/seen`);
}

export function subscribePush(data) {
    return client.postResource(`/push`, data);
}

export function unsubscribePush(data) {
    return client.deleteResource(`/push`, data);
}