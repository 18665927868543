import { Client } from '../client';
import { API_LOCATION } from '../../config';
import modelsClient from './modelsClient';
import productsClient from './productsClient';

const client = new Client(`${API_LOCATION}/model-editor/api`);

function getMLVersions() {
    return client.getResource(`/ml-versions`);
}

function getCalculationFunctions() {
    return client.getResource(`/calcfuncs`);
}

function getViews() {
    return client.getResource('/views');
}

const modelEditor = {
    ...modelsClient,
    ...productsClient,
    getCalculationFunctions,
    getMLVersions,
    getViews,
};

export default modelEditor;