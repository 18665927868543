import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';

/* A wrapper component around Semantic Dropdown component that aims to standardize event propagation */
class _Dropdown extends Component {
    render () {
        return <Dropdown {...this.props} onChange={(_, data) => {
            const { onChange } = this.props;
            if(!onChange) {
                return;
            }
    
            const newSelectedValue = data.value;
            const standardEvent = {
                target: {
                    value: newSelectedValue
                }
            };
            onChange(standardEvent);
        }}/>;
    }
}

export default _Dropdown;
