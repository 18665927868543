import React from 'react';
import lodash from 'lodash';
import { Checkbox, Input } from 'semantic-ui-react';

class NodeDataTagActive extends React.Component {
    state = {
        tagToCheck: '',
        checkLastYear: false,
    };

    componentDidMount = () => {
        this.setState({
            tagToCheck: lodash.get(this.props, 'node.data.tagToCheck', ''),
            checkLastYear: lodash.get(
                this.props,
                'node.data.checkLastYear',
                false
            ),
        });
    };

    getData() {
        return this.state;
    }

    render() {
        return (
            <div>
                <label>
                    Angiv tag at tjekke
                    <br />
                    <Input
                        fluid
                        value={this.state.tagToCheck}
                        onChange={(_, { value }) =>
                            this.setState({ tagToCheck: value.trim() })
                        }
                    />
                    <br />
                    <Checkbox
                        checked={this.state.checkLastYear}
                        onChange={(_, { checked }) =>
                            this.setState({ checkLastYear: checked })
                        }
                    />
                    <span
                        className='noselect'
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            this.setState({
                                checkLastYear: !this.state.checkLastYear,
                            })
                        }
                    >
                        <span style={{ position: 'relative', top: '-3px' }}>
                            &nbsp;Tjek sidste års værdi?
                        </span>
                    </span>
                </label>
            </div>
        );
    }
}

export default NodeDataTagActive;
