import React, { useEffect, useRef, useState } from 'react';
import { Icon, Input, List } from 'semantic-ui-react';
import * as util from './mlUtil';

import styles from './MappingTable.module.css';

const TagSearcher = ({ open, allowedTags, onPick }) => {
    const [pivot, setPivot] = useState('');
    const [didSearch, setDidSearch] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [selectionIndex, setSelectionIndex] = useState(0);
    const searchRef = useRef();

    useEffect(() => {
        if (!open) {
            return;
        }

        searchRef.current.inputRef.current.focus();
    }, [open]);

    useEffect(() => {
        if (!pivot || pivot.length <= 1) {
            setSuggestions([]);
            setDidSearch(false);
            return;
        }

        const suggestions = (
            [...allowedTags]
            .filter(tag => tag.toLowerCase().includes(pivot.toLowerCase()))
            .splice(0, 3)
        )

        setDidSearch(true);
        setSuggestions(suggestions);
        setSelectionIndex(0);
    }, [pivot, allowedTags]);

    const pickHappened = value => {
        searchRef.current.inputRef.current.value = '';
        setPivot('');
        setDidSearch(false);
        setSuggestions([]);
        setSelectionIndex(0);
        onPick(value);
    };

    const updateSelectionIndex = newIndex => {
        if (newIndex < 0) {
            return;
        }

        if (newIndex >= suggestions.length) {
            return;
        }

        setSelectionIndex(newIndex);
    };

    const handleKeyPress = e => {
        if (!didSearch) {
            return;
        }

        switch (e.keyCode) {
            case 38: // up
                updateSelectionIndex(selectionIndex - 1);
                return;
            case 40: // down
                updateSelectionIndex(selectionIndex + 1);
                return;
            case 13: // enter
                e.preventDefault();
                suggestions[selectionIndex] && pickHappened(suggestions[selectionIndex]);
                return;
            default:
        }
    };

    return (
        <>
            <Input
                ref={searchRef}
                placeholder='Search for tag...'
                icon='search'
                fluid
                onChange={(_, { value }) => setPivot(value)}
                onKeyDown={handleKeyPress}
            />
            {
                didSearch &&
                <List>
                    {suggestions.map((s, idx) => {
                        const style = {
                            opacity: idx === selectionIndex ? 1 : 0.50,
                        };

                        return (
                            <List.Item
                                key={s}
                                title={s}
                                children={
                                    <span style={style} onMouseEnter={() => setSelectionIndex(idx)}>
                                        <Icon name={idx === selectionIndex ? 'pointing right' : undefined} />
                                        {util.trunc(s)}
                                    </span>
                                }
                                className={styles.tagOption}
                                onClick={() => pickHappened(s)}
                                icon='x'
                            />
                        );
                    })}
                    {
                        didSearch && suggestions.length === 0 &&
                        <List.Item>
                            No results returned...
                        </List.Item>
                    }
                </List>
            }
        </>
    );
};

export default TagSearcher;