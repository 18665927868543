export function formatNumber(inpNum, showDecimals = false) {
    if (isNaN(inpNum)) {
        return inpNum;
    }
    try {
        const asInt = Math.trunc(inpNum);
        let decimals = '';
        if (showDecimals) {
            if (inpNum === 0) {
                decimals = ',00';
            } else {
                decimals = ',' + (inpNum % 1).toFixed(2).split('.')[1];
            }
        }

        const digits = (asInt + '').split('').reverse();
        let res = '';
        for (var i = 0; i < digits.length; ++i) {
            res = digits[i] + res;
            if (i % 3 === 2 && i < digits.length - 1) {
                res = "." + res;
            }
        }
        if (res.startsWith('-.')) {
            res = res.replace('-.', '-')
        }
        return res + decimals;
    } catch (err) {
        return inpNum;
    }
}