import React from 'react';
import AugmentedTextarea from 'molecules/AugmentedTextarea';

const FormulaInput = ({ value, onChange, placeholder, documentation = [], referenceNames = [] }) => {
    return (
        <AugmentedTextarea
            defaultValue={value}
            onChange={onChange}
            placeholder={placeholder}
            containerStyle={{ borderTopLeftRadius: '4px' }}
            syntaxConfiguration={[
                {
                    prefix: '$',
                    pattern: /\$([a-z0-9_.]+)\b/gi,
                    autocompleteItems: referenceNames.map(name => ({
                        label: name,
                        icon: 'calculator',
                    }))
                },
                {
                    prefix: '',
                    pattern: /([a-z_]+)\b/gi,
                    autocompleteItems: documentation.map(func => ({ label: func.signature, icon: 'cube' })),
                    colorOverwrites: {
                        active: '#2003fc',
                    },
                },
                {
                    prefix: '',
                    pattern: /([0-9]+)\b/gi,
                    colorOverwrites: {
                        standard: '#1fbff0',
                    },
                },
                {
                    prefix: '',
                    pattern: /(-|\+|>|<|=|\*|\/|\(|\)|,|;|&|\|)/gi,
                    colorOverwrites: {
                        standard: '#3c2752',
                    },
                },
            ]}
        />
    );
    
};

export default FormulaInput;