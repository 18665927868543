import { APP_LOCATION } from '../config';

/**
 * Generates a URL to "app" by prepending the base URL to the supplied path
 * (which must start with a slash)
 * The base URL will depend on environment (development, dev, prod).
 * Eg:
 * path: /product/123
 * Return: http://localhost:8000/product/123
 */
function getAppUrl(path) {
    return `${APP_LOCATION}${path}`;
}

export { getAppUrl };
