import React from 'react';
import { Menu, Form, Button, Container, Dropdown, Icon, Loader, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import _, { set } from 'lodash';
import modelEditor from 'http/modelEditor';
import Checkbox from 'atoms/Checkbox';
import TextInput from 'atoms/TextInput/TextInput';
import RuleManager from 'model-editor/molecules/RuleManager/RuleManager';
import withNavShell from 'model-editor/molecules/withNavShell';
import RoleManager from 'model-editor/molecules/RoleManager';
import FieldManager from 'model-editor/molecules/FieldManager';
import { getRadioQuestionOptions } from 'model-editor/util/radioQuestion';
import { nodeTypes } from 'model-editor/nodeMetadata';
import Tooltip from 'atoms/Tooltip';
import IconPicker from 'atoms/IconPicker';

import styles from './ProductsSectionView.module.css';
import HintManager from 'model-editor/molecules/HintManager/HintManager';
import DataManager from 'model-editor/molecules/HintManager/DataManager';


const TAB_FELTER = 'felter';
const TAB_SEKTION = 'sektion';

class ProductsSectionView extends React.Component {
    state = {
        tabSelected: TAB_FELTER,
        section: {},
        sections: [],
        fieldIds: [],
        nodes: [],
        resourceNodes: [],
        loading: true
    };

    componentDidMount () {
        this.loadRequiredData();
    }

    componentDidUpdate = (prevProps) => {
        const getSlug = props => props.match?.params?.sectionSlug;

        if (getSlug(prevProps) !== getSlug(this.props)) {
            this.setState({ loading: true }, () => {
                this.loadRequiredData();
            });
        }
    };

    loadRequiredData = async () => {
        await Promise.all([
            this.loadFieldIds(),
            this.loadSection(),
            this.loadSections(),
            this.loadNodes(),
        ]);
        this.props.updateNavShell();
        this.setState({
            loading: false
        });
    };

    sectionUpdater = (prop) => {
        return e => {
            let valueToSet;
            try {
                valueToSet = e.target.value;
            } catch (error) {
                valueToSet = e;
            }

            this.updateSection(prop, valueToSet);
        };
    };

    updateSection = (propertyPath, updateValue) => {
        const { section } = this.state;
        _.set(section, propertyPath, updateValue);
        this.setState({
            section: { ...section }
        });
    }

    loadFieldIds = async () => {
        const modelID = this.props.match.params.modelId;
        const fieldIds = await modelEditor.getFieldIds(modelID);
        this.setState({
            fieldIds,
        });
    };

    loadSection = async () => {
        const modelId = this.props.match.params.modelId;
        const sectionSlug = this.props.match.params.sectionSlug;
        const section = await modelEditor.getSectionBySlug(modelId, sectionSlug);
        this.setState({
            section,
        });
    };

    loadSections = async () => {
        const modelID = this.props.match.params.modelId;
        const sections = await modelEditor.getSections(modelID);
        this.setState({ sections });
    };

    loadNodes = async () => {
        const modelID = this.props.match.params.modelId;
        const nodes = await modelEditor.getModelNodes(modelID);

        // load full resource nodes
        const shallowResourceNodes = nodes.filter(node => node.type === nodeTypes.MultipleResources);
        const resourceNodes = await Promise.all(shallowResourceNodes.map(node => {
            return modelEditor.getNodeByID(modelID, node.id);
        }));

        this.setState({
            resourceNodes,
            nodes,
        });
    };

    goToSection = slug => {
        
        const modelID = this.props.match.params.modelId;
        this.props.history.push(`/model-editor/${modelID}/sections/${slug}`);
    };

    saveSection = async () => {
        const { section }           = this.state;
        const modelID               = section.modelID;
        const title                 = section.title;
        const slug                  = section.slug;
        const youtubeID             = section.youtubeID;
        const breadcrumbTitle       = section.breadcrumbTitle;
        const requiresPayment       = section.requiresPayment;
        const dataIsCopyable        = section.dataIsCopyable;
        const isHidden              = section.isHidden;
        const icon                  = section.icon;
        const hasVisibilityRules    = section.hasVisibilityRules;
        const visibilityRules       = section.visibilityRules;
        const hasValidationRules    = section.hasValidationRules;
        const validationRules       = section.validationRules;
        const fields                = section.fields;
        const requiresRoles         = section.requiresRoles;
        const roles                 = section.roles;
        const printable             = section.printable;
        const printTitle            = section.printTitle;
        const alwaysUnlocked        = section.alwaysUnlocked;
        const isAssociatedWithHint  = section.isAssociatedWithHint;
        const hint                  = section.hint;

        const mandatoryProperties = [title, slug, icon];

        if (!mandatoryProperties.every(p => p)) {
            toast.error('Titel, slug eller ikon mangler');
            return;
        }

        const modelId = this.props.match.params.modelId;
        const sectionId = this.state.section.id;
        await modelEditor.updateSection(modelId, sectionId, {
            id: sectionId,
            modelID,
            title,
            slug,
            youtubeID,
            breadcrumbTitle,
            icon,
            requiresPayment,
            dataIsCopyable,
            fields,
            isHidden,
            hasVisibilityRules,
            visibilityRules,
            hasValidationRules,
            validationRules,
            requiresRoles,
            roles,
            printable,
            printTitle,
            alwaysUnlocked,
            isAssociatedWithHint,
            hint
        });
        this.props.history.push(`/model-editor/${modelId}/sections`);
    };

    saveAndGoToSection = async slug => {
        await this.saveSection();
        this.goToSection(slug);
    };

    saveAndGoToCreateSection = async () => {
        const { modelId } = this.props.match.params;
        await this.saveSection();
        this.props.history.push(`/model-editor/${modelId}/create-section`);
    };

    updateTab = tabSelected => {
        return () => this.setState({ tabSelected });
    };

    renderSelectedTab = () => {
        switch (this.state.tabSelected) {
            case TAB_SEKTION:
                return this.renderSectionTab();
            case TAB_FELTER:
                return this.renderFieldsTab();
            default:
                return null;
        }
    };

    onRoleChange = (role, value) => {
        const { section } = this.state;
        const sectionRoles = section.roles || [];
        if (value) {
            !sectionRoles.includes(role) && sectionRoles.push(role);
        } else {
            sectionRoles.splice(sectionRoles.indexOf(role), 1);
        }
        section.roles = sectionRoles;
        this.setState({ section });
    };

    activatePrintOnAllFields = () => {
        const { section } = this.state;
        for (let field of section.fields) {
            set(field, 'printOptions.shouldPrint', true);
        }
        this.setState({ section });
    };

    renderRoleManager = () => {
        const sectionRoles = new Set(this.state.section.roles);
        return <>
            <p>
                <i>Brugeren skal have mindst 1 af de valgte roller før sektionen bliver vist</i>
            </p>
            <RoleManager
                onChange={(id, value) => this.onRoleChange(id, value)}
                selectedRoles={sectionRoles}
            />
        </>;
    };

    renderLoader = () => (
        <Segment basic>
            <Loader
                inline='centered'
                active size='huge'
            />
        </Segment>
    );

    /**
        Renders hint manager
    */
    renderHintManager = () => {
        const { section } = this.state;

        // --------------------------------------------------

        /* Hint manager */
        const dataManager = new DataManager(() => section, (propertyPath, value) => this.updateSection(propertyPath, value));

        const hintManager = <HintManager hintType={section.hint?.type} dataManager={dataManager} />;
        return hintManager;
    };

    renderSectionTab = () => {
        const {
            title,
            slug,
            youtubeID,
            breadcrumbTitle,
            isHidden,
            hasVisibilityRules,
            requiresRoles,
            visibilityRules,
            requiresPayment,
            dataIsCopyable,
            icon,
            printable,
            printTitle,
            alwaysUnlocked,
        } = this.state.section;
        const { modelId } = this.props.match.params;

        // for getting radio question options
        const getOptionsByID = nodeID => {
            return getRadioQuestionOptions(modelId, nodeID);
        };

        return (
            <Form style={{ margin: '1em' }}>
                <Form.Field>
                    <label>Titel</label>
                    <TextInput defaultValue={title} onChange={this.sectionUpdater('title')} type='text' />
                </Form.Field>
                <Form.Field>
                    <label>Slug</label>
                    <TextInput defaultValue={slug} onChange={this.sectionUpdater('slug')} type='text' />
                </Form.Field>
                <Form.Field>
                    <label>YouTube ID</label>
                    <TextInput
                        defaultValue={youtubeID}
                        onChange={this.sectionUpdater('youtubeID')}
                        placeholder='Ikke påkrævet'
                        type='text'
                    />
                </Form.Field>
                <Form.Field>
                    <label>Brødkrummetitel</label>
                    <TextInput
                        defaultValue={breadcrumbTitle}
                        onChange={this.sectionUpdater('breadcrumbTitle')}
                        placeholder='Ikke påkrævet'
                        type='text'
                    />
                </Form.Field>
                <Form.Field>
                    <label>Skjul sektion?</label>
                    <Checkbox checked={isHidden} onChange={this.sectionUpdater('isHidden')} toggle/>
                </Form.Field>
                <Form.Field>
                    <label>Sektion kræver betaling?</label>
                    <Checkbox
                        checked={requiresPayment}
                        onChange={this.sectionUpdater('requiresPayment')}
                        toggle
                    />
                </Form.Field>
                <Form.Field>
                    <label>
                        Tillad rådgiverkopiering?
                        <Tooltip content='Tillader rådgivere at kopiere data fra klienter ind på sektionens felter' />
                    </label>
                    <Checkbox
                        checked={dataIsCopyable}
                        onChange={this.sectionUpdater('dataIsCopyable')}
                        toggle
                    />
                </Form.Field>
                <Form.Field>
                    <label>
                        Altid låst op?
                        <Tooltip content='Aktivér hvis felter på denne sektion altid skal være låst op (selvom der eksempelvis er sendt til underskrift)' />
                    </label>
                    <Checkbox
                        checked={alwaysUnlocked}
                        onChange={this.sectionUpdater('alwaysUnlocked')}
                        toggle
                    />
                </Form.Field>
                <Form.Field>
                    <label>Har synlighedsregler?</label>
                    <Checkbox
                        checked={hasVisibilityRules}
                        onChange={this.sectionUpdater('hasVisibilityRules')}
                        toggle
                    />
                    {
                        hasVisibilityRules &&
                        <RuleManager
                            nodes={this.state.nodes}
                            rules={visibilityRules}
                            modelId={modelId}
                            disabled={false}
                            getOptionsByID={getOptionsByID}
                            deliverData={this.sectionUpdater('visibilityRules')}
                        />
                    }
                </Form.Field>
                <Form.Field>
                    <label>Kræver bestemte roller?</label>
                    <Checkbox
                        checked={requiresRoles}
                        onChange={this.sectionUpdater('requiresRoles')}
                        toggle
                    />
                    {
                        requiresRoles &&
                        this.renderRoleManager()
                    }
                </Form.Field>
                <Form.Field>
                    <label>Sektion kan udskrives?</label>
                    <Checkbox
                        checked={printable}
                        onChange={this.sectionUpdater('printable')}
                        toggle
                    />
                </Form.Field>
                {
                    printable && (
                        <Form.Field>
                            <label>Titel på udskrift</label>
                            <TextInput
                                placeholder='Ikke påkrævet - bruger sektionens hovedtitel hvis ikke angivet'
                                onChange={this.sectionUpdater('printTitle')}
                                defaultValue={printTitle}
                            />
                        </Form.Field>
                    )
                }
                <Form.Field>
                    <label>Vælg ikon</label>
                    <IconPicker
                        icon={icon}
                        onChange={this.sectionUpdater('icon')}
                    />
                </Form.Field>
                {this.renderHintManager()}
            </Form>
        );
    };

    renderFieldsTab = () => {
        const { modelId } = this.props.match.params;
        return (
            <FieldManager
                fields={this.state.section.fields}
                fieldIds={this.state.fieldIds}
                nodes={this.state.nodes}
                resourceNodes={this.state.resourceNodes}
                printable={this.state.section.printable}
                onChange={this.sectionUpdater('fields')}
                save={this.saveSection}
                modelId={modelId}
                stickyMenu
            />
        );
    };

    renderDropdownMenu = () => {
        const { section, sections } = this.state;
        return (
            <Dropdown item icon='wrench' simple open={false}>
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={this.saveAndGoToCreateSection}
                        content='Ny sektion'
                    />
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span>Åbn</span>
                        <Dropdown.Menu>
                            {sections.map(({ title, slug }) => <Menu.Item
                                content={title}
                                onClick={() => this.saveAndGoToSection(slug)}
                            />)}
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.saveSection} content='Gem' />
                    <Dropdown.Divider />
                    <Dropdown.Item className={styles.sectionViewMenuActions}>
                        <Icon name='dropdown' />
                        <span>Handlinger</span>
                        <Dropdown.Menu>
                            <Menu.Item
                                link
                                disabled={!section.printable}
                                content='Aktivér print på alle felter'
                                onClick={this.activatePrintOnAllFields}
                            />
                        </Dropdown.Menu>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    renderMenu = () => {
        if (this.state.loading) {
            return null;
        }

        const { tabSelected } = this.state;
        return (
            <div className={styles.sectionViewMenu}>
                <Menu attached='top' color='blue'>
                    {this.renderDropdownMenu()}
                    <Menu.Item
                        name='Felter'
                        icon='browser'
                        onClick={this.updateTab(TAB_FELTER)}
                        active={tabSelected === TAB_FELTER}
                    />
                    <Menu.Item
                        name='Sektion'
                        icon='columns'
                        onClick={this.updateTab(TAB_SEKTION)}
                        active={tabSelected === TAB_SEKTION}
                    />
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button onClick={this.saveSection} positive size='mini'>Gem</Button>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        );
    };

    render () {
        const { loading } = this.state;

        return (
            <Container disabled={loading} fluid>
                {loading  && this.renderLoader()}
                {!loading && this.renderMenu()}
                {!loading && this.renderSelectedTab()}
            </Container>
        );
    }

}

export default withNavShell(withRouter(ProductsSectionView));
