import React from 'react';
import tinycolor from "tinycolor2";
import { Grid } from 'semantic-ui-react';
import Cytoscape from '../../atoms/Cytoscape/Cytoscape';
import { withRouter } from 'react-router-dom';
import EventList from '../../atoms/EventList';
import { stylesheet, layout, options } from './graphStylesheet';
import './ExecutionView.css';

function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class ExecutionView extends React.Component {
    state = {
        highlightedNode: '',
        showGraph: false,
    };

    cyRef = cy => {
        this.cy = cy;
    };

    nodeById = nodeId => {
        return this.props.graph.nodes.find(n => n.id === nodeId);
    };

    convertNode = node => {
        const { execution } = this.props;
        const { color, prefix, name, id } = node;

        const finalName = (prefix || '') + name;
        const nodeInExecution = !!execution.events.find(e => e.nodeId === id);
        const finalColor = nodeInExecution ? color : 'gray';
        return {
            data: {
                id: node.id,
                name: finalName,
                color: finalColor,
                borderColor: tinycolor(finalColor).darken().toString(),
                grabbable: false
            }
        };
    };

    convertEdge = edge => {
        const { execution } = this.props;
        const { color } = this.nodeById(edge.from);
        const edgeInExecution = !!execution.events.find(e => {
            return e.nodeId === edge.to;
        });
        const finalColor = edgeInExecution ? color : 'gray';
        return {
            data: {
                source: edge.from,
                target: edge.to,
                color: finalColor
            }
        };
    };

    panTo = async (node, duration = 1000, padding = 250) => {
        this.cy.animate({
            fit: {
                eles: node,
                padding
            }
        }, { duration });

        await sleep(duration);
        return;
    };

    collectionFromIds = ids => {
        let collection = this.cy.collection();
        ids.forEach(id => {
            collection = collection.union(this.cy.getElementById(id));
        });
        return collection;
    };

    startHoverNode = id => {
        if (!this.cy) return ;
        const cyNode = this.cy.getElementById(id);
        cyNode.style('background-color', 'yellow');
        this.cy.stop();
        this.cy.clearQueue();
        this.panTo(cyNode, 200, 340);
    };

    stopHoverNode = id => {
        if (!this.cy) return ;
        const cyNode = this.cy.getElementById(id);
        const graphNode = this.nodeById(id);
        cyNode.style('background-color', graphNode.color);
    };

    render () {
        const { graph, execution } = this.props;
        const { showGraph } = this.state;
        const { nodes, edges } = graph;
        const elements = {
            nodes: nodes.map(this.convertNode),
            edges: edges.map(this.convertEdge)
        };

        const executionEvents = execution.events.map(event => {
            const node = nodes.find(n => n.id === event.nodeId)
            return {
                icon: node.icon,
                name: node.name,
                ...event
            };
        });

        return (
            <Grid className='executionContainer'>
                <Grid.Row>
                    <Grid.Column width={4} className='nodeListContainer' textAlign='center'>
                        <EventList
                            events={executionEvents}
                            nodes={nodes}
                            highlightedNode={this.state.highlightedNode}
                            onMouseEnter={this.startHoverNode}
                            onMouseLeave={this.stopHoverNode}
                        />
                    </Grid.Column>
                    <Grid.Column width={12}>
                        { 
                            !showGraph ?
                            <button
                                onClick={() => this.setState({ showGraph: true })}
                                children='Show graph'
                            /> :
                            <Cytoscape
                                containerID='cy'
                                elements={elements}
                                cyRef={this.cyRef}
                                cytoscapeOptions={options}
                                style={stylesheet}
                                layout={layout}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(ExecutionView)

