import React, { Component, createRef } from 'react';
import { Button, Grid, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import EmailEditor from 'react-email-editor';

import email from 'http/email';
import { withRESTResource } from 'molecules/withRESTResource';
import AutoStamp from 'atoms/AutoStamp';
import VariableTable from './VariableTable';

import styles from './TemplatesDetail.module.css';

class TemplatesDetail extends Component {
    editor = createRef();
    name = createRef();
    state = {
        editorReady: false,
        editorLoaded: false,
        working: false,
    };

    doWork = fn => {
        return async () => {
            this.setState({ working: true });
            await fn();
            this.setState({ working: false });
        };
    };

    componentDidUpdate = () => {
        const { editorLoaded, editorReady } = this.state;
        if (!editorLoaded && editorReady && this.editor.current) {
            this.setState({ editorLoaded: true });
            this.editor.current.loadDesign(this.props.rest.data.markdown);
        }
    };

    exportEmail = () => {
        const editor = this.editor.current;
        if (!editor) {
            return Promise.resolve(null);
        }

        return new Promise(resolve => editor.exportHtml(resolve));
    };

    save = async () => {
        const { id, name } = this.props.rest.data;
        const exported = await this.exportEmail();
        if (!exported) {
            return;
        }

        let newName;
        if (this.name.current.value !== name) {
            newName = this.name.current.value;
        }

        const { html, design } = exported;
        try {
            await email.patchTemplate(id, {
                html,
                markdown: design,
                name: newName,
            });
            toast.success(`"${name}" blev gemt`);
            this.props.history.push('/emails/templates');
        } catch (e) {
            toast.error(<span>Skabelonen blev ikke gemt:<br />{e.message}</span>);
        }
    };

    onEditorLoaded = () => {
        this.setState({ editorReady: true });
    };

    renderTemplate = template => {
        const { editorLoaded, working } = this.state;
        return (
            <div>
                <Grid columns={2} verticalAlign='middle'>
                    <Grid.Column>
                        <Header size='large'>
                            <input
                                defaultValue={template.name}
                                className={styles.nameInput}
                                placeholder='Navngiv din skabelon'
                                spellCheck={false}
                                ref={this.name}
                            />
                            <Header.Subheader>
                                Sidst ændret d. <AutoStamp stamp={template.updatedAt} />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            disabled={!editorLoaded || working}
                            primary
                            content='Gem'
                            labelPosition='right'
                            icon='save'
                            onClick={this.doWork(this.save)}
                            floated='right'
                        />
                    </Grid.Column>
                </Grid>
                <Segment
                    className={styles.editorContainer}
                    raised
                    loading={working}
                    disabled={working}
                >
                    <EmailEditor
                        onLoad={this.onEditorLoaded}
                        ref={this.editor}
                        minHeight={window.screen.availHeight * 0.70}
                    />
                </Segment>
                <div className={styles.variablesContainer}>
                    <VariableTable />
                </div>
            </div>
        );
    };

    render = () => {
        const { loading, error, data } = this.props.rest;
        if (loading) {
            return <Loader inline='centered' active />
        }

        if (error) {
            return <Message
                error
                content='Der opstod en fejl'
            />;
        }

        return this.renderTemplate(data);
    };
}

export default withRESTResource(TemplatesDetail, ({ match }) => {
    return email.getTemplate(match.params.template);
});