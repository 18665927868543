import React, { useMemo, useState } from 'react';
import { Segment, Header, Input, Grid, Icon, Popup, Checkbox } from 'semantic-ui-react';
import { nodeMetadata, nodeCategories } from 'model-editor/nodeMetadata';

const MAX_NODES = 10;

const NodeMultiselect = ({ nodes, selected, onNodeClicked }) => {
    const [searchFilter, setSearchFilter] = useState('');
    const [showOnlyPicked, setShowOnlyPicked] = useState(false);

    const sortedNodes = useMemo(() => {
        return nodes.sort((na, nb) => {
            if (na.name > nb.name) return 1;
            if (na.name < nb.name) return -1;
            return 0;
        });
    }, [nodes]);

    const nodesFiltered = useMemo(() => {
        const filtered = [];

        for (let node of sortedNodes) {
            if (filtered.length >= MAX_NODES) {
                break;
            }

            if (showOnlyPicked && !selected.has(node.id)) {
                continue;
            }

            if (!searchFilter) {
                filtered.push(node);
                continue;
            }

            const filter = searchFilter.toLowerCase();
            const name = node.name.toLowerCase();
            const tag = node.tag.toLowerCase();
            const type = node.type.toLowerCase();

            if (name.includes(filter) || tag.includes(filter) || type === filter) {
                filtered.push(node);
            }
        }

        return filtered;
    }, [sortedNodes, searchFilter, showOnlyPicked, selected]);

    return (
        <div>
            <Segment style={{ padding: '0.5em' }}>
                <Grid columns={2} verticalAlign='middle'>
                    <Grid.Column width={8}>
                        <Input
                            onChange={(_, { value }) => setSearchFilter(value.trim())}
                            icon='search'
                            placeholder='Søg efter noder...'
                            iconPosition='left'
                            fluid
                        />
                    </Grid.Column>
                    <Grid.Column width={8} textAlign='right'>
                        <Checkbox
                            toggle
                            label='Vis kun valgte?'
                            onChange={() => setShowOnlyPicked(!showOnlyPicked)}
                        />
                    </Grid.Column>
                </Grid>
                
            </Segment>
            
            {
                nodesFiltered?.length === 0 &&
                <Header size='small' inverted>Ingen relevante noder fundet...</Header>
            }
            {
                nodesFiltered?.length > 0 &&
                <Segment.Group>
                    {nodesFiltered.map(node => {
                        const meta = Object.values(nodeMetadata).find(nmd => node.type === nmd.type);
                        const icon = nodeCategories[meta.category].icon;
                        const isSelected = selected?.has(node.id);
                        const style = { padding: '1em', cursor: 'pointer' };

                        return (
                            <Segment
                                style={style}
                                secondary={!isSelected}
                                onClick={() => onNodeClicked(node.id)}
                                className='noselect'
                            >
                                <Grid columns={2}>
                                    <Grid.Column width={12}>
                                        <Icon
                                            name={isSelected ? 'check circle' : 'circle'}
                                            color={isSelected ? 'green' : 'grey'}
                                            style={{ opacity: isSelected ? 1 : 0.25 }}
                                        />
                                        {isSelected ? <b>{node.name}</b> : node.name}
                                    </Grid.Column>
                                    <Grid.Column width={4} textAlign='right'>
                                        <Popup
                                            content={meta.type}
                                            position='top center'
                                            trigger={
                                                <span style={{ color: meta.color }}>
                                                    <Icon name={icon} />
                                                </span>
                                            }
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        );
                    })}
                </Segment.Group>
            }
        </div>
    );
};

export default NodeMultiselect;