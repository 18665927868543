import React, { Component } from 'react';
import { Loader, Header, Table, Button, Icon } from 'semantic-ui-react';
import AutoStamp from 'atoms/AutoStamp';
import paymentHttp from 'http/payment';

class InvoiceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bill: [],
            loading: true,
            currentPage: 1,
            itemsPerPage: 10,
        }
    }

    handleDeleteClick = async (id) => {
        if (window.confirm("Er du helt sikker på at du vil slette dette køb?")) {
            try {
                await paymentHttp.updateAccountantBill(id);
                this.fetchData();
            } catch (error) {
                console.error("Error deleting invoice:", error);
            }
        }
    }

    fetchData = () => {
        const { id } = this.props.user;
        paymentHttp.getAccountantBillEntries(id)
        .then(result => {
            this.setState({
                bill: result,
                loading: false
            });
        })
        .catch(error => {
            this.setState({ result: "Assemble the developers! Something went horribly wrong" });
            console.log(error);
        });
    }

    componentDidMount = async () => {
        const { id } = this.props.user;
        paymentHttp.getAccountantBillEntries(id)
        .then (result => {
            this.setState({
                bill: result,
                loading: false
            });
            console.log("surpliend faktura id:"+ id);
            console.log(result);
        })
        .catch ( 
            error => {
                this.setState({ result: "Assemble the developers! Something went horribly wrong" });
                console.log(error);
            }
         )
    };


    renderBills = ({ accountantID, accountant, clientID, client, productID, taxYear, timestamp, invoiceID }) => {
        return (
            <Table.Row key={invoiceID}>
                <Table.Cell>{client}</Table.Cell>
                <Table.Cell>{productID}</Table.Cell>
                <Table.Cell>{taxYear}</Table.Cell>
                <Table.Cell>
                    <AutoStamp stamp={timestamp}  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <Icon 
                        textAlign="right" 
                        name='trash alternate outline' 
                        size='small' 
                        onClick={() => this.handleDeleteClick(invoiceID)}
                        style={{ cursor: 'pointer' }}
                    />
                </Table.Cell>
            </Table.Row>
        );
    };


      
      // Add new functions for pagination
      handleNextPage = () => {
        this.setState(prevState => ({ currentPage: prevState.currentPage + 1 }));
      };
      
      handlePreviousPage = () => {
        this.setState(prevState => ({ currentPage: prevState.currentPage - 1 }));
      };



    renderFiles = () => {
        const {
            bill,
            currentPage,
            itemsPerPage,
        } = this.state;

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        const currentItems = bill.slice(indexOfFirstItem, indexOfLastItem);
        const groupedByMonth = currentItems.reduce((acc, item) => {
            const month = new Date(item.timestamp).toLocaleDateString('da-DK', { month: 'long', year: 'numeric' });
            acc[month] = acc[month] ? [...acc[month], item] : [item];
            return acc;
          }, {});



        if (bill.length === 0) {
            return <Header>Ingen historik fundet! {this.state.result}</Header>;
        }

        return (
            <div>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Klient</Table.HeaderCell>
                            <Table.HeaderCell>Produkt</Table.HeaderCell>
                            <Table.HeaderCell>Skatte år</Table.HeaderCell>
                            <Table.HeaderCell>Købt</Table.HeaderCell>
                            <Table.HeaderCell>Slet købet</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                    {Object.keys(groupedByMonth).map(month => (
                        <>
                            <Table.Row>
                            <Table.Cell colSpan="5" textAlign="center"><b>{month}</b></Table.Cell>
                            </Table.Row>
                            {groupedByMonth[month].map(this.renderBills)}
                        </>
                        ))}
                    </Table.Body>

                </Table>
                <div>
                <button onClick={this.handlePreviousPage} disabled={currentPage === 1}>Tilbage</button>
                <button onClick={this.handleNextPage} disabled={indexOfLastItem >= bill.length}>Næste</button>
            </div>
          </div>
        );
    };

    render = () => {
        const { loading } = this.state;

        let content;
        if (loading) {
            content = <Loader active inline="centered" size="huge" />;
        } else {
            content = this.renderFiles();
        }

        return <div>{content}</div>;
    };
}

export default InvoiceList;