import React, { Component } from 'react';
import { Table, Button, Icon, Message, Label, Loader } from 'semantic-ui-react';
import CreateModal from './OnetimePaymentCreateModal';
import http from 'http/onetimePayments';

class RightAligned extends Component {
    render () {
        return (
            <div style={{ textAlign: 'right' }}>{this.props.children}</div>
        );
    }
}

export default class OnetimePaymentsMaster extends Component {
    state = {
        onetimePayments: [],
        modalActive: false,
        loading: true,
        working: false
    };

    async componentDidMount () {
        const onetimePayments = await http.getAllOnetimePayments();
        this.setState({
            loading: false,
            onetimePayments,
        });
    }

    renderOTPTable = () => {
        const { onetimePayments } = this.state;
        if (onetimePayments.length === 0) {
            return (
                <Message info>
                    <Message.Header textAlign='left'>Her er tomt...</Message.Header>
                    <p>Der er ikke oprettet nogen enkeltkøbsprodukter endnu</p>
                </Message>
            );
        }

        return (
            <Table selectable compact sortable>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right'>Pris</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { 
                        onetimePayments.map(otp => {
                            const { name, id, isActive, price } = otp;
                            return this.renderTableRow(name, id, isActive, price);
                        })
                    }
                </Table.Body>
            </Table>
        );
    };

    renderTableRow = (name, id, isActive, price) => {
        return (
            <Table.Row key={id} onClick={() => this.rowClicked(id)}>
                <Table.Cell><b>{name}</b></Table.Cell>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>
                    <Label color={isActive ? 'blue' : 'gray'}>
                        {isActive ? 'Aktiv' : 'Inaktiv'}
                    </Label>
                </Table.Cell>
                <Table.Cell textAlign='right'>{price} kr.</Table.Cell>
            </Table.Row>
        );
    };

    rowClicked = id => {
        this.props.history.push(`/payment/onetime-payments/${id}`);
    };

    toggleModal = async openOrClosed => {
        await this.clearForm();
        this.setState({
            modalActive: openOrClosed
        });
    };

    clearForm = () => {
        return new Promise (resolve => {
            this.setState({
                name: '',
                id: '',
                price: '',
                description: '',
                isActive: false,
                requiredRoles: [],
                error: ''
            }, resolve);
        });
    };

    updateFormData = (e, property) => {
        this.setState({
            [property]: e.target.value
        });
    };

    setError = error => {
        this.setState({ error, working: false });
    };

    submitForm = async () => {
        const { name, id, price, description, isActive, requiredRoles } = this.state;
        if (!name)
            return this.setError('Indtast et navn på enkeltkøbet');

        if (!id)
            return this.setError('Indtast et ID på enkeltkøbet');
        
        if (!price)
            return this.setError('Indtast en pris på enkeltkøbet');

        if (isNaN(price) || price < 0)
            return this.setError('Prisen skal være et positivt heltal');
        
        this.setState({ working: true });
        const { success, data } = await http.createOnetimePayment(
            name,
            id,
            price,
            description,
            !!isActive,
            requiredRoles,
        );

        if (success) {
            this.setState({
                onetimePayments: data,
                working: false,
                modalActive: false
            });
        } else {
            this.setError('Enkelkøbsproduktet kunne ikke oprettes');
        }

    };

    render () {
        const {
            modalActive,
            name,
            id,
            description,
            price,
            error,
            loading,
            working,
            isActive,
            requiredRoles,
        } = this.state;

        if (loading) {
            return <Loader
                active
                inline='centered'
                size='huge'
            />;
        }

        return (
            <>
                <h1>Enkeltkøb</h1>
                <RightAligned>
                    <Button animated='vertical' color='green' onClick={() => this.toggleModal(true)}>
                        <Button.Content hidden>Opret</Button.Content>
                        <Button.Content visible>
                            <Icon name='cart plus'/>
                        </Button.Content>
                    </Button>
                </RightAligned>
                {this.renderOTPTable()}
                <CreateModal
                    open={modalActive} 
                    onClose={() => this.toggleModal(false)}
                    updateFormData={this.updateFormData}
                    submitForm={this.submitForm}
                    name={name}
                    id={id}
                    description={description}
                    price={price}
                    error={error}
                    working={working}
                    isActive={isActive}
                    requiredRoles={requiredRoles}
                />
            </>
        );
    }
}