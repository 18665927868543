import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/erst`);

class ErstClient {
    testXBRL = (xbrlXml, supplementaryInstanceXml) => {
        return client.postResource('/test-xbrl', { xbrlXml, supplementaryInstanceXml });
    };
}

const erst = new ErstClient();

export default erst;