window.__env ||= {};

const cfgVal = (key, def) => {
    const configLookup = window.__env[key];
    if (configLookup !== undefined) {
        return configLookup;
    }

    const envLookup = import.meta.env[`VITE_${key}`];
    if (envLookup !== undefined) {
        return envLookup;
    }

    return def;
};

export const API_LOCATION                  = cfgVal('API_LOCATION', 'http://localhost:4000');
export const APP_LOCATION                  = cfgVal('APP_LOCATION', 'http://localhost:8000');
export const MAIN_DASHBOARD_LOCATION       = cfgVal('MAIN_DASHBOARD_LOCATION', 'http://localhost:3001');
export const STRIPE_DASHBOARD_LOCATION     = cfgVal('STRIPE_DASHBOARD_LOCATION', 'https://dashboard.stripe.com/test');
export const MAILGUN_ANALYTICS_LOCATION    = cfgVal('MAILGUN_ANALYTICS_LOCATION', 'https://app.mailgun.com/app/sending/domains/mg.digitalrevisor.nu/analytics');
export const APP_TITLE                     = cfgVal('APP_TITLE', 'Admin ∙ Local');
export const MODEL_EDITOR_SUBDOMAIN_HOST   = cfgVal('MODEL_EDITOR_SUBDOMAIN_HOST', '');
export const TRAININGDATA_ACTIVE           = !!cfgVal('TRAININGDATA_ACTIVE', 'true');
export const SPLIT_OTHER_EXTERNAL_EXPENSES = !!cfgVal('SPLIT_OTHER_EXTERNAL_EXPENSES', 'true');