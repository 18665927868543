import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Loader, Segment, Form, Button, Divider, Grid, List } from 'semantic-ui-react';
import http from 'http/payment';
import downloadBlob from 'shared/downloadBlob';
import { formatNumber } from 'shared/formatNumber';
import { formatUnix } from 'shared/dateTimeFormatting';
import { confirm } from 'shared/globalModal';
import { STRIPE_DASHBOARD_LOCATION } from 'config';
import { formatInvoiceMethod } from './format';
import styles from './Invoice.module.css';
import TextArea from 'atoms/TextArea/TextArea';

class InvoiceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice: {},
            loading: true,
            working: false,
            pdf: null,
            newComment: ''
        };
    }

    invoiceID = () => this.props.match.params.invoiceid;
    
    componentDidMount = async () => {
        const invoice = await this.fetchInvoice();
        const { bytes: pdf } = await http.getAccountantInvoicePDFAsB64(this.invoiceID());
        this.setState({
            loading: false,
            invoice,
            pdf,
        });
    };

    fetchInvoice = async () => {
        const invoice = await http.getAccountantInvoice(this.invoiceID());
        return invoice;
    };

    paidButtonClicked = async () => {
        const sure = await confirm('Er du sikker?');
        if (!sure) {
            return;
        }

        this.setState({ working: true });
        await http.updateAccountantInvoicePaid(this.invoiceID(), true);
        const invoice = await this.fetchInvoice();
        this.setState({
            working: false,
            invoice
        });
    };

    downloadPDFClicked = async () => {
        this.setState({ working: true });
        const { blob } = await http.getAccountantInvoicePDF(this.invoiceID());

        const { dateOfIssue, totalPrice } = this.state.invoice;
        const formattedDate = formatUnix(Date.parse(dateOfIssue));
        downloadBlob(blob, `faktura__${this.invoiceID()}__${formattedDate}__${totalPrice}.pdf`);
        this.setState({ working: false });
    };
    
    renderLoading = () => {
        return <Segment basic textAlign='center'>
            <Loader inline active />
        </Segment>;
    };

    detailLine = (label, value) => {
        return <Form.Field>
            <label>{label}</label>
            {value}
        </Form.Field>
    };

    detailLineIf = (expression, label, value) => {
        if (!expression) {
            return;
        }
        return this.detailLine(label, value);
    }

    async handleAddComment(comment) {
        await http.updateAccountantInvoiceComments(this.state.invoice.invoiceID, [comment], []);
        this.state.invoice.comments.push(comment);
        this.setState({ invoice: this.state.invoice, newComment: '' });
    }

    async handleDeleteComment(index) {
        const sure = await confirm('Er du sikker på at du vil slette denne kommentar?');
        if (!sure) { return; }
        const { invoice } = this.state;
        const comment = invoice.comments[index];
        await http.updateAccountantInvoiceComments(invoice.invoiceID, [], [comment]);
        invoice.comments = invoice.comments.filter(x => x !== comment);
        this.setState({ invoice });
    }

    stripePaymentLink = paymentIntentID => {
        return <a
            href={`${STRIPE_DASHBOARD_LOCATION}/payments/${paymentIntentID}`}
            children='Gå til betaling på Stripe'
            target='_blank'
            rel='noopener noreferrer'
        />;
    };

    profileLink = (label, accountantID) => {
        return <Link to={`/accounts/reporting-entities/${accountantID}`}>
            {label}
        </Link>
    };

    renderInvoice = () => {
        const {
            accountantID,
            receiverName,
            invoiceID,
            dateOfIssue,
            invoiceMethod,
            totalPrice,
            paid,
            paymentIntentID,
        } = this.state.invoice;
        return <Grid columns={2} stackable>
            <Grid.Column>
                <Form loading={this.state.working} className={styles.invoiceForm}>
                    {this.detailLine('Rådgiver', this.profileLink(receiverName, accountantID))}
                    {this.detailLine('Fakturanr.', invoiceID)}
                    {this.detailLineIf(!!paymentIntentID, 'Stripebetaling', this.stripePaymentLink(paymentIntentID))}
                    {this.detailLine('Udstedelsesdato', formatUnix(Date.parse(dateOfIssue)))}
                    {this.detailLine('Faktureringsmetode', formatInvoiceMethod(invoiceMethod))}
                    {this.detailLine('Samlet pris', formatNumber(totalPrice) + ' kr.')}
                    {this.detailLine('Betalt', paid ? 'Ja' : 'Nej')}
                </Form>
            </Grid.Column>
            <Grid.Column>
                <object
                    data={`data:application/pdf;base64,${this.state.pdf}`}
                    className={styles.inlineInvoice}
                    children='PDF'
                />
            </Grid.Column>
        </Grid>;
    };

    renderInvoiceComments() {
        return <Segment basic>
            <Divider />
            <List verticalAlign='middle'>
                {
                    this.state.invoice.comments.map((comment, index) => {
                        return <List.Item>
                            <List.Content floated='right'>
                                <Button size='tiny' onClick={() => this.handleDeleteComment(index)}>Slet</Button>
                            </List.Content>
                            <List.Content>{comment}</List.Content>
                        </List.Item>
                    })
                }
            </List>
            <TextArea 
                placeholder={'Tilføj en ny kommentar'}
                fluid={true}
                value={this.state.newComment}
                onChange={(e) => this.setState({ newComment: e.target.value })}
            ></TextArea>
            <Button content='Tilføj' labelPosition='left' icon='edit' primary onClick={() => this.handleAddComment(this.state.newComment)} />
        </Segment>;
    }

    renderPaidButton = () => {
        const { paid } = this.state.invoice;
        return <Segment basic textAlign='right'>
            <Divider />
            <Button
                onClick={this.downloadPDFClicked}
                disabled={this.state.working}
                content='Download faktura'
                icon='download'
            />
            { !paid && <Button 
                onClick={this.paidButtonClicked}
                disabled={this.state.working}
                content='Marker som betalt'
                primary
            /> }
        </Segment>;
    };

    render () {
        const { loading } = this.state;
        if (loading) {
            return this.renderLoading();
        }
        return <>
            {this.renderInvoice()}
            {this.renderInvoiceComments()}
            {this.renderPaidButton()}
        </>;
    }
}

export default withRouter(InvoiceTable);