import React from 'react';

const Sticky = ({ children, bottom, top, style }) => {
    return (
        <div
            style={{
                userSelect: 'none',
                fontSize: '14px',
                position: 'sticky',
                bottom: bottom ? '0' : undefined,
                top: top ? '0' : undefined,
                borderTop: '1px solid lightgray',
                borderBottom: '1px solid lightgray',
                background: 'white',
                width: '100vw',
                padding: '5px',
                zIndex: 1,
                ...style,
            }}
            children={children}
        />
    );
};

export default Sticky;