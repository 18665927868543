import React, { Component } from 'react';
import { Dropdown, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { nodeTypes } from 'model-editor/nodeMetadata';
import modelEditor from 'http/modelEditor';

class NodeDataMultipleResourcesSum extends Component {
    state = {
        loading: true,
        error: null,
        resourceNode: null,
        exportedTags: [],
        data: {
            sumTag: null,
        },
    };

    componentDidMount = async () => {
        await this.fetchDependencyResourceNode();
        
        this.setState({
            loading: false,
            data: {
                sumTag: this.props.node.data?.sumTag,
            },
        });
    };

    componentDidUpdate = async (prevProps) => {
        if (prevProps.node?.edges?.length !== this.props.node?.edges?.length) {
            await this.fetchDependencyResourceNode();
        }
    };

    setError = error => this.setState({ error });

    getResourceExportedTags = ({ calculations, questions }) => {
        return [
            ...calculations.map(({ tag }) => ({ tag, icon: 'calculator' })),
            ...questions.map(({ tag }) => ({ tag, icon: 'question circle' })),
        ];
    };

    fetchDependencyResourceNode = async () => {
        const { edges } = this.props.node;
        const modelID = this.props.modelId;

        this.setError(null);

        if (edges.length === 0) {
            this.setError('Forbind knuden til en ressourceknude');
            return;
        }

        if (edges.length > 1) {
            this.setError('Knuden bør kun have 1 afhængighed');
            return;
        }

        const depedencyNodeID = edges[0];
        const depedencyNode = await modelEditor.getNodeByID(modelID, depedencyNodeID);

        if (depedencyNode.type !== nodeTypes.MultipleResources) {
            this.setError(`Den forbundne knude skal være en "${nodeTypes.MultipleResources}"-knude`);
            return;
        }

        const exportedTags = this.getResourceExportedTags(depedencyNode.data);

        this.setState({
            resourceNode: depedencyNode,
            exportedTags,
        });
    };

    getData = () => {
        return this.state.data;
    };

    render = () => {
        const { error, loading, data, exportedTags } = this.state;

        if (loading) {
            return (
                <Loader
                    size='huge'
                    inline='centered'
                    active
                />
            );
        }

        if (error) {
            return (
                <Message error content={error} icon='warning' />
            );
        }

        return (
            <Segment secondary>
                <Header>Vælg tag fra ressourcen</Header>
                <Dropdown
                    selection
                    search
                    fluid
                    defaultValue={data.sumTag}
                    options={exportedTags.map(({ tag, icon }) => ({
                        text: tag,
                        value: tag,
                        icon,
                    }))}
                    onChange={(_, { value }) => this.setState({
                        data: {
                            sumTag: value,
                        },
                    })}
                />
            </Segment>
        );
    };
}

export default NodeDataMultipleResourcesSum;