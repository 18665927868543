import React, { Component, useState } from 'react';
import { Button, Dropdown, Form, Header, Icon, Loader, Menu, Message, Segment, Transition } from 'semantic-ui-react';
import uuid from 'uuid/v4';
import productEngine from 'http/productEngine';
import modelEditor from 'http/modelEditor';
import Tooltip from 'atoms/Tooltip';

const ClosingSheetQuestionEditor = ({ xbrlTags, closingSheetQuestionGroups, setClosingSheetQuestionGroups }) => {
    const [openGroupIds, setOpenGroupIds] = useState([]);

    const makeNewQuestion = () => {
        return {
            id: uuid(),
            questionText: '',
        };
    };

    const makeNewQuestionGroup = () => {
        const id = uuid();

        setOpenGroupIds([...openGroupIds, id]);

        return {
            id: id,
            triggeringXbrlTags: [],
            questions: [makeNewQuestion()],
        };
    };

    const updateQuestionGroup = (groupID, updator = {}) => {
        setClosingSheetQuestionGroups(closingSheetQuestionGroups.map(group => {
            if (group.id === groupID) {
                return { ...group, ...updator };
            }

            return group;
        }));
    };

    return (
        <div>
            {closingSheetQuestionGroups.map(({ id, questions, triggeringXbrlTags }) => {
                const isOpen = openGroupIds.includes(id);

                const content = (
                    <Transition visible={isOpen} animation='slide down' duration={200}>
                        <Segment>
                            <Form>
                                <Form.Field>
                                    <label>XBRL-tags</label>
                                    <Dropdown
                                        fluid
                                        selection
                                        search
                                        multiple
                                        placeholder='Select tag(s)'
                                        onChange={(_, { value }) => updateQuestionGroup(id, { triggeringXbrlTags: value })}
                                        defaultValue={triggeringXbrlTags}
                                        options={xbrlTags.map(tag => ({
                                            text: tag,
                                            value: tag,
                                        }))}
                                    />
                                </Form.Field>
                                {questions.map((question, i) => {
                                    return (
                                        <Form.Field key={question.id}>
                                            <label>
                                                Question #{i + 1}&nbsp;
                                                <Icon
                                                    link
                                                    name='trash'
                                                    color='red'
                                                    onClick={() => {
                                                        updateQuestionGroup(id, {
                                                            questions: questions.filter(q => q.id !== question.id),
                                                        });
                                                    }}
                                                />
                                            </label>
                                            <textarea
                                                style={{ height: '75px' }}
                                                value={question.questionText}
                                                onChange={e => {
                                                    updateQuestionGroup(id, {
                                                        questions: questions.map(q => {
                                                            if (q.id === question.id) {
                                                                return {
                                                                    ...q,
                                                                    questionText: e.target.value,
                                                                };
                                                            }

                                                            return q;
                                                        })
                                                    });
                                                }}
                                            />
                                        </Form.Field>
                                    );
                                })}
                                <Form.Field>
                                    <Button
                                        fluid
                                        content='New question'
                                        icon='plus'
                                        onClick={e => {
                                            e.preventDefault();
                                            updateQuestionGroup(id, {
                                                questions: [...questions, makeNewQuestion()]
                                            });
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        </Segment>
                    </Transition>
                );

                const toggleGroupOpen = () => {
                    const copy = [...openGroupIds];
                    if (isOpen) {
                        copy.splice(copy.indexOf(id), 1);
                    } else {
                        copy.push(id);
                    }
                    setOpenGroupIds(copy);
                };

                const deleteGroup = () => {
                    setClosingSheetQuestionGroups(closingSheetQuestionGroups.filter(group => {
                        return group.id !== id;
                    }));
                };

                return (
                    <Segment.Group key={id}>
                        <Segment tertiary={!isOpen}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <Header
                                        size='small'
                                        style={{ cursor: 'pointer' }}
                                        icon={isOpen ? 'chevron down' : 'chevron right'}
                                        content={triggeringXbrlTags.join(', ') || 'Empty group'}
                                        onClick={toggleGroupOpen}
                                    />
                                </div>
                                {isOpen && (
                                    <div>
                                        <Icon
                                            link
                                            name='trash'
                                            color='red'
                                            onClick={deleteGroup}
                                        />
                                    </div>
                                )}
                            </div>
                        </Segment>
                        {content}
                    </Segment.Group>
                );
            })}

            <Button
                onClick={() => setClosingSheetQuestionGroups([...closingSheetQuestionGroups, makeNewQuestionGroup()])}
                content='New question group'
                icon='plus'
                fluid
                basic
                inverted
            />
        </div>
    );
};

class NodeDataERPData extends Component {
    constructor(props) {
        super(props);

        this.tabs = [
            {
                render: this.renderGeneralSettings,
                title: 'General settings'
            },
            {
                render: this.renderClosingSheetSettings,
                title: 'Closing sheet questions'
            },
        ];

        const data = {
            previousERPDataNode: '',
            selectedResourceSlug: '',
            selectedTags: [],
            closingSheetQuestionGroups: [],

            ...(props?.node?.data || {}),
        };

        this.state = {
            loading: true,
            selectedTab: this.tabs[0],
            error: '',
            resourceTemplates: [],
            xbrlTags: [],
            previousModelErpNodes: [],
            data,
        };
    }

    updateData = (mutator = {}) => {
        const { data } = this.state;
        this.setState({
            data: {
                ...data,
                ...mutator,
            },
        });
    };

    getData = () => {
        return this.state.data;
    };

    fetchPreviousErpNodes = async () => {
        const { modelId, node } = this.props;

        const { previousModel } = await modelEditor.getModelMetadata(modelId);
        if (!previousModel) {
            return [];
        }

        const previousModelNodes = await modelEditor.getModelNodes(previousModel);
        
        return previousModelNodes.filter(previousNode => {
            return previousNode.type === node.type;
        });
    };


    componentDidMount = async () => {
        const newState = {};

        try {
            const { xbrlTags } = await modelEditor.getModelTags(this.props.modelId);
            newState.xbrlTags = xbrlTags.sort();
            newState.resourceTemplates = await productEngine.getResourceTemplates();
            newState.previousModelErpNodes = await this.fetchPreviousErpNodes();
        } catch (e) {
            newState.error = e.message;
        }

        newState.loading = false;

        this.setState(newState);
    };

    renderGeneralSettings = () => {
        const { resourceTemplates, xbrlTags, data, previousModelErpNodes } = this.state;

        return (
            <Form inverted>
                <Form.Field>
                    <label>
                        Select previous model ERPData node
                        <Tooltip
                            content='Used for rolling mappings from previous year to current year'
                        />
                    </label>
                    <Dropdown
                        selection
                        defaultValue={data.previousERPDataNode}
                        options={previousModelErpNodes.map(node => {
                            return {
                                text: node.name,
                                value: node.id,
                                key: node.id,
                            };
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>
                        Select a resource
                        <Tooltip
                            content='Choosing a resource template will allow connecting resources to accounts in the mapping widget'
                        />
                    </label>
                    <Dropdown
                        selection
                        search
                        defaultValue={data.selectedResourceSlug}
                        onChange={(_, { value }) => this.updateData({ selectedResourceSlug: value })}
                        options={resourceTemplates.map(({ name, slug }) => {
                            return {
                                text: name,
                                value: slug,
                                key: slug,
                            };
                        })}
                    />
                </Form.Field>
                {data.selectedResourceSlug && (
                    <Form.Field>
                        <label>Choose tags that are mappable with the resource</label>
                        <Dropdown
                            selection
                            search
                            multiple
                            defaultValue={data.selectedTags}
                            onChange={(_, { value }) => this.updateData({ selectedTags: value })}
                            options={xbrlTags.map(tag => {
                                return {
                                    text: tag,
                                    value: tag,
                                    key: tag,
                                };
                            })}
                        />
                    </Form.Field>
                )}
            </Form>
        );
    };

    renderClosingSheetSettings = () => {
        return (
            <Segment basic style={{ margin: 0 }}>
                <ClosingSheetQuestionEditor
                    xbrlTags={this.state.xbrlTags || []}
                    closingSheetQuestionGroups={this.state.data.closingSheetQuestionGroups}
                    setClosingSheetQuestionGroups={closingSheetQuestionGroups => this.updateData({ closingSheetQuestionGroups })}
                />
            </Segment>
        );
    };

    render () {
        const { loading, error, selectedTab } = this.state;

        if (loading) {
            return (
                <Loader
                    inline
                    active
                    size='large'
                />
            );
        }

        if (error) {
            return (
                <Message
                    header='Der opstod en fejl'
                    content={error}
                    error
                />
            );
        }

        const menu = (
            <Menu inverted>
                {this.tabs.map(tab => {
                    return (
                        <Menu.Item
                            content={tab.title}
                            active={tab === this.state.selectedTab}
                            onClick={() => this.setState({ selectedTab: tab })}
                        />
                    );
                })}
            </Menu>
        );

        return (
            <div>
                {menu}
                {selectedTab.render()}
            </div>
        );
    }
}

export default NodeDataERPData;