import React, {Component} from 'react';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import cxtmenu from 'cytoscape-cxtmenu';
import edgehandles from 'cytoscape-edgehandles';

cytoscape.use(edgehandles);
cytoscape.use(cxtmenu);
cytoscape.use(dagre);

/*
 * BASED ON: https://github.com/ybarukh/react-cytoscape
 */
class ReactCytoscape extends Component {

    shouldComponentUpdate () {
        return this.props.editor;
    }

    getCyID() {
        return this.props.containerID || 'cy';
    }

    getContainer() {
        return this.container;
    }

    defaultStyle() {
        return [
            {
                selector: 'node',
                css: {
                    'content': ele => ele.data('label') || ele.data('id'),
                    'text-valign': 'center',
                    'text-halign': 'center'
                }
            },
            {
                selector: '$node > node',
                css: {
                    'padding-top': '10px',
                    'padding-left': '10px',
                    'padding-bottom': '10px',
                    'padding-right': '10px',
                    'text-valign': 'top',
                    'text-halign': 'center',
                    'background-color': '#bbb'
                }
            },
            {
                selector: 'edge',
                css: {
                    'target-arrow-shape': 'triangle'
                }
            },
            {
                selector: ':selected',
                css: {
                    'background-color': 'black',
                    'line-color': 'black',
                    'target-arrow-color': 'black',
                    'source-arrow-color': 'black'
                }
            }
        ]
    }

    style() {
        return this.props.style || this.defaultStyle();
    }

    elements() {
        return this.props.elements || {};
    }

    layout() {
        return this.props.layout || { name: 'cola' };
    }

    cytoscapeOptions() {
        return this.props.cytoscapeOptions || {};
    }

    build() {
        this.cy = cytoscape({
            boxSelectionEnabled: false,
            autounselectify: true,
            wheelSensitivity: 0.25,
            maxZoom: 4,
            minZoom: 0.25,

            // make above attributes overwriteable through "cytoscapeOptions"
            ...this.cytoscapeOptions(),

            container: this.getContainer(),
            style: this.style(),
            elements: this.elements(),
            layout: this.layout(),
        });

        if (this.props.cyRef) {
            this.props.cyRef(this.cy);
        }

        return this.cy;
    }

    componentWillUnmount() {
        this.clean();
    }

    componentDidMount() {
        this.build();
    }

    componentDidUpdate() {
        this.clean();
        this.build();
    }

    clean() {
        if (this.cy) {
            this.cy.destroy();
        }
    }

    getContainerStyle = () => {
        const containerStyle = this.props.containerStyle || {};
        const defaultStyle = {
            height: '100%',
            width: '100%',
            display: 'block',
            backgroundColor: 'white',
        };

        return {
            ...defaultStyle,
            ...containerStyle,
        };
    };

    render = () => {
        return (
            <div
                id={this.getCyID()}
                style={this.getContainerStyle()}
                ref={ele => this.container = ele}
            />
        );
    };
}

export default ReactCytoscape;
