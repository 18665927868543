import { Client } from './client';
import { API_LOCATION } from '../config';

const cvrClient = new Client(`${API_LOCATION}/cvr`);

function getSammenligningstal (cvr, date) {
    return cvrClient.getResource(`/${cvr}/sammenligningstal/${date}`);
}

const cvr = {
    getSammenligningstal,
};

export default cvr;