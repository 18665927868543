import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';
import readableID from 'shared/readableID';

const makeProductKey = (productID, taxYear) => `${productID}.${taxYear}`;

const TaxYearPurchaseMatrice = ({ purchases = [] }) => {
    const { lowestTaxYear, highestTaxYear, products, countMap } = useMemo(() => {
        // render only relevant tax years
        let lowestTaxYear = Infinity;
        let highestTaxYear = -Infinity;

        // render only relevant products
        const encounteredProducts = new Set();

        const formattedStats = {};

        for (const { productID, taxYear } of purchases) {
            encounteredProducts.add(productID);

            // update highest/lowest tax year
            const nty = Number(taxYear);
            if (nty < lowestTaxYear) {
                lowestTaxYear = nty;
            }
            
            if (nty > highestTaxYear) {
                highestTaxYear = nty;
            }

            const productKey = makeProductKey(productID, taxYear);

            formattedStats[productKey] ||= 0;
            formattedStats[productKey]++;
        }

        return {
            lowestTaxYear,
            highestTaxYear,
            products: [...encounteredProducts],
            countMap: formattedStats,
        };
    }, [purchases]);

    const renderTableHeader = () => {
        const cells = [];
        
        for (let i = lowestTaxYear; i <= highestTaxYear; i++) {
            cells.push(<Table.HeaderCell textAlign='center'>{i}</Table.HeaderCell>);
        }

        return <Table.Header>
            <Table.Row>
                <Table.HeaderCell />
                {cells}
            </Table.Row>
        </Table.Header>;
    };

    const renderTableRow = (productID) => {
        const cells = [];

        for (let i = lowestTaxYear; i <= highestTaxYear; i++) {
            const key = makeProductKey(productID, i);
            const count = countMap[key] || 0;

            cells.push(<Table.Cell textAlign='center'>{count}</Table.Cell>);
        }

        return <Table.Row>
            <Table.Cell>{readableID(productID)}</Table.Cell>
            {cells}
        </Table.Row>;
    };

    return (
        <Table definition>
            {renderTableHeader()}
            <Table.Body>
                {products.map(productID => renderTableRow(productID))}
            </Table.Body>
        </Table>
    )
};

export default TaxYearPurchaseMatrice;