import React from 'react';
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react';
import cn from 'shared/cns';
import styles from './ColoredText.module.css';

function ColoredText ({
    content,
    children,
    color,
    size,
    float,
    link,
    newWindow,
    bold,
    italic,
    padded,
    noWrap,
    icon,
    iconPosition,
    underlined,
    lineThrough,
    disabled,
    loading,
    style,
    className,
    ...props
}) {
    const cns = new Set([styles[color]]);
    if (className) {
        cns.add(className);
    }

    if (!disabled && link) {
        cns.add(styles.link);
        if (underlined !== false) {
            cns.add(styles.underlined);
        }
    }

    if (styles[size]) {
        cns.add(styles[size]);
    }

    if (bold) {
        cns.add(styles.bold);
    }

    if (italic) {
        cns.add(styles.italic);
    }

    if (underlined) {
        cns.add(styles.underlined);
    }

    if (lineThrough) {
        cns.add(styles.lineThrough);
    }

    if (padded) {
        cns.add(styles.padded);   
    }

    if (disabled) {
        cns.add(styles.disabled);
    }

    if (noWrap) {
        cns.add(styles.noWrap);
    }

    if (icon) {
        if (!React.isValidElement(icon)) {
            const iconToUse = loading ? 'spinner' : icon;
            icon = <Icon
                name={iconToUse}
                loading={loading}
            />;
        }
    }

    let leftIcon;
    let rightIcon;
    if (icon) {
        if (iconPosition === 'left') {
            leftIcon = icon;
        } else {
            rightIcon = icon;
        }
    }

    const textJSX = (
        <span
            className={cn(...[...cns])}
            style={{ float, ...(style || {}) }}
            {...props}
        >
            {leftIcon && <span style={{ textDecoration: 'none' }}>{leftIcon}</span>}
            {content || children}
            {rightIcon && <span> {rightIcon}</span>}
        </span>
    );

    if (typeof link === 'string' && link) {
        if (/^http(s):\/\//.test(link)) {
            // outgoing link
            return (
                <a
                    children={textJSX}
                    href={link}
                    rel='noopener noreferrer'
                    target={newWindow ? '_blank' : undefined}
                />
            );
        }

        // internal link
        return <Link to={link}>
            {textJSX}
        </Link>;
    }

    return textJSX;
}

export default ColoredText;