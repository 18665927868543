import lodash from 'lodash';
import React, { useState } from 'react';
import { Button, Dropdown, Form, Icon, Input, Popup, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createSlug } from 'shared/createSlug';

import styles from './RadioQuestionEditor.module.css';

const createCleanQuestion = () => ({
    label: '',
    value: '',
    tooltip: '',
});

const CreateAnswerPortal = ({ trigger, onCreate, open, onOpen, onClose }) => {
    const [answer, setAnswer] = useState(createCleanQuestion());
    const [valueTouched, setValueTouched] = useState(false);

    const update = mutator => {
        setAnswer({ ...answer, ...mutator });
    };

    const doCreate = () => {
        if (!answer.label) {
            toast.error('Label er påkrævet');
            return;
        }

        if (!answer.value) {
            toast.error('Svarværdi er påkrævet');
            return;
        }

        onCreate(answer);
        setValueTouched(false);
        setAnswer(createCleanQuestion());
    };

    const doClose = () => {
        setValueTouched(false);
        onClose();
    };

    const answerForm = (
        <Form className={styles.portalForm}>
            <Form.Field>
                <label>Label</label>
                <Input
                    autoFocus
                    placeholder='Label'
                    defaultValue={answer.label}
                    onChange={(_, { value }) => {
                        const updator = { label: value };

                        // suggest slug, if answer value not touched yet
                        if (!valueTouched) {
                            updator.value = createSlug(value);
                        }

                        update(updator);
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>Svarværdi</label>
                <Input
                    placeholder='Svarværdi'
                    value={answer.value}
                    onChange={(_, { value }) => {
                        setValueTouched(true);
                        update({ value });
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>Tooltip</label>
                <Input
                    style={{ opacity: 1 }}
                    placeholder='Tooltip'
                    defaultValue={answer.tooltip}
                    onChange={(_, { value }) => {
                        update({ tooltip: value });
                    }}
                />
            </Form.Field>
            <Form.Field>
                <Button
                    primary
                    onClick={doCreate}
                    fluid
                    content='Opret'
                    icon='plus'
                />
            </Form.Field>
        </Form>
    );

    return (
        <Popup
            trigger={trigger}
            content={answerForm}
            position='left center'
            on='click'
            open={open}
            onOpen={onOpen}
            onClose={doClose}
        />
    );
};

const AnswerTable = ({ answers, addAnswer, updateAnswer, deleteAnswer, renderRowOptions, taxonomyValues }) => {
    const [creating, setCreating] = useState(false);

    const hasTaxonomyValues = taxonomyValues?.length > 0;

    const hasAnswers = () => {
        return answers?.length > 0;
    };

    const renderTableHeader = () => {
        if (!hasAnswers()) {
            return null;
        }

        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Svarværdi</Table.HeaderCell>
                    <Table.HeaderCell>Label</Table.HeaderCell>
                    {hasTaxonomyValues && <Table.HeaderCell>Taksonomiværdi</Table.HeaderCell>}
                    <Table.HeaderCell>Tooltip</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    };

    const renderTableBody = () => {
        if (!hasAnswers()) {
            return null;
        }

        return (
            <Table.Body>
                {
                    answers?.map((answer, idx) => {
                        const { label, value, taxonomyValue, tooltip } = answer;

                        // render optional additional options for given row
                        let additionalOptions;
                        if (renderRowOptions instanceof Function) {
                            additionalOptions = renderRowOptions({
                                ...answer,
                                idx,
                            });
                        }

                        return (
                            <Table.Row key={label}>
                                <Table.Cell>
                                    <Input
                                        className={styles.tableInput}
                                        disabled
                                        value={value}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Input
                                        className={styles.tableInput}
                                        defaultValue={label}
                                        onBlur={e => updateAnswer(idx, { label: e.target.value })}
                                    />
                                </Table.Cell>
                                {
                                    hasTaxonomyValues &&
                                    (
                                        <Table.Cell>
                                            <Dropdown
                                                selection
                                                search
                                                fluid
                                                value={taxonomyValue}
                                                onChange={(_, { value }) => updateAnswer(idx, { taxonomyValue: value })}
                                                options={taxonomyValues?.map(option => {
                                                    return {
                                                        text: option,
                                                        value: option,
                                                    };
                                                })}
                                            />
                                        </Table.Cell>
                                    )
                                }
                                <Table.Cell>
                                    <Input
                                        className={styles.tableInput}
                                        defaultValue={tooltip}
                                        onBlur={e => updateAnswer(idx, { tooltip: e.target.value })}
                                    />
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    {additionalOptions}
                                    <Icon
                                        link
                                        name='x'
                                        color='red'
                                        size='large'
                                        onClick={() => deleteAnswer(idx)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })
                }
            </Table.Body>
        );
    };

    const renderTableFooter = () => {
        return (
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.Cell colSpan={4}>
                        <CreateAnswerPortal
                            trigger={
                                <Button
                                    primary={!creating}
                                    content='Opret svarmulighed'
                                    labelPosition='left'
                                    icon='plus'
                                />
                            }
                            open={creating}
                            onOpen={() => setCreating(true)}
                            onClose={() => setCreating(false)}
                            onCreate={answer => {
                                addAnswer(answer);
                                setCreating(false);
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        );
    };

    return (
        <>
            <Table basic='very'>
                {renderTableHeader()}
                {renderTableBody()}
                {renderTableFooter()}
            </Table>
        </>
    );
};

const RadioQuestionEditor = ({ answers = [], onChange, renderRowOptions, taxonomyValues }) => {
    const addAnswer = answer => {
        const newAnswers = [...answers, answer];
        onChange(newAnswers);
    };

    const updateAnswer = (idx, updator) => {
        const newAnswers = [...answers];
        for (let [key, value] of Object.entries(updator)) {
            lodash.set(newAnswers[idx], key, value);
        }
        onChange(newAnswers);
    };

    const deleteAnswer = (idx) => {
        const newAnswers = [...answers];
        newAnswers.splice(idx, 1);
        onChange(newAnswers);
    };

    return (
        <AnswerTable
            answers={answers}
            addAnswer={addAnswer}
            updateAnswer={updateAnswer}
            deleteAnswer={deleteAnswer}
            renderRowOptions={renderRowOptions}
            taxonomyValues={taxonomyValues}
        />
    );
};

export default RadioQuestionEditor;