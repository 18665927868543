import React, { useState } from 'react';
import { Loader, Message, Pagination, Table } from 'semantic-ui-react';
import { countCreditTransactionEvents, getCreditTransactionEvents } from 'http/credits';
import useRestResource from 'shared/hooks/useRestResource';
import { formatUnix } from 'shared/dateTimeFormatting';

const PAGE_SIZE = 10;
const COL_SPAN = 3;

const CreditTransactions = ({ uid }) => {
    const [pageIndex, setPageIndex] = useState(0);

    const countResource = useRestResource({
        fetcher: () => countCreditTransactionEvents(uid),
        args: [uid],
    });
    const eventsResource = useRestResource({
        fetcher: () => getCreditTransactionEvents(uid, pageIndex, PAGE_SIZE),
        args: [uid, pageIndex],
    });

    const amountOfTransactions = countResource.data;
    const totalPages = Math.ceil(amountOfTransactions / PAGE_SIZE);

    const renderSingleRow = content => {
        return (
            <Table.Row>
                <Table.Cell colSpan={COL_SPAN}>
                    {content}
                </Table.Cell>
            </Table.Row>
        );
    };
    
    const renderTableBody = () => {
        const isLoading = eventsResource.loading || countResource.loading;
        if (isLoading) {
            return renderSingleRow(<Loader active inline='centered' />);
        }
        
        const errorOccured = eventsResource.error || countResource.error;
        if (errorOccured) {
            return renderSingleRow(<Message content='Der opstod en fejl' error />);
        }
        
        const currentTransactions = eventsResource.data;
        return (
            currentTransactions.map(({ timestamp, comment, balanceChange, productName, taxYear, clientName }) => {
                const text = [productName, taxYear, clientName, comment].filter(x => x).join(' ∙ ');

                return (
                    <Table.Row key={timestamp}>
                        <Table.Cell>{formatUnix(timestamp)}</Table.Cell>
                        <Table.Cell>{text}</Table.Cell>
                        <Table.Cell textAlign='right'>
                            {balanceChange > 0 && '+'}{balanceChange}
                        </Table.Cell>
                    </Table.Row>
                );
            })
        );
    };

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Tidspunkt</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Balanceændring</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {renderTableBody()}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan={COL_SPAN} textAlign='center'>
                        {!countResource.loading && (
                            <Pagination
                                activePage={pageIndex + 1}
                                totalPages={totalPages}
                                onPageChange={(_, { activePage }) => setPageIndex(activePage - 1)}
                            />
                        )}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );

};

export default CreditTransactions;