import React from 'react';
import { Form, Icon, Input, Label } from 'semantic-ui-react';
import CopyToClipboard from 'atoms/CopyToClipboard';

import styles from './ValueDisplayer.module.css';

const ValueDisplayer = ({ label, value, copyable }) => {
    if (!value) {
        return null;
    }

    let copyBtn;
    if (value && copyable) {
        copyBtn = <CopyToClipboard text={value}>
            <Label
                basic
                className={styles.copy}
                content={<Icon name='copy' fitted />}
            />
        </CopyToClipboard>;
    }

    return <Form.Field>
        <label>{label}</label>
        <Input
            className={styles.valueDisplayer}
            value={value}
            label={copyBtn}
            labelPosition={copyBtn ? 'right' : undefined}
            disabled
        />
    </Form.Field>;
};

export default ValueDisplayer;