import React, { useEffect, useState } from 'react';
import { Divider, Form } from 'semantic-ui-react';
import Hint, { HintTypes } from './Hint';

import Checkbox from 'atoms/Checkbox';
import Dropdown from 'atoms/Dropdown';

import _ from 'lodash';
import { cssAggregateClasses } from 'shared/utility';
import './HintManager.css';

const HintManager = props => {
    const { hintType, dataManager } = props;
    const { getCurrentField, updateField } = dataManager;

    const [selectedHintType, setSelectedHintType] = useState(hintType || HintTypes.BASIC);

    useEffect(() => {
        /* Make sure that if no hint type has been read from the backend, we set the `<currentField>.hint.type` to a safe value */
        if(!hintType) {
            updateField('hint.type', selectedHintType);
        }
    }, []);

    const prepareHintSelection = () => {
        const hintSelection = Object.entries(HintTypes).map(([key, value]) => ({
            key,
            text: value,
            value
        }));
        return hintSelection;
    };

    /* Toggle controls whether to show hint manager content */
    const renderToggle = () => {
        const associateWithHintToggle = (
            <Form.Field>
                <label>Associate with hint?</label>
                <Checkbox
                    onChange={e => updateField('isAssociatedWithHint', e.target.value)}
                    checked={_.get(getCurrentField(), 'isAssociatedWithHint', false)}
                    toggle
                />
            </Form.Field>
        );
        return associateWithHintToggle;
    };

    const renderContent = () => {
        const hintSelection = prepareHintSelection();
        return (
            <>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Hint type</label>
                        <Dropdown
                            placeholder='Select hint type'
                            fluid
                            selection
                            options={hintSelection}
                            onChange={event => {
                                const newHintType = event.target.value;
                                setSelectedHintType(newHintType);
                                updateField('hint.type', newHintType);
                            }}
                            defaultValue={selectedHintType}
                        />
                    </Form.Field>
                </Form.Group>
                <Divider />
                <Hint hintType={selectedHintType} dataManager={dataManager} />
            </>
        );
    };

    const hintManagerSectionStyle = cssAggregateClasses([
        { _class: 'hint-manager' },
        { _class: 'hint-manager-active', predicate: () => getCurrentField().isAssociatedWithHint }
    ]);

    const hintManagerContentStyle = cssAggregateClasses([
        { _class: 'hint-manager-content' },
        { _class: 'hint-manager-content-active', predicate: () => getCurrentField().isAssociatedWithHint }
    ]);

    return (
        <div className={hintManagerSectionStyle}>
            {renderToggle()}
            <div className={hintManagerContentStyle}>
                <Divider />   
                {renderContent()}
            </div>
        </div>
    );
};

export default HintManager;
