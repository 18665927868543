import { localeDate } from 'shared/dateTimeFormatting';

const formatPeriod = ({ instant, startDate, endDate }) => {
    if (instant) {
        return localeDate(instant);
    }

    return `${localeDate(startDate)} - ${localeDate(endDate)}`;
};

export default formatPeriod;