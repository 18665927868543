import React from 'react';
import { Input } from 'semantic-ui-react';
import SubSection from './SubSection';

const NumberRule = props => {
    const { disabled, rule, updateRule } = props;

    const inputField = (
        <Input
            type='number'
            placeholder='Indtast et tal'
            disabled={disabled}
            defaultValue={rule?.compareValue}
            onChange={(_, { value }) => updateRule({ compareValue: value })}
        />
    );

    return (
        <SubSection
            input={inputField}
            {...props}
        />
    );
};

export default NumberRule;