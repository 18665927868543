import modelEditor from 'http/modelEditor';

export const getRadioQuestionOptions = async (modelID, questionID) => {
    const questionNode = await modelEditor.getNodeByID(modelID, questionID);

    const radioOptions = questionNode.data?.options || [];

    return radioOptions.map(({ optionText, optionValue }) => ({
        text: optionText,
        value: optionValue,
    }));
};