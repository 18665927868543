import React from 'react';
import { List } from 'semantic-ui-react';

const StatDisplayer = ({ stats }) => {
    return <div style={{ textAlign: 'center' }}>
        <List horizontal divided>
            {
                stats.map((stat, i) => {
                    return (
                        <List.Item>
                            {stat}
                        </List.Item>
                    );
                })
            }
        </List>
    </div>;
};

export default StatDisplayer;