import React from 'react';
import Page from "../atoms/Page";
import {Card, List} from 'semantic-ui-react'

const PIPELINES = [
    'accountplans',
    'admin',
    'api',
    'app',
    'billy',
    'cvr',
    'digital-signature',
    'dinero',
    'document-generator',
    'events',
    'e-conomic',
    'e-mail',
    'file-storage',
    `internal-finances`,
    'invoice-generator',
    'model-editor',
    'payment',
    'product-engine',
    'revisorbot',
    'tax-calculations',
    'uniconta',
    `xbrl-document`
];

class Welcome extends React.Component {

    render() {

        return <Page>
            <Card.Group>
                <Card>
                    <Card.Content>
                        <Card.Header>Link to Prod</Card.Header>
                        <Card.Description>
                            <List bulleted>
                                <List.Item><a href='https://app.digitalrevisor.nu'>App</a></List.Item>
                                <List.Item><a href='https://admin.digitalrevisor.nu'>Admin</a></List.Item>
                                <List.Item><a href='https://www.digitalrevisor.nu'>Webflow</a></List.Item>
                            </List>
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card>
                    <Card.Content>
                        <Card.Header>Link to Dev</Card.Header>
                        <Card.Description>
                            <List bulleted>
                                <List.Item><a href='https://dev.digitalrevisor.nu'>App</a></List.Item>
                                <List.Item><a href='https://admin.dev.digitalrevisor.nu'>Admin</a></List.Item>
                                <List.Item><a href='https://digitalrevisor.webflow.io'>Webflow</a></List.Item>
                            </List>
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card>
                    <Card.Content>
                        <Card.Header>Links to Systems</Card.Header>
                        <Card.Description>
                            <List bulleted>
                                <List.Item><a href='https://docs.digitalrevisor.nu'>Docs</a></List.Item>
                                <List.Item><a href='https://gitlab.com/Digital-Revisor'>GitLab</a></List.Item>
                                <List.Item><a href='https://console.cloud.google.com/home/dashboard?project=digitalrevisor-ops'>Google Cloud Console</a></List.Item>
                            </List>
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.Group>
            <Card>
                <Card.Content>
                    <Card.Header>Build Pipeline Status</Card.Header>
                    <Card.Description>
                        <List>
                            {PIPELINES.map((repo, i) => {
                                return <List.Item key={repo}><a href={`https://gitlab.com/Digital-Revisor/${repo}/commits/master`} key={i}><img alt={repo} src={`https://gitlab.com/Digital-Revisor/${repo}/badges/master/pipeline.svg`} /> {repo}</a></List.Item>
                            })}
                        </List>
                    </Card.Description>
                </Card.Content>
            </Card>
        </Page>
    }
}

export default Welcome;
