import React, { useCallback, useState } from 'react';
import { Dropdown, Form, Icon, Message, Segment, Table } from 'semantic-ui-react';
import paymentHttp from 'http/payment';
import useRestResource from 'shared/hooks/useRestResource';
import { firstDayOfMonth } from 'shared/dateTimeFormatting';
import DatePicker from 'atoms/DatePicker';
import { formatNumber } from 'shared/formatNumber';

const getDefaultDates = () => {
    const start = firstDayOfMonth();
    
    let end = firstDayOfMonth();
    end.setMonth(end.getMonth() + 1);
    
    return [start, end];
};

const BookkeeperScoreboard = () => {
    const [defStart, defEnd] = getDefaultDates();

    const [startDate, setStartDate] = useState(defStart);
    const [endDate, setEndDate] = useState(defEnd);
    const [limit, setLimit] = useState(10);

    const scoreboardFetcher = useCallback(() => {
        return paymentHttp.getAccountantPurchasesInRange(startDate, endDate, limit);
    }, [startDate, endDate, limit]);

    const { error, data } = useRestResource({
        fetcher: scoreboardFetcher,
        args: [startDate, endDate, limit],
    });

    if (error) {
        return <Message error content='Der opstod en fejl' />;
    }
    
    const limitOptions = [];
    for (let i = 3; i <= 50; i++) {
        limitOptions.push({
            text: i,
            value: i,
        });
    }

    const searchOptions = (
        <Segment>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Fra</label>
                        <DatePicker value={startDate} onChange={date => setStartDate(new Date(date))} />
                    </Form.Field>
                    <Form.Field>
                        <label>Til (ikke inklusiv)</label>
                        <DatePicker value={endDate} onChange={date => setEndDate(new Date(date))} />
                    </Form.Field>
                    <Form.Field>
                        <label>Antal (max 50)</label>
                        <Dropdown
                            selection
                            search
                            options={limitOptions}
                            defaultValue={limit}
                            onChange={(_, { value }) => setLimit(Number(value))}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment> 
    );

    const table = (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Placering</Table.HeaderCell>
                    <Table.HeaderCell>Navn</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Antal køb</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Estimeret indkomst</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {(data || []).map(({ displayName, count }, idx) => {
                    const trophyIndices = ['yellow', 'grey', 'brown'];

                    return (
                        <Table.Row>
                            <Table.Cell>
                                #{idx + 1}
                                {trophyIndices[idx] && (
                                    <Icon
                                        name='trophy'
                                        color={trophyIndices[idx]}
                                        style={{ marginLeft: '0.5em' }}
                                    />
                                )}
                            </Table.Cell>
                            <Table.Cell>{displayName}</Table.Cell>
                            <Table.Cell textAlign='right'>{count}</Table.Cell>
                            <Table.Cell textAlign='right'>{formatNumber(count * 695)} kr.</Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );

    return (
        <>
            {searchOptions}
            {table}
        </>
    );
};

export default BookkeeperScoreboard;