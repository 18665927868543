import PDFViewer from 'molecules/PDFViewer';
import React, { useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';

const TestPDFViewer = ({ pdfURL, sections, error }) => {
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        if (!sections) {
            return;
        }

        const activeSection = sections?.find(section => section.defaultActive);
        const startPageIndex = activeSection?.pageNumber - 1 || 0;

        setPageIndex(startPageIndex);
    }, [sections]);

    const renderPageMenu = () => {
        if (!sections) {
            return null;
        }

        const activePageNumber = pageIndex + 1;
        const activePage = sections.find(({ pageNumber }, idx) => {
            const lower = pageNumber;
            const upper = sections[idx + 1]?.pageNumber || Infinity;

            return activePageNumber >= lower && activePageNumber < upper;
        });

        return (
            <Menu fluid vertical secondary>
                {sections.map((page) => {
                    const isActive = activePage === page;
                    const { pageNumber, sectionTitle } = page;
                    return (
                        <Menu.Item
                            active={isActive}
                            content={sectionTitle}
                            onClick={() => setPageIndex(pageNumber - 1)}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        />
                    );
                })}
            </Menu>
        );
    };

    
    const renderContent = () => {
        if (error) {
            return error;
        }
        
        const sideElementsWidth = '200px';
        
        return (
            <>
                <div style={{ width: sideElementsWidth }}>
                    {renderPageMenu()}
                </div>
                <PDFViewer
                    pdfURL={pdfURL}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                />
                <div style={{ width: sideElementsWidth }} />
            </>
        );
    };

    return (
        <div
            children={renderContent()}
            style={{
                display: 'flex',
                justifyContent: 'center',
                background: 'lightgray',
                padding: '1em' ,
            }}
        />
    );
};

export default TestPDFViewer;