import JWTDecode from 'jwt-decode';
import { API_LOCATION } from '../config';

export function refreshAccessToken () {
    return new Promise((resolve, reject) => {
        fetch(`${API_LOCATION}/token/access`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            },
            mode: 'cors'
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    localStorage.accessToken = res.data;
                }
                resolve();
            })
            .catch(reject);
    });
}

class ErrorWithData extends Error {
    constructor(message, data) {
        super(message);
        this.data = data;
    }
}


export class Client {
    constructor(API_LOCATION) {
        this.API_LOCATION = API_LOCATION;
    }

    async _resource (method, path, data) {
        const resp = await method(path, data);
        if (!resp.success) {
            throw new ErrorWithData(resp.message, resp.data);
        }
        return resp.data;
    }

    async get(path, doEncodeURI = true) {
        const init = await this.getInitialization('GET');
        return await this.superFetch(`${this.API_LOCATION}${path}`, init, doEncodeURI);
    }

    async getResource(path) {
        return this._resource(this.get.bind(this), path);
    }

    async post(path, data) {
        const init = await this.getInitialization('POST');
        init['body'] = JSON.stringify(data);
        return await this.superFetch(`${this.API_LOCATION}${path}`, init);
    }

    async postResource(path, data) {
        return this._resource(this.post.bind(this), path, data);
    }

    async patchResource(path, data) {
        return this._resource(this.patch.bind(this), path, data);
    }

    async put(path, data) {
        const init = await this.getInitialization('PUT');
        init['body'] = JSON.stringify(data);
        return await this.superFetch(`${this.API_LOCATION}${path}`, init);
    }

    async putResource(path, data) {
        return this._resource(this.put.bind(this), path, data);
    }

    async delete(path, data) {
        const init = await this.getInitialization('DELETE');
        init['body'] = JSON.stringify(data);
        return await this.superFetch(`${this.API_LOCATION}${path}`, init);
    }

    async deleteResource(path, data) {
        return this._resource(this.delete.bind(this), path, data);
    }

    async patch(path, data) {
        const init = await this.getInitialization('PATCH');
        init['body'] = JSON.stringify(data);
        return await this.superFetch(`${this.API_LOCATION}${path}`, init);
    }

    async superFetch(target, options, doEncodeURI = true) {
        if (doEncodeURI) {
            target = encodeURI(target);
        }
        try {
            let response = await fetch(target, options);
            if (parseInt(response.status, 10) === 401) {
                console.log('Attempting refetch in ½ a sec');
                await new Promise(resolve => setTimeout(resolve, 500)); // Pause for 500 ms3
                
                const refreshToken = localStorage.getItem("token");
                const accessToken = await this.fetchAccessToken(refreshToken); // Update access token
                options.headers["Authorization"] = `Bearer ${accessToken}` // Attach new acces token to new response
                response = await fetch(target, options); // Retry original fetch
                const data = await response.json() // Parse response to JSON
                
                if (response.ok) return data; // Return success
                else throw new Error('Refetch attempt failed'); // Throw error on a failed second attempt
            } else if (response.ok) {
                const data = await response.json() // Parse response to JSON
                return data;
            } else {
                try {
                    const data = await response.json() // Parse response to JSON
                    return data;
                } catch {
                    return null;
                }
            }
        } catch (e) {
            console.log(`Failed to fetch ${target} with error ${e.message}`);
            console.log(e);
            throw new Error(e);
        }

    }

    async fetchFile(path) {
        const init = {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/xml'
            },
            mode: 'cors',
            cache: 'default',
            credentials: 'include'
        };
        const token = localStorage.getItem("accessToken");
        if (token) {
            init.headers["Authorization"] = `Bearer ${token}`;
        }
        const blob = await fetch(`${this.API_LOCATION}${path}`, init).then((result) => {
            return result.blob();
        });
        return blob;
    }

    async getInitialization(method) {
        let init = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            credentials: 'include'
        };
        // If the user is logged in, add the JWT token
        init = await this.addAccessToken(init);
        return init;
    }

    async addAccessToken(init) {
        const refreshToken = localStorage.getItem("token"); // Get refresh token
        if (refreshToken) { // If there is a refresh token we wanna attach a access token to the request since the user is logged in
            let accessToken = localStorage.getItem("accessToken"); // Attempt to get access token
            if (!accessToken) { // If theres is straight up no token well fetch a new one
                accessToken = await this.fetchAccessToken(refreshToken);
            } else {
                const decodedAccessToken = JWTDecode(accessToken);
                const expTime = new Date(decodedAccessToken.exp * 1000);
                const now = new Date();
                if (expTime <= now) { // If there is a access token but its expired, we fetch a new one
                    accessToken = await this.fetchAccessToken(refreshToken);
                }
            }
            init.headers["Authorization"] = `Bearer ${accessToken}`; // Attach the access token to the request
        }
        return init;
    }

    async fetchAccessToken(refreshToken) {
        const accessData = await fetch(`${API_LOCATION}/token/access`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`
            },
            mode: 'cors',
            cache: 'default',
        })
            .then(result => result.json());
        if (accessData.data) {
            localStorage.setItem('accessToken', accessData.data);
            return accessData.data
        } else {
            throw('Could not fetch accessToken', accessData)
        }
    }
}

const client = new Client(API_LOCATION);

export default client;
