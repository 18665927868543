import loadScript from './loadScript';

let workerConfigured = false;

export const loadPdfjs = async () => {
    const version = '2.2.228';
    const hostAndPath = `cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}`;

    await loadScript(`https://${hostAndPath}/pdf.min.js`, {
        crossorigin: 'anonymous',
        referrerpolicy: 'no-referrer',
    });

    const pdfjs = window.pdfjsLib;

    if (!workerConfigured) {
        const pdfjsWorkerSrc = `//${hostAndPath}/pdf.worker.js`;
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerSrc;

        workerConfigured = true;
    }

    return pdfjs;
};