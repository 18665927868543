import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Icon, Segment } from 'semantic-ui-react';
import { setQueryParam } from 'shared/queryParams';
import ColoredText from 'atoms/ColoredText';

import { tabIds } from '.';
import { setConfigValue } from './config';
import * as blockService from './blockService';
import { inputEditorTabs } from './InputEditor';
import model from './model';


const goToBuildingBlock = (error, { pdfTemplate, setPdfTemplate, setTab }) => {
    const blocks = [...pdfTemplate?.blocks];
    blockService.expandTargetBlock(blocks, error.blockID);
    setPdfTemplate({
        ...pdfTemplate,
        blocks,
    });

    const schemaCategory = error.schemaCategory || model.schemaCategories.meta;
    const categoryIndex = Object.keys(model.schemaCategories).findIndex(category => {
        return category === schemaCategory;
    });
    setConfigValue('selectedBlockTab', categoryIndex === -1 ? 0 : categoryIndex);

    setConfigValue('selectedField', error.blockID);
    setTab(tabIds.blocks);
};

const goToTextVariation = (error, { setTab }) => {
    setQueryParam('tag', error.textVariationTag);
    setConfigValue('inputEditorSelectedTab', inputEditorTabs.textVariations);
    setTab(tabIds.inputs);
};

const goToInputDefinition = (error, { setTab }) => {
    setQueryParam('definition', error.inputDefinition);
    setConfigValue('inputEditorSelectedTab', inputEditorTabs.inputDefinitions);
    setTab(tabIds.inputs);
};

const getErrorType = error => {
    if (error.blockID) {
        return {
            header: 'Building block error',
            linkText: 'Go to faulty building block',
            linkHandler: goToBuildingBlock,
            preMessage: ({ pdfTemplate }) => {
                const block = pdfTemplate.blocks.find(block => block.id === error.blockID);
                const blockType = model.blockTypes[block.kind];
                const blockTypeJSX = (
                    <b>
                        <Icon name={blockType.icon} />
                        {blockType.label}
                    </b>
                );
                
                const schemaCategory = model.schemaCategories[error.schemaCategory];
                const blockCategoryJSX = schemaCategory && (
                    <span>
                        &nbsp;in the&nbsp;
                        <b>
                            <Icon name={schemaCategory.semanticIcon} />
                            {schemaCategory.name}
                        </b>
                        &nbsp;category
                    </span>
                );

                return (
                    <span>
                        Error in {blockTypeJSX} block{blockCategoryJSX}:
                    </span>
                );
            },
        };
    }

    if (error.textVariationTag) {
        return {
            header: 'Text variation error',
            linkText: 'Go to faulty text variation',
            linkHandler: goToTextVariation,
            preMessage: () => {
                return (
                    <span>
                        Error in text varation with tag <b>{error.textVariationTag}</b>:
                    </span>
                );
            },
        };
    }

    if (error.inputDefinition) {
        return {
            header: 'Input definition error',
            linkText: 'Go to input definitions',
            linkHandler: goToInputDefinition,
        };
    }

    return {
        header: 'Error',
    };
};

const ValidationError = ({ error, ...props }) => {
    if (!error) {
        return null;
    }

    const errorType = getErrorType(error);

    return (
        <Segment.Group>
            <Segment>
                <Icon name='warning sign' color='red' />
                <b>{errorType.header}</b>
            </Segment>
            <Segment>
                {errorType.preMessage && errorType.preMessage(props)}
                <ReactMarkdown>{error.message}</ReactMarkdown>
            </Segment>
            {
                errorType.linkText &&
                <Segment secondary>
                    <ColoredText
                        link
                        bold
                        underlined={false}
                        iconPosition='left'
                        icon='arrow right'
                        content={errorType.linkText}
                        onClick={() => errorType.linkHandler(error, props)}
                    />
                </Segment>
            }
        </Segment.Group>
    );
};

export default ValidationError;
