import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Navigation from './navigation';

// subpages
import FeatureFlags from './FeatureFlags';
import EventsMaster from './EventsMaster';
import Sammenligningstal from './Sammenligningstal';
import ReportingPeriodTest from './ReportingPeriodTest';

/*
    <NavItem onClick={this.closeMenu} to='/events' name='event' label='Aktivitet'/>
    <NavItem onClick={this.closeMenu} to='/sammenligningstal' name='format_list_numbered' label='Sammenligningstal'/>
    <NavItem onClick={this.closeMenu} to='/feature-flags' name='flag' label='Feature flag'/>
    <NavItem onClick={this.closeMenu} to='/reporting-period' name='work' label='Skatteår til regnskabsår'/>

    <Route path='/events' component={EventsMaster} />
    <Route exact path='/sammenligningstal' component={Sammenligningstal} />
    <Route exact path='/feature-flags' component={FeatureFlags} />
    <Route exact path='/reporting-period' component={ReportingPeriodTest} />
*/

function Email () {
    return (
        <Container>
            <Navigation />
            <Switch>
                <Route path='/development/feature-flags' component={FeatureFlags} />
                <Route path='/development/events' component={EventsMaster} />
                <Route path='/development/sammenligningstal' component={Sammenligningstal} />
                <Route path='/development/reporting-period' component={ReportingPeriodTest} />

                <Redirect from='/development' to='/development/feature-flags' exact />
            </Switch>
        </Container>
    );
}

export default Email;