import React, { useEffect, useState } from 'react';
import { Loader, Message, Header, Table, Icon, Modal, Button, Form, Input, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import useRestResource from 'shared/hooks/useRestResource';
import payment from 'http/payment';
import accounts from 'http/accounts';
import deepCopy from 'shared/deepCopy';
import AutoStamp from 'atoms/AutoStamp';
import ColoredText from 'atoms/ColoredText';

const AccountPackagePurchases = ({ uid, addonProductID, purchases, refetch }) => {
    const [selectedPurchase, setSelectedPurchase] = useState(null);

    const handleSave = async () => {
        const shouldDoUpdate = '_id' in selectedPurchase;

        if (shouldDoUpdate) {
            await payment.putAddonProductData(uid, addonProductID, selectedPurchase._id, selectedPurchase.data);
        } else {
            await payment.registerAddonProductOwnership(uid, addonProductID, selectedPurchase.data);
        }

        toast.success(`Adgangen blev ${shouldDoUpdate ? 'opdateret' : 'oprettet'}`);

        refetch();
        setSelectedPurchase(null);
    };

    const handleDelete = async () => {
        await payment.deleteAddonProductPurchase(uid, addonProductID, selectedPurchase._id);
        toast.success(`Adgangen blev fjernet`);
        refetch();
        setSelectedPurchase(null);
    };

    const sortedByTaxYear = [...purchases].sort((a, b) => {
        return Number(b.data.taxYear) - Number(a.data.taxYear);
    });

    const editPurchaseModal = selectedPurchase && (
        <Modal open onClose={() => setSelectedPurchase(null)}>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Skatteår</label>
                        <Input
                            defaultValue={selectedPurchase.data.taxYear}
                            onChange={(_, { value }) => setSelectedPurchase({ ...selectedPurchase, data: { ...selectedPurchase.data, taxYear: value } })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Calendly booking (fjern for at nulstille)</label>
                        <Input
                            defaultValue={selectedPurchase.data.calendlyBookingLink}
                            onChange={(_, { value }) => setSelectedPurchase({ ...selectedPurchase, data: { ...selectedPurchase.data, calendlyBookingLink: value } })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {selectedPurchase._id && (
                    <Popup
                        basic
                        on='click'
                        position='top center'
                        trigger={
                            <Button
                                basic
                                floated='left'
                                color='red'
                                content='Slet'
                                icon='x'
                            />
                        }
                        content={
                            <ColoredText
                                content='Bekræft'
                                icon='x'
                                iconPosition='left'
                                underlined={false}
                                onClick={handleDelete}
                                link
                            />
                        }
                    />
                )}
                
                <Button
                    content='Annuller'
                    color='black'
                    onClick={() => setSelectedPurchase(null)}
                />
                <Button
                    content='Gem'
                    color='green'
                    icon='save'
                    onClick={handleSave}
                />
            </Modal.Actions>
        </Modal>
    );

    return (
        <div>
            {editPurchaseModal}
            <Header>Revisorpakken</Header>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>Købstidspunkt</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Skattår</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Har booket møde?</Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Header>
                <Table.Body>
                    {sortedByTaxYear.map((purchase) => {
                        const { accessGrantedTimestamp, data } = purchase;
                        return (
                            <Table.Row>
                                <Table.Cell>
                                    <AutoStamp stamp={accessGrantedTimestamp} />
                                </Table.Cell>
                                <Table.Cell textAlign='center'>{data.taxYear}</Table.Cell>
                                <Table.Cell textAlign='center'>
                                    <Icon
                                        name={data.calendlyBookingLink ? 'check' : 'x'}
                                        color={data.calendlyBookingLink ? 'green' : 'black'}
                                    />
                                </Table.Cell>
                                <Table.Cell textAlign='right'>
                                    <Icon name='pencil' link onClick={() => setSelectedPurchase(deepCopy(purchase))} />
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan={4} textAlign='right'>
                            <Button
                                primary
                                icon='plus'
                                content='Giv adgang til revisorpakken'
                                onClick={() => setSelectedPurchase({ data: {} })}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

const AccountantCoursePurchases = ({ purchases }) => {
    const [enrichedPurchases, setEnrichedPurchases] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const enrichPurchasesPromises = Promise.all(purchases.map(async ({ accessGrantedTimestamp, data }) => {
            const associatedLogin = await accounts.getLoginByID(data.courseAttendeeLoginID);
            return {
                accessGrantedTimestamp: accessGrantedTimestamp,
                associatedLogin
            };
        }));

        enrichPurchasesPromises
            .then(enrichedPurchases => setEnrichedPurchases(enrichedPurchases))
            .finally(() => setLoading(false))
    }, [purchases]);

    if (loading) {
        return (
            <Loader inline='centered' active />
        );
    }

    if (!enrichedPurchases) {
        return (
            <Message error content='Der opstod en fejl' />
        );
    }

    return (
        <div>
            <Header>Adgange købt til rådgiverkurset</Header>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>Købstidspunkt</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Login der har fået adgang</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {enrichedPurchases.map(({ accessGrantedTimestamp, associatedLogin }) => {
                        return (
                            <Table.Row>
                                <Table.Cell>
                                    <AutoStamp stamp={accessGrantedTimestamp} />
                                </Table.Cell>
                                <Table.Cell textAlign='right'>{associatedLogin.loginEmail}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </div>
    );
};

const AddonProducts = ({ account }) => {
    const uid = account.id;

    const { loading, error, data: addonProductPurchases, refetch } = useRestResource({
        fetcher: () => payment.getAllCustomerPurchasedAddonProducts(uid),
        args: [uid],
    });

    if (loading) {
        return (
            <Loader inline='centered' active />
        );
    }

    if (error) {
        return (
            <Message error content={error} />
        );
    }

    const groupedByProductID = {};
    for (const purchase of addonProductPurchases) {
        groupedByProductID[purchase.productID] ||= [];
        groupedByProductID[purchase.productID].push(purchase);
    }

    return (
        <>
            {account.roles.includes('privat') && (
                <AccountPackagePurchases
                    addonProductID={'accountantSparring'}
                    uid={uid}
                    purchases={groupedByProductID.accountantSparring || []}
                    refetch={refetch} 
                />
            )}

            {account.roles.includes('revisor') && (
                <AccountantCoursePurchases
                    purchases={groupedByProductID.accountantCourse || []}
                
                />
            )}
        </>
    );
};

export default AddonProducts;