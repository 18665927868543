import React from 'react';
import { Menu } from 'semantic-ui-react';
import { useReportTemplatesConfig } from '../config'; 
import InputDefinitionsEditor from './InputDefinitionsEditor';
import UserContextEditor from './UserContextEditor';
import TextVariationsEditor from './TextVariationsEditor';
import ModelConnectionEditor from './ModelConnectionEditor';

export const inputEditorTabs = {
    inputDefinitions: 'Input definitions',
    userContext: 'User context test values',
    textVariations: 'Text variations',
    modelSynchronization: 'Model synchronization',
};

const DEFAULT_TAB = inputEditorTabs.inputDefinitions;

const InputEditor = (props) => {
    const [selectedTab, setSelectedTab] = useReportTemplatesConfig('inputEditorSelectedTab', DEFAULT_TAB);

    const tabs = [
        [InputDefinitionsEditor, inputEditorTabs.inputDefinitions, 'sitemap'],
        [TextVariationsEditor, inputEditorTabs.textVariations, 'ordered list'],
        [ModelConnectionEditor, inputEditorTabs.modelSynchronization, 'share alternate'],
        [UserContextEditor, inputEditorTabs.userContext, 'user'],
    ];

    const [ComponentToRender] = tabs[tabs.findIndex(([_, name]) => name === selectedTab)];

    return (
        <>
            <Menu tabular attached='top' style={{ marginBottom: '-1px' }}>
                {tabs.map(([_, name, icon]) => {
                    const isActive = name === selectedTab;
                    return (
                        <Menu.Item
                            key={name}
                            active={isActive}
                            content={name}
                            icon={icon}
                            link={!isActive}
                            style={{ background: isActive ? '#fafafa' : undefined }}
                            onClick={() => setSelectedTab(name)}
                        />
                    );
                })}
            </Menu>
            <ComponentToRender {...props} />
        </>
    );
};

export default InputEditor;