import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Header, Icon, List, Progress, Segment, Table } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { roleMap } from 'shared/roles';
import accounts from 'http/accounts';
import payment from 'http/payment';
import AudienceSelector from 'molecules/AudienceSelector';
import AutoStamp from 'atoms/AutoStamp';
import User from 'shared/User';
import { injectPaymentInformation } from 'shared/payment';
import { partitionArray, renameProperty } from 'shared/utility';

import TooltipIcon from '../TooltipIcon';
import AccountsTable from '../AccountsTable';

const ReportingEntitiesMaster = ({ initAudience, forceAdvancedView, onAudienceSelectorChanged, audienceSelectorRef, campaign }) => {
    const [products, setProducts] = useState([]);
    const [working, setWorking] = useState(true);
    const [audience, setAudience] = useState({});
    const [tempAudience, setTempAudience] = useState({});
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [searchProgress, setSearchProgress] = useState(0);

    const accountsTableRef = useRef();

    useEffect(() => {
        if(initAudience) {
            setTempAudience(initAudience);
            setAudience(initAudience);
        }
        if(forceAdvancedView) {
            setAdvancedSearch(true);
        }

        const doFetchProducts = async () => {
            const products = await payment.getSAASProducts();

            setProducts(products);
            setWorking(false);
        };

        doFetchProducts();
    }, []);

    const accountsFetcher = useCallback(async (...args) => {
        let fetchedAccounts = [];
        setSearchProgress(0);

        if(advancedSearch) {
            const searchResult = await accounts.performUserSearch({ audience });
            const id = 'id';
            const preparedAccounts = User.unmarshalUserList(searchResult.result).map(x => renameProperty(x, `_${id}`, id));

            const BULK_CAPACITY = 50;
            const bulks = partitionArray(preparedAccounts, BULK_CAPACITY);
            let processedBulks = 0;
            for(let i = 0; i < bulks.length; i++) {
                fetchedAccounts.push(...await injectPaymentInformation(bulks[i]));
                setSearchProgress(Math.ceil((++processedBulks / bulks.length) * 100));
            }
        } else {
            const { page, filter, direction, sortBy } = args[0];
            const paginatedAccounts = await accounts.getAccountsPaginated(page - 1, { filter, direction, sortBy });
            fetchedAccounts = await injectPaymentInformation(paginatedAccounts);
        }
        return fetchedAccounts;
    }, [advancedSearch, audience]);

    const history = useHistory();

    const getSubtitleRoles = (roles) => {
        return (
            Object.values(roleMap)
            .filter(role => roles.includes(role.id))
            .filter(role =>
                // disclude roles that make up the main title
                [
                    roleMap.privat.id,
                    roleMap.erhverv.id,
                    roleMap.revisor.id,
                    roleMap.akademi.id,
                ].includes(role.id) === false,
            )
            .map(role => role.name)
        );
    };

    const getEntityLink = entityID => `/accounts/reporting-entities/${entityID}`;

    const downloadAsCSV = () => accountsTableRef.current?.downloadAsCSV();

    const renderEntityRowCells = entity => {
        const subtitleComponents = [
            entity.cvr,
            entity.getTitle(),
            ...getSubtitleRoles(entity.roles),
        ];

        const getProduct = id => {
            return products.find(product => product.id === id);
        };

        const accumulateClientPurchases = () => {
            let totalClientPurchases = 0;

            for (let taxYears of Object.values(entity.clientStats)) {
                for (let { count } of Object.values(taxYears)) {
                    totalClientPurchases += count;
                }
            }

            return totalClientPurchases.toString();
        };

        return (
            <>
                <Table.Cell verticalAlign='middle'>
                    <Header size='tiny'>
                        <Icon name={entity.getIcon()} />
                        <Header.Content>
                            {entity.getDisplayName()}
                            <Header.Subheader>
                                {subtitleComponents.filter(v => !!v).join(' ∙ ')}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>

                {/* render active subscriptions as green icons */}
                <Table.Cell textAlign='center'>
                    {entity.subscriptions?.map(subscription => {
                        // lookup associated product
                        const product = getProduct(subscription);
                        const descriptiveName = product?.descriptiveName || '';
                        const semanticIcon = product?.semanticIcon || '';
                        
                        return (
                            <TooltipIcon
                                content={descriptiveName}
                                icon={semanticIcon}
                                color='green'
                            />
                        );
                    })}
                </Table.Cell>

                {/* render purchases as icons w/ tooltips */}
                <Table.Cell textAlign='center'>
                    {/* owned tax years (regular users) */}
                    {Object.entries(entity.ownedProducts).map(([productID, years]) => {
                        const product = getProduct(productID);
                        const descriptiveName = product?.descriptiveName || '';
                        const semanticIcon = product?.semanticIcon || '';

                        return (
                            <TooltipIcon
                                icon={semanticIcon}
                                text={years.length}
                                content={
                                    <span>
                                        {descriptiveName}
                                        <List bulleted>
                                            {years.sort().map(year => <List.Item>{year}</List.Item>)}
                                        </List>
                                    </span>
                                }
                            />
                        );
                    })}

                    {/* owned onetime payments */}
                    {entity.onetimePayments.map(({ name, icon }) => (
                        <TooltipIcon
                            icon={icon}
                            content={name}
                        />
                    ))}

                    {/* client stats (accountants only) */}
                    {
                        entity.roles.includes(roleMap.revisor.id) &&
                        <TooltipIcon
                            icon='users'
                            text={accumulateClientPurchases()}
                            content='Antal klientskatteår købt'
                        />
                    }
                </Table.Cell>
                <Table.Cell>
                    <AutoStamp stamp={entity.createdAt} />
                </Table.Cell>
                <Table.Cell>
                    <AutoStamp stamp={entity.latestActivity} />
                </Table.Cell>
            </>
        );
    };

    const renderContent = () => {
        return (
            <AccountsTable
                key={advancedSearch}
                columns={[
                    {
                        title: 'Navn',
                    },
                    {
                        title: 'Abonnementer',
                        textAlign: 'center',
                    },
                    {
                        title: 'Køb',
                        textAlign: 'center',
                    },
                    {
                        title: 'Oprettet',
                        sortProperty: 'createdAt',
                    },
                    {
                        title: 'Seneste aktivitet',
                        sortProperty: 'latestActivity',
                    },
                ]}
                maxPageSize={20}
                loading={working}
                onRowClicked={entity => history.push(getEntityLink(entity.id))}
                onRowCtrlClicked={entity => window.open(getEntityLink(entity.id), '_blank')}
                renderRowCells={renderEntityRowCells}
                fetchTotalPages={accounts.countAccounts}
                fetchPage={accountsFetcher}
                search={!advancedSearch}
                defaultSort='createdAt'
                searchPlaceholder='Søg på navn eller CVR-nummer...'
                usePagination={!advancedSearch}
                ref={accountsTableRef}
                advancedSearch={advancedSearch}
                limit={50}
            />
        );
    };

    return (
        <>
            {
                !forceAdvancedView &&
                <div style={{ textAlign: 'center' }}>
                    <br />
                    <Checkbox
                        label='Avanceret søgning'
                        onChange={(_, { checked }) => setAdvancedSearch(checked)}
                    />
                </div>
            }
            { advancedSearch && (
                <>
                    <Segment>
                        <AudienceSelector
                            onChange={tempAudience => {
                                setTempAudience({...tempAudience});
                                onAudienceSelectorChanged && onAudienceSelectorChanged(tempAudience);
                            }}
                            defaultOpen={false}
                            defaultValue={audience}
                            campaign={campaign}
                            ref={audienceSelectorRef}
                        />
                        <Button primary fluid onClick={() => { setAudience(tempAudience) }}>Søg</Button>
                        <Header>Søgefremskridt</Header>
                        <Progress percent={searchProgress} indicating={searchProgress !== 100} />
                    </Segment>
                </>
            )}
            {renderContent()}
            { advancedSearch &&
                <Button primary fluid onClick={downloadAsCSV}>Gem som CSV</Button>
            }
        </>
    );
};

export default ReportingEntitiesMaster;