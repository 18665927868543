import React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { getTaxYearPurchaseTransactions } from 'http/credits';
import useRestResource from 'shared/hooks/useRestResource';
import TaxYearPurchaseMatrice from '../TaxYearPurchaseMatrice';

const PurchaseMatrice = ({ uid }) => {
    const { loading, error, data } = useRestResource({
        fetcher: () => getTaxYearPurchaseTransactions(uid),
        args: uid,
    });

    if (loading) {
        return (
            <Loader inline='centered' active />
        );
    }

    if (error) {
        return (
            <Message error content='Der opstod en fejl' />
        );
    }

    return (
        <TaxYearPurchaseMatrice purchases={data} />
    );
};

export default PurchaseMatrice;