import React, { useEffect, useState } from 'react';
import { Icon, Loader, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import accounts from 'http/accounts';
import AutoStamp from 'atoms/AutoStamp';

const Logins = ({ uid }) => {
    const [working, setWorking] = useState(false);
    const [logins, setLogins] = useState([]);

    useEffect(() => {
        const doFetchLogins = async () => {
            const logins = await accounts.getAccountLogins(uid);

            setLogins(logins);
            setWorking(false);
        };

        doFetchLogins();
    }, [uid]);

    const renderLoginsTable = () => {
        return (
            <Table basic='very'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Email
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Dato tiltrådt
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Tilknytning
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {logins.map(({ id, email, dateJoined, role }) => (
                        <Table.Row key={id}>
                            <Table.Cell>
                                <Link to={`/accounts/logins/${id}`}>
                                    {email}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>
                                <AutoStamp stamp={dateJoined} />
                            </Table.Cell>
                            <Table.Cell>
                                <Icon name={role.icon} color={role.color} />
                                {role.title}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        );
    };

    return (
        working ?
        <Loader inline='centered' active /> :
        renderLoginsTable()
    );
};

export default Logins;