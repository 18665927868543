import React, { useState } from 'react';
import { Button, Header, Icon, Input, Popup } from 'semantic-ui-react';
import email from 'http/email';
import MasterTable from 'molecules/MasterTable';
import { localeDate, localeTime } from 'shared/dateTimeFormatting';
import { withRouter } from 'react-router-dom';

const getCampaigns = async () => {
    const campaigns = await email.getCampaigns();
    
    campaigns.forEach(campaign => {
        if (campaign.isSent) {
            campaign.statusSortKey = '2-Sendt';
        } else if (campaign.sendDate) {
            campaign.statusSortKey = `0-Planlagt-${new Date(campaign.sendDate).toISOString()}`;
        } else {
            campaign.statusSortKey = '1-Ikke afsendt';
        }
    });

    return campaigns;
};

function CampaignsMaster ({ history }) {
    const [working, setWorking] = useState(false);
    const [campaignName, setCampaignName] = useState('');

    const goToCampaign = id => {
        history.push(`/emails/campaigns/${id}`);
    };

    const createCampaign = async () => {
        if (!campaignName) {
            return;
        }

        setWorking(true);
        const campaign = await email.createCampaign(campaignName);
        goToCampaign(campaign.id);
    };

    const formatIsSent = (isSent, sendDate, timeSent) => {
        let text;
        let icon;
        let color;
        if (isSent) {
            text = 'Afsendt';
            icon = 'check';
            color = 'green';
        } else if(sendDate) {
            const formattedSendDate = new Date(sendDate).toLocaleString('da-DK', {
                year: '2-digit', 
                month: 'numeric', 
                day: 'numeric',
                hour: 'numeric', 
                minute: 'numeric'
            });

            text = formattedSendDate;
            icon = 'clock';
            color = 'yellow';
        } else {
            text = 'Ikke afsendt';
            icon = 'ellipsis horizontal';
        }
        const iconJSX = <span>
            <Icon name={icon} color={color} /> {text}
        </span>;
        
        if(isSent) {
            return <Popup 
                trigger={iconJSX}
                position='top center'
                content={`Sendt d. ${localeDate(timeSent)}`}
                inverted
            />;
        } else if(sendDate) {
            return <Popup 
                trigger={iconJSX}
                position='top center'
                content={`Afsendelse planlagt til d. ${localeDate(sendDate)} kl. ${localeTime(sendDate)}`}
                inverted
            />;
        } else {
            return iconJSX;
        }
    };

    const createCampaignButton = <Button
        onClick={createCampaign}
        disabled={working || !campaignName}
        content='Opret kampagne'
        primary
    />;

    return <div>
        <Header as='h2'>
            <Icon name='send' />
            <Header.Content>
                Kampagner
                <Header.Subheader>
                    Opret, se og redigér kampagner
                </Header.Subheader>
            </Header.Content>
        </Header>
        <MasterTable
            headers={['Navn', 'Oprettet d.', 'Status', 'Oprettet af']}
            sortProperties={['name', 'createdAt', 'statusSortKey', 'timeSent']}
            defaultSort='createdAt'
            formatRow={({ name, createdAt, isSent, timeSent, creator, sendDate }) => [
                name,
                localeDate(createdAt),
                formatIsSent(isSent, sendDate, timeSent),
                creator,
            ]}
            fetchResources={getCampaigns}
            onRowClicked={({ id }) => goToCampaign(id)}
            deleteResource={({ id }) => email.deleteCampaign(id)}
            deleteResourceMessage={({ name }) => `Vil du slette kampagnen med navn "${name}"?`}
            rowOptions={['delete']}
            createForm={<Input
                onChange={(_, { value }) => setCampaignName(value)}
                onKeyDown={e => e.key === 'Enter' && createCampaign()}
                placeholder='Navn på kampagne'
                labelPosition='right'
                label={createCampaignButton}
            />}
            filterBy='name'
            paginated
            sortable
        />
    </div>;
}

export default withRouter(CampaignsMaster);