import { Client } from './client';
import { API_LOCATION } from '../config';
import { makeQueryParams } from 'shared/queryParams';

const trainingdataClient = new Client(`${API_LOCATION}/trainingdata`);

export function getMappings() {
    return trainingdataClient.getResource('/mappings');
}

export function countAccounts(accountTypes) {
    return trainingdataClient.getResource('/accounts/count' + makeQueryParams({
        accountTypes,
    }));
}

export function getAccounts(accountTypes, limit, lastID) {
    return trainingdataClient.getResource('/accounts' + makeQueryParams({
        accountTypes,
        limit,
        lastID,
    }));
}

export function upsertMappings(mappings) {
    return trainingdataClient.putResource('/mappings', mappings);
}