import client from './client';

/**
 * @typedef EmailOptions
 * @type {object}
 * @property {string} [previewText]
 * @property {string} [senderName]
 * @property {string} [receiverName]
 */

 /**
  * @param {string} receiver 
  * @param {string} template 
  * @param {string} subject 
  * @param {EmailOptions} options 
  */
function sendEmail (receiver, template, subject, options = {}) {
    const { previewText, senderName, receiverName } = options;
    return client.post('/e-mail', {
        receiver,
        template,
        subject,
        previewText,
        senderName,
        receiverName
    });
}

 /**
  * @param {string[]} receivers 
  * @param {string} template 
  * @param {string} subject 
  * @param {EmailOptions} options 
  */
function enqueueEmails (receivers, template, subject, options = {}) {
    const { previewText, senderName } = options;
    return client.post('/e-mail/enqueue', {
        receivers,
        template,
        subject,
        previewText,
        senderName
    });
}

function sendServiceEmail({ subject, body, receiver, link }) {
    return client.postResource('/e-mail/servicemail', {
        subject,
        body,
        receiver,
        link,
    });
}

function validateTemplate (template) {
    return client.post('/e-mail/templates/validate', { template });
}

function getTemplateVariables () {
    return client.getResource('/e-mail/templates/variables');
}

function getTemplates () {
    return client.getResource('/e-mail/templates');
}

function createTemplate (name) {
    return client.postResource('/e-mail/templates', { name });
}

function deleteTemplate (id) {
    return client.deleteResource(`/e-mail/templates/${id}`);
}

function copyTemplate (id) {
    return client.postResource(`/e-mail/templates/${id}/copy`);
}

function getTemplate (id) {
    return client.getResource(`/e-mail/templates/${id}`);
}

function patchTemplate (id, toUpdate = {}) {
    return client.patchResource(`/e-mail/templates/${id}`, toUpdate);
}

function getCampaigns () {
    return client.getResource('/e-mail/campaigns');
}

function getOnboardingCampaigns () {
    return client.getResource('/e-mail/campaigns?onboarding=true');
}

function getCampaign (id) {
    return client.getResource(`/e-mail/campaigns/${id}`);
}

function createCampaign (name, onboarding = false) {
    return client.postResource('/e-mail/campaigns', { name, onboarding });
}

function deleteCampaign (id) {
    return client.deleteResource(`/e-mail/campaigns/${id}`);
}

function patchCampaign (id, toUpdate = {}) {
    return client.patchResource(`/e-mail/campaigns/${id}`, toUpdate);
}

function sendCampaign (id, expectedReceiverCount) {
    return client.postResource(`/e-mail/campaigns/${id}/send`, { expectedReceiverCount });
}

function sendCampaignTestEmail (id, testEmail) {
    return client.postResource(`/e-mail/campaigns/${id}/test`, { testEmail });
}

function createNotification (title) {
    return client.postResource(`/notifications`, { title });
}

function patchNotification (id, toUpdate = {}) {
    return client.patchResource(`/notifications/${id}`, {id , toUpdate,});
}

function getNotifications () {
    return client.getResource('/notifications');
}

function getNotification (id) {
    return client.getResource(`/notifications/${id}`, id);
}

function sendNotification (id, expectedReceiverCount, userID) {
    return client.postResource(`/notifications/${id}`, { expectedReceiverCount, userID });
}

function sendTestNotification (id, userID, testNotification) {
    return client.postResource(`/notifications/${id}/test`, { userID, testNotification });
}

function deleteNotification (id) {
    return client.deleteResource(`/notifications/${id}`);
}

function getNotificationsWithoutLoginID () {
    return client.getResource('/notifications/noLoginID');
}


const email = {
    sendEmail,
    enqueueEmails,
    sendServiceEmail,
    validateTemplate,
    getTemplateVariables,

    getTemplates,
    getTemplate,
    createTemplate,
    deleteTemplate,
    patchTemplate,
    copyTemplate,

    getCampaigns,
    getCampaign,
    createCampaign,
    deleteCampaign,
    patchCampaign,
    sendCampaign,
    sendCampaignTestEmail,
    getOnboardingCampaigns,

    createNotification,
    patchNotification,
    getNotifications,
    getNotification,
    sendNotification,
    deleteNotification,
    getNotificationsWithoutLoginID,
    sendTestNotification
};

export default email;
