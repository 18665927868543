import React from 'react';
import { Segment } from 'semantic-ui-react';
import NodeDataView from "./NodeDataView";

class NodeDataVSO extends React.Component {
    getView() {
        let view = null;
        if (this.data && this.data.getView) {
            view = this.data.getView();
        }
        return view;
    }

    render() {
        const { modelId, node } = this.props;

        return <>
            <Segment inverted>
                <NodeDataView ref={data => this.data = data} node={node} modelId={modelId} />
            </Segment>
        </>;
    }

}

export default NodeDataVSO;