import { APP_TITLE } from 'config';
import { toast } from 'react-toastify';
import purge from './purge';

function onAppStartup (publicModelEditorMode) {
    toast.configure({
        position: toast.POSITION.BOTTOM_CENTER,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        bodyClassName: 'Toastify__centered-text',
    });

    purge();

    document.title = publicModelEditorMode ? 'Models' : APP_TITLE;
}

export default onAppStartup;