import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Loader, Segment, Table } from 'semantic-ui-react';
import http from 'http/payment';
import AutoStamp from 'atoms/AutoStamp';
import { formatNumber } from 'shared/formatNumber';
import { formatInvoiceMethod } from './format';

class InvoiceTable extends Component {
    state = {
        invoices: [],
        loading: true,
    };

    setLoading = loading => this.setState({ loading });

    work = async fn => {
        this.setLoading(true);
        await fn();
        this.setLoading(false);
    };
    
    componentDidMount = async () => {
        await this.fetchInvoices();
        this.setLoading(false);
    };

    componentDidUpdate = async prevProps => {
        if (prevProps.showPaid !== this.props.showPaid) {
            await this.work(this.fetchInvoices);
        }
    };

    fetchInvoices = async () => {
        const { showPaid } = this.props;
        const invoices = await http.getAccountantInvoices({ paid: Number(showPaid) });
        this.setState({ invoices });
    };
    
    renderLoading = () => {
        return <Segment basic textAlign='center'>
            <Loader inline active />
        </Segment>;
    };

    renderInvoice = invoice => {
        const {
            invoiceID,
            receiverName,
            dateOfIssue,
            invoiceMethod,
            totalPrice
        } = invoice;
        return (
            <Table.Row>
                <Table.Cell>
                    <Link to={`/payment/invoices/${invoiceID}`}>
                        {invoiceID}
                    </Link>
                </Table.Cell>
                <Table.Cell>
                    {receiverName}
                </Table.Cell>
                <Table.Cell>
                    <AutoStamp stamp={Date.parse(dateOfIssue)} />
                </Table.Cell>
                <Table.Cell>
                    {formatInvoiceMethod(invoiceMethod)}
                </Table.Cell>
                <Table.Cell>
                    {formatNumber(totalPrice, true)} kr.
                </Table.Cell>
            </Table.Row>
        );
    };

    renderInvoices = () => {
        const { invoices } = this.state;
        return invoices.map(this.renderInvoice);
    };

    renderInvoiceTable = () => {
        return <Table>
            <Table.Header>
                <Table.HeaderCell>Fakturanr.</Table.HeaderCell>
                <Table.HeaderCell>Rådgiver</Table.HeaderCell>
                <Table.HeaderCell>Udstedelsesdato</Table.HeaderCell>
                <Table.HeaderCell>Faktureringsmetode</Table.HeaderCell>
                <Table.HeaderCell>Samlet pris</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {this.renderInvoices()}
            </Table.Body>
        </Table>
    };

    render () {
        const { loading } = this.state;
        if (loading) {
            return this.renderLoading();
        }
        return this.renderInvoiceTable();
    }
}

export default withRouter(InvoiceTable);