import React, { useEffect, useState } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import SubSection from './SubSection';

const EnumRule = props => {
    const { disabled, rule, updateRule, getOptions } = props;
    const [loading, setLoading] = useState(!!getOptions);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const loadOptions = async () => {
            if (!getOptions) {
                return;
            }

            const options = await getOptions();
            setOptions(options);
            setLoading(false);
        };
        loadOptions();
    }, [getOptions]);

    const renderInputField = () => {
        const defaultValue = rule?.compareValue;
        const onChange = (_, { value }) => updateRule({ compareValue: value });

        if (getOptions) {
            // has options => render dropdown
            return (
                <Dropdown
                    selection
                    placeholder='Vælg en svarmulighed'
                    options={options}
                    loading={loading}
                    defaultValue={defaultValue}
                    disabled={loading || disabled}
                    onChange={onChange}
                />
            );
        }

        // no options => text field
        return (
            <Input
                placeholder='Indtast svarmulighed'
                disabled={disabled}
                defaultValue={defaultValue}
                onChange={onChange}
            />
        );
    };

    return (
        <SubSection
            input={renderInputField()}
            operators={['==', '!=']}
            {...props}
        />
    );
};

export default EnumRule;