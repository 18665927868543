import React, { Component } from 'react';
import { get } from 'lodash';

const AFLY = 'askForLastYear';

class NumberQuestion extends Component {
    constructor (props) {
        super(props);
        this.state = {
            [AFLY]: get(props, `node.data.${AFLY}`, false),
        };
    }

    getData = () => {
        return {
            [AFLY]: this.state[AFLY],
        };
    };

    onChange = e => {
        this.setState({
            [AFLY]: e.target.checked,
        });
    };

    render = () => <section>
        Spørg også efter sidste års tal
        &nbsp;
        <input
            type='checkbox'
            defaultChecked={this.state[AFLY]}
            onChange={this.onChange}
        />
    </section>;
}

export default NumberQuestion;