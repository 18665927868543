import React from 'react';

const StoreContext = React.createContext();

export class StoreProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: []
        };
    }
    
    updateMenu(newMenuArray) {
        this.setState({
            menu: newMenuArray
        });
    }

    render() {
        return (
            <StoreContext.Provider value={{
                ...this.state,
                updateMenu: this.updateMenu.bind(this)
            }}>
                {this.props.children}
            </StoreContext.Provider>
        );
    }
}

export function withStore(Component) {
    return function StoreComponent(props) {
        return (
            <StoreContext.Consumer>
                { store => <Component {...props} store={store} />}
            </StoreContext.Consumer>
        );
    };
}

export const StoreConsumer = StoreContext.Consumer;
