import React from 'react';
import { Form, TextArea } from 'semantic-ui-react';
import TextInput from 'atoms/TextInput/TextInput';

const HintBasic = props => {
    const { dataManager } = props;
    const { getCurrentField, updateField } = dataManager;

    const currentField = getCurrentField();

    return (
        <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Title</label>
                    <TextInput onChange={e => updateField('hint.data.title', e.target.value)} value={currentField.hint?.data?.title || ''}/>
                </Form.Field>
            </Form.Group>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Description</label>
                    <TextArea onChange={e => updateField('hint.data.description', e.target.value)} value={currentField.hint?.data?.description || ''}/>
                </Form.Field>
            </Form.Group>
        </>
    );
};

export default HintBasic;
