import React from 'react';

class NodeDataDocumentGenerator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            generateInternalRapport: props.node?.data?.generateInternalRapport
        };
    }

    getData() {
        const { generateInternalRapport } = this.state;
        return {
            generateInternalRapport
        };
    }

    render() {
        const { generateInternalRapport } = this.state;
        return <div>
            <label>Generer intern rapport?</label>&nbsp;&nbsp;
            <input
                key={0}
                defaultChecked={generateInternalRapport}
                type='checkbox'
                onChange={e => this.setState({
                    generateInternalRapport: e.target.checked
                })}
            />
        </div>;
    }
}

export default NodeDataDocumentGenerator;
