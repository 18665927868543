import React from 'react';
import { Route, Switch } from 'react-router-dom'
import { Segment } from 'semantic-ui-react';

import OnetimePaymentsMaster from './OnetimePaymentsMaster';
import OnetimePaymentsDetail from './OnetimePaymentDetail';

class OnetimePayments extends React.Component {
    render = () => (
        <Segment>
            <Switch>
                <Route exact path='/payment/onetime-payments' component={OnetimePaymentsMaster}/>
                <Route path='/payment/onetime-payments/:otpid' component={OnetimePaymentsDetail}/>
            </Switch>
        </Segment>
    );
}

export default OnetimePayments;
