import React from 'react';
import UserInformationPortal from '../../molecules/UserInfoPortal/UserInfoPortal';

class Overview extends React.Component {
    render () {
        return (
            <UserInformationPortal />
        );
    }
}

export default Overview;