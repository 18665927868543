import React, { Component } from 'react';
import { Transition, Button } from 'semantic-ui-react';

class JsonView extends Component {

    state = { active: false }

    toggleActive = () => {
        this.setState({ active: !this.state.active })
    }

    render() { 
        return (
            <div>
            <Button onClick={this.toggleActive}>{ this.state.active ? "Hide data" : "Show Data"}</Button>
            <Transition.Group animation="fade down" duration={200}>
                {this.state.active && <pre>{JSON.stringify(this.props.data, null, 3)}</pre>}
            </Transition.Group>
            </div>
        );
    }
}
 
export default JsonView;