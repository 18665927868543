import React from 'react';
import interpret from '@digital-revisor/node-formula-interpreter';
import { calculationVersion } from '@digital-revisor/node-formula-interpreter/lib/version';
import { Checkbox, Divider, Icon, List, Segment } from 'semantic-ui-react';
import { get, set } from 'lodash';

import { dataTypes } from 'model-editor/nodeMetadata';
import CalculationDocumentation from './documentation';
import CalcTester from './calcTester';
import FoldableSegment from '../../../molecules/FoldableSegment';
import http from '../../../../http/modelEditor';
import FormulaInput from 'model-editor/molecules/FormulaInput';

const refRegexp = () => /\$([a-zA-Z_0-9.]+)/gi;

class NodeDataCalculation extends React.Component {
    state = {
        documentation: [],
        settings: {},
        outputBoolean: false,
        neverLocked: false,
        requiresAllDependencies: false,
        code: undefined,
    };

    componentDidMount = async () => {
        this.setState({ code: get(this.props, 'node.data.originalExpression', '') });
        const documentation = await http.getCalculationFunctions();
        const settings = get(this.props.node, 'data.settings') || {};
        const outputBoolean = get(this.props.node, 'data.outputBoolean', false);
        const neverLocked = get(this.props.node, 'data.neverLocked', false);
        const requiresAllDependencies = get(this.props.node, 'data.requiresAllDependencies', false);
        this.setState({ documentation, settings, outputBoolean, neverLocked, requiresAllDependencies });
    };

    replaceNamesWithID = expression => {
        return expression.replace(refRegexp(), s => {
            const node = this.props.nodes.find(n => n.name === s.slice(1));
            if (node) {
                return `$${node.id}`;
            }
            return s;
        });
    };

    getData () {
        const data = {};

        // interpret code & build math tree
        const expression = this.state.code;
        const correctedExpression = this.replaceNamesWithID(expression);
        const mathTree = interpret(correctedExpression);
        // eslint-disable-next-line no-console
        console.log(mathTree, JSON.stringify(mathTree));
        data.mathTree = JSON.stringify(mathTree);
        data.originalExpression = expression;
        data.calculationVersion = calculationVersion;

        data.settings = this.state.settings;
        data.outputBoolean = this.state.outputBoolean;
        data.neverLocked = this.state.neverLocked;
        data.requiresAllDependencies = this.state.requiresAllDependencies;

        return data;
    }

    getDatatype = () => {
        if (this.state.outputBoolean) {
            return dataTypes.BOOLEAN;
        }

        return dataTypes.NUMBER;
    };

    getReferenceNames () {
        const { edges } = this.props.node;
        const { nodes } = this.props;

        return edges.map(e => {
            const node = nodes.find(n => n.id === e);
            if (node) return node.name;
            return null;
        });
    }

    updateCode (code) {
        this.setState({
            code
        });
    }

    renderUsedEdges = () => {
        const expression = this.state.code;
        const { edges } = this.props.node;
        const { nodes } = this.props;

        const usedNodesJSX = edges.map(e => {
            const edge = nodes.find(n => n.id === e);
            if (!edge) {
                console.error('Edge not found: ', e)
            }
            const edgeName = edge ? edge.name : '';
            const regexp = new RegExp(`\\$${edgeName}(?![a-zA-Z_])`);
            const used = regexp.test(expression);

            return {
                used,
                edgeName,
                edgeID: e,
            };
        }).sort((a, b) => {
            if (a.edgeName > b.edgeName) return 1;
            if (a.edgeName < b.edgeName) return -1;
            return 0;
        }).map(({ used, edgeName, edgeID }) => {
            return (
                <List.Item style={{ color: 'black' }} key={edgeID}>
                    <Icon
                        name={used ? 'check' : 'x'}
                        color={used ? 'green' : 'orange'}
                    />
                    {' ' + edgeName}
                </List.Item>
            );
        });

        return <List>{usedNodesJSX}</List>
    };

    renderSettings = () => {
        const { nodes, node } = this.props;
        const settings = this.state.settings;

        if (node.edges.length === 0) {
            return <div>Ingen indstillinger at vise</div>;
        }

        return <List style={{ color: 'black' }}>
            {node.edges.map(e => {
                const node = nodes.find(n => n.id === e);
                const inputSettings = settings[node.id] || {};
                return <List.Item>
                    <Checkbox
                        toggle
                        label={`Brug sidste års værdi for ${node.name}?`}
                        checked={inputSettings.useLastYear}
                        onChange={(_, { checked }) => this.setState(state => {
                            set(state, `settings.${node.id}.useLastYear`, checked);
                            return true;
                        })}
                    />
                </List.Item>;
            })}
        </List>;
    };

    renderCodeInput = () => {
        const { documentation, code } = this.state;
        const referenceNames = this.getReferenceNames();

        if (code === undefined) {
            return null;
        }

        return (
            <FormulaInput
                value={code}
                onChange={code => this.setState({ code })}
                documentation={documentation}
                referenceNames={referenceNames}
            />
        );
    };

    render () {
        const { documentation, outputBoolean, neverLocked, requiresAllDependencies } = this.state;
        const { edges } = this.props.node;
        const { nodes } = this.props;
        const { mathTree } = this.props.node.data;
        
        return (
            <Segment.Group>
                <FoldableSegment header='Udregning' icon='calculator' defaultOpen>
                    {this.renderCodeInput()}
                    {this.renderUsedEdges()}
                </FoldableSegment>
                <FoldableSegment header='Indstillinger' icon='cog'>
                    <Checkbox
                        toggle
                        checked={outputBoolean}
                        onClick={() => this.setState({ outputBoolean: !outputBoolean })}
                        label='Returnér ja/nej fremfor tal?'
                    />
                    <Divider />
                    <Checkbox
                        toggle
                        checked={neverLocked}
                        onClick={() => this.setState({ neverLocked: !neverLocked })}
                        label='Kan køre selvom produktet er låst?'
                    />
                    <Divider />
                    <Checkbox
                        toggle
                        checked={requiresAllDependencies}
                        onClick={() => this.setState({ requiresAllDependencies: !requiresAllDependencies })}
                        label='Kræver at alle afhængighedsnoder skal have en værdi før udregning køres?'
                    />
                    <Divider />
                    {this.renderSettings()}
                </FoldableSegment>
                <FoldableSegment header='Test' icon='check'>
                    <CalcTester nodes={nodes} edges={edges} tokens={mathTree} />
                </FoldableSegment>
                <FoldableSegment header='Udregningsfunktioner' icon='numbered list'>
                    <CalculationDocumentation alwaysOpen documentation={documentation} />
                </FoldableSegment>
            </Segment.Group>
        );
    }
}

export default NodeDataCalculation;