import React from 'react';

class ToggleQuestion extends React.Component {

    constructor (props) {
        super(props);
        const {alternateYes, alternateNo} = this.props.node.data;
        if (alternateYes || alternateNo) {
            this.state = {
                alternateLabels: true
            };
        } else {
            this.state = {
                alternateLabels: false
            };
        }
    }

    getData () {
        const {alternateLabels} = this.state;
        if (alternateLabels && this.choiceOne && this.choiceTwo) {
            return {
                alternateYes: this.choiceOne.value,
                alternateNo: this.choiceTwo.value
            }
        } else {
            return {};
        }
    }

    handleChange (e) {
        const {checked} = e.target;
        this.setState({
            alternateLabels: checked
        });
    }

    render () {
        const {alternateLabels} = this.state;
        const {alternateYes, alternateNo} = this.props.node.data;
        const style = {
            color: alternateLabels? 'white' : 'gray'
        };
        return (
            <section>
                <label>Skal valgmulighederne have andre tekster end 'Ja' og 'Nej'?</label>
                <input type='checkbox' checked={alternateLabels} onChange={this.handleChange.bind(this)}/>
                <br/>
                <label style={style}>Valgmulighed 1 </label>
                <input disabled={!alternateLabels} defaultValue={alternateYes} ref={(r) => this.choiceOne = r}/>
                <br/>
                <label style={style}>Valgmulighed 2 </label>
                <input disabled={!alternateLabels} defaultValue={alternateNo} ref={(r) => this.choiceTwo = r}/>
            </section>
        );
    }
}

export default ToggleQuestion;