import React, { useEffect, useState } from 'react';
import { Container, Form, Header, Icon, Loader, Segment, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { formatUnix } from 'shared/dateTimeFormatting';
import { confirm } from 'shared/globalModal';
import accounts from 'http/accounts';
import ValueDisplayer from '../ValueDisplayer';
import SideMenu from '../SideMenu';
import ActionItem from '../ActionItem';

const pageIdentifiers = {
    LOGIN_DETAIL: 'LOGIN_DETAIL',
    LOGIN_ENTITIES: 'LOGIN_ENTITIES',
};

const LoginsDetail = () => {
    const [working, setWorking] = useState(true);
    const [login, setLogin] = useState({});
    const [activePage, setActivePage] = useState(pageIdentifiers.LOGIN_DETAIL);
    const [reportingEntities, setReportingEntities] = useState([]);
    const params = useParams();

    const formatBool = bool => bool ? 'Ja' : 'Nej';

    useEffect(() => {
        const doFetchLogin = async () => {
            const [login, reportingEntities] = await Promise.all([
                accounts.getLoginByID(params.id),
                accounts.getLoginReportingEntities(params.id),
            ]);

            setLogin(login);
            setReportingEntities(reportingEntities);
            setWorking(false);
        };

        doFetchLogin();
    }, [params.id]);

    const onToggleAdmin = async () => {
        const isAdmin = login.isAdmin;

        const isSure = await confirm({
            header: (
                isAdmin ?
                'Fjern administratorrettigheder' :
                'Gør til administrator'
            ),
            content: 'Er du sikker på at du vil ' + (
                isAdmin ?
                'fjerne brugerens administratorrettigheder?' :
                'tildele brugeren administratorrettigheder?'
            ),
        });

        if (!isSure) {
            return;
        }

        const updatedLogin = await accounts.toggleLoginAdmin(params.id);
        toast.success(
            'Administratorrettigheder' +
            (
                isAdmin ?
                ' fjernet' :
                ' tildelt'
            )
        );
        
        setLogin(updatedLogin);
    };

    const subPages = [
        {
            id: pageIdentifiers.LOGIN_DETAIL,
            title: 'Logindetaljer',
            icon: 'signup',
            render: () => (
                <Form style={{ width: '100%' }}>
                    <ValueDisplayer
                            label='Email'
                            value={login.loginEmail}
                            copyable
                        />
                        <ValueDisplayer
                            label='Telefon'
                            value={login.phone}
                            copyable
                        />
                    <Form.Group widths='equal'>
                        <ValueDisplayer
                            label='Oprettelsesdato'
                            value={formatUnix(login.createdAt)}
                        />
                        <ValueDisplayer
                            label='Sidst logget ind d.'
                            value={formatUnix(login.latestLogin)}
                        />
                    </Form.Group>
                    <ValueDisplayer
                        label='Email verificeret'
                        value={formatBool(login.isVerified)}
                    />
                    <Form.Group widths='equal'>
                        <ValueDisplayer
                            label='Modtager nyhedsbrev'
                            value={formatBool(login.wantsNews)}
                        />
                        <ValueDisplayer
                            label='Modtager driftsmails'
                            value={formatBool(login.wantsStatusMails)}
                        />
                    </Form.Group>
                    <ValueDisplayer
                        label='Er administrator på Digital Revisor'
                        value={formatBool(login.isAdmin)}
                    />
                    <Form.Field>
                        <ActionItem
                            icon='star'
                            content={(login.isAdmin ? 'Fjern ' : 'Tildel ') + 'administratorrettigheder'}
                            onClick={onToggleAdmin}
                        />
                    </Form.Field>
                </Form>
            ),
        },
        {
            id: pageIdentifiers.LOGIN_ENTITIES,
            title: 'Indberetningsenheder',
            icon: 'users',
            render: () => (
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Navn
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Sidst tilgået
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Tilknytning
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {reportingEntities.map(entity => {
                            return (
                                <Table.Row key={entity.uid}>
                                    <Table.Cell>
                                        <Link to={`/accounts/reporting-entities/${entity.uid}`}>
                                            {entity.displayName}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatUnix(entity.lastAccessed)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon name={entity.role?.icon} color={entity.role?.color} />
                                        {entity.role?.title}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            ),
        },
    ];

    const renderHeader = () => {
        let icon;
        let title;

        if (working) {
            icon = 'spinner';
            title = 'Indlæser...';
        } else {
            icon = login.segment === 'privat' ? 'user' : 'building';
            title = login.loginEmail;
        }

        return (
            <Header as='h3'>
                <Icon
                    circular
                    name={icon}
                    loading={working}
                />
                <Header.Content>
                    {title}
                </Header.Content>
            </Header>
        );
    };

    return (
        <Container>
            {renderHeader()}
            <Segment>
                <SideMenu
                    menuItems={subPages}
                    activeItemID={activePage}
                    onMenuItemClicked={newPage => setActivePage(newPage)}
                    content={
                        working ?
                        <Loader inline='centered' active /> :
                        subPages.find(page => page.id === activePage).render()
                    }
                />
            </Segment>
        </Container>
    );
};

export default LoginsDetail;