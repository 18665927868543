import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import TaxonomyParser from 'http/taxonomyParser';

import { countryOptions } from './model';

const MetadataEditor = ({ metadata, setMetadata, isParsingTaxonomy }) => {
    const [taxonomyVersions, setTaxonomyVersions] = useState([]);
    
    useEffect(() => {
        (async () => {
            const taxonomyVersions = await TaxonomyParser.getTaxonomyVersions();
            setTaxonomyVersions(taxonomyVersions);
        })();
    }, []);

    const awaitingTaxonomy = taxonomyVersions.length === 0 || isParsingTaxonomy;

    return (
        <Form style={{ width: '100%', borderSpacing: '0px' }}>
            <Form.Field>
                <label>Internal name</label>
                <Input
                    defaultValue={metadata.name}
                    onChange={(_, { value }) => setMetadata({ ...metadata, name: value })}
                />
            </Form.Field>
            <Form.Field>
                <label>Taxonomy</label>
                <Dropdown
                    selection
                    defaultValue={metadata.taxonomy}
                    placeholder='Select a taxonomy'
                    onChange={async (_, { value }) => {
                        setMetadata({
                            ...metadata,
                            taxonomy: value,
                        });
                    }}
                    options={taxonomyVersions.map(t => ({ key: t.id, value: t.id, text: t.displayName }))}
                    loading={awaitingTaxonomy}
                    disabled={awaitingTaxonomy}
                />
            </Form.Field>
            <Form.Field>
                <label>Supported languages</label>
                <Dropdown
                    options={countryOptions}
                    defaultValue={metadata.supportedLanguages}
                    placeholder='Select language(s)'
                    onChange={(_, { value }) => setMetadata({ ...metadata, supportedLanguages: value })}
                    multiple
                    fluid
                    search
                    selection
                />
            </Form.Field>
        </Form>
    );
};

export default MetadataEditor;