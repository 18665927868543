import client from './client';

const url_root = '/payments/onetime-payments';

function getAllOnetimePayments () {
    return client.getResource(url_root);
}

function getOnetimePayment (optId) {
    return client.get(`${url_root}/${optId}`);
}

function deleteOnetimePayment (optId) {
    return client.delete(`${url_root}/${optId}`);
}

function createOnetimePayment (name, id, price, description, isActive, requiredRoles) {
    return client.post(url_root, {
        name,
        id,
        price,
        description,
        isActive,
        requiredRoles,
    });
}

function updateOnetimePayment (optId, onetimePayment) {
    return client.put(`${url_root}/${optId}`, onetimePayment);
}

function getActivityTriggers () {
    return client.get(`${url_root}/activity-triggers/all`);
}

const methods = {
    getAllOnetimePayments,
    createOnetimePayment,
    getOnetimePayment,
    updateOnetimePayment,
    deleteOnetimePayment,
    getActivityTriggers
};

export default methods;
