import Papa from 'papaparse';
import { downloadCSV } from './downloadFile';

/**
 * @param {string} rawCSV 
 */
export const parseCSV = (rawCSV) => {
    const { data } = Papa.parse(rawCSV);
    const [header, ...rows] = data;

    return rows.filter(row => row.length === header.length).map(row => {
        const out = {};

        for (const colIdx in header) {
            const columnHeader = header[colIdx];
            if (!columnHeader) {
                continue;
            }

            out[columnHeader] = row[colIdx];
        }

        return out;
    });
};

export const downloadArrayAsCSV = (arrayOfObjects, fileName) => {
    const keys = [];
    
    for (const object of arrayOfObjects) {
        for (const key of Object.keys(object)) {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        }
    }

    const csv = new CSV(keys);

    for (const dims of arrayOfObjects) {
        for (let key of keys) {
            const value = dims[key];
            const valueAsString = value?.toString() || '';
            csv.addToRow(key, valueAsString);
        }
        csv.newLine();
    }

    return csv.download(fileName);
};

class CSV {
    /**
     * Constructs a new CSV writer
     * @param {string[]} columnNames - The column names of the CSV
     * @param {string} [seperator] - The char that seperates the values. Default char is ';'
     */
    constructor (columnNames, seperator = ';') {
        this.seperator = seperator;
        this.columnNames = columnNames;
        this.currentRow = {};
        this.data = '';
    }

    _currentRowAsString () {
        const out = [];
        this.columnNames.forEach(cn => {
            out.push(this.currentRow[cn] || '');
        });
        return out.join(this.seperator) + '\n';
    }

    /**
     * Puts the specified value into the column of the current row
     * @param {string} colName - The column to write to
     * @param {string} data - The data to write
     */
    addToRow (colName, data) {
        this.currentRow[colName] = data;
    }

    /**
     * Writes the current row to the buffer and prepares a new row
     */
    newLine () {
        this.data += this._currentRowAsString();
        this.currentRow = {};
    }

    /**
     * Queues the browser to download the CSV.
     * If the current row buffer contains data, it is written to the main buffer 
     * before the download is triggered
     * @param {string} filename - Desired filename. The .csv extension is added automatically
     */
    download (filename = 'data') {
        if (Object.keys(this.currentRow) > 0) {
            this.newLine();
        }
        const header = this.columnNames.join(this.seperator) + '\n';
        const out = header + this.data;

        downloadCSV(out, `${filename}.csv`);
    }
}

export default CSV;