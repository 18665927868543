import { Route, Switch, Link, useHistory } from 'react-router-dom';
import { Button, Header, Popup } from 'semantic-ui-react';
import { Manager as LoginManager, EnsureLoggedIn, withUser } from 'shared/LoginManager';
import { appContentContainerID } from 'shared/appContentContainer';
import { StoreProvider, withStore } from '../store';
import Login from "../pages/Login";
import ModelEditorMaster from "./pages/Master";

const _NavShell = ({ children, store, user }) => {
    if (!user.loggedIn) {
        return children;
    }

    return (
        <div style={{ height: '100vh', width: '100vw', display: 'flex' }}>
            <div style={{ width: '250px', background: 'black', height: '100vh' }}>
                <Link to='/model-editor'>
                    <div style={{ padding: '1em', cursor: 'pointer' }}>
                        <Header inverted>Models</Header>
                    </div>
                </Link>
                {store.menu}
            </div>
            <div
                style={{
                    width: `calc(100vw - 250px)`,
                    height: '100vh',
                    overflowY: 'scroll',
                }}
            >
                {children}
            </div>
        </div>
    );
};

const NavShell = withUser(withStore(_NavShell));

const _LogoutButton = ({ user }) => {
    if (!user.loggedIn) {
        return null;
    }

    const logOutButton = (
        <Button
            circular
            icon='sign out'
            style={{
                position: 'fixed',
                right: '1em',
                top: '1em'
            }}
            onClick={user.logout}
        />
    );

    return (
        <Popup
            basic
            content='Log ud'
            trigger={logOutButton}
        />
    );
};

const LogoutButton = withUser(_LogoutButton);

const RedirectToModelEditor = () => {
    useHistory().push('/model-editor');
};

const PublicModelEditor = () => {
    return (
        <StoreProvider>
            <LoginManager>
                <div className='App' id={appContentContainerID}>
                    <NavShell>
                        <Switch>
                            <Route path='/login' component={Login} />
                            <EnsureLoggedIn requiredRole='external-model-builder'>
                                <Route path='/model-editor' component={ModelEditorMaster} />
                                <Route path='/' exact component={RedirectToModelEditor} />
                            </EnsureLoggedIn>
                        </Switch>
                        <LogoutButton />
                    </NavShell>
                </div>
            </LoginManager>
        </StoreProvider>
    );
};

export default PublicModelEditor;