import React, { Component } from 'react';
import { Header, Icon, Label, Loader, Segment, Popup, List, Button } from 'semantic-ui-react';
import { formatUnix } from 'shared/dateTimeFormatting';
import { confirm } from 'shared/globalModal';
import payment from 'http/payment';
import otps from 'http/onetimePayments';

import ProductCard from './ProductCard';
import styles from './OnetimePayments.module.css';

const OTPOwnagePicker = ({ onetimePayments, ownedPayments, onChoose }) => {
    // find unowned onetime payments
    const notOwned = onetimePayments.filter(otp => !ownedPayments.has(otp.id));

    // render nothing, if user owns all onetime payments
    if (notOwned.length === 0) {
        return null;
    }

    return (
        <Popup
            on='click'
            position='left center'
            trigger={<Button primary content='Giv adgang til enkeltkøb' />}
            content={
                <span>
                    <Header>Vælg enkeltkøb at give adgang til:</Header>
                    <List divided>
                        {notOwned.map(({ id, name, semanticIcon }) => (
                            <List.Item
                                key={id}
                                className={styles.otpItem}
                                content={name}
                                icon={semanticIcon}
                                onClick={() => onChoose(id)}
                            />
                        ))}
                    </List>
                </span>
            }
        />
    );
};

class OnetimePayments extends Component {
    state = {
        loading: true,
        working: false,
        onetimePayments: [],
        userOnetimePayments: [],
    };

    componentDidMount = async () => {
        await Promise.all([
            this.fetctOnetimePayments(),
            this.fetchUserOnetimePayments(),
        ]);

        this.setState({
            loading: false,
        });
    };

    fetctOnetimePayments = async () => {
        const onetimePayments = await otps.getAllOnetimePayments();
        this.setState({ onetimePayments });
    };

    fetchUserOnetimePayments = async () => {
        const userOnetimePayments = await payment.getCustomerOnetimePaymentsFull(this.props.uid);
        this.setState({ userOnetimePayments });
    };

    mutateOnetimePaymentOwnership = async handler => {
        this.setState({ loading: true });
        await handler();
        await this.fetchUserOnetimePayments();
        this.setState({ loading: false });
    };

    grantAccessToOnetimePayment = otpID => {
        const uid = this.props.uid;

        this.mutateOnetimePaymentOwnership(() => {
            return payment.registerOnetimePaymentOwnage(uid, otpID);
        });
    };

    removeAccessToOnetimePayment = async otpID => {
        const uid = this.props.uid;

        const otp = this.state.onetimePayments.find(otp => otp.id === otpID);

        const isSure = await confirm(`Er du sikker på at du vil fjerne brugerens adgang til ${otp.name}?`);
        if (!isSure) {
            return;
        }

        this.mutateOnetimePaymentOwnership(() => {
            return payment.unregisterOnetimePaymentOwnage(uid, otpID);
        });
    };

    renderSubscriptions = () => {
        const { userOnetimePayments } = this.state;

        if (userOnetimePayments.length === 0) {
            return (
                <Segment textAlign='center'>
                    <Icon name='dropbox' size='big' color='grey' />
                    <br />
                    <Header>
                        Brugeren har ikke købt nogen enkeltkøb endnu
                    </Header>
                </Segment>
            );
        }

        return (
            <>
                {userOnetimePayments.map(({ id, name, icon, dateOfPurchase }) => {
                    const description = (
                        <Label basic circular>
                            <Icon name='money' />
                            Købt d. {formatUnix(dateOfPurchase)}
                        </Label>
                    );
                    return (
                        <ProductCard
                            key={id}
                            name={name}
                            icon={icon}
                            description={description}
                            actions={
                                <Icon
                                    onClick={() => this.removeAccessToOnetimePayment(id)}
                                    name='x'
                                    color='black'
                                    circular
                                    link
                                />
                            }
                        />
                    );
                })}
            </>
        );
    };

    render = () => {
        const { loading, onetimePayments, userOnetimePayments } = this.state;

        if (loading) {
            return <Loader
                inline='centered'
                size='huge'
                active
            />;
        }
        
        return <>
            {this.renderSubscriptions()}
            <div style={{ textAlign: 'right' }}>
                <OTPOwnagePicker
                    onetimePayments={onetimePayments}
                    ownedPayments={new Set(userOnetimePayments.map(x => x.id))}
                    onChoose={this.grantAccessToOnetimePayment}
                />
            </div>
        </>;
    };
}

export default OnetimePayments;