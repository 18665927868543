const roles = [
    {
        name: 'Privat',
        id: 'privat',
        icon: 'user',
        immutable: true,
        color: 'green'
    },
    {
        name: 'Erhverv',
        id: 'erhverv',
        icon: 'building',
        immutable: true,
        color: 'red'
    },
    {
        name: 'Academy user',
        id: 'akademi',
        icon: 'book',
        color: 'green',
    },
    {
        name: 'Admin',
        id: 'admin',
        icon: 'star',
        color: 'yellow'
    },
    {
        name: 'SAPro tester',
        id: 'saproTester',
        icon: 'key',
        color: 'purple'
    },
    {
        name: 'Revisor',
        id: 'revisor',
        icon: 'briefcase',
        color: 'brown'
    },
    {
        name: 'Holdingselskab',
        id: 'holding',
        icon: 'fax',
        color: 'olive'
    },
    {
        name: 'Dineromedarbejder',
        id: 'dinero-staff',
        icon: 'id badge',
        color: 'blue',
    },
    {
        name: 'Dynaccountmedarbejder',
        id: 'dynaccount-staff',
        icon: 'id badge',
        color: 'green',
    },
    {
        name: 'Legal Desk',
        id: 'legaldesk-staff',
        icon: 'id badge',
        color: 'red',
    },
    {
        name: 'Nybegynder til mapning',
        id: 'mapping-newbie',
        icon: 'wheelchair',
        color: 'pink',
    },
    {
        name: 'Advanceret bruger',
        id: 'advanced-user',
        icon: 'wrench',
        color: 'purple',
    },
    {
        name: 'Bypass sikkerhedscheck',
        id: 'bypass-saftycheck',
        icon: 'clipboard check',
        color: 'red',
    },
    {
        name: 'Ekstern modelbygger',
        id: 'external-model-builder',
        icon: 'cubes',
        color: 'blue',
    },
];

export const roleMap = roles.reduce((acc, role) => {
    acc[role.id] = role;
    return acc;
}, {});

export default roles;