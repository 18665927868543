import { Client } from '../client';
import { API_LOCATION } from '../../config';

const client = new Client(`${API_LOCATION}/model-editor/api/products`);

function getProduct(productID) {
    return client.getResource(`/${productID}`);
}

function getProducts() {
    return client.getResource('');
}

function createProduct(productId, productName) {
    return client.postResource('', {
        productId,
        productName,
    });
}

function updateProduct(productId, product) {
    return client.putResource(`/${productId}`, product);
}

function publishProduct(productID) {
    return client.postResource(`/${productID}/publish`);
}

function deleteProduct(productID, productName) {
    return client.deleteResource(`/${productID}`, { productName });
}

const productsClient = {
    getProduct,
    getProducts,
    createProduct,
    updateProduct,
    publishProduct,
    deleteProduct,
};

export default productsClient;