import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';

class InternalBookingDetails extends Component {
    renderLine(line, i) {
        return <div key={`line_${i}`} style={{ marginBottom: '10px' }}>
            <div>{`Name: ${line.accountName}`}</div>
            <div>{`Postering text: ${line.text}`}</div>
            <div>{`Amount: ${line.amount} dkk`}</div>
            <div>{`Moms code: ${line.accountVatCode}`}</div>
        </div>;
    }

    renderBooking(booking, i) {
        return <div key={`booking_${i}`}>
            {
                booking.lines.map((line, i) => this.renderLine(line, i))
            }
        </div>
    }

    renderCharge(charge, i) {
        return <div key={`charge_${i}`} style={{ border: '1px solid green', marginBottom: '10px', padding: '5px' }}>
            <div>{`Charge id: ${charge.id}`}</div>
            <div>{`Charge user e-mail: ${charge.userEmail}`}</div>
            <br/>
            {
                charge.bookings.map((booking, i) => this.renderBooking(booking, i))
            }
        </div>
    }

    renderCharges() {
        const { charges, fetching } = this.props;
        if(!charges) {
            return !fetching ? null : <Loader inline active size='small'></Loader>; 
        } else {
            return <>
                { charges.map((charge, i) => this.renderCharge(charge, i)) }
            </>
        }
    }

    render() {
        return this.renderCharges();
    };
}

export default InternalBookingDetails;