import React, {Component} from 'react';

class NodeDataFlowContext extends Component {
    getData () {
        return null;
    }

    render () {
        return <></>;
    }
}

export default NodeDataFlowContext;
