import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Form, Loader, Message, Search, Segment } from 'semantic-ui-react';
import { dataTypes } from 'model-editor/nodeMetadata';
import modelEditor from 'http/modelEditor';
import taxonomyParser from 'http/taxonomyParser';
import useRestResource from 'shared/hooks/useRestResource';

const allowedDataTypes = {
    [dataTypes.NUMBER]:      tagMeta => ['xbrli:monetaryItemType', 'xbrli:decimalItemType'].includes(tagMeta.type),
    [dataTypes.STRING]:      tagMeta => ['xbrli:stringItemType', 'nonnum:domainItemType'].includes(tagMeta.type),
    [dataTypes.ENUM_STRING]: tagMeta => tagMeta.typeSpecification?.validValues?.length > 0,
    [dataTypes.STRING_LIST]: tagMeta => ['xbrli:stringItemType', 'nonnum:domainItemType'].includes(tagMeta.type),
    [dataTypes.BOOLEAN]:     tagMeta => ['xbrli:booleanItemType'].includes(tagMeta.type),
    [dataTypes.DATE]:        tagMeta => ['xbrli:dateItemType'].includes(tagMeta.type),
};

const fetchTaxonomyTags = async modelID => {
    const { taxonomy } = await modelEditor.getModelMetadata(modelID);
    if (!taxonomy) {
        return null;
    }

    const tags = await taxonomyParser.listTags(taxonomy);
    const tagsByName = {};
    const tagsByID = {};
    for (const tag of tags) {
        tagsByID[tag.id] = tag;
        if (!(tag.name in tagsByName)) {
            tagsByName[tag.name] = tag;
        }
    }

    return {
        taxonomy,
        tagsByName,
        tagsByID,
    };
};

const TaxonomyOptions = ({ nodeData, setNodeData }) => {
    const [tagQuery, setTagQuery] = useState(nodeData?.taxonomyConfig?.mainTag);
    const [memberTagDimensions, setMemberTagDimensions] = useState([]);
    const [memberTags, setMemberTags] = useState([]);

    const { isPrimo, mainTag, dimensionTag, memberTag } = nodeData.taxonomyConfig || {};

    const taxonomyTags = useRestResource({
        fetcher: fetchTaxonomyTags,
        args: [nodeData?.modelID],
    });

    const relevantMainTags = useMemo(() => {
        if (!(nodeData.dataType in allowedDataTypes)) {
            return [];
        }

        if (!tagQuery) {
            return [];
        }

        if (!taxonomyTags.data) {
            return [];
        }

        const tagQueryLowerCase = tagQuery.toLowerCase();

        return Object.values(taxonomyTags.data.tagsByID).filter(tag => {
            if (tag.isAbstract) return false;
            if (!allowedDataTypes[nodeData.dataType](tag)) return false;
            if (!(tag.name.toLowerCase().includes(tagQueryLowerCase) || tag.label.toLowerCase().includes(tagQueryLowerCase))) return false;

            return true;
        }).slice(0, 5);
    }, [taxonomyTags.data, nodeData.dataType, tagQuery]);

    useEffect(() => {
        setMemberTagDimensions([]);

        if (!mainTag) {
            return;
        }

        if (!taxonomyTags.data) {
            return;
        }

        const doFetchMemberTagDimenions = async () => {
            const { id } = taxonomyTags.data.tagsByName[mainTag];
            const memberTagDimensions = await taxonomyParser.getMemberDimensionsByTag(taxonomyTags.data.taxonomy, id);
            setMemberTagDimensions(memberTagDimensions.map(tagID => taxonomyTags.data.tagsByID[tagID].name));
        };

        doFetchMemberTagDimenions();
    }, [taxonomyTags.data, mainTag]);

    useEffect(() => {
        setMemberTags([]);

        if (!dimensionTag) {
            return;
        }

        if (!taxonomyTags.data) {
            return;
        }

        const doFetchMemberTags = async () => {
            const { id } = taxonomyTags.data.tagsByName[dimensionTag];
            const memberTags = await taxonomyParser.getAllChildrenByTag(taxonomyTags.data.taxonomy, id);
            setMemberTags(memberTags.map(tagID => taxonomyTags.data.tagsByID[tagID].name));
        };

        doFetchMemberTags();
    }, [taxonomyTags.data, dimensionTag]);

    const updateTaxonomyData = (updator = {}) => {
        const { taxonomyConfig, ...restOfTheData } = nodeData;

        const newTaxonomyData = {
            ...(taxonomyConfig || {}),
            ...updator,
        };

        setNodeData({
            taxonomyConfig: newTaxonomyData,
            ...restOfTheData,
        });
    };

    const setMainTag = mainTag => {
        updateTaxonomyData({
            mainTag,
            dimensionTag: '',
            memberTag: '',
        });
    };

    if (taxonomyTags.loading) {
        return (
            <Loader
                size='big'
                inline='centered'
                active
            />
        );
    }

    if (taxonomyTags.error) {
        return (
            <Message
                error
                icon='warning sign'
                content={taxonomyTags.error.message}
            />
        );
    };

    if (!taxonomyTags.data) {
        return (
            <Message
                icon='info circle'
                content='Ingen taksonomi valgt under "Metadata"'
            />
        );
    }
    

    if (!(nodeData.dataType in allowedDataTypes)) {
        return <span>Denne node kan ikke konfigureres i henhold til taksonomien</span>;
    }

    const mainTagDescription = taxonomyTags.data.tagsByName[mainTag];


    return (
        <Segment>
            <Form>
                <Form.Field error={tagQuery && !mainTag}>
                    <label>XBRL-tag</label>
                    <Search
                        fluid
                        placeholder='Søg efter tag...'
                        noResultsMessage='Ingen tags fundet...'
                        defaultValue={mainTagDescription?.label}
                        onResultSelect={(_, { result }) => setMainTag(result.tag)}
                        onSearchChange={(_, { value }) => {
                            setTagQuery(value);
                            if (mainTag) {
                                setMainTag('');
                            }
                        }}
                        results={relevantMainTags.map(tag => {
                            return {
                                tag: tag.name,
                                title: tag.label,
                                description: tag.name,
                            };
                        })}
                    />
                </Form.Field>
                {
                    mainTagDescription?.isBalance && 
                    <Form.Field>
                        <label>Tidsangivelse</label>
                        <Dropdown
                            selection
                            value={!!isPrimo}
                            onChange={(_, { value }) => updateTaxonomyData({ isPrimo: value })}
                            options={[
                                {
                                    text: 'Ultimo',
                                    value: false,
                                },
                                {
                                    text: 'Primo',
                                    value: true,
                                },
                            ]}
                        />
                    </Form.Field>
                }
                {
                    memberTagDimensions.length > 0 &&
                    <Form.Field>
                        <label>Dimension</label>
                        <Dropdown
                            selection
                            search
                            clearable
                            value={dimensionTag}
                            onChange={(_, { value }) => updateTaxonomyData({ dimensionTag: value })}
                            options={memberTagDimensions.map(tag => {
                                const dimensionTag = taxonomyTags.data.tagsByName[tag];
                                return {
                                    text: dimensionTag.label,
                                    description: dimensionTag.name,
                                    value: dimensionTag.name,
                                };
                            })}
                        />
                    </Form.Field>
                }
                {
                    memberTags.length > 0 &&
                    <Form.Field>
                        <label>Member</label>
                        <Dropdown
                            selection
                            search
                            clearable
                            value={memberTag}
                            onChange={(_, { value }) => updateTaxonomyData({ memberTag: value })}
                            options={memberTags.map(tag => {
                                const memberTag = taxonomyTags.data.tagsByName[tag];
                                return {
                                    text: memberTag.label,
                                    description: memberTag.name,
                                    value: memberTag.name,
                                };
                            })}
                        />
                    </Form.Field>
                }
            </Form>
        </Segment>
    );
}

export default TaxonomyOptions;