import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { eventListenerHub } from 'shared/eventListenerHub';

// 2 => [0.50]
// 3 => [0.33, 0.67]
// 4 => [0.25, 0.50, 0.75]
const evenColumnSpacing = (amountOfColumns) => {
    const part = 1 / amountOfColumns;
    const out = [];
    let cur = 0;
    for (let i = 0; i < amountOfColumns - 1; i++) {
        cur += part;
        out.push(cur);
    }
    return out;
}

const WidthSelector = ({ value, onChange, columns }) => {
    const [dragState, setDragState] = useState({
        dragging: false,
        draggedIndex: -1,
    });
    const [containerWidth, setContainerWidth] = useState(-1);
    const [mouseX, setMouseX] = useState(0);
    const [hover, setHover] = useState(false);
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const containerRef = useRef();
    const pickerWidth = 4;

    const snapPrecision = factor => Math.round(factor * 100) / 100;

    useEffect(() => {
        if (typeof columns !== 'number') {
            return;
        }

        const clampedColumns = Math.max(0, Math.min(10, columns));
        const currentColumns = (value || []).length + 1;
        if (currentColumns === clampedColumns) {
            return;
        }

        onChange(evenColumnSpacing(clampedColumns));
    }, [value, columns, onChange]);

    useEffect(() => {
        return eventListenerHub(window, {
            resize: () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            },
        });
    }, []);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!containerRef.current) {
            return;
        }

        if (dragState.dragging === false) {
            return;
        }

        return eventListenerHub(window, {
            mouseup: () => {
                const copy = [...value];
                const containerWidth = containerRef.current.offsetWidth - pickerWidth;
                const newFactor = (mouseX - (pickerWidth / 2)) / containerWidth;
                copy[dragState.draggedIndex] = snapPrecision(Math.max(0, Math.min(1, newFactor)));
                onChange(copy.sort());
                setDragState({ dragging: false, draggedIndex: -1 });
            },
        });
    } , [dragState, setDragState, onChange, value, mouseX]);

    useEffect(() => {
        if (!containerRef.current) {
            setContainerWidth(-1);
            return;
        }

        setContainerWidth(containerRef.current.offsetWidth);

        const handleMouseMove = e => {
            if (!containerRef.current) {
                return;
            }

            const { left } = containerRef.current.getBoundingClientRect();
            const mousePosOnContainer = e.clientX - left;
            setMouseX(mousePosOnContainer);
        };

        return eventListenerHub(window, { mousemove: handleMouseMove });
    }, [setContainerWidth, windowSize]);

    return <>
        <div
            className='noselect'
            ref={containerRef}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                width: '100%',
                background: 'transparent',
                border: '1px solid lightgray',
                display: 'inline-block',    
                height: '38px',
                borderRadius: '4px',
            }}
        >
            {containerWidth !== -1 && value?.sort()?.map((factor, idx) => {
                const isBeingDragged = dragState.dragging && idx === dragState.draggedIndex;
                const effectiveContainerWidth = containerWidth - pickerWidth;

                let len;
                if (isBeingDragged) {
                    len = Math.round(((mouseX - (pickerWidth / 2)) / effectiveContainerWidth) * effectiveContainerWidth);
                } else {
                    len = Math.round(factor * effectiveContainerWidth);
                }
                len = snapPrecision(Math.max(0, Math.min(len, effectiveContainerWidth)));

                return (
                    <Popup
                        open={hover}
                        position='bottom center'
                        content={`${Math.round(len / effectiveContainerWidth * 100)} %`}
                        trigger={
                            <div
                                onMouseDown={() => setDragState({ dragging: true, draggedIndex: idx })}
                                style={{
                                    cursor: isBeingDragged ? 'grabbing' : 'grab',
                                    display: 'block',
                                    position: 'absolute',
                                    left: `${len}px`,
                                    width: `${pickerWidth}px`,
                                    height: '37px',
                                    background: 'gray',
                                    borderRadius: '4px',
                                }}
                            />
                        }
                    />
                );
            })}
        </div>
    </>;
};

export default WidthSelector;
