import React, { useMemo, useState } from 'react';
import { Button, Input, Segment } from 'semantic-ui-react';
import lodash from 'lodash';
import { prompt } from 'shared/globalModal';
import { createSlug } from 'shared/createSlug';
import FoldableSegment from 'model-editor/molecules/FoldableSegment';
import FormulaInput from 'model-editor/molecules/FormulaInput';

const CalculationEditor = ({ onChange, calculations, signatures, fieldIds }) => {
    const [query, setQuery] = useState('');

    const updateCalculation = (tag, propertyPath, value) => {
        const index = calculations.findIndex(calc => calc.tag === tag);
        lodash.set(calculations[index], propertyPath, value);
        onChange(calculations);
    };

    const removeCalculation = tag => {
        const calculationsCopy = [...calculations];
        const index = calculations.findIndex(calc => calc.tag === tag);
        calculationsCopy.splice(index, 1);
        onChange(calculationsCopy);
    };

    const createNewCalculation = async () => {
        const inputTag = await prompt('Navngiv udregningen');
        if (!inputTag) {
            return;
        }

        const tag = createSlug(inputTag);
        if (!tag) {
            return;
        }

        const newCalculation = {
            tag,
            expression: '',
        };

        onChange([...calculations, newCalculation]);
    };

    const calculationsToShow = useMemo(() => calculations.filter(calculation => {
        const trimmedQuery = query.trim().toLowerCase();
        if (!trimmedQuery) {
            return true;
        }

        return calculation.tag.toLowerCase().includes(trimmedQuery);
    }), [query, calculations]);

    return (
        <div>
            <Input
                icon='search'
                iconPosition='left'
                onChange={(_, { value }) => setQuery(value)}
                placeholder='Filter calculations...'
            />

            {/* calculations */}
            {calculationsToShow.map(calculation => {
                // find relevant reference tags
                const relevantReferences = fieldIds
                    .filter((field) => field.id !== calculation.tag)
                    .map((field) => field.id);

                return (
                    <FoldableSegment
                        key={calculation.tag}
                        header={calculation.tag}
                        icon='calculator'
                    >
                        <FormulaInput
                            value={calculation.expression}
                            onChange={(expression) =>
                                updateCalculation(calculation.tag, 'expression', expression)
                            }
                            documentation={signatures}
                            referenceNames={relevantReferences}
                        />
                        <Segment basic textAlign='right'>
                            <Button
                                content='Slet'
                                color='red'
                                icon='trash alternate'
                                basic
                                onClick={() => removeCalculation(calculation.tag)}
                            />
                        </Segment>
                    </FoldableSegment>
                );
            })}

            {/* actions */}
            <Segment basic textAlign='right'>
                <Button
                    onClick={createNewCalculation}
                    content='Opret udregning'
                    icon='plus'
                    primary
                />
            </Segment>
        </div>
    );
};

export default CalculationEditor;