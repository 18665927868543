import React from 'react';
import { Form, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import RadioQuestionEditor from 'model-editor/atoms/RadioQuestionEditor';
import RuleManager from 'model-editor/molecules/RuleManager/RuleManager';
import taxonomyParser from 'http/taxonomyParser';
import modelEditor from 'http/modelEditor';

const formStyles = {
    width: '100%',
    borderSpacing: '0px',
};

const RuleEditorModal = ({ onClose, option, rules, nodes, onChange }) => {
    return (
        <Modal
            onClose={onClose}
            open={!!option}
            closeIcon
        >
            <Modal.Header>Redigér regler</Modal.Header>
            <Modal.Content>
                <Form style={formStyles}>
                    <Form.Field>
                        <RuleManager
                            nodes={nodes}
                            deliverData={onChange}
                            fieldID={option?.optionValue}
                            rules={rules || []}
                            showAdvisoryMessage
                            basic
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
        </Modal>
    );
};

class RadioQuestion extends React.Component {
    constructor (props) {
        super(props);
        const options = this.props.node.data.options || [];
        const ruleMap = this.ruleMapFromOptions(options);
        this.state = {
            options,
            ruleMap,
            taxonomyEnumOptions: null,
            editIdx: null,
        };
    }

    async componentDidMount() {
        const { taxonomyConfig, modelID } = this.props.node;
        
        const taxonomyEnumOptions = [];
        if (taxonomyConfig?.mainTag) {
            const { taxonomy } = await modelEditor.getModelMetadata(modelID);
            const taxonomyTags = await taxonomyParser.listTags(taxonomy);
            const tagDescription = taxonomyTags.find(tag => tag.name === taxonomyConfig.mainTag);
            taxonomyEnumOptions.push(...(tagDescription?.typeSpecification?.validValues || []));
        }

        this.setState({ taxonomyEnumOptions });
    }

    getData () {
        const options = this.injectRulesIntoOptions();
        return { options };
    }

    ruleMapFromOptions = options => {
        const ruleMap = {};

        options.forEach(({ optionValue, optionRules }) => {
            ruleMap[optionValue] = optionRules;
        });

        return ruleMap;
    };

    injectRulesIntoOptions = () => {
        const { options, ruleMap } = this.state;
        return options.map(option => {
            option.optionRules = ruleMap[option.optionValue];
            return option;
        });
    };

    optionToAnswer = option => ({
        label: option.optionText,
        value: option.optionValue,
        taxonomyValue: option.optionTaxonomyValue,
        tooltip: option.optionTooltip,
    });

    answerToOption = answer => ({
        optionText: answer.label,
        optionValue: answer.value,
        optionTaxonomyValue: answer.taxonomyValue,
        optionTooltip: answer.tooltip,
    });

    optionHasRules = optIdx => {
        const { ruleMap, options } = this.state;
        const optVal = options[optIdx]?.optionValue;
        return ruleMap[optVal]?.length > 0;
    };

    onAnswersChange = answers => {
        this.setState({
            options: answers.map(this.answerToOption),
        });
    };

    onRulesChange = rules => {
        const { ruleMap, options, editIdx } = this.state;

        // update rule map
        ruleMap[options[editIdx].optionValue] = rules;

        this.setState({ ruleMap });
    };

    render () {
        const { ruleMap, options, editIdx, taxonomyEnumOptions } = this.state;

        return (
            <Segment>
                <Header>Svarmuligheder</Header>
                <RadioQuestionEditor
                    answers={options.map(this.optionToAnswer)}
                    onChange={this.onAnswersChange}
                    taxonomyValues={taxonomyEnumOptions}
                    renderRowOptions={({ idx }) => (
                        <Icon.Group size='large'>
                            <Icon
                                name='eye'
                                color={this.optionHasRules(idx) ? 'black' : 'grey'}
                                link
                                onClick={() => this.setState({ editIdx: idx })}
                            />
                            {
                                this.optionHasRules(idx) &&
                                <Icon
                                    name='check'
                                    corner='top right'
                                    color='green'
                                />
                            }
                        </Icon.Group>
                    )}
                />
                <RuleEditorModal
                    option={options[editIdx]}
                    rules={ruleMap[options[editIdx]?.optionValue]}
                    onClose={() => this.setState({ editIdx: null })}
                    onChange={this.onRulesChange}
                    nodes={this.props.nodes}
                />
            </Segment>
        );
    }
}

export default RadioQuestion;
