import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';
import TemplatesMaster from './Templates/Master';
import TemplatesDetail from './Templates/Detail';
import CampaignsMaster from './Campaigns/Master';
import CampaignsDetail from './Campaigns/Detail';
import OnboardingCampaignsMaster from './Campaigns/Master/onboarding';
import Navigation from './navigation';

function Email () {
    return (
        <Container>
            <Navigation />
            <Segment>
                <Switch>
                    <Route path='/emails/campaigns/:campaign' component={CampaignsDetail} />
                    <Route path='/emails/campaigns' component={CampaignsMaster} />
                    <Route path='/emails/onboarding/:campaign' component={CampaignsDetail} />
                    <Route path='/emails/onboarding' component={OnboardingCampaignsMaster} />
                    <Route path='/emails/templates/:template' component={TemplatesDetail} />
                    <Route path='/emails/templates' component={TemplatesMaster} />
                    <Redirect from='/emails' to='/emails/campaigns' exact />
                </Switch>
            </Segment>
        </Container>
    );
}

export default Email;