import React from 'react';
import TextInput from '../../../atoms/TextInput/TextInput';
import { Button, Icon, Table, Popup } from 'semantic-ui-react';

import styles from './ProductDetail.module.css';

class BasicInfoEditor extends React.Component {
    render () {
        const {
            basicInformation,
            updateBasicInfoLine,
            deleteBasicInformation,
            createBasicInfoLine
        } = this.props;
        return (
            <div>
                <Table basic='very' compact className={styles.grundTable}>
                    {
                        basicInformation.length > 0 && (
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Tekst</Table.HeaderCell>
                                    <Table.HeaderCell>Tag</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        )
                    }
                    <Table.Body>
                        {
                            basicInformation.map((bi, i) => {
                                const { description, tag } = bi;
                                return (
                                    <Table.Row key={JSON.stringify(bi) + i}>
                                        <Table.Cell>
                                            <TextInput defaultValue={description} onBlur={e => updateBasicInfoLine('description', i, e)}/>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <TextInput defaultValue={tag} onBlur={e => updateBasicInfoLine('tag', i, e)}/>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Popup
                                                inverted
                                                position='bottom right'
                                                content='Slet linie'
                                                trigger={
                                                    <Button
                                                        onClick={() => deleteBasicInformation(i)}
                                                        children={<Icon name='delete' />}
                                                        className={styles.deleteIcon}
                                                        circular
                                                        icon
                                                    />
                                                }
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })
                        }
                     </Table.Body>
                </Table>
                <Button
                    onClick={() => createBasicInfoLine()}
                    className={styles.nyLinieButton}
                    content='Ny linje'
                    icon='plus'
                />
            </div>
        );
    }
}

export default BasicInfoEditor;