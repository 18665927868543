/**
 * Creates & clicks on a download link with the given blob.
 * Removes the link after download has been triggered.
 * @param {Blob} blob 
 * @param {string} filename 
 */
function downloadBlob (blob, filename) {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export default downloadBlob;