import React from 'react';
import { Button, Dropdown, Form, Table, TextArea } from 'semantic-ui-react';
import TextInput from 'atoms/TextInput/TextInput';

const HintVideos = props => {
    const {
        dataManager,
        idColumn,
        options
    } = props;
    const { getCurrentField, updateField } = dataManager;

    const currentField = getCurrentField();

    // ==================================================

    const renderTable = () => {
        const table = (
            <Table celled>
                {renderTableHeader()}
                {renderTableBody()}
                {renderTableFooter()}
            </Table>
        );
        return table;
    };

    const renderTableHeader = () => {
        const tableHeader = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width="3">{idColumn.header}</Table.HeaderCell>
                    <Table.HeaderCell width="5">Title</Table.HeaderCell>
                    <Table.HeaderCell width="5">Description</Table.HeaderCell>
                    <Table.HeaderCell width="2">YouTube video ID</Table.HeaderCell>
                    <Table.HeaderCell width="1"></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
        return tableHeader;
    };

    const renderTableBody = () => {
        const renderVideo = (hintVideo, i) => {
            const tableRow = (
                <Table.Row>
                    <Table.Cell>
                        <Form.Field>
                            <Dropdown
                                selection
                                search
                                onChange={(_, { value }) => updateField(`hint.data.videos[${i}].id`, value)}
                                value={hintVideo.id || ''}
                                placeholder={idColumn.dropdownPlaceholder}
                                options={options}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field>
                            <TextInput onChange={e => updateField(`hint.data.videos[${i}].title`, e.target.value)} value={hintVideo.title || ''}/>
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field>
                            <TextArea
                                onChange={e => updateField(`hint.data.videos[${i}].description`, e.target.value)}
                                value={hintVideo.description || ''}
                                rows={1}
                            />
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                        <Form.Field>
                            <TextInput onChange={e => updateField(`hint.data.videos[${i}].ytVideoID`, e.target.value)} value={hintVideo.ytVideoID || ''}/>
                        </Form.Field>
                    </Table.Cell>
                    <Table.Cell textAlign='center'>
                        <Button
                            content="Slet"
                            onClick={() => {
                                const videos = currentField.hint.data.videos;
                                const updatedVideos = videos.filter(video => video !== hintVideo);
                                updateField(`hint.data.videos`, updatedVideos);
                            }}
                        />
                    </Table.Cell>
                </Table.Row>
            );
            return tableRow;
        };

        const videos = currentField.hint?.data?.videos || [];
        const tableBody = (
            <Table.Body>
                { videos.map((video, i) => renderVideo(video, i)) }
            </Table.Body>
        );
        return tableBody;
    };

    const renderTableFooter = () => {
        const tableFooter = (
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan="5" >
                        <Button
                            content="Add"
                            primary
                            onClick={() => {
                                const videos = currentField.hint?.data?.videos || [];

                                const newVideo = {
                                    id: '',
                                    title: '',
                                    description: '',
                                    ytVideoID: ''
                                };
                                videos.push(newVideo);
                                updateField(`hint.data.videos`, videos);
                            }}
                            fluid
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        )
        return tableFooter;
    };

    return renderTable();
};

export default HintVideos;
