import React, { Component, createRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Message,
    Table,
    Button,
    Container,
    Loader,
    Header,
    Modal,
    Form,
    Dropdown,
    Label,
    Icon,
    List,
    Input,
    Segment,
    Grid,
    Divider,
} from 'semantic-ui-react';
import { get, has, set, debounce } from 'lodash';
import cns from 'shared/cns';
import readableID from 'shared/readableID';
import getTaxYears from 'shared/getRelevantTaxYears';
import { confirm } from 'shared/globalModal';
import paymentHttp from 'http/payment';
import productHttp from 'http/products';
import PurchaseStats from './PurchaseStats';
import styles from './PricesControlPanel.module.css';


const MAX_STEPS = 10;

function PriceLadder ({ ladder, products: allProducts, onChange, onDelete }) {
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [steps, setSteps] = useState(get(ladder, 'priceSteps.length', 0));
    const priceField = createRef();

    useEffect(() => {
        if (priceField.current) {
            if (!mounted) {
                // focus price field on mount
                priceField.current.focus();
                setMounted(true);
            }
        }
    }, [priceField, mounted, setMounted]);

    const updateLadder = (propertyPath, value) => {
        set(ladder, propertyPath, value);
        setError(validateLadder(ladder));
        onChange(ladder);
    };

    const ladderUpdater = (propertyPath, extractor) => {
        return (...args) => {
            const value = extractor(...args);
            updateLadder(propertyPath, value);
        };
    };

    const ladderUpdaterSemanticNumber = propertyPath => {
        return ladderUpdater(propertyPath, (_, { value }) => Number(value));
    };

    const ladderUpdaterSemantic = propertyPath => {
        return ladderUpdater(propertyPath, (_, { value }) => value);
    };

    const stepsChanged = amount => {
        const priceSteps = get(ladder, 'priceSteps', []);
        updateLadder('priceSteps', priceSteps.splice(0, amount));
        setSteps(amount);
    };

    const deletePriceLadder = async () => {
        if (!await confirm('Er du sikker?')) {
            return;
        }

        onDelete && onDelete();
    };

    const validateLadder = ladder => {
        const priceSteps = get(ladder, 'priceSteps', []);

        // validate that the amount of clients is increasing for each step
        for (let i = 1; i < priceSteps.length; i++) {
            const prevValue = priceSteps[i - 1];
            const currentValue = priceSteps[i];
            if (prevValue && prevValue.clientLimit >= currentValue.clientLimit) {
                return 'Klientantallet i pristrappen skal være stigende';
            }
        }

        // validate type of final price
        if (isNaN(get(ladder, 'finalPrice'))) {
            return 'Pris skal være et tal';
        }

        // no validation errors
        return null;
    };

    const renderStep = (idx, clpath, ppath, lastStep = false) => {
        let defaultValue;
        let placeholder;
        if (!lastStep) {
            defaultValue = get(ladder, clpath);
            placeholder = 'Angiv klientantal';
        } else {
            defaultValue = '*';
        }
        return (
            <Table.Row key={ppath}>
                <Table.Cell>
                    #{idx + 1}
                </Table.Cell>
                <Table.Cell>
                    <Input
                        onChange={debounce(ladderUpdaterSemanticNumber(clpath), 200)}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        disabled={lastStep}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Input
                        onChange={debounce(ladderUpdaterSemanticNumber(ppath), 200)}
                        defaultValue={get(ladder, ppath)}
                        placeholder='Angiv pris'
                    />
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderSteps = () => {
        const stepsJSX = [];

        // render all steps
        for (let i = 0; i < steps; i++) {
            const clpath = `priceSteps.${i}.clientLimit`;
            const ppath = `priceSteps.${i}.price`;
            stepsJSX.push(renderStep(i, clpath, ppath));
        }

        // render final price as a step
        stepsJSX.push(renderStep(stepsJSX.length, null, 'finalPrice', true));

        return <Table>
            <Table.Header>
                <Table.HeaderCell>Trin</Table.HeaderCell>
                <Table.HeaderCell>Antal klienter før næste trin udløses</Table.HeaderCell>
                <Table.HeaderCell>Trinpris</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {stepsJSX}
            </Table.Body>
        </Table>;
    };

    const renderEditorModal = () => {
        const priceSteps = get(ladder, 'priceSteps', []);
        const products = get(ladder, 'products', []);
        const taxYears = get(ladder, 'taxYears', []);

        // prepare "Amount of steps" picker options
        const options = [];
        for (let i = 0; i < MAX_STEPS; i++) {
            options.push({
                text: i + 1,
                value: i + 1,
            });
        }
       
        return (
            <Modal open={modal} onClose={() => setModal(false)}>
                <Modal.Header>
                    <Icon name='sort amount up' />
                    Rediger pristrappe
                </Modal.Header>
                <Modal.Content>
                    <Form className={styles.wideform} onSubmit={() => setModal(false)}>
                        <Form.Field>
                            <label>Produkter</label>
                            <Dropdown
                                selection
                                search
                                multiple
                                options={allProducts.map(({ id }) => {
                                    const text = readableID(id);
                                    return { value: id, text };
                                })}
                                onChange={ladderUpdaterSemantic('products')}
                                defaultValue={products}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Skatteår</label>
                            <Dropdown
                                selection
                                search
                                multiple
                                options={getTaxYears({ goForwardYears: 10 }).map(t => ({ value: t, text: t }))}
                                onChange={ladderUpdaterSemantic('taxYears')}
                                defaultValue={taxYears}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Divider />
                        </Form.Field>
                        <Form.Field>
                            <label>Antal steps før højeste trin</label>
                            <Dropdown
                                selection
                                options={options}
                                onChange={(_, { value }) => stepsChanged(value)}
                                defaultValue={priceSteps.length}
                            />
                        </Form.Field>
                        {renderSteps()}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    { error &&
                        <Label
                            color='red'
                            icon='warning circle' 
                            content={error}
                        />
                    }
                    <Button
                        content='Luk'
                        disabled={error}
                        onClick={() => setModal(false)}
                    />
                </Modal.Actions>
            </Modal>
        );
    };

    const renderClientPurchaseRange = (from, to, price) => {
        return <List.Item>
            <div className={styles.rangeContainer}>
                <div className={cns(styles.rcItem, styles.fleft)}>
                    <Icon name='user' />
                    {from} - {to}
                </div>
                <div className={cns(styles.rcItem, styles.fright)}>
                    {price} kr./stk
                </div>
            </div>       
        </List.Item>;
    };

    const renderPreviewList = (list, notFoundMsg, formatter = v => v) => {
        if (list.length === 0) {
            return <i>{notFoundMsg}</i>
        }

        return <List bulleted>
            {list.map(v => {
                return <List.Item>{formatter(v)}</List.Item>
            })}
        </List>
    };

    const renderPreviewProducts = () => {
        const products = get(ladder, 'products', []);
        return renderPreviewList(products, 'Ingen produkter angivet', v => readableID(v));
    };

    const renderPreviewTaxYears = () => {
        const products = get(ladder, 'taxYears', []);
        return renderPreviewList(products, 'Ingen skatteår angivet');
    };

    const renderPreviewSteps = () => {
        const priceSteps = get(ladder, 'priceSteps', []);
        const finalPrice = get(ladder, 'finalPrice');

        if (finalPrice === undefined) {
            return <i>Ingen pristrin defineret</i>;
        }

        // render client ranges + prices
        const steps = priceSteps.map(({ clientLimit, price }, idx) => {
            let from;
            if (idx === 0) {
                from = 1;
            } else {
                from = priceSteps[idx - 1].clientLimit + 1;
            }
            return <List.Item>
                {renderClientPurchaseRange(from, clientLimit, price)}
            </List.Item>;
        });

        // render the final price as the last step
        const lastStep = priceSteps[priceSteps.length - 1];
        if (lastStep) {
            const afterLastStep = lastStep.clientLimit + 1;
            steps.push(renderClientPurchaseRange(afterLastStep, '*', finalPrice));
        } else {
            steps.push(renderClientPurchaseRange(1, '*', finalPrice));
        }

        return <List>{steps}</List>;
    };

    const renderPreview = () => {
        return <>
            <Grid columns={4} verticalAlign='middle' celled>
                <Grid.Column width={4}>
                    {renderPreviewSteps()}
                </Grid.Column>
                <Grid.Column width={5}>
                    {renderPreviewProducts()}
                </Grid.Column>
                <Grid.Column width={5}>
                    {renderPreviewTaxYears()}
                </Grid.Column>
                <Grid.Column textAlign='center' width={2}>
                        <Icon
                            name='edit'
                            link
                            size='big'
                            onClick={() => setModal(true)}
                        />
                        <Icon
                            name='x'
                            color='red'
                            link
                            size='big'
                            onClick={deletePriceLadder}
                        />
                </Grid.Column>
            </Grid>
        </>;
    };

    return <>
        {renderEditorModal()}
        {renderPreview()}
    </>;
}

const STANDARD = 'standard';

class PricesControlPanel extends Component {
    state = {
        loading: true,
        working: false,
        error: false,
        products: [],
        upperTaxYear: new Date().getFullYear() + 2,
        taxYearsToShow: 8,
        deal: null,
    };

    componentDidMount = async () => {
        const { uid } = this.props;
        try {
            const [deal, products] = await Promise.all([
                paymentHttp.getAccountantPriceDeal(uid),
                this.fetchActiveProducts(),
            ]);
            this.setState({ products, deal });
        } catch (e) {
            this.setState({
                error: 'Kunne ikke hente prisliste'
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    getViewableTaxYears = () => {
        const { upperTaxYear, taxYearsToShow } = this.state;
        const out = [];

        for (let taxYearOffset = 0; taxYearOffset < taxYearsToShow; taxYearOffset++) {
            const taxYear = 1 + upperTaxYear - taxYearsToShow + taxYearOffset;
            out.push(taxYear.toString());
        }

        return out;
    };

    mutateDeal = mutator => {
        const { deal } = this.state;
        mutator(deal);
        this.setState({ deal });
    };

    createNewPriceDeal = async () => {
        const deal = await paymentHttp.createAccountantPriceDeal(this.props.uid);
        this.setState({ deal });
    };

    startWorking = () => this.setState({ working: true, error: false });

    savePriceDeal = async () => {
        this.startWorking();
        try {
            const { uid } = this.props;
            await paymentHttp.updateAccountantPriceDeal(uid, this.state.deal);
            toast.success('Prisaftalen blev opdateret');
        } catch (e) {
            this.setState({ error: e.message });
        } finally {
            this.setState({ working: false });
        }
    };

    priceChanged = (productPath, taxYearPath) => {
        return e => {
            this.mutateDeal(deal => {
                if (!has(deal, productPath)) {
                    // ensure tax year container is an object
                    set(deal, productPath, {});
                }
    
                set(deal, taxYearPath, Number(e.target.value));
            });
        }
    };

    ladderChanged = idx => {
        return ladder => {
            this.mutateDeal(deal => deal.ladders[idx] = ladder);
        };
    };

    fetchActiveProducts = async () => {
        const resp = await productHttp.getActiveProducts();
        if (!resp.success) {
            throw new Error(resp.message);
        }
        return resp.data;
    };

    createPriceLadder = () => {
        this.mutateDeal(deal => deal.ladders.push({}));
    };

    deletePriceLadder = idx => {
        this.mutateDeal(deal => deal.ladders.splice(idx, 1));
    };

    renderTableHeader = () => {
        const taxYears = this.getViewableTaxYears();
        const { upperTaxYear } = this.state;
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <Icon
                            name='chevron circle left'
                            link
                            onClick={() =>
                                this.setState({
                                    upperTaxYear: upperTaxYear - 1,
                                })
                            }
                        />
                        <span className='noselect'>Skatteår&nbsp;</span>
                        <Icon
                            name='chevron circle right'
                            link
                            onClick={() =>
                                this.setState({
                                    upperTaxYear: upperTaxYear + 1,
                                })
                            }
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell content='Standard' textAlign='center'/>
                    { taxYears.map(ty => <Table.HeaderCell key={ty} textAlign='center'>{ty}</Table.HeaderCell>) }
                </Table.Row>
            </Table.Header>
        );
    };

    renderProductCell = (taxYear, id) => {
        const { deal } = this.state;
        const productPath = `priceMatrice.prices.${id}`;
        const taxYearPath = `${productPath}.${taxYear}`;
        const price = get(deal, taxYearPath);
        return (
            <Table.Cell key={taxYear + id}>
                <input
                    placeholder='-'
                    className={styles.priceField}
                    defaultValue={price}
                    onChange={this.priceChanged(productPath, taxYearPath)}
                />
            </Table.Cell>
        );
    };

    renderProductRow = ({ id }) => {
        const taxYears = this.getViewableTaxYears();
        return (
            <Table.Row key={id}>
                <Table.Cell>
                    <Header size='small'>{readableID(id)}</Header>
                </Table.Cell>
                { this.renderProductCell(STANDARD, id) }
                { taxYears.map(ty => this.renderProductCell(ty, id)) }
            </Table.Row>
        );
    };

    renderTableBody = () => {
        const { products } = this.state;
        return (
            <Table.Body>
                { products.map(this.renderProductRow) }
            </Table.Body>
        );
    };

    renderPricesTable = () => {
        return (
            <Table striped>
                { this.renderTableHeader() }
                { this.renderTableBody() }
            </Table>
        );
    };

    renderError = () => {
        const { error } = this.state;
        if (!error) {
            return null;
        }
        return (
            <Message
                content={error}
                icon='warning'
                error
            />
        );
    };

    renderLadder = (ladder, idx) => {
        const { products } = this.state;
        return <PriceLadder
            ladder={ladder}
            products={products}
            onChange={this.ladderChanged(idx)}
            onDelete={() => this.deletePriceLadder(idx)}
        />;
    };

    renderLadders = () => {
        const { ladders } = this.state.deal;
        return <Segment stacked>
            <Header icon='sort amount up' content='Pristrapper' />
            {ladders.map(this.renderLadder)}
            <Button.Group>
                <Button
                    content='Opret pristrappe'
                    labelPosition='right'
                    icon='plus'
                    onClick={this.createPriceLadder}
                />
            </Button.Group>
        </Segment>;
    };

    renderPriceMatrice = () => {
        return <Segment stacked>
            <Header icon='block layout' content='Prismatrice' />
            {this.renderPricesTable()}
        </Segment>
    };

    renderPurchaseStats = () => {
        return <Segment stacked>
            <Header icon='list alternate' content='Købsoversigt' />
            <PurchaseStats uid={this.props.uid} />
        </Segment>
    };

    renderPriceDeal = () => {
        return <>
            {this.renderLadders()}
            {this.renderPriceMatrice()}
            {this.renderPurchaseStats()}
            <div className={styles.saveContainer}>
                <Button
                    primary
                    content='Gem'
                    labelPosition='right'
                    icon='save'
                    onClick={this.savePriceDeal}
                />
            </div>
        </>;
    };

    renderContent = () => {
        const { loading, deal } = this.state;
        if (loading) {
            return null;
        }

        // no deal => render deal creation segment
        if (!deal) {
            return <Segment textAlign='center'>
                <Header>Bogholderen har ikke nogen prisaftale endnu</Header>
                <Button
                    primary
                    content='Opret prisaftale'
                    labelPosition='right'
                    icon='plus'
                    onClick={this.createNewPriceDeal}
                />
            </Segment>;
        }

        // deal => render deal details
        return (
            <>
                {this.renderPriceDeal()}
                {this.renderError()}
            </>
        );
    };

    render () {
        const { loading, working } = this.state;
        return (
            <Container>
                <Loader active={loading || working} inline='centered' />
                {this.renderContent()}
            </Container>
        );
    }
}

export default PricesControlPanel;