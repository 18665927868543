import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { DraggableItem, DragAndDropZone } from 'model-editor/atoms/DragAndDrop';
import deepCopy from 'shared/deepCopy';
import * as arrayUtil from 'shared/array';
import * as blockService from '../blockService';
import CreateBlockButton from './CreateBlockButton';
import Block from './Block';

const getGroupSize = (idx, closers) => {
    const closer = closers[idx];
    if (closer === undefined) {
        return 1;
    }

    return closer - idx + 1;
};

const BlockView = ({
    blocks = [],
    setBlocks,
    selectedBlockID,
    setSelectedBlockID,
    supportedLanguages,
    inputDefinitions,
    userContext,
    scrollToBlock,
}) => {
    const [draggedID, setDraggedID] = useState(null);
    const [blockClipboard, setBlockClipboard] = useState(null);

    useEffect(() => {
        if (!scrollToBlock) {
            return;
        }

        const selectedElement = document.getElementById(selectedBlockID);
        if (!selectedElement) {
            return;
        }

        selectedElement.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
        // eslint-disable-next-line
    }, [selectedBlockID, scrollToBlock]);

    const mutateBlock = useCallback((idx, updator) => {
        const blocksCopy = [...blocks];
        updator(blocksCopy[idx]);
        setBlocks(blocksCopy);
    }, [blocks, setBlocks]);

    const mutateMany = useCallback((idx, closers, updator) => {
        const blocksCopy = [...blocks];
        for (let blockIdx = idx; blockIdx < closers[idx]; blockIdx++) {
            updator(blocksCopy[blockIdx]);
        }
        setBlocks(blocksCopy);
    }, [blocks, setBlocks]);

    const expandAll = useCallback((idx, closers) => {
        mutateMany(idx, closers, block => {
            if (blockService.isComposite(block)) {
                block.expanded = true;
            }
        });
    }, [mutateMany]);

    const collapseAll = useCallback((idx, closers) => {
        mutateMany(idx, closers, block => {
            if (blockService.isComposite(block)) {
                block.expanded = false;
            }
        });
    }, [mutateMany]);

    const insertBlocks = useCallback((idx, closers, ...blocksToInsert) => {
        const blocksCopy = [...blocks];

        const prevBlock = blocksCopy[idx - 1];
        if (blockService.isCollapsed(prevBlock)) {
            idx = closers[idx - 1] + 1;
        }

        setBlocks([
            ...blocksCopy.slice(0, idx),
            ...blocksToInsert,
            ...blocksCopy.slice(idx),
        ]);
    }, [blocks, setBlocks]);

    const deleteBlock = useCallback((idx, closers, originalIndices, activeEffectiveIndex) => {
        if (blockService.isCloser(blocks[idx])) {
            return;
        }

        const groupSize = getGroupSize(idx, closers);
        const blocksCopy = [...blocks];

        const newSelection = blocksCopy[originalIndices[activeEffectiveIndex] + groupSize];
        setSelectedBlockID(newSelection?.id);

        blocksCopy.splice(idx, groupSize);
        setBlocks(blocksCopy);
    }, [blocks, setBlocks, setSelectedBlockID]);

    const cloneBlock = useCallback((idx, closers) => {
        const groupSize = getGroupSize(idx, closers);
        const blocksCopy = [...blocks];

        const [first, ...rest] = (
            blocksCopy
            .slice(idx, idx + groupSize)
            .map(blockService.createBlockCopy)
        );

        setSelectedBlockID(first.id);
        insertBlocks(idx, closers, first, ...rest);
    }, [blocks, insertBlocks, setSelectedBlockID]);

    const createBlock = useCallback((blockType, closers) => {
        const indexOfSelected = blocks.findIndex(block => block.id === selectedBlockID) + 1;
        const [first, ...rest] = blockService.createBlock(blockType);
        setSelectedBlockID(first.id);
        insertBlocks(indexOfSelected, closers, first, ...rest);
    }, [blocks, setSelectedBlockID, selectedBlockID, insertBlocks]);

    const moveBlock = useCallback((sourceIdx, destinationIdx, closers, originalIndices) => {
        const blocksCopy = [...blocks];

        // convert dnd indices into actual indices in the blocks list
        const aActualIdx = originalIndices[sourceIdx];
        const bActualIdx = originalIndices[destinationIdx];

        if (!blockService.canMove(blocksCopy[aActualIdx])) {
            return;
        }

        let destinationBlock = blocksCopy[bActualIdx];
        let destinationIndex = bActualIdx;

        // if dropped on closed composite block from above
        // => move block below closed group
        if (blockService.isCollapsed(destinationBlock) && aActualIdx <= bActualIdx) {
            destinationIndex = closers[bActualIdx];
        }

        if (blockService.isComposite(blocksCopy[aActualIdx])) {
            const groupSize = closers[aActualIdx] - aActualIdx + 1;
            arrayUtil.moveGroup(blocksCopy, groupSize, aActualIdx, destinationIndex);
        } else {
            arrayUtil.move(blocksCopy, aActualIdx, destinationIndex);
        }

        setBlocks(blocksCopy);
    }, [blocks, setBlocks]);
    
    const renderedBlocks = useMemo(() => {
        const result = {
            elements: [],
            originalIndices: {},
            openers: {},
            closers: {},
            activeIndex: -1,
            activeEffectiveIndex: -1,
        };
        const nestingStack = [];

        let effectiveIndex = 0;

        blocks.forEach((block, idx) => {
            const isComposite = blockService.isComposite(block);
            const isCloser = blockService.isCloser(block);

            let opener;
            let openerIdx;
            if (isCloser) {
                [opener, openerIdx] = nestingStack.pop();
                result.closers[openerIdx] = idx;
                result.openers[idx] = openerIdx;
            }

            // hide if any parent groups are closed
            // and don't render the closer block of a closed group
            let isHidden = isCloser && !opener.expanded;
            let levelOfHighlight = null;
            let level = nestingStack.length - 1;
            while (level >= 0) {
                const [curOpener] = nestingStack[level--];
                if (!curOpener.expanded) {
                    isHidden = true;
                }

                if (curOpener.id === selectedBlockID) {
                    levelOfHighlight = level + 1;
                }
            }

            if (!isHidden) {
                const blockToConsider = opener || block;
                const idxOfBlockToConsider = opener ? openerIdx : idx;
                const indexToUse = effectiveIndex++;
                const isSelected = selectedBlockID === block.id;

                if (isSelected) {
                    result.activeIndex = idx;
                    result.activeEffectiveIndex = indexToUse;
                }

                result.originalIndices[indexToUse] = idx;

                result.elements.push(
                    <DraggableItem
                        id={block.id}
                        key={block.id}
                        index={indexToUse}
                        isDragDisabled={!blockService.canMove(block)}
                    >
                        <Block
                            id={block.id}
                            key={block.id}
                            label={blockService.getLabel(blockToConsider, inputDefinitions, userContext.language)}
                            icon={blockService.getIcon(blockToConsider)}
                            isComposite={blockService.isComposite(blockToConsider)}
                            levelOfHighlight={levelOfHighlight}
                            supportedLanguages={supportedLanguages}
                            expanded={blockToConsider.expanded}
                            identation={nestingStack.length}
                            isHighlighted={isSelected || idxOfBlockToConsider === result.activeIndex}
                            isCloser={isCloser}
                            isBeingDragged={draggedID === blockToConsider.id}
                            onSelect={() => setSelectedBlockID(block.id)}
                            onExpand={() => mutateBlock(idxOfBlockToConsider, block => block.expanded = !block.expanded)}
                            onDelete={() => deleteBlock(idxOfBlockToConsider, result.closers, result.originalIndices, result.activeEffectiveIndex)}
                            onCopy={() => cloneBlock(idxOfBlockToConsider, result.closers)}
                            onExpandAll={() => expandAll(idxOfBlockToConsider, result.closers)}
                            onCollapseAll={() => collapseAll(idxOfBlockToConsider, result.closers)}
                            onLog={() => console.log(block)}
                        />
                    </DraggableItem>
                );
            }

            if (isComposite) {
                nestingStack.push([block, idx]);
            }
        });

        return result;
    }, [
        blocks,
        cloneBlock,
        deleteBlock,
        mutateBlock,
        expandAll,
        collapseAll,
        draggedID,
        selectedBlockID,
        setSelectedBlockID,
        supportedLanguages,
        inputDefinitions,
        userContext,
    ]);

    const onDragEnd = (result) => {
        setDraggedID(null);

        if (!result.destination) {
            return;
        }

        moveBlock(
            result.source.index,
            result.destination.index,
            renderedBlocks.closers,
            renderedBlocks.originalIndices,
        );
    };

    useEffect(() => {
        const onKeyDown = e => {
            if (document.activeElement !== document.body) {
                return;
            }

            const {
                activeEffectiveIndex,
                activeIndex,
                closers,
                originalIndices,
            } = renderedBlocks;

            const key = e.key;

            if (e.ctrlKey || e.metaKey) {
                switch (key) {
                    case 'c': {
                        if (blockService.isCloser(blocks[activeIndex])) {
                            return;
                        }
                        const groupSize = getGroupSize(activeIndex, closers);
                        const groupToCopy = deepCopy(blocks.slice(activeIndex, activeIndex + groupSize));
                        setBlockClipboard(groupToCopy);
                        return;
                    }
                        
                    case 'v': {
                        if (!blockClipboard) {
                            return;
                        }
                        const blockIdx = activeIndex + 1;
                        const [first, ...rest] = blockClipboard.map(blockService.createBlockCopy);
                        setSelectedBlockID(first.id);
                        insertBlocks(blockIdx, closers, first, ...rest);
                        return;
                    }

                    case 'ArrowUp': {
                        moveBlock(activeEffectiveIndex, activeEffectiveIndex - 1, closers, originalIndices);
                        return;
                    }

                    case 'ArrowDown': {
                        moveBlock(activeEffectiveIndex, activeEffectiveIndex + 1, closers, originalIndices);
                        return;
                    }
                        
                    default: {
                        console.log('Unsupported key', key);
                        return;
                    }
                }
            }

            switch (key) {
                case 'Delete': {
                    deleteBlock(activeIndex, closers, originalIndices, activeEffectiveIndex);
                    return;
                }

                case 'ArrowUp': {
                    e.preventDefault();
                    const prevBlock = blocks[originalIndices[activeEffectiveIndex - 1]];
                    prevBlock && setSelectedBlockID(prevBlock.id);
                    return;
                }

                case 'ArrowDown': {
                    e.preventDefault();
                    const nextBlock = blocks[originalIndices[activeEffectiveIndex + 1]];
                    nextBlock && setSelectedBlockID(nextBlock.id);
                    return;
                }

                case 'ArrowRight': {
                    if (blockService.isComposite(blocks[activeIndex])) {
                        mutateBlock(activeIndex, block => block.expanded = true);
                        return;   
                    }
                    return;
                }

                case 'ArrowLeft': {
                    if (blockService.isCloser(blocks[activeIndex])) {
                        setSelectedBlockID(blocks[renderedBlocks.openers[activeIndex]].id);
                        mutateBlock(renderedBlocks.openers[activeIndex], block => {
                            block.expanded = false
                        });
                        return;   
                    }

                    if (blockService.isComposite(blocks[activeIndex])) {
                        mutateBlock(activeIndex, block => block.expanded = false);
                        return;   
                    }
                    return;
                }

                default: {
                    return;
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);
        return () => {
            return window.removeEventListener('keydown', onKeyDown);
        };
    }, [
        setSelectedBlockID,
        blocks,
        renderedBlocks,
        blockClipboard,
        cloneBlock,
        deleteBlock,
        setBlockClipboard,
        insertBlocks,
        mutateBlock,
        moveBlock,
    ]);

    return (
        <>
            {renderedBlocks.elements.length > 0 &&
                <div
                    onClick={() => document.activeElement.blur()}
                    style={{
                        background: 'rgb(251, 251, 251)',
                        border: '1px solid rgb(238, 238, 238)',
                        marginBottom: '1em',
                    }}
                >
                    <DragAndDropZone
                        droppableId='report-pdf-blocks-dnd'
                        onBeforeDragStart={({ draggableId }) => setDraggedID(draggableId)}
                        onDragEnd={e => onDragEnd(e, renderedBlocks.originalIndices, renderedBlocks.closers)}
                        children={renderedBlocks.elements}
                    />
                </div>
            }
            <div
                style={{
                    bottom: 0,
                    zIndex: 1,
                    position: 'sticky',
                    marginBottom: '1em',
                    background: 'white',
                }}
            >
                <Button.Group fluid>
                    {
                        [[false, 'block'], [true, 'group']].map(([composite, label]) => {
                            return (
                                <CreateBlockButton
                                    key={label}
                                    onCreate={blockID => createBlock(blockID, renderedBlocks.closers)}
                                    composite={composite}
                                    label={label}
                                />
                            );
                        })
                    }
                </Button.Group>
            </div>
        </>
    );
};

export default BlockView;