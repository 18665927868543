import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import SubSection from './SubSection';

const BooleanRule = props => {
    const { disabled, rule, updateRule } = props;

    const inputField = (
        <Dropdown
            fluid
            selection
            disabled={disabled}
            defaultValue={rule.compareValue}
            placeholder='Vælg svarværdi'
            options={[
                { text: 'Sand', value: 'true' },
                { text: 'Falsk', value: 'false' },
            ]}
            onChange={(_, { value }) => updateRule({ compareValue: value })}
        />
    );

    return (
        <SubSection
            input={inputField}
            operators={['==', '!=']}
            {...props}
        />
    );
};

export default BooleanRule;