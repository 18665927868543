class UnexpectedTokenError extends Error {
    constructor (badToken, i, ctx) {
        ctx && ctx.debug();
        super(`Unexpected token: '${badToken}' at index ${i}`);
    }
}

function isWhitespace(c) {
    return (
        c === ' ' ||
        c === '\n' ||
        c === '\t' ||
        c === '\r'
    );
}

function movePastNext(ctx, toPass) {
    while (ctx.hasNext() && ctx.peek(toPass.length) !== toPass) {
        ctx.i++;
    }
    ctx.i += toPass.length;
}

function moveUntilNextNonSpace(ctx) {
    while (ctx.hasNext()) {
        if (!isWhitespace(ctx.peek())) {
            break;
        }
        ctx.i++;
    }
}

function readNextAttributeName(ctx) {
    let name = '';
    while (ctx.hasNext() && ctx.peek() !== '=') {
        name += ctx.next();
    }
    ctx.i++;
    return name;
}

function readNextAttributeValue(ctx) {
    let value = '';
    while (ctx.peek() !== '"') {
        value += ctx.next();
    }
    ctx.i++;
    return value;
}

function parseNextAttribute(ctx) {
    const name = readNextAttributeName(ctx);
    if (ctx.peek() !== '"') {
        throw new UnexpectedTokenError(ctx.peek(), ctx.i);
    }
    ctx.i++;
    const value = readNextAttributeValue(ctx);
    return { name, value };
}

function readNextPlainText(ctx) {
    let out = '';
    while (!['<', '>'].includes(ctx.peek())) {
        out += ctx.next();
    }
    return out.trim();
}

function parseNextChildren(ctx, parentTagName) {
    const children = [];
    while (ctx.hasNext()) {
        moveUntilNextNonSpace(ctx);
        if (ctx.peek(2) === '</') {
            ctx.i += 2;
            const tagName = readNextTagName(ctx);
            if (tagName === parentTagName) {
                ctx.i++;
                break;
            } else {
                throw new Error(`Unexpected closing tag: "${tagName}". Expected "${parentTagName}"`);
            }
        }
        if (ctx.peek() === '<') {
            const child = parseNextTag(ctx);
            children.push(child);
        }
        const plainText = readNextPlainText(ctx);
        plainText && children.push(plainText);
    }
    return children;
}

function readNextTagName(ctx) {
    let out = '';
    while (ctx.hasNext() && !isWhitespace(ctx.peek()) && ctx.peek() !== '>') {
        out += ctx.next();
    }
    return out;
}

function parseNextTag(ctx) {
    const out = {
        tagName: '',
        children: [],
        attributes: {},
    };
    moveUntilNextNonSpace(ctx);
    while (ctx.peek(4) === '<!--') {
        movePastNext(ctx, '-->');
        moveUntilNextNonSpace(ctx);
    }
    if (ctx.peek() !== '<') {
        throw new UnexpectedTokenError(ctx.peek(), ctx.i, ctx);
    }
    ctx.i++;
    out.tagName = readNextTagName(ctx);
    while (ctx.hasNext()) {
        moveUntilNextNonSpace(ctx);
        if (ctx.peek(2) === '/>') {
            ctx.i += 2;
            return out;
        }
        if (ctx.peek() === '>') {
            ctx.i++;
            out.children = parseNextChildren(ctx, out.tagName);
            return out;
        }
        const { name, value } = parseNextAttribute(ctx);
        out.attributes[name] = value;
    }
}

function cutMeta(xml) {
    const match = xml.match(/<[a-zA-Z_]/);
    if (!match) {
        throw new Error('Failed to find root tag');
    }

    return xml.substring(match.index);
};

class XMLContext {
    constructor (xml, i = 0) {
        this.i = i;
        this.xml = xml;
    }
    hasNext () {
        return this.i < this.xml.length;
    }
    peek (len = 1) {
        return this.xml.substr(this.i, len);
    }
    next (len = 1) {
        const out = this.xml.substr(this.i, len);
        this.i += len;
        return out;
    }
    debug (msg) {
        msg && console.log(msg);
        console.log(this.xml);
        let spcs = '';
        for (let i = 0; i < this.i; i++) spcs += ' ';
        spcs += '^';
        console.log(spcs);
    }
}

function OutputStream(options) {
    let buffer = '';
    return {
        write: toWrite => buffer += toWrite,
        writeNewline: () => buffer += options.beatify ? '\n' : '',
        writeIdentation: level => buffer += options.beatify ? ' '.repeat(level * options.indentation) : '',
        get: () => buffer,
    };
}

function writeNode(outs, node, level = 0) {
    outs.writeIdentation(level);
    if (typeof node === 'number') {
        outs.write('' + node);
        return;
    }
    if (typeof node === 'boolean') {
        outs.write(node ? 'true' : 'false');
        return;
    }
    if (typeof node === 'string') {
        outs.write(node);
        return;
    }
    outs.write('<');
    outs.write(node.tagName);
    const attrEntries = Object.entries(node.attributes);
    if (attrEntries.length > 0) {
        outs.write(' ');
    }
    for (let i = 0; i < attrEntries.length; i++) {
        const [key, value] = attrEntries[i];
        outs.write(key);
        outs.write('="');
        outs.write(value);
        outs.write('"');
        if (i !== attrEntries.length - 1) {
            outs.write(' ');
        }
    }
    if (node.children.length === 0) {
        outs.write(' />');
        return
    }
    outs.write('>');
    outs.writeNewline();
    for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        writeNode(outs, child, level + 1);
        if (i !== node.children.length - 1) {
            outs.writeNewline();
        }
    }
    outs.writeNewline();
    outs.writeIdentation(level);
    outs.write('</');
    outs.write(node.tagName);
    outs.write('>');
}

const defaultOptions = {
    beatify: true,
    indentation: 4,
};

export function writeXML(root, options = defaultOptions) {
    const outs = OutputStream(options);
    writeNode(outs, root);
    return outs.get();
}

export function parseXML(xml) {
    xml = cutMeta(xml);
    return parseNextTag(new XMLContext(xml));
}