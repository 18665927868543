// ------------------------- OBJECTS -------------------------

const renameProperty = function(obj, target, newName) {
    if(!obj.hasOwnProperty(target) || obj.hasOwnProperty(newName) || target === newName) {
        return obj;
    }
    obj[newName] = obj[target];
    delete obj[target];
    return obj;
}

// ------------------------- ARRAYS -------------------------

const partitionArray = (array, groupCapacity) => {
    let nGroups = Math.ceil(array.length / groupCapacity);
    const groups = [];
    for(let i = 0; i < nGroups; i++) {
        groups[i] = [];
    }
    for(let i = 0; i < array.length; i++) {
        const gi = Math.floor(i / groupCapacity);
        groups[gi].push(array[i]);
    }
    return groups;
};

// ------------------------- COMPARE FUNCTIONS -------------------------

const comparators = {
    DEFAULT: function(a, b) {
        let res;
        if(a < b) {
            res = -1;
        } else if(a === b) {
            res = 0;
        } else {
            res = 1;
        }
        return res;
    },
    LEXICOGRAPHICAL: function(a, b) {
        return a.localeCompare(b);
    }
};

// ------------------------- CSS STYLE -------------------------

/**
 * Aggregates classes given their associated predicates are true. If no predicate is provided, the predicate is considered true by default.
 * 
 * @param {{_class: string, predicate: Function}[]} array 
 */
const cssAggregateClasses = array => {
    return array.filter(({ predicate }) => !predicate || predicate()).map(({ _class }) => _class).join(' ');
};

// ----------------------------------------------------------

export {
    renameProperty,
    partitionArray,
    comparators,
    cssAggregateClasses
};
