import React from 'react'
import { Table, Label, Pagination, Loader, Segment } from 'semantic-ui-react';
import { getEventsPage, getEventsPageCount } from 'http/events';
import JsonDataView from './JsonView';

class EventsMaster extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            events: [],
            pages: 1,
            loading: true,
        }
    }

    componentDidMount = async () => {
        await this.fetchEvents(1);
        
        this.setState({
            loading: false,
            pages: await getEventsPageCount(),
        });
    };

    goToEventPage (e) {
        return this.fetchEvents(e.target.getAttribute("value"));
    }

    async fetchEvents(page) {
        const events = await getEventsPage(page);
        this.setState({ events });
    }

    render() {
        if (this.state.loading) {
            return <Segment textAlign='center'>
                <Loader inline active size='huge' />
                <h3>Henter begivenheder...</h3>
            </Segment>;
        }

        return (
            <Segment>
                <Segment textAlign='center' basic>
                    <Pagination
                        defaultActivePage={1}
                        totalPages={this.state.pages}
                        onPageChange={(e) => this.goToEventPage(e)}
                    />
                </Segment>      
                <Table celled compact="very" size="small">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell collapsing>#</Table.HeaderCell>
                            <Table.HeaderCell collapsing textAlign="center">Subject</Table.HeaderCell>
                            <Table.HeaderCell>User</Table.HeaderCell>
                            <Table.HeaderCell>Created</Table.HeaderCell>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            this.state.events.map(ev => {
                                return <Table.Row key={`${ev.subject}-${ev.sequence}`}>
                                    <Table.Cell>{ev.sequence}</Table.Cell>
                                    <Table.Cell textAlign='center'><Label color={this.rowType(ev.subject)}>{ev.subject}</Label></Table.Cell>
                                    <Table.Cell>{ev.displayName}</Table.Cell>
                                    <Table.Cell>{new Date(ev.createdAt).toLocaleString()}</Table.Cell>
                                    <Table.Cell>{this.renderEvent(ev)}</Table.Cell>
                                </Table.Row>
                            })
                        }
                    </Table.Body>
                </Table>
                <Segment textAlign='center' basic>
                    <Pagination
                        defaultActivePage={1}
                        totalPages={this.state.pages}
                        onPageChange={(e) => this.goToEventPage(e)}
                    />
                </Segment>
            </Segment>
        )
    }

    renderEvent(event) {
        switch (event.subject) {
            case 'model.published.2':
                if (event.event.name !== '') {
                    return `${event.event.id} (${event.event.name})`
                } else {
                    return event.event.id
                }
            case 'events.e-conomic-did-register.1':
                return event.event.active ? 'Registered' : 'Unregistered';
            case 'erp.e-conomic-registration.1':
                return event.event.active ? 'Registered' : 'Unregistered';
            case 'model.delete_facts.1':
                return `ModelId: ${event.event.modelId}, UserId: ${event.event.userId}`
            default:
                return <JsonDataView data={event} />
        }
    }

    rowType(subject) {

        const publishType = new RegExp("published", "gi").test(subject);
        const registType = new RegExp("regist", "gi").test(subject);
        const deleteType = new RegExp("delete", "gi").test(subject);
        const errorType = new RegExp("error", "gi").test(subject);

        if(publishType) return "green";
        if(registType) return "blue";
        if(deleteType) return "purple";
        if(errorType) return "red";

        return "grey";
    }
}

export default EventsMaster
