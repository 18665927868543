import React from 'react';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import modelEditor from 'http/modelEditor';
import IconPicker from 'atoms/IconPicker';
import { createSlug } from '../../../shared/createSlug';

import TextInput from "../../../atoms/TextInput/TextInput";
import withNavShell from '../../molecules/withNavShell';
import './ProductsCreateSection.css';

class ProductsCreate extends React.Component {
    state = { error: null };

    componentDidMount () {
        this.props.updateNavShell();
    }

    async createSection () {
        const { modelId } = this.props.match.params;
        const slug = this.sectionSlug.getValue();
        const title = this.sectionTitle.getValue();
        const icon = this.state.icon;
        if (!slug || !title || !icon) {
            toast.error('Slug, titel og ikon er påkrævet');
            return;
        }

        try {
            await modelEditor.createSection(modelId, slug, title, icon);
            this.props.history.push(`/model-editor/${modelId}/sections/${slug}`);
        } catch {
            this.setState({
                error: true
            });
        }
    }

    updateSlug (e) {
        const title = e.target.value;
        const slug = createSlug(title, '-');
        this.setState({
            slug,
        });
    }

    render () {
        const {error} = this.state;
        if (error === null) {
            return (
                <div className='product-view'>
                    <div className='model'>
                        <div className="editor">
                            <h1 className='main-header'>Opret sektion</h1>
                            <section>
                                <label>
                                    Titel
                                    <br/>
                                    <TextInput onChange={(e) => this.updateSlug(e)} ref={(r) => this.sectionTitle = r}/>
                                </label>
                                <br/>
                                <br/>
                                <label>
                                    Slug
                                    <br/>
                                    <TextInput defaultValue={this.state.slug} ref={(r) => this.sectionSlug = r} key={this.state.slug}/>
                                </label>
                                <br/>
                                <br/>
                                <label>
                                    Ikon
                                    <br/>
                                    <IconPicker
                                        icon={this.state.icon}
                                        onChange={icon => this.setState({ icon })}
                                    />
                                </label>
                                <br/>
                                <br/>
                                <Button
                                    primary
                                    content='Opret sektion'
                                    onClick={this.createSection.bind(this)}
                                />
                            </section>
                        </div>
                    </div>
                </div>
             );
        }  else {
            return <div>I hit an error, check the console</div>;
        }
    }
}

export default withNavShell(ProductsCreate);
