import client from './client';
const PREFIX = '/stats/chats';

async function allChats() {
    const resp = await client.get(PREFIX);
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return resp.data;
}

function chatsByEmail(email) {
    return client.getResource(`${PREFIX}/${email}`);
}

const tawktoChats = {
    allChats,
    chatsByEmail,
};

export default tawktoChats;