import React from 'react';
import { Checkbox, Icon, Input, Loader, Popup, Table } from 'semantic-ui-react';
import { nodeTypes } from 'model-editor/nodeMetadata';
import modelEditor from 'http/modelEditor';
import taxonomyParser from 'http/taxonomyParser';

class NodeDataXBRLSum extends React.Component {
    constructor(props) {
        super(props);

        const { node } = props;
        const { flip, tags } = node.data;
        this.state = {
            flip,
            tags: tags || [],
            newTag: '',
            loading: true,
            taxonomyTags: null,
        };
    }

    componentDidMount = async () => {
        const { modelId } = this.props;
        const { taxonomy } = await modelEditor.getModelMetadata(modelId);
        if (!taxonomy) {
            this.setState({ loading: false });
            return;
        }

        const allTaxonomyTags = await taxonomyParser.listTags(taxonomy);
        const taxonomyTags = new Set(allTaxonomyTags.map(tag => tag.name));
        this.setState({
            loading: false,
            taxonomyTags,
        });
    };

    onReverseSignChanged = checked => {
        this.setState({
            flip: checked,
        });
    };

    onNewTagChanged = (_, { value }) => {
        this.setState({
            newTag: value
        });
    };

    addTag = () => {
        const { tags, newTag } = this.state;

        const trimmedTag = newTag.trim();
        if (!trimmedTag) {
            return;
        }
        
        this.setState({
            tags: [...tags, trimmedTag],
            newTag: '',
        });
    };

    removeTag = idxToRemove => {
        this.setState({
            tags: this.state.tags.filter((_, tagIdx) => tagIdx !== idxToRemove),
        });
    }

    getData() {
        const { flip, tags } = this.state;
        return {
            flip,
            tags,
        };
    }

    shouldDoTagValidation = () => {
        if (!this.state.taxonomyTags) {
            return false;
        }

        return this.props.node.type === nodeTypes.XBRLSum;
    };

    doTagInTaxonomyValidation = tag => {
        if (!this.shouldDoTagValidation()) {
            return { inTaxonomy: true };
        }
        
        const { taxonomyTags } = this.state;
        const trimmedTag = tag.trim();
        const inTaxonomy = taxonomyTags.has(trimmedTag);
        if(taxonomyTags && trimmedTag) {
            const feedback = inTaxonomy ? 'Taxonomien indeholder dette tag': 'Taxonomien indeholder ikke dette tag'
            const trigger = <Icon name={inTaxonomy ? 'check circle green': 'question yellow'} />;
            const popup = <Popup content={feedback} trigger={trigger} position='top center' />;
            return { inTaxonomy, icon: popup };
        } else {
            return { inTaxonomy: true };
        }
    }

    render() {
        const { flip, tags, newTag, loading } = this.state;
        if (loading) {
            return <Loader inline='centered' active />;
        }

        const { inTaxonomy, icon } = this.doTagInTaxonomyValidation(newTag);

        return (
            <div>
                <Checkbox
                    checked={flip}
                    onChange={(_, { checked }) => this.onReverseSignChanged(checked)}
                />
                <span
                    onClick={() => this.onReverseSignChanged(!flip)}
                    children='Vend fortegn'
                    className='noselect'
                    style={{
                        top: '-3px',
                        color: 'white',
                        cursor: 'pointer',
                        position: 'relative',
                        marginLeft: '0.5em',
                    }}
                />
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                Valgte tags:
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tags.map((tag, i) => {
                            const { icon } = this.doTagInTaxonomyValidation(tag);
                            return (
                                <Table.Row>
                                    <Table.Cell>{icon}&nbsp;{tag}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <Popup
                                            content='Slet tag'
                                            position='left center'
                                            trigger={
                                                <Icon
                                                    link
                                                    name='x'
                                                    color='red'
                                                    onClick={() => this.removeTag(i)}
                                                />
                                            }
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'>
                                <Input
                                    label='Nyt tag'
                                    placeholder='Indtast tag'
                                    onChange={this.onNewTagChanged}
                                    onKeyDown={e => e.code === 'Enter' && this.addTag()}
                                    value={newTag}
                                    error={!inTaxonomy}
                                    icon={icon}
                                    fluid
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        );
    }
}

export default NodeDataXBRLSum;
