import React, { Component } from 'react';
import { Loader, Header, List } from 'semantic-ui-react';
import tawktoChats from 'http/tawktoChats';
import AutoStamp from 'atoms/AutoStamp';

class ChatHistory extends Component {
    state = {
        chats: [],
        loading: true,
    };

    componentDidMount = async () => {
        const { email } = this.props.user;
        const chats = await tawktoChats.chatsByEmail(email);
        this.setState({
            chats,
            loading: false,
        });
    };

    renderChat = ({ createdAt, chatName }) => {
        return <List.Item>
            <strong>{chatName}</strong>
            <br />
            <AutoStamp stamp={createdAt} /> 
        </List.Item>;
    };

    renderChats = () => {
        const { chats } = this.state;
        
        if (chats.length === 0) {
            return <Header>Ingen chats fundet</Header>;
        }

        return <List divided>
            {chats.reverse().map(this.renderChat)}
        </List>;
    };

    render = () => {
        const { loading } = this.state;

        let content;
        if (loading) {
            content = <Loader active inline='centered' size='huge' />;
        } else {
            content = this.renderChats();
        }

        return <div>
            {content}
        </div>;
    };
}

export default ChatHistory;