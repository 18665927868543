import React, {Component} from 'react';

const cvrElements = {
    cvr: {
        title: 'CVR-nr',
        type: 'String'
    },
    businessForm: {
        title: 'Virksomhedsform',
        type: 'String'
    },
    accountingPeriodStartDate: {
        title: 'Regnskabsperiode start',
        type: 'String'
    },
    accountingPeriodEndDate: {
        title: 'Regnskabsperiode slut',
        type: 'String'
    },
    startDate: {
        title: 'Startdato',
        type: 'Date'
    },
    endDate: {
        title: 'Ophørsdato',
        type: 'Date'
    },
    companyName: {
        title: 'Virksomhedsnavn',
        type: 'String'
    },
    road: {
        title: 'Vejnavn',
        type: 'String'
    },
    houseNumber: {
        title: 'Husnummer',
        type: 'String'
    },
    postalCode: {
        title: 'Postnr.',
        type: 'String'
    },
    city: {
        title: 'By',
        type: 'String'
    },
    home: {
        title: 'Hjemsted',
        type: 'String'
    },
    directorName: {
        title: 'Adm. Direktør',
        type: 'String'
    },
    directors: {
        title: 'Direktører',
        type: 'StringList'
    },
    executiveBoardMembers: {
        title: 'Direktionsmedlemmer',
        type: 'StringList'
    },
    boardChairman: {
        title: 'Bestyrelsesformand',
        type: 'String'
    },
    boardViceChairman: {
        title: 'Bestyrelsesnæstformand',
        type: 'String'
    },
    boardMembers: {
        title: 'Bestyrelsesmedlemmer',
        type: 'StringList'
    },
    accountingCompany: {
        title: 'Revisor virksomhed',
        type: 'String'
    },
    firstAccountingPeriodStartDate: {
        title: 'Første regnskabsperiode start',
        type: 'Date'
    },
    firstAccountingPeriodEndDate: {
        title: 'Første regnskabsperiode slut',
        type: 'Date'
    },
    companyGoal: {
        title: 'Selskabets formål',
        type: 'String'
    },
    registeredCapital: {
        title: 'Registreret kapital',
        type: 'Number'
    },
    supervisoryBoardChairman: {
        title: 'Formand for tilsynsråd',
        type: 'String'
    },
    supervisoryBoardViceChairman: {
        title: 'Næstformand for tilsynsråd',
        type: 'String'
    },
    supervisoryBoardMembers: {
        title: 'Tilsynsråd',
        type: 'StringList'
    },
    averageNumberOfEmployees: {
        title: 'Antal ansatte gennemsnit',
        type: 'Number'
    },
    averageNumberOfEmployeesLastYear: {
        title: 'Antal ansatte gennemsnit forrigt år',
        type: 'Number'
    },
    isFinalYear: {
        title: 'Virksomhedens ophørs år',
        type: 'Boolean'
    },
    mainIndustryCode: {
        title: 'Branchekode',
        type: 'String',
    },
};

class NodeDataCvrDataElement extends Component {
    getData () {
        if (!this.elementType) {
            return {};
        }
        return {
            elementType: this.elementType.value
        };
    }

    getDatatype () {
        if (!this.elementType) {
            return 'String';
        }
        return cvrElements[this.elementType.value].type;
    }

    render () {
        const { elementType } = this.props.node.data;
        return (
            <section>
                <hr/>
                <label>Vælg CVR data</label>
                <br/>
                <select ref={r => this.elementType = r} defaultValue={elementType}>
                    <option value=''>-</option>
                    {
                        Object.keys(cvrElements).map(c => {
                            const description = cvrElements[c];
                            return <option value={c}>{description.title}</option>;
                        })
                    }
                </select>
                <br/>
                <br/>
            </section>
        );
    }
}

export default NodeDataCvrDataElement;