// First tax year sold on Digital Revisor!
const MIN_YEAR = 2016;

function getRelevantTaxYears({ goBackYears = 5, goForwardYears = 1 } = {}) {
    const nowYear = new Date().getFullYear();
    const startYear = Math.max(nowYear - goBackYears, MIN_YEAR);
    const out = [];
    for (let i = startYear; i <= nowYear + goForwardYears; i++) {
        out.push(i.toString());
    }
    return out;
}

export default getRelevantTaxYears;