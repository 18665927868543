import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Icon, Input, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import modelEditor from 'http/modelEditor';
import NodeMetadata, { nodeCategories } from 'model-editor/nodeMetadata';
import useSearch from 'shared/hooks/useSearch';

const ModelEditorSearch = ({ modelId }) => {
    const history = useHistory();
    const [selectionIndex, setSelectionIndex] = useState(0);
    const [searchOpen, setSearchOpen] = useState(false);
    const [id] = useState(Math.random().toString());
    const searchHandler = useCallback(query => {
        return modelEditor.search(modelId, query);
    }, [modelId]);
    const search = useSearch(searchHandler);

    const goToLink = useCallback(link => {
        setSearchOpen(false);
        search.setQuery('');
        setSelectionIndex(0);
        history.push(`/model-editor/${modelId}${link}`);
    }, [search, modelId, history]);

    useEffect(() => {
        const onKeyDown = e => {
            // ctrl/cmd + F
            if ((e.ctrlKey || e.metaKey) && e.keyCode === 70) {
                if (searchOpen) {
                    setSearchOpen(false);
                    return;
                }
                e.preventDefault();
                setSearchOpen(true);
            }

            if (!search.results || !searchOpen) {
                return;
            }
            
            switch (e.keyCode) {
                case 38: // up
                    if (selectionIndex === 0) {
                        setSelectionIndex(search.results.length - 1);
                    } else {
                        setSelectionIndex(selectionIndex - 1);
                    }
                    return;
                case 40: // down
                    setSelectionIndex((selectionIndex + 1) % search.results.length);
                    return;
                case 13: // enter
                    const pickedResult = search.results[selectionIndex];
                    if (pickedResult) {
                        goToLink(pickedResult.link);
                    }
                    return;
                default:
            }
        };

        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [searchOpen, setSearchOpen, selectionIndex, setSelectionIndex, search.results, goToLink]);

    if (!searchOpen) {
        return null;
    }

    return (
        <div
            id={id}
            onClick={e => e.target.id === id && setSearchOpen(false)}
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                textAlign: 'center',
                position: 'fixed',
                padding: '2em',
                height: '100%',
                width: '100%',
                zIndex: 10000,
                left: 0,
                top: 0,
            }}
        >
            <div style={{ width: '500px', display: 'inline-block' }}>
                <Input
                    onChange={debounce((_, { value }) => search.setQuery(value), 250)}
                    placeholder='Søg efter noder, sektioner, felter...'
                    iconPosition='left'
                    icon='search'
                    size='big'
                    defaultValue={search.query}
                    loading={search.searching}
                    fluid
                    autoFocus
                />
                {
                    search.results?.length > 0 &&
                    search.results.map(({ link, title, subTitle, label, icon }, idx) => {
                        const isSelected = idx === selectionIndex;

                        if (!icon) {
                            icon = nodeCategories[NodeMetadata(subTitle)?.category]?.icon;
                        }

                        const style = {
                            cursor: 'pointer',
                            opacity: isSelected ? 1 : 0.95,
                        };

                        return (
                            <Segment
                                onMouseEnter={() => setSelectionIndex(idx)}
                                onClick={() => goToLink(link)}
                                style={style}
                                textAlign='left'
                            >
                                <Grid columns={2}>
                                    <Grid.Column verticalAlign='middle' width={2} textAlign='center'>
                                        <Icon name={icon} size='big' color={isSelected ? 'green' : 'black'} />
                                    </Grid.Column>
                                    <Grid.Column width={14}>
                                        <strong>{title}</strong>
                                        <br />
                                        {label} ∙ {subTitle}
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default ModelEditorSearch;