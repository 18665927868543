import React, { Component } from 'react';
import { Button, Input, Radio, Segment, Select, Table } from 'semantic-ui-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Navigation from './navigation';
import InternalBookingOverview from './overview';
import InternalBookingDetails from './details';
import { generateCharges } from 'http/internalFinances'
import CSV from 'shared/CSV';

class AutomatedBooking extends Component {
    constructor(props) {
        super(props);
        this.timeIntervals = {
            SELECTED_MONTH: 'SELECTED_MONTH',
            WHOLE_YEAR: 'WHOLE_YEAR'
        };
        this.months = [
            'januar',
            'februar',
            'marts',
            'april',
            'maj',
            'juni',
            'juli',
            'august',
            'september',
            'oktober',
            'november',
            'december'
        ].map((month, i) => ({
            key: i,
            value: i,
            text: month
        }));
        const now = new Date();
        now.setMonth(now.getMonth() - 1)
        this.state = {
            year: now.getFullYear(),
            timeInterval: this.timeIntervals.SELECTED_MONTH,
            posteringerTimeInterval: undefined,
            month: now.getMonth(),
            charges: null,
            fetching: false,
            lineLimit: 10e6,
            lastBilagNr: 0,
            generatingCSVs: false
        }
    }

    onYearChanged = (e) => {
        this.setState({ year: e.target.value ? Number(e.target.value) : '' });
    }

    onTimeIntervalTypeSelected = (data) => {
        this.setState({ timeInterval: data.value });
    }

    onMonthChanged = (data) => {
        this.setState({ month: data.value });
    }

    onFetchData = async () => {
        const { month, timeInterval, year } = this.state;
        const monthNumber = timeInterval === this.timeIntervals.SELECTED_MONTH ?  month + 1 : 0;
        this.setState({ charges: null, fetching: true });
        try {
            const charges = await generateCharges(year, monthNumber);
            this.setState({
               charges,
               posteringerTimeInterval: timeInterval
            });
        } catch(error) {
            this.setState({
                charges: null,
                posteringerTimeInterval: ''
            });
            alert(`Kunne ikke generere betalinger... Error: ${error.message}`);
        }
        this.setState({
            fetching: false
        });
    }

    onLastBilagNrChanged = (e) => {
        this.setState({ lastBilagNr: e.target.value ? Number(e.target.value) : '' });
    }

    generateCSVFiles = async () => {
        const { month, year, posteringerTimeInterval, charges, lineLimit, lastBilagNr } = this.state;
        this.setState({
            generatingCSVs: true
        });
        
        const pad = n => {
            const strN = n.toString();
            return strN.length === 1 ? '0' + strN : strN;
        };
    
        const createNewCSV = () => new CSV(['Type', 'Bilag', 'Dato', 'Tekst', 'Konto', 'Moms', 'Debet/kredit', 'Modkonto', 'Revisors navn']);

        const files = [];
        let outLineIndex = 0;
    
        let bilagNr = lastBilagNr + 1;
        for(let charge of charges) {
            const { drUser } = charge;
            const { date, lines } = charge.bookings[0];
            const { year, month, day } = date;

            outLineIndex += lines.length;
            const fileIdx = Math.floor(outLineIndex / lineLimit);
            if(!files[fileIdx]) {
                files[fileIdx] = createNewCSV();
            }
    
            const csv = files[fileIdx];
            for(let i = 0; i < lines.length; i++) {
                const line = lines[i];
                const amount = (-line.amount).toString().replace('.', ',');
    
                csv.addToRow('Type', i === 0 ? 'F' : '');
                csv.addToRow('Bilag', bilagNr);
                csv.addToRow('Dato', [day, month, year].map(x => pad(x)).join('/'));
                csv.addToRow('Tekst', line.text);
                csv.addToRow('Konto', line.accountNumber);
                csv.addToRow('Moms', line.accountVatCode);
                csv.addToRow('Debet/kredit', amount);
                csv.addToRow('Modkonto', line.balancingAccountNumber);
                csv.addToRow('Revisors navn', drUser ? drUser.displayName : '');
                csv.newLine();
            }
            bilagNr++;
        }
    
        for(let i = 0; i < files.length; i++) {
            const filename = [
                'posteringer',
                posteringerTimeInterval === this.timeIntervals.SELECTED_MONTH ? this.months[month].text : year,
                files.length === 1 ? '' : `part_${i + 1}`
            ].filter(x => x).join('_');
            files[i].download(filename);
        }

        this.setState({
            generatingCSVs: false
        });
    }

    renderMonthSelection = () => {
        if(this.state.timeInterval === this.timeIntervals.WHOLE_YEAR) {
            return null;
        }

        return (
            <Table.Row>
                <Table.Cell>Måned</Table.Cell>
                <Table.Cell textAlign='right' colSpan='2'>
                    <Select placeholder='Vælg måned' options={this.months} defaultValue={this.state.month} onChange={(_, data) => this.onMonthChanged(data)} />
                </Table.Cell>
            </Table.Row>
        );
    };

    renderQueryContext = () => {
        const { year, fetching } = this.state;

        return <>
            <Table celled unstackable compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='3' textAlign='center'>Søgning parametre</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>År</Table.Cell>
                        <Table.Cell textAlign='right' colSpan='2'>
                            <Input placeholder={`e.g. 2022`} value={year} type='number' onChange={(e) => this.onYearChanged(e)} />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Tidsinterval</Table.Cell>
                        <Table.Cell textAlign='center'>
                            <Radio
                                label='Udvalgt måned'
                                name='time_interval'
                                value={this.timeIntervals.SELECTED_MONTH}
                                checked={this.state.timeInterval === this.timeIntervals.SELECTED_MONTH}
                                onChange={(_, data) => this.onTimeIntervalTypeSelected(data)}
                            />
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            <Radio
                                label='Hele året'
                                name='time_interval'
                                value={this.timeIntervals.WHOLE_YEAR}
                                checked={this.state.timeInterval === this.timeIntervals.WHOLE_YEAR}
                                onChange={(_, data) => this.onTimeIntervalTypeSelected(data)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    { this.renderMonthSelection() }
                </Table.Body>
            </Table>
            <Button primary fluid onClick={this.onFetchData} loading={fetching}>Hent betalinger</Button>
        </>
    }

    renderMenu = () => {
        const { charges, fetching } = this.state;
        const props = {
            charges,
            fetching
        };
        return <>
            <Navigation />
            <Switch>
                <Route path='/payment/internal-booking/overview' render={() => <InternalBookingOverview {...props} />} />
                <Route path='/payment/internal-booking/details' render={() => <InternalBookingDetails {...props} />} />
                <Redirect from='/payment/internal-booking' to='/payment/internal-booking/overview' exact />
            </Switch>
        </>
    }

    renderGenerateSection = () => {
        const { charges, lastBilagNr, generatingCSVs } = this.state;
        return <>
            <Table celled unstackable compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='2' textAlign='center'>CSV generering parametre</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Det sidste bilagsnummer</Table.Cell>
                        <Table.Cell textAlign='right'>
                            <Input placeholder={`e.g. 1234`} value={lastBilagNr} type='number' onChange={(e) => this.onLastBilagNrChanged(e)} />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Button primary fluid disabled={!charges || generatingCSVs} loading={generatingCSVs} onClick={this.generateCSVFiles}>Generer CSV filer</Button>
        </>
    }

    render = () => {
        return <>
            <Segment>
                {this.renderQueryContext()}
            </Segment>
            <Segment>
                {this.renderMenu()}
            </Segment>
            <Segment>
                {this.renderGenerateSection()}
            </Segment>
        </>;
    };
}

export default AutomatedBooking;