import React, { Component } from 'react';
import { stampDescription, formatUnix, date2ISO8601 } from '../../shared/dateTimeFormatting';
import { Popup } from 'semantic-ui-react';

class AutoStamp extends Component {
    constructor (props) {
        super(props);
        this.interval = null;
        this.state = {
            stamp: stampDescription(this.props.stamp)
        };
    }

    updateStamp = () => {
        this.setState({
            stamp: stampDescription(this.props.stamp)
        });
    };

    componentDidMount = () => {
        this.interval = window.setInterval(this.updateStamp, 1000);
    };

    componentWillUnmount = () => {
        window.clearInterval(this.interval);
    };

    render () {
        const { stamp, simple, position, lang, noPopup } = this.props;
        if (!stamp) {
            return null;
        }

        if (simple) {
            return <span>{date2ISO8601(new Date(stamp))}</span>
        }
        
        const stampSpan = <span>{stampDescription(stamp, lang)}</span>;
        if (noPopup) {
            return stampSpan;
        }

        return <Popup
            trigger={stampSpan}
            content={formatUnix(stamp)}
            position={position || 'right center'}
        />;
    }
}

export default AutoStamp;