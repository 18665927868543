import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';
import copyToClipboard from 'shared/copyToClipboard';

const copyMessages = [
    'Kopieret!',
    'Dobbelkopiering!',
    'Tredobbelkopiering!',
    'Firdobbelkopiering!',
    'Femdobbelkopiering!',
];

function CopyToClipboard({ text, trigger, children }) {
    const [open, setOpen] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(null);
    const [copyCount, setCopyCount] = useState(-1);

    const doClose = () => {
        setOpen(false);
        setCopyCount(-1);
        clearTimeout(popupTimeout);
        setPopupTimeout(null);
    };

    const handleCopy = () => {
        copyToClipboard(text);
        setOpen(true);
        setCopyCount(copyCount + 1);
        clearTimeout(popupTimeout);
        setPopupTimeout(setTimeout(() => {
            doClose();
        }, 2000));
    };

    return (
        <Popup
            on='click'
            position='top center'
            trigger={children || trigger}
            content={open && copyMessages[copyCount % copyMessages.length]}
            open={open}
            onOpen={handleCopy}
            onClose={doClose}
        />
    );
}

export default CopyToClipboard;