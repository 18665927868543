import * as model from '@digital-revisor/node-report-editor-model';
import { formatNumber } from 'shared/formatNumber';

export const editorDataTypesMeta = {
    [model.definitionDataTypes.String]: {
        name: 'Text',
        icon: 'text cursor',
    },
    [model.definitionDataTypes.Number]: {
        name: 'Number',
        icon: 'calculator',
    },
    [model.definitionDataTypes.Date]: {
        name: 'Date',
        icon: 'calendar alternate',
    },
    [model.definitionDataTypes.Boolean]: {
        name: 'Yes/no',
        icon: 'check circle',
    },
    [model.definitionDataTypes.StringList]: {
        name: 'Text list',
        icon: 'list',
    },
    [model.definitionDataTypes.Resource]: {
        name: 'Resource',
        icon: 'cog',
    },
};


const jsDataTypeToModelDataType = {
    number:  model.definitionDataTypes.Number,
    boolean: model.definitionDataTypes.Boolean,
    string:  model.definitionDataTypes.String,
};

export const inputDataTypes = [
    model.definitionDataTypes.String,
    model.definitionDataTypes.Number,
    model.definitionDataTypes.Date,
    model.definitionDataTypes.Boolean,
    model.definitionDataTypes.StringList,
    model.definitionDataTypes.Resource,
].map(id => ({
    id,
    icon: editorDataTypesMeta[id].icon,
    description: editorDataTypesMeta[id].name,
}));

export const taxoTypeMap = {
    'xbrli:dateItemType':     model.definitionDataTypes.Date,
    'xbrli:stringItemType':   model.definitionDataTypes.String,
    'xbrli:monetaryItemType': model.definitionDataTypes.Number,
};

export const countryOptions = [
    { key: 'dk', value: 'dk', flag: 'dk', text: 'Danish' },
    { key: 'nl', value: 'nl', flag: 'nl', text: 'Dutch' },
    { key: 'gb', value: 'gb', flag: 'gb', text: 'English' },
    { key: 'se', value: 'se', flag: 'se', text: 'Swedish' },
];

export const userContextSuggestions = Object.entries(model.defaultUserContextValues).map(([key, value]) => {
    const dataType = jsDataTypeToModelDataType[typeof value];
    return {
        label: key,
        icon: editorDataTypesMeta[dataType].icon,
        dataType,
    };
});

export const applyBlockTextTemplating = (text, definitions) => {
    if (!text) {
        return '<MISSING TEXT>';
    }
    
    const definitionMap = {};
    definitions.forEach(definition => {
        definitionMap[definition.tag] = definition;
    });

    const { activeYear, lastYear, noteReferences } = model.variableNamespaces;
    
    const patterns = [
        [new RegExp(activeYear.pattern), definition => definition?.testValue],
        [new RegExp(lastYear.pattern), definition => definition?.testValueLastYear],
        [new RegExp(noteReferences.pattern), () => '*'],
    ];
    
    patterns.forEach(([pattern, valueGetter]) => {
        text = text.replace(pattern, (fullMatch, name) => {    
            const definition = definitionMap[name];

            const value = (
                definition
                    ? valueGetter(definition)
                    : fullMatch
            );
    
            switch (definition?.dataType) {
                case model.definitionDataTypes.Number: {
                    return formatNumber(value);
                }
    
                case model.definitionDataTypes.Boolean: {
                    return value?.toString();
                }
    
                case model.definitionDataTypes.Date: {
                    return new Date(value).toLocaleDateString('da-DK', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    });
                }
    
                default: {
                    return value?.toString() || fullMatch;
                }
            }
        });
    });

    return text;
};

export default model;