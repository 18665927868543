import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, Form, Header, Icon, Input, Loader, Message, Modal, Segment, Transition } from 'semantic-ui-react';
import * as credits from 'http/credits';
import useRestResource from 'shared/hooks/useRestResource';
import ColoredText from 'atoms/ColoredText';
import DatePicker from 'atoms/DatePicker';
import Tooltip from 'atoms/Tooltip';
import CreditTransactions from './CreditTransactions';
import PriceStepsEditor from './PriceStepsEditor';
import PurchaseMatrice from './PurchaseMatrice';

const Credits = ({ user }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [creditsToAdd, setCreditsToAdd] = useState(0);
    const [givenForFree, setGivenForFree] = useState(false);
    const [transactionComment, setTransactionComment] = useState('');
    const [newExpirationDate, setNewExpirationDate] = useState(null);

    const creditsBalanceResource = useRestResource({
        fetcher: () => credits.getCreditsBalance(user.id),
    });

    const creditsBoxResource = useRestResource({
        fetcher: () => credits.getCreditsBox(user.id),
        args: [user],
    });

    const updateCreditsBox = async (propsToUpdate = {}) => {
        creditsBoxResource.setLocalData({ ...creditsBoxResource.data, ...propsToUpdate });
    };

    const saveCreditsBoxChanges = async () => {
        try {
            await credits.patchCreditsBox(user.id, creditsBoxResource.data);
            creditsBoxResource.refetch();
        } catch (e) {
            toast.error(e.message);
        }
    };

    const submitCreditsTransaction = async newBalance => {
        if (newBalance > 0) {
            if (!newExpirationDate) {
                return toast.error('Du skal sætte en udløbsdato!');
            }

            if (new Date() >= newExpirationDate) {
                return toast.error('Udløbsdato skal være efter dags dato');
            }
        }

        setModalOpen(false);
        await credits.addToCreditBalance(user.id, creditsToAdd, givenForFree, new Date(newExpirationDate), transactionComment);
        creditsBalanceResource.refetch();
        creditsBoxResource.refetch();
        setCreditsToAdd(0);
        setTransactionComment('');
        toast.success(`Credits blev ${creditsToAdd > 0 ? 'tilføjet' : 'fjernet'}!`);
    };

    const allResources = [creditsBalanceResource, creditsBoxResource];

    if (allResources.some(resource => resource.loading)) {
        return (
            <Loader inline='centered' active />
        );
    }

    if (allResources.some(resource => resource.error)) {
        return (
            <Message
                error
                content={
                    <span>
                        <Icon name='warning circle' />
                        Der opstod en fejl
                    </span>
                }
            />
        );
    }

    const creditsBox = creditsBoxResource.data || {};
    const newBalance = (creditsBalanceResource.data ?? 0) + creditsToAdd;

    const modal = modalOpen && (
        <Modal open onClose={() => setModalOpen(false)}>
            <Modal.Header>Regulér credits</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>
                            Balanceændring&nbsp;
                            <Tooltip>
                                OBS: Lægger antallet til den nuværende sum.
                                Antallet må gerne være et negativt tal.
                            </Tooltip>
                        </label>
                        <Input
                            type='number'
                            onChange={(_, { value }) => {
                                const balanceChange = Number(value);
                                setCreditsToAdd(balanceChange);
                                if (balanceChange <= 0) {
                                    setGivenForFree(false);
                                }
                            }}
                            defaultValue={creditsToAdd}
                        />
                        <div style={{ marginTop: '0.5em' }}>Ny balance: {newBalance}</div>
                    </Form.Field>
                    <Transition visible={creditsToAdd > 0} animation='fade up'>
                        <Form.Field>
                            <label>
                                Gratis credits?&nbsp;
                                <Tooltip>Slå til hvis balanceændringen ikke er knyttet til en faktura</Tooltip>
                            </label>
                            <Checkbox
                                checked={givenForFree}
                                onChange={(_, { checked }) => setGivenForFree(checked)}
                                toggle
                            />
                        </Form.Field>
                    </Transition>
                    <Transition visible={newBalance > 0} animation='fade up'>
                        <Form.Field>
                            <label>
                                Udløbsdato
                            </label>
                            <DatePicker
                                value={newExpirationDate ? newExpirationDate : undefined}
                                onChange={expirationDate => setNewExpirationDate(expirationDate)}
                            />
                            <div style={{ marginTop: '0.5em' }}>
                                <ColoredText
                                    link
                                    content='Sæt til 18 måneder fra dags dato'
                                    onClick={() => {
                                        const now = new Date();
                                        now.setMonth(now.getMonth() + 18);
                                        setNewExpirationDate(now);
                                    }}
                                />
                            </div>
                        </Form.Field>
                    </Transition>
                    <Form.Field>
                        <label>Besked til brugeren (ikke påkrævet)</label>
                        <textarea
                            defaultValue={transactionComment}
                            onChange={e => setTransactionComment(e.target.value)}
                            style={{ resize: 'vertical' }}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='Annuller'
                    color='black'
                    onClick={() => setModalOpen(false)}
                />
                <Button
                    content='Regulér credits'
                    icon='exchange'
                    primary
                    onClick={() => submitCreditsTransaction(newBalance)}
                    disabled={creditsToAdd === 0}
                />
            </Modal.Actions>
        </Modal>
    );

    const expirationDateSet = new Date(creditsBox.expirationDate).getTime() > 0;

    const creditsBoxForm = (
        <Form>
            <Form.Field>
                <label>Nuværende credits balance</label>
                <span children={creditsBalanceResource.data ?? 0} />
                {' ∙ '}
                <ColoredText
                    link
                    content='Regulér'
                    onClick={() => {
                        if (expirationDateSet) {
                            setNewExpirationDate(creditsBox.expirationDate);
                        }
                        setModalOpen(true);
                    }}
                />
            </Form.Field>
            <Form.Field>
                <label>Udløbsdato</label>
                <DatePicker
                    value={expirationDateSet ? creditsBox.expirationDate : undefined}
                    onChange={expirationDate => updateCreditsBox({ expirationDate: new Date(expirationDate) })}
                />
                {expirationDateSet && (
                    <ColoredText
                        link
                        content='Fjern udløbsdato'
                        onClick={() => updateCreditsBox({ expirationDate: new Date(0) })}
                    />
                )}
            </Form.Field>
            <Form.Field>
                <label>Tillad overtræk?</label>
                <Checkbox
                    toggle
                    defaultChecked={creditsBox?.overdrawAllowed}
                    onChange={(_, { checked }) => updateCreditsBox({ overdrawAllowed: checked })}
                />
            </Form.Field>
            {creditsBox?.overdrawAllowed && (
                <Form.Field>
                    <label>Pris pr. credit ved overtræk</label>
                    <Input
                        type='number'
                        defaultValue={creditsBox?.overdrawPricePerCredit}
                        onChange={(_, { value }) => {
                            updateCreditsBox({ overdrawPricePerCredit: Number(value) })
                        }}
                    />
                </Form.Field>
            )}
            <Form.Field>
                <label>Pristrappe</label>
                <Checkbox
                    toggle
                    label='Angiv brugerdefineret pristrappe'
                    checked={creditsBox?.hasCustomPriceSteps}
                    onChange={(_, { checked }) => updateCreditsBox({ hasCustomPriceSteps: checked })}
                />
                {creditsBox?.hasCustomPriceSteps && (
                    <PriceStepsEditor
                        customPriceSteps={creditsBox?.customPriceSteps || []}
                        onChange={customPriceSteps => updateCreditsBox({ customPriceSteps })}
                    />
                )}
            </Form.Field>
            <Form.Field>
                <label>Transaktionslog</label>
                <CreditTransactions uid={user.id} />
            </Form.Field>
        </Form>
    );

    return (
        <Segment
            basic
            loading={creditsBoxResource.loading}
            style={{ padding: '0' }}
        >
            <div style={{ display: 'flex' }}>
                <Header content='Credits' icon='dollar' style={{ flexGrow: 1 }} />
                <Button
                    content='Gem ændringer'
                    icon='save'
                    primary={creditsBoxResource.hasChanges()}
                    disabled={!creditsBoxResource.hasChanges()}
                    onClick={saveCreditsBoxChanges}
                />
            </div>
            {creditsBoxForm}
            <PurchaseMatrice uid={user.id} />
            {modal}
        </Segment>
    );
};

export default Credits;