import React from 'react';

class NodeDataErpSupportsBooking extends React.Component {
    getData() {
        return null;
    }

    render() {
        return null;
    }
}

export default NodeDataErpSupportsBooking;
