class Accountant {
    constructor (accountantData) {
        if (accountantData) {
            Object.assign(this, accountantData);
        }
    }

    /**
     * @param {any[]} alist - List of accountants from server
     * @returns {Accountant[]} 
     */
    static unmarshalAccountants (alist) {
        const out = [];
        for (let accountant of alist) {
            out.push(new Accountant(accountant));
        }
        return out;
    }

    countActiveProducts () {
        const out = {};
        const { clientOwnedProducts } = this;
        if (!clientOwnedProducts) {
            return out;
        }
        const addActiveProducts = (productID, amount) => {
            out[productID] = out[productID] || 0;
            out[productID] += amount;
        };
        for (let products of Object.values(clientOwnedProducts)) {
            for (let [ pid, taxYears ] of Object.entries(products)) {
                taxYears && taxYears.length && addActiveProducts(pid, taxYears.length);
            }
        }
        return out;
    }

    countActiveSubscriptions () {
        let out = 0;
        const { clientSubscriptions } = this;
        if (!clientSubscriptions) {
            return out;
        }
        for (let products of Object.values(clientSubscriptions)) {
            out += Object.keys(products).length;
        }
        return out;
    }
}

export default Accountant;