import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import email from 'http/email';
import { withRESTResource } from 'molecules/withRESTResource';

function TemplatePicker ({ rest, defaultValue, disabled, onChange }) {
    const { loading, error, data } = rest;

    const templates = loading ? [] : data;

    return <Dropdown
        selection
        search
        onChange={(_, { value }) => onChange && onChange(value)}
        defaultValue={defaultValue}
        placeholder='Vælg template'
        loading={loading}
        disabled={loading || error || disabled}
        error={error}
        options={templates.map(({ name, id }) => ({
            text: name,
            value: id,
            key: id,
        }))}
    />;
}

export default withRESTResource(TemplatePicker, () => email.getTemplates());