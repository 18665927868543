import React, { Component, createRef, useEffect, useState } from 'react';
import {
  Form,
  Loader,
  Message,
  Menu,
  Input,
  Button,
  Header,
  Icon,
  Segment,
  Transition,
  Checkbox,
  Divider,
  Dropdown,
  Table,
  Modal,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardMeta,
} from 'semantic-ui-react';

import email from 'http/email';
import { withRESTResource } from 'molecules/withRESTResource';
import AutoStamp from 'atoms/AutoStamp';
import { toast } from 'react-toastify';
import accounts from 'http/accounts';
import * as flags from 'http/featureFlags';
import campaignTypes from '../../../../model/campaign/CampaignTypes';

import { MAILGUN_ANALYTICS_LOCATION } from 'config';
import { prompt } from 'shared/globalModal';
import { withUser } from 'shared/LoginManager';
import CampaignTypes from '../../../../model/campaign/CampaignTypes';
import ReportingEntitiesMaster from 'pages/Accounts/ReportingEntities/master';
import DateTimePicker from 'atoms/DateTimePicker';

import TemplatePicker from './TemplatePicker';
import CampaignSelector from './CampaignSelector';
import RecipientSelector from './RecipientSelector';
import styles from './CampaignsDetail.module.css';

const SendButton = ({
  audienceQuery,
  recipientSearchConfiguration,
  useUpdatedNewsletterSearch,
  onClick,
  disabled,
  wantsNews,
  wantsStatusMails,
}) => {
  const [audienceCount, setAudienceCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const performFetch = async () => {
      let count;

      if (useUpdatedNewsletterSearch) {
        const recipients = await accounts.searchNewletterRecipients(recipientSearchConfiguration);
        count = recipients.length;
      } else {
        count = await accounts.countAudienceTargets({
          audienceQuery,
          wantsNews,
          wantsStatusMails,
        });
      }

      setAudienceCount(count);
      setLoading(false);
    };
    performFetch();
  }, [useUpdatedNewsletterSearch, recipientSearchConfiguration, audienceQuery, wantsNews, wantsStatusMails]);

  return (
    <Button
      primary
      onClick={() => onClick(audienceCount)}
      disabled={disabled || loading || audienceCount === 0}
      loading={loading}
      content={`Send til ${audienceCount} ${
        audienceCount === 1 ? "bruger" : "brugere"
      }!`}
      labelPosition="right"
      size="huge"
      icon="send"
    />
  );
};

class CampaignsDetail extends Component {
  constructor() {
    super();
    this.tabs = [
      { name: "Indstillinger", render: this.renderSettings },
      { name: "Målgruppe", render: this.renderTargetGroup },
      { name: "Afsendelse", render: this.renderSending },
      { name: "Analytics", render: this.renderAnalytics },
    ];
    this.state = {
      tab: this.tabs[0],
      campaign: null,
      audience: [],
      products: [],
      sendError: null,
      isReady: false,
      remKey: 0, // HACK: Incrementing this key forces ReportingEntityMaster component rerender
      showPastDateWarning: false,
      isModalOpen: false,
      loading: true,
      useUpdatedNewsletterSearch: false,
    };
    this.audienceSelectorRef = createRef();
  }

  doWork = (todo) => {
    return async (...args) => {
      this.setState({ working: true });
      await todo(...args);
      this.setState({ working: false });
    };
  };
  
  componentDidMount = async () => {
    this.setState({
      useUpdatedNewsletterSearch: await flags.getFlagValue('USE_UPDATED_NEWSLETTER_SEARCH'),
      loading: false,
    });
  };

  componentDidUpdate = (prev) => {
    if (prev.rest.loading && !this.props.rest.loading) {
      this.setState({ campaign: this.props.rest.data });
    }

    if (prev.rest.working && !this.props.rest.working) {
      this.setState({ campaign: this.props.rest.data });
    }

    if (this.state.campaign?.emailConfigurations.length === 0) {
      this.updateCampaign({
        emailConfigurations: [{ subject: "", templateID: "" }],
      });
    }
  };

  updateCampaign = (propsToUpdate = {}) => {
    const campaign = { ...this.state.campaign };
    for (let [key, value] of Object.entries(propsToUpdate)) {
      campaign[key] = value;
    }
    this.setState({ campaign });
  };

  saveCampaign = async () => {
    const {
      id,
      name,
      type,
      emailConfigurations,
      nameOfSender,
      audienceSelector,
      recipientSearchConfiguration,
      sendDate,
    } = this.state.campaign;

    // Validate campaign
    if (
      emailConfigurations.length === 0 ||
      emailConfigurations.some((ec) => !ec.subject || !ec.templateID)
    ) {
      toast.error("Manglende data vedr. emailkonfigurationer!");
      return false;
    }

    if (type === CampaignTypes.REGULAR && emailConfigurations.length > 1) {
      toast.error(
        "Almindelige kampagner skal ikke tilføjes mere end 1 emailkonfiguration"
      );
      return false;
    }

    if (type === CampaignTypes.SPLIT && emailConfigurations.length === 1) {
      toast.error("Splitkampagner skal tilføjes mere end 1 emailkonfiguration");
      return false;
    }

    const toUpdate = {
      name,
      type,
      emailConfigurations: emailConfigurations,
      nameOfSender,
      audienceSelector,
      recipientSearchConfiguration,
      sendDate,
    };

    await email.patchCampaign(id, toUpdate);
    return true;
  };

  saveAndExit = async () => {
    const success = await this.saveCampaign();
    if (success) {
      toast.success(`"${this.state.campaign.name}" blev gemt`);
      this.props.history.push("/emails/campaigns");
    }
  };

  sendCampaign = async (expectedReceiverCount) => {
    const { campaign } = this.state;

    try {
      this.setState({ sendError: null });
      await this.saveCampaign();
      await email.sendCampaign(campaign.id, expectedReceiverCount);
      await this.props.refetch();
    } catch (e) {
      this.setState({ sendError: e.message });
    }
  };

  promptSendTestMail = async () => {
    const { campaign } = this.state;
    const { user } = this.props.user;

    const inputEmail = await prompt({
      header: 'Indtast e-mail',
      value: user.email,
    });

    const testEmail = inputEmail && inputEmail.trim();
    if (!testEmail) {
      return;
    }

    this.setState({ working: true });
    try {
      await this.saveCampaign();
      await email.sendCampaignTestEmail(campaign.id, testEmail);
      toast.success(
        `Testmail blev sendt til ${testEmail}`
      );
    } catch ({ message }) {
      toast.error(message);
    } finally {
      this.setState({ working: false });
    }
  };

  renderSettings = () => {
    const { campaign } = this.state;
    return (
      <Form className={styles.form}>
        <Form.Field>
          <label>Kampagnenavn</label>
          <Input
            disabled={campaign.isSent}
            defaultValue={campaign.name}
            onChange={(_, { value }) => this.updateCampaign({ name: value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Kampagnetype</label>
          <Dropdown
            selection
            disabled={campaign.isSent}
            defaultValue={campaign.type}
            options={Object.entries(campaignTypes).map(([key, value]) => ({
              key,
              value,
              text: value,
            }))}
            onChange={(_, { value }) => this.updateCampaign({ type: value })}
          />
        </Form.Field>
        {this.renderEmailTemplateSettings()}
        <Form.Field>
          <label>Afsendernavn</label>
          <Input
            placeholder="Ikke påkrævet"
            disabled={campaign.isSent}
            defaultValue={campaign.nameOfSender}
            onChange={(_, { value }) =>
              this.updateCampaign({ nameOfSender: value })
            }
          />
        </Form.Field>
        <Form.Field>
          <label>Afsendelsedato</label>
          <DateTimePicker
            isClearable={true}
            disabled={campaign.isSent}
            value={campaign.sendDate}
            timeIntervals={5}
            onChange={(value) => {
              const currentDate = new Date();
              const selectedDate = value ? new Date(value) : null;
              if (selectedDate && selectedDate < currentDate) {
                // Show the modal
                this.setState({ isModalOpen: true });
              } else {
                const sendDate = selectedDate ? selectedDate : null;
                this.updateCampaign({ sendDate });
              }
            }}
          />
        </Form.Field>
      </Form>
    );
  };

  // --------------------------------------------------

  renderEmailTemplateSettings() {
    const renderTableHeader = () => {
      const tableHeader = (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email subject</Table.HeaderCell>
            <Table.HeaderCell>Email template</Table.HeaderCell>
            <Table.HeaderCell width="1"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      );
      return tableHeader;
    };

    const renderTableBody = () => {
      const { campaign } = this.state;
      if (!campaign.emailConfigurations) {
        return null;
      }

      const tableBody = (
        <Table.Body>
          {campaign.emailConfigurations.map((emailConfiguration, i) => {
            const { subject, templateID } = emailConfiguration;
            const tableRow = (
              <Table.Row
                key={i}
                className={
                  campaign.type === CampaignTypes.REGULAR && i > 0
                    ? styles["ignored-email-config"]
                    : ""
                }
              >
                <Table.Cell>
                  <Form.Field>
                    <Input
                      placeholder="Påkrævet"
                      disabled={campaign.isSent}
                      defaultValue={subject}
                      onChange={(_, { value }) => {
                        emailConfiguration.subject = value;
                        this.updateCampaign({
                          emailConfigurations: [
                            ...campaign.emailConfigurations,
                          ],
                        });
                      }}
                    />
                  </Form.Field>
                </Table.Cell>
                <Table.Cell>
                  <Form.Field>
                    <TemplatePicker
                      disabled={campaign.isSent}
                      defaultValue={templateID}
                      onChange={(templateID) => {
                        emailConfiguration.templateID = templateID;
                        this.updateCampaign({
                          emailConfigurations: [
                            ...campaign.emailConfigurations,
                          ],
                        });
                      }}
                    />
                  </Form.Field>
                </Table.Cell>
                <Table.Cell>
                  <Button
                    content="X"
                    onClick={() => {
                      campaign.emailConfigurations =
                        campaign.emailConfigurations.filter(
                          (ec) => ec !== emailConfiguration
                        );
                      this.updateCampaign({
                        emailConfigurations: [...campaign.emailConfigurations],
                      });
                    }}
                    disabled={this.state.campaign.isSent}
                  />
                </Table.Cell>
              </Table.Row>
            );
            return tableRow;
          })}
        </Table.Body>
      );
      return tableBody;
    };

    const renderTableFooter = () => {
      const tableFooter = (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="3" textAlign="right">
              {this.state.campaign.type === campaignTypes.SPLIT && (
                <Button
                  content="Add"
                  primary
                  onClick={() => {
                    const newEmailConfiguration = {
                      subject: "",
                      templateID: "",
                    };
                    this.updateCampaign({
                      emailConfigurations: [
                        ...this.state.campaign.emailConfigurations,
                        newEmailConfiguration,
                      ],
                    });
                  }}
                  disabled={this.state.campaign.isSent}
                />
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
      return tableFooter;
    };

    const renderTable = () => {
      const table = (
        <Table celled>
          {renderTableHeader()}
          {renderTableBody()}
          {renderTableFooter()}
        </Table>
      );
      return table;
    };

    const emailConfigurations = this.state.campaign?.emailConfigurations;
    let missingEmailConfigWarning;
    if (!emailConfigurations) {
      missingEmailConfigWarning = (
        <>
          <p>Du mangler at tilføje emailkonfigurationer</p>
        </>
      );
    }

    return (
      <>
        <Header>Emailkonfigurationer</Header>
        {missingEmailConfigWarning}
        {renderTable()}
      </>
    );
  }

  // --------------------------------------------------

  renderTargetGroup = () => {
    const { remKey, useUpdatedNewsletterSearch } = this.state;

    if (useUpdatedNewsletterSearch) {
      return (
        <RecipientSelector
          recipientSearchConfiguration={this.state.campaign.recipientSearchConfiguration}
          onChange={recipientSearchConfiguration => this.updateCampaign({ recipientSearchConfiguration })}
        />
      );
    }

    return (
      <div>
          {this.state.isSelectingCampaign && (
            <CampaignSelector
              selectedCampaignID={this.state?.campaign?.id}
              onClose={() => this.setState({ isSelectingCampaign: false })}
              onCampaignSelected={({ audienceSelector }) => {
                this.audienceSelectorRef.current.setAudience(audienceSelector);
                this.updateCampaign({ audienceSelector });
                toast.success("Kampagnens målgruppe blev indlæst");
                this.setState({ remKey: remKey + 1 });
              }}
            />
        )}

        <Button
          content="Indlæs målgruppe fra kampagne"
          onClick={() => this.setState({ isSelectingCampaign: true })}
        />

        <ReportingEntitiesMaster
          initAudience={this.state.campaign.audienceSelector}
          forceAdvancedView={true}
          onAudienceSelectorChanged={(audienceSelector) => {
            this.updateCampaign({ audienceSelector });
          }}
          audienceSelectorRef={this.audienceSelectorRef}
          campaign={this.state.campaign}
          key={remKey}
        />
      </div>
    );
  };

  renderSending = () => {
    const { campaign, working, sendError, isReady } = this.state;

    let errorMessage;
    if (sendError) {
      errorMessage = (
        <>
          <br />
          <Message compact error>
            <Icon name="warning circle" /> {sendError}
          </Message>
        </>
      );
    }

    let content;
    if (!campaign.isSent) {
      const { wantsNews, wantsStatusMails, ...audienceQuery } = campaign.audienceSelector;
      const { recipientSearchConfiguration } = this.state.campaign;

      content = (
        <>
          <SendButton
            recipientSearchConfiguration={recipientSearchConfiguration}
            useUpdatedNewsletterSearch={this.state.useUpdatedNewsletterSearch}
            audienceQuery={audienceQuery}
            wantsNews={wantsNews}
            wantsStatusMails={wantsStatusMails}
            onClick={this.doWork(this.sendCampaign)}
            disabled={working || !isReady}
          />
          <br />
          <Checkbox
            label="Jeg er klar til at sende"
            onChange={(_, { checked }) => this.setState({ isReady: checked })}
            defaultChecked={isReady}
            className={styles.readycb}
            toggle
          />
          {errorMessage}
          <Divider />
          <span
            children="Send testmails af hver emailkonfiguration"
            className={styles.clickableText}
            onClick={this.promptSendTestMail}
          />
        </>
      );
    }

    return (
      <Segment textAlign="center" basic>
        <Transition.Group>
          {campaign.isSent && (
            <Header as="h2" color="green" icon>
              <Icon name="send" />
              Kampagne afsendt!
              <Header.Subheader>
                Kampagnen blev sendt afsted d.{" "}
                <AutoStamp stamp={campaign.timeSent} />
              </Header.Subheader>
            </Header>
          )}
        </Transition.Group>
        {content}
      </Segment>
    );
  };

  renderAnalytics = () => {
    const { campaign } = this.state;
    console.log(campaign);
    if (!campaign.isSent) {
      return (
        <Message
          icon="info circle"
          content="Når kampagnen er afsendt, vil du kunne se statistik her"
        />
      );
    }

    return (
      <>
        <a
          href={`${MAILGUN_ANALYTICS_LOCATION}/${encodeURIComponent(
            campaign.name
          )}/overview`}
          children="Se analytics på Mailgun"
          target="_blank"
          rel="noopener noreferrer"
        />
        ;
        <Card>
          <CardContent>
            <CardHeader content={campaign.name}></CardHeader>
            <CardMeta>
              <AutoStamp stamp={campaign.timeSent} />
            </CardMeta>
            <CardDescription>
              Kampange afsendt til: {campaign.campaignReceivers} brugere
              <Icon name="universal access" />
              Notifikation afsendt til {campaign.notificationReceivers} brugere
              <Icon name="sign language" />
            </CardDescription>
          </CardContent>
          <CardContent extra>
            <a>
              Notifikationer er åbent af {campaign.notificationReader}
              <Icon name="eye" />
            </a>
          </CardContent>
        </Card>
      </>
    );
  };

  renderSelectedTab = () => {
    const { loading, error } = this.props.rest;
    if (loading || this.state.loading || !this.state.campaign) {
      return <Loader inline="centered" active />;
    }

    if (error) {
      return <Message error content="Der opstod en fejl" />;
    }

    return this.state.tab.render();
  };

  renderTabs = () => {
    const { working, campaign } = this.state;
    return (
      <Menu secondary pointing>
        {this.tabs.map((tab) => {
          return (
            <Menu.Item
              key={tab.name}
              disabled={this.state.working}
              onClick={() => this.setState({ tab, sendError: null })}
              active={tab.name === this.state.tab.name}
              link={tab.name !== this.state.tab.name}
              content={tab.name}
            />
          );
        })}
        <Menu.Menu position="right">
          <Menu.Item>
            <Button
              onClick={this.saveAndExit}
              disabled={working || !campaign || campaign.isSent}
              content="Gem kampagne"
              labelPosition="right"
              icon="save"
              primary
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  };

  render = () => {
    const { isModalOpen } = this.state;

    return (
      <>
        {this.renderTabs()}
        {this.renderSelectedTab()}

        <Modal
          open={isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
        >
          <Modal.Header>Puuuha! Det var dælme ikke så godt</Modal.Header>
          <Modal.Content>
            <p>
              Du valgte en dato i fortiden. <br />
              <br />
              Godt nok er vi gode men vi kan altså ikke gå tilbage i tiden for
              at sende mail. <br />
              Næste gang må du bare være lidt hurtigere på aftrækkeren :P
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ isModalOpen: false })}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  };
}

export default withUser(
  withRESTResource(CampaignsDetail, ({ match }) => {
    return email.getCampaign(match.params.campaign);
  })
);
