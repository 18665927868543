import { capitalize } from 'lodash';

const readableID = id => {
    const words = (
        id
        .replace(/_/gi, ' ')
        .split(' ')
        .filter(w => w)
        .map(w => capitalize(w))
        .join(' ')
    );

    return words;
};

export default readableID;