import React, { useEffect, useRef, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { loadPdfjs } from 'shared/pdfjs';

const PDFViewer = ({ pdfURL, pageIndex, setPageIndex, viewportWidth = 595.28, viewportHeight = 841.89 }) => {
    const [pdf, setPDF] = useState(null);
    const [pageRender, setPageRender] = useState(null);
    const renderRef = useRef();

    useEffect(() => {
        setPDF(null);
        setPageRender(null);

        if (!pdfURL) {
            return;
        }

        const doLoadPDF = async () => {
            const pdfjs = await loadPdfjs();
            const task = pdfjs.getDocument(pdfURL);
            const pdf = await task.promise;
            setPDF(pdf);
        };
    
        doLoadPDF();
    }, [pdfURL]);

    useEffect(() => {
        if (!pdf) {
            return;
        }

        const doRenderCurrentPage = async () => {
            const page = await pdf.getPage(pageIndex + 1);

            const canvas = document.createElement('canvas');
            canvas.width = viewportWidth;
            canvas.height = viewportHeight;

            // OBS: some computers will not render the PDF correctly
            // if hardware acceleration is turned on in the users browser.
            // (probably has something to do with the users graphics card)
            // Rendering the page twice seems to fix the problem.
            // 
            // See: https://github.com/mozilla/pdf.js/issues/14641
            for (let i = 0; i < 2; i++) {
                await page.render({
                    canvasContext: canvas.getContext('2d'),
                    viewport: page.getViewport({ scale: 1 }),
                }).promise;
            }

            const imageData = canvas.toDataURL('image/png');
            
            setPageRender(imageData);
            canvas.remove();
        };

        doRenderCurrentPage();
    }, [pageIndex, pdf, setPageRender, viewportWidth, viewportHeight]);

    const renderCurrentPage = () => {
        if (!pageRender) {
            return (
                <div
                    style={{
                        width: viewportWidth,
                        height: viewportHeight,
                        background: 'white',
                        display: 'inline-block',
                        paddingTop: '5em',
                        textAlign: 'center',
                        opacity: 0.75,
                    }}
                    children={
                        <span>
                            <Loader inline active />
                            <h3>Loading PDF...</h3>
                        </span>
                    }
                />
            );
        }

        return (
            <div style={{ background: 'white', width: viewportWidth, height: viewportHeight }}>
                <img
                    className='noselect'
                    alt='pdf'
                    ref={renderRef}
                    src={pageRender}
                    onClick={e => {
                        const clickPos = e.clientX - renderRef.current.getBoundingClientRect().x;
                        const width = renderRef.current.offsetWidth;
                        
                        if (clickPos <= width / 2) {
                            // left side clicked - go back
                            pageIndex > 0 && setPageIndex(pageIndex - 1);
                        } else {   
                            // right side clicked - go forward
                            pageIndex < pdf.numPages - 1 && setPageIndex(pageIndex + 1);
                        }
                    }}
                />
            </div>
        );
    };

    const renderFooter = () => {
        if (!pdf) {
            return null;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <div
                    style={{
                        width: viewportWidth,
                        borderTop: '1px solid gray',
                        display: 'inline-block',
                    }}
                    children={<p>{pageIndex + 1} / {pdf.numPages}</p>}
                />
            </div>
        );
    };

    return (
        <div>
            {renderCurrentPage()}
            {renderFooter()}
        </div>
    );
};

export default PDFViewer;