import React from 'react';
import Checkbox from '../../../atoms/Checkbox';
import TextInput from '../../../atoms/TextInput/TextInput';
import styles from './ProductDetail.module.css';
import { Button, Form } from 'semantic-ui-react';

class VariationEdtior extends React.Component {
    renderModelOptions = ({ id, name }) => {
        return <option value={id} key={id}>
            {name || id}
        </option>;
    };

    render () {
        const {
            updateSelectedVariation,
            selectedVariation,
            deleteVariation,
            models,
            selectedVariationIndex
        } = this.props;
        const { modelId, year, type, status, requiresPayment } = selectedVariation;
        
        return (
            <div key={JSON.stringify(selectedVariation)}>
                <Form.Field>
                    <label>Årstal</label>
                    <TextInput defaultValue={year} onBlur={e => updateSelectedVariation('year', e)}/>
                </Form.Field>
                <Form.Field>
                    <label>Type</label>
                    <TextInput defaultValue={type} onBlur={e => updateSelectedVariation('type', e)}/>
                </Form.Field>
                <Form.Field>
                    <label>Status</label>
                    <select defaultValue={status} onChange={e => updateSelectedVariation('status', e)}>
                        <option value=''>-</option>
                        <option value='highlighted'>Fremhævet</option>
                        <option value='active'>Aktiv</option>
                        <option value='locked'>Låst</option>
                        <option value='future_teaser'>Kommer senere</option>
                    </select>
                </Form.Field>
                <Form.Field>
                    <label>Tilknyttet model</label>
                    <select defaultValue={modelId} onChange={e => updateSelectedVariation('modelId', e)}>
                        <option value=''>-</option>
                        {
                            models.map(this.renderModelOptions)
                        }
                    </select>
                </Form.Field>
                <Form.Field>
                    <label>Adgang kræver betaling?</label>
                    <Checkbox
                        toggle
                        checked={requiresPayment}
                        onChange={e => updateSelectedVariation('requiresPayment', e)}
                    />
                </Form.Field>
                <Button
                    content='Slet variation'
                    icon='delete'
                    type='danger'
                    color='red'
                    onClick={() => deleteVariation(selectedVariationIndex)}
                    className={styles.deleteButton}
                />
            </div>
        );
    }
}

export default VariationEdtior;