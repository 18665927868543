import lodash from 'lodash';
import uuid from 'uuid/v4';
import deepCopy from 'shared/deepCopy';
import model, { applyBlockTextTemplating } from './model';

export const isComposite = block => {
    return model.blockTypes[block?.kind]?.composite;
};

export const isCloser = block => {
    return block?.kind === model.blockTypes.groupCloser.id;
};

export const getIcon = block => {
    return model.blockTypes[block?.kind]?.icon;
};

export const getBlockTypeLabel = block => {
    return model.blockTypes[block?.kind]?.label;
};

export const isExpanded = block => {
    return block?.expanded;
};

export const isCollapsed = block => {
    return isComposite(block) && !isExpanded(block);
};

export const getLabel = (block, definitions, chosenLanguage) => {
    const blockType = model.blockTypes[block.kind];
    if (!blockType) {
        return 'Empty block';
    }

    const lang = chosenLanguage || model.defaultUserContextValues.language;
    for (const textProperty of blockType.schema.text) {
        const text = lodash.get(block?.data, [textProperty.slug, lang].join('.'));
        if (!text) {
            continue;
        }

        const compiledText = applyBlockTextTemplating(text, definitions);
        if (compiledText) {
            return compiledText;
        }
    }

    return blockType.label;
};

export const getBlockDefaultData = type => {
    const schema = model.blockTypes[type]?.schema;
    if (!schema) {
        return {};
    }

    const defaultData = {};
    for (const properties of Object.values(schema)) {
        for (const property of properties.flat()) {
            if (property.defaultValue === undefined) {
                continue;
            }
            defaultData[property.slug] = property.defaultValue;
        }
    }

    return defaultData;
};

export const applyDefaultValues = block => {
    const defaultData = getBlockDefaultData(block?.kind);

    for (const [key, value] of Object.entries(defaultData)) {
        if (block.data[key] === undefined) {
            block.data[key] = value;
        }
    }
};

export const createBlock = type => {
    const blocksToCreate = [type];
    const blockType = model.blockTypes[type];
    if (blockType?.composite) {
        blocksToCreate.push(model.blockTypes.groupCloser.id);
    }

    return blocksToCreate.map(kind => ({
        id: uuid(),
        data: getBlockDefaultData(kind),
        expanded: false,
        kind,
    }));
};

export const createBlockCopy = block => {
    return { ...deepCopy(block), id: uuid() };
};

export const canMove = block => {
    if (block?.kind === model.blockTypes.groupCloser.id) {
        return false;
    }

    if (isExpanded(block)) {
        return false;
    }

    return true;
};

export const getBlockStackTrace = (blocks, blockID) => {
    const ancestorStack = [];
    for (const block of blocks) {
        if (blockID === block.id) {
            return ancestorStack;
        }

        if (isComposite(block)) {
            ancestorStack.push(block);
        }

        if (isCloser(block)) {
            ancestorStack.pop();
        }
    }

    return ancestorStack;
};

export const findEldestAncestor = (blocks, childID) => {
    const [eldestAncestor] = getBlockStackTrace(blocks, childID);
    return eldestAncestor;
};

export const expandTargetBlock = (blocks, blockID) => {
    const stacktrace = getBlockStackTrace(blocks, blockID);
    const toExpand = new Set([...stacktrace.map(block => block.id), blockID]);
    for (const block of blocks) {
        if (!toExpand.has(block.id)) continue;
        if (!isComposite(block)) continue;
        block.expanded = true;
    }
};

export const getBlockTypeActiveSchemaCategories = (blockType) => {
    const blockMeta = model.blockTypes[blockType];

    return Object.keys(blockMeta.schema).filter(category => {
        return blockMeta.schema[category]?.length > 0;
    });
};