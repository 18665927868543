import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import styles from './FileuploadButton.module.css';

class FileuploadButton extends Component {
    hiddenUploadBtn = React.createRef();

    getAcceptedFileTypes = () => {
        let { accept } = this.props;
        if (typeof accept === 'string') {
            accept = [accept];
        }
        return accept.map(a => `.${a}`).join();
    };

    renderHiddenUploadField = () => {
        return (
            <input
                type='file'
                className={styles.hiddenUploadBtn}
                ref={this.hiddenUploadBtn}
                multiple={this.props.multiple}
                accept={this.getAcceptedFileTypes()}
                onChange={e => {
                    const { multiple } = this.props;
                    const files = e.target.files;
                    this.props.onChange(multiple ? files : files[0]);
                }}
            />
        );
    };

    uploadButtonClicked = () => {
        this.hiddenUploadBtn.current.click();
    };

    render () {
        const { size, icon, content, ...props } = this.props;
        return <>
            {this.renderHiddenUploadField()}
            <Button
                primary
                labelPosition='right'
                size={size}
                icon={icon}
                content={content}
                onClick={this.uploadButtonClicked}
                {...props}
            />
        </>;
    }
}

FileuploadButton.defaultProps = {
    size: 'medium',
    icon: 'cloud upload',
    content: 'Upload',
    multiple: false,
    accept: [],
};

export default FileuploadButton;