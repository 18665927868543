import { useCallback, useState } from 'react';
import { Button, Container, Dropdown, Form, Loader, Message, Segment } from 'semantic-ui-react';
import * as dataWarehouse from 'http/dataWarehouse';
import useRestResource from 'shared/hooks/useRestResource';
import DatePicker from 'atoms/DatePicker';
import readableID from 'shared/readableID';
import CSV from 'shared/CSV';

const segments = [
    'private',
    'business',
    'accountant',
];

const eventKinds = [
    'newSale',
    'resale',
    'churn',
    'recovered',
];

const makeOptions = elements => {
    return elements.map(element => {
        return {
            text: readableID(element),
            value: element,
            key: element,
        };
    });
};

const getDefaultStartDate = () => {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(0);
    startDate.setFullYear(startDate.getFullYear());
    return startDate;
};

const getDefaultEndDate = () => {
    const endDate = getDefaultStartDate();
    endDate.setFullYear(endDate.getFullYear() + 1);
    return endDate;
};

const SaasMetrics = () => {
    const [fromDate, setFromDate] = useState(getDefaultStartDate());
    const [toDate, setToDate] = useState(getDefaultEndDate());
    const [segment, setSegment] = useState(segments[0]);
    const [eventKind, setEventKind] = useState(eventKinds[0]);

    const { data: result, loading, error } = useRestResource({
        fetcher: () => dataWarehouse.countSaasEvents(fromDate.toISOString().substring(0, 10), toDate.toISOString().substring(0, 10), segment, eventKind),
        args: [fromDate, toDate, segment, eventKind],
    });

    const downloadEventsAsCSV = useCallback(async () => {
        const from = fromDate.toISOString().substring(0, 10)
        const to = toDate.toISOString().substring(0, 10);
        const events = await dataWarehouse.getSaasEvents(from, to, segment, eventKind);

        const csv = new CSV(['tidspunkt', 'type', 'segment', 'adminLink']);

        for (const {timestamp, kind, segment, uid } of events) {
            csv.addToRow('tidspunkt', timestamp);
            csv.addToRow('type', kind);
            csv.addToRow('segment', segment);
            csv.addToRow('adminLink', `${window.location.origin}/accounts/reporting-entities/${uid}`);
            csv.newLine();
        }
        
        csv.download(`saas_metrics_from_${from}_to_${to}_${segment}_${eventKind}`);
    }, [fromDate, toDate, segment, eventKind]);

    const renderContent = () => {
        if (loading) {
            return (
                <Loader
                    inline='centered'
                    active
                />
            );
        }

        if (error) {
            return (
                <Message error content='Der opstod en fejl' />
            );
        }

        return (
            <div style={{ marginTop: '1em' }}>
                <b>Resultat:</b> {result}
            </div>
        );
    };

    return (
        <Container>
            <Segment style={{ marginTop: '1em' }}>
                <Form>
                    <Form.Field>
                        <label>Startdato</label>
                        <DatePicker value={fromDate} onChange={date => setFromDate(new Date(date))} />
                    </Form.Field>
                    <Form.Field>
                        <label>Slutdato (ej inklusiv)</label>
                        <DatePicker value={toDate} onChange={date => setToDate(new Date(date))} />
                    </Form.Field>
                    <Form.Field>
                        <label>Segment</label>
                        <Dropdown
                            selection
                            defaultValue={segment}
                            onChange={(_, { value }) => setSegment(value)}
                            options={makeOptions(segments)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Begivenhedstype</label>
                        <Dropdown
                            selection
                            defaultValue={eventKind}
                            onChange={(_, { value }) => setEventKind(value)}
                            options={makeOptions(eventKinds)}
                        />
                    </Form.Field>
                </Form>
                {renderContent()}
                <Button
                    content='Download CSV'
                    icon='download'
                    onClick={downloadEventsAsCSV}
                />
            </Segment>
        </Container>
    );
};

export default SaasMetrics;