import React, { Component } from 'react';

class NodeDataProductDataElement extends Component {
    render = () => {
        return (
            <span>
                Denne nodetype er erstattet af <b>PrimoValue</b>!
            </span>
        );
    };
}

export default NodeDataProductDataElement;