import React from 'react';
import AugmentedTextarea from 'molecules/AugmentedTextarea';
import model, { editorDataTypesMeta, userContextSuggestions } from './model';

const LanguageTextarea = ({ onChange, defaultValue, suggestions, textVariations, style, containerStyle, placeholder }) => {
    const numberSuggestions = suggestions.filter(({ dataType }) => {
        return dataType === model.definitionDataTypes.Number;
    });
    
    const textVariationSuggestions = (textVariations || []).map(textVariation => {
        return {
            label: textVariation.tag,
            icon: editorDataTypesMeta[model.definitionDataTypes.String].icon,
            dataType: model.definitionDataTypes.String,
        };
    });

    const { activeYear, lastYear, noteReferences, userContext } = model.variableNamespaces;

    return (
        <AugmentedTextarea
            defaultValue={defaultValue}
            onChange={onChange}
            style={style}
            containerStyle={containerStyle}
            placeholder={placeholder}
            syntaxConfiguration={[
                ...[/(\/\/)/gi, /(\{\{)/gi, /(\}\})/gi].map(pattern => ({
                    pattern,
                    prefix: '',
                    colorOverwrites: {
                        standard: '#d74af7',
                    },
                })),
                {
                    prefix: activeYear.operatorPrefix,
                    pattern: new RegExp(activeYear.pattern),
                    autocompleteItems: [
                        ...suggestions,
                        {
                            label: model.reservedTags.FOR_EACH_ELEMENT.tag,
                            dataType: model.definitionDataTypes.String,
                            icon: editorDataTypesMeta[model.definitionDataTypes.String].icon,
                        },
                    ],
                },
                {
                    prefix: lastYear.operatorPrefix,
                    pattern: new RegExp(lastYear.pattern),
                    autocompleteItems: numberSuggestions,
                },
                {
                    prefix: noteReferences.operatorPrefix,
                    pattern: new RegExp(noteReferences.pattern),
                    autocompleteItems: [
                        {
                            label: model.reservedTags.NEXT_NOTE_NUMBER.tag,
                            dataType: model.definitionDataTypes.Number,
                            icon: 'plus circle',
                        },
                        ...numberSuggestions,
                    ],
                },
                {
                    prefix: userContext.operatorPrefix,
                    pattern: new RegExp(userContext.pattern),
                    autocompleteItems: userContextSuggestions,
                },
                {
                    prefix: model.variableNamespaces.textVariations.operatorPrefix,
                    pattern: model.variableNamespaces.textVariations.pattern,
                    autocompleteItems: textVariationSuggestions,
                },
            ]}
        />
    );
};

export default LanguageTextarea;