import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import NodeSelect from './NodeSelect';

const operatorDescriptions = {
    '==':     'skal være lig med',
    '!=':     'skal være forskellig fra',
    '>':      'skal være større end',
    '>=':     'skal være større end eller lig med',
    '<':      'skal være mindre end',
    '<=':     'skal være mindre end eller lig med',
    'regexp': 'skal matche',
};

const SubSection = ({ rule, updateRule, isDynamic, operators, nodes, disabled, input }) => {
    const { comparator, compareRefId } = rule;

    const renderCompareValueSection = () => {
        if (isDynamic) {
            return (
                <Form.Field>
                    <NodeSelect
                        nodes={nodes}
                        defaultValue={compareRefId}
                        onChange={compareRefId => updateRule({
                            compareRefId,
                        })}
                    />
                </Form.Field>
            );
        }

        return (
            <Form.Field>
                {input}
            </Form.Field>
        );
    };

    const getOperators = () => {
        if (!operators) {
            return Object.keys(operatorDescriptions);
        }

        return operators;
    };

    return (
        <Form.Group widths='equal'>
            <Form.Field>
                <Dropdown
                    fluid
                    selection
                    key={comparator}
                    defaultValue={comparator}
                    disabled={disabled}
                    placeholder='Vælg operator'
                    onChange={(_, { value }) => updateRule({
                        comparator: value,
                    })}
                    options={getOperators().map(operator => ({
                        text: operatorDescriptions[operator],
                        value: operator,
                    }))}
                />
            </Form.Field>
            {renderCompareValueSection()}
        </Form.Group>
    );
};

export default SubSection;