import React, { useRef, useState } from 'react';
import { Form, Button, Input, Segment } from 'semantic-ui-react';
import { withUser } from 'shared/LoginManager';

const LoginForm = ({ user }) => {
    const [fetching, setFetching] = useState(false);
    const emailRef = useRef();
    const passwordRef = useRef();

    const performLogin = async () => {
        const email = emailRef.current.inputRef.current.value;
        const password = passwordRef.current.inputRef.current.value;

        setFetching(true);
        await user.login(email, password);
        setFetching(false);
    };

    const submitIfEnter = e => e.code === 'Enter' && performLogin();

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form as={Segment} style={{ width: '500px', marginTop: '4em' }}>
                <Form.Field>
                    <label>Email</label>
                    <Input ref={emailRef} onKeyDown={submitIfEnter} placeholder='Indtast e-mail adresse...' />
                </Form.Field>
                <Form.Field>
                    <label>Password</label>
                    <Input ref={passwordRef} type='password' onKeyDown={submitIfEnter} placeholder='Password...' />
                </Form.Field>
                <Form.Field>
                    <Button
                        fluid
                        onClick={performLogin}
                        color='green'
                        content='Login'
                        icon='sign in'
                        disabled={fetching}
                        loading={fetching}
                    />
                </Form.Field>
            </Form>
        </div> 
    );
};

export default withUser(LoginForm)
