import React from 'react';
import { Flag, Form, Icon, Label } from 'semantic-ui-react';

import { useReportTemplatesConfig } from './config';
import LanguageTextarea from './LanguageTextarea';
import { countryOptions } from './model';

const LanguageFormFields = ({ label, languages, onChange, values, textVariations, placeholder, suggestions = [] }) => {
    const [chosenLanguage, setChosenLanguage] = useReportTemplatesConfig('chosenLanguage');
    const activeLang = chosenLanguage || languages[0];
    const defaultValue = values && values[activeLang];
    const reportChange = value => onChange(activeLang, value);

    return (
        <Form.Field key={activeLang}>
            {label && label}
            <div>
                {languages.map(lang => {
                    const langInfo = countryOptions.find(co => co.key === lang);
                    const active = langInfo.value === activeLang;
                    const langValue = values && values[langInfo.value];
                    return (
                        <Label
                            basic
                            onClick={() => setChosenLanguage(langInfo.value)}
                            content={
                                <span>
                                    <Flag
                                        name={langInfo.flag}
                                        style={{
                                            top: '1px',
                                            position: 'relative',
                                        }}
                                    />
                                    {langInfo.text}
                                    &nbsp;
                                    <Icon
                                        name={langValue ? 'check circle' : 'exclamation circle'}
                                        color={langValue ? (active ? 'green' : 'black') : 'orange'}
                                        style={{ opacity: active ? 1 : 0.50, }}
                                    />
                                </span>
                            }
                            style={{
                                borderTopLeftRadius: '4px',
                                borderTopRightRadius: '4px',
                                marginBottom: '-1px',
                                backgroundColor: active ? 'initial' : 'lightgray',
                                cursor: active ? 'initial' : 'pointer',
                                opacity: active ? 1 : 0.50,
                            }}
                        />
                    )
                })}
                <LanguageTextarea
                    defaultValue={defaultValue}
                    onChange={value => reportChange(value)}
                    suggestions={suggestions}
                    textVariations={textVariations}
                    placeholder={placeholder}
                />
            </div>
        </Form.Field>
    );
};

export default LanguageFormFields;