import React, { useState } from 'react';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import uuid from 'uuid/v4';
import lodash from 'lodash';
import { move } from 'shared/array';
import { confirm } from 'shared/globalModal';
import { DragAndDropZone, DraggableItem } from 'model-editor/atoms/DragAndDrop';
import ColoredText from 'atoms/ColoredText';
import CodeTextarea from '../CodeTextarea';
import LanguageFormFields from '../LanguageFormFields';

const TextSwitchCase = ({ suggestions, supportedLanguages, calculationFunctions, onChange, cases = [] }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [dragDisabled, setDragDisabled] = useState(true);

    const addNewCase = () =>
        onChange([
            ...cases,
            {
                id: uuid(),
                expression: '',
                text: {},
            },
        ]);

    const mutateCase = (idx, mutator) => {
        const casesCopy = [...cases];
        mutator(casesCopy[idx]);
        onChange(casesCopy);
    };

    const handleDelete = async id => {
        const isSure = await confirm('Are you sure?');
        if (!isSure) {
            return;
        }

        onChange(cases.filter(c => c.id !== id));
    };

    const onDragEnd = (result) => {
        setIsDragging(false);
        if (!result.destination) {
            return;
        }
        const casesCopy = [...cases];
        const idxA = result.source.index;
        const idxB = result.destination.index;
        move(casesCopy, idxA, idxB);
        onChange(casesCopy);
    };

    return (
        <DragAndDropZone
            droppableId='textSwitchCase'
            onDragStart={() => setIsDragging(true)}
            onDragEnd={onDragEnd}
        >
            {cases.map(({ id, expression, text }, idx) => {
                return (
                    <DraggableItem
                        id={id}
                        key={id}
                        index={idx}
                        isDragDisabled={dragDisabled}
                    >
                        <Segment key={id} style={{ marginBottom: '-1px' }}>
                            <Grid columns={2}>
                                <Grid.Column>
                                    <CodeTextarea
                                        onChange={(code) =>
                                            mutateCase(
                                                idx,
                                                (c) => (c.expression = code)
                                            )
                                        }
                                        suggestions={suggestions}
                                        calculationFunctions={calculationFunctions}
                                        defaultValue={expression}
                                        style={{ height: '180px' }}
                                        containerStyle={{ borderTopLeftRadius: '4px' }}
                                        placeholder='Input an expression...'
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ flexGrow: 1 }}>
                                            <LanguageFormFields
                                                onChange={(lang, value) => mutateCase(idx, textCase => {
                                                    lodash.set(textCase, ['text', lang].join('.'), value);
                                                })}
                                                languages={supportedLanguages}
                                                values={text}
                                                suggestions={suggestions}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                marginLeft: '1em',
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Icon
                                                onClick={() => handleDelete(id)}
                                                name='x'
                                                color='red'
                                                link
                                            />
                                            <br />
                                            <Icon
                                                name='arrows alternate'
                                                onMouseEnter={() => setDragDisabled(false)}
                                                onMouseLeave={() => setDragDisabled(true)}
                                            />
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </Segment>
                    </DraggableItem>
                );
            })}
            {
                !isDragging &&
                <Segment
                    onClick={addNewCase}
                    textAlign='center'
                    className='noselect'
                    style={{
                        border: '2px dashed lightgray',
                        background: 'transparent',
                        cursor: 'pointer',
                    }}
                    children={
                        <ColoredText
                            icon='plus'
                            content='Add case'
                            size='big'
                            iconPosition='left'
                        />
                    }
                />
            }
        </DragAndDropZone>
    );
};

export default TextSwitchCase;
