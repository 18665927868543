import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/internal-finances/api`);

export function generateCharges(year, month) {
    return client.getResource(`/booking/generate-charges?year=${year}&month=${month}`);
}

export function createBookingsInErp(charges) {
    return client.postResource('/booking/create-bookings-in-erp', charges);
}
