import React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import InputSection, { InputSectionRow } from './InputSection'
import LogoPicker from './LogoPicker';
import model, { countryOptions } from '../model';

const UserContextEditor = ({ userContext = {}, setUserContext }) => {
    const valuesToUse = {};
    for (const [key, value] of Object.entries(model.defaultUserContextValues)) {
        const contextValue = userContext[key];
        const valueToUse = (
            contextValue === undefined
                ? value
                : contextValue
        );
        valuesToUse[key] = valueToUse;
    }

    const handleChange = (_, { id, checked, value }) => {
        const valueInQuestion = (
            checked === undefined
                ? value
                : checked
        );
        setUserContext({
            ...valuesToUse,
            [id]: valueInQuestion,
        });
    };

    return (
        <InputSection>
            <InputSectionRow>
                <Grid columns={2} divided>
                    <Grid.Column>
                        <Form style={{ borderSpacing: '0px', width: '100%' }}>
                            <Form.Input
                                defaultValue={valuesToUse.taxYear}
                                id='taxYear'
                                label='Tax year'
                                type='number'
                                onChange={handleChange}
                            />
                            <Form.Dropdown
                                defaultValue={valuesToUse.language}
                                id='language'
                                label='Report language'
                                onChange={handleChange}
                                options={countryOptions}
                                selection
                            />
                            <Form.Checkbox
                                defaultChecked={valuesToUse.isClassB}
                                id='isClassB'
                                label='Is class B?'
                                onChange={handleChange}
                            />
                            <Form.Checkbox
                                defaultChecked={valuesToUse.isInternal}
                                id='isInternal'
                                label='Is internal report?'
                                onChange={handleChange}
                            />
                            <Form.Checkbox
                                defaultChecked={valuesToUse.isFirstYear}
                                id='isFirstYear'
                                label='Is first year?'
                                onChange={handleChange}
                            />
                            <Form.Checkbox
                                defaultChecked={valuesToUse.hasBought}
                                id='hasBought'
                                label='Has bought?'
                                onChange={handleChange}
                            />
                        </Form>
                    </Grid.Column>
                    <Grid.Column textAlign='center' verticalAlign='middle'>
                        <LogoPicker
                            logo={valuesToUse.logo}
                            onChange={value => handleChange(null, { id: 'logo', value })}
                        />
                    </Grid.Column>
                </Grid>
                
            </InputSectionRow>
        </InputSection>
    );
};

export default UserContextEditor;