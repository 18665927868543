import React from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';

const META_KEY = (
    window?.navigator?.userAgent.toLowerCase().includes('mac') ?
    '⌘' :
    'Ctrl'
);

export const shortcuts = [
    {
        description: 'Quick filter access',
        shift: META_KEY,
        keys: ['F'],
    },
    {
        description: 'Change page',
        shift: META_KEY,
        keys: ['◁', '▷'],
    },
    {
        description: 'Change page size',
        shift: META_KEY,
        keys: ['△', '▽'],
    },
    {
        description: 'Save progress',
        shift: META_KEY,
        keys: ['S'],
    },
    {
        description: 'Discover unfinished pages',
        shift: 'Alt',
        keys: ['◁', '▷'],
    },
    {
        description: 'Verify account',
        shift: 'Alt',
        keys: [],
        mouse: true,
    },
];

const Key = ({ content }) => {
    return (
        <span style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            borderBottom: '2px solid gray',
            borderTop: '1px solid lightgray',
            borderLeft: '1px solid lightgray',
            borderRight: '1px solid lightgray',
            boxShadow: '1px 1px 1px 1px lightgray',
            width: content?.length > 1 ? '48px' : '32px',
            marginRight: '4px',
            marginLeft: '4px',
            height: '32px',
            fontSize: '1em',
            fontWeight: 'bold',
        }}>
            {content}
        </span>
    );
};

const Shortcut = ({ description, shift, keys, mouse }) => {
    return (
        <div style={{ whiteSpace: 'nowrap', width: '350px', display: 'inline-block' }}>
            <b>{description}</b>
            <div style={{ height: '0.5em' }} />
            <div>
                {shift && (
                    <><Key content={shift} />{' + '}</>
                )}
                {keys.map(key => <Key content={key} />)}
                {mouse && (
                    <Icon
                        style={{ fontSize: '1.5em', top: '6px', position: 'relative' }}
                        name='mouse pointer'
                    />
                )}
            </div>
        </div>
    )
};

export const ShortcutReel = () => {
    return <Popup
        offset={[-15, 0]}
        trigger={
            <span>
                <Icon name='question circle' color='blue' />
                Shortcuts
                &nbsp;
            </span>
        }
        content={
            <Grid columns={2}>
                {shortcuts.map(shortcut => {
                    return <Grid.Column>
                        <Shortcut {...shortcut} />
                    </Grid.Column>
                })}
            </Grid>
        }
    />;
};

export default Shortcut;