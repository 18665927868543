const fieldIdPattern = /[\d\w]{8}-[\d\w]{4}-[\d\w]{4}-[\d\w]{4}-[\d\w]{12}/gi;

function findAsField (id, product) {
    for (let section of product.sections) {
        for (let field of section.fields) {
            const regexp = new RegExp(field.id, 'g');
            if (regexp.test(id)) {
                return field;
            }
        }
    }
}

function getName (id, model, product) {
    if (id.match(fieldIdPattern)) {
        const field = findAsField(id, product);
        if (field) {
            if (field.reference) {
                return id.replace(fieldIdPattern, getName(field.reference, model, product));
            } else {
                return id.replace(fieldIdPattern, field.label);
            }
        }
    }

    for (let node of model.nodes) {
        const regexp = new RegExp(node.id, 'g');
        if (regexp.test(id)) {
            return id.replace(node.id, node.name);
        }
    }
    return id;
}

function product2graph (product, model) {
    const { facts, actions, questions } = product;

    const nodes = [];
    const edges = [];

    // Push root node
    nodes.push({ id: product.id, name: product.id, color: 'purple', icon: 'product hunt' });

    // Push facts
    Object.values(facts).forEach(fact => {
        const name = getName(fact.id, model, product);
        const prefix = '!';
        nodes.push({
            id: fact.id,
            name,
            color: 'blue',
            icon: 'exclamation',
            prefix
        });
        fact.supply.forEach(s => edges.push({ to: fact.id, from: s }));
    });

    // Push actions
    Object.values(actions).forEach(action => {
        const name = getName(action.id, model, product);
        const prefix = '√';
        nodes.push({
            id: action.id,
            name,
            color: 'green',
            icon: 'cogs',
            prefix
        });
        edges.push({ to: action.id, from: action.supply });
    });

    // Push questions
    Object.values(questions).forEach(question => {
        const name = getName(question.id, model, product);
        const prefix = '?';
        nodes.push({
            id: question.id,
            name,
            color: 'pink',
            icon: 'help',
            prefix
        });
        edges.push({ to: question.id, from: question.supply });
    });

    return { nodes, edges };
}

export default product2graph;