import React, { useState } from 'react';
import FileuploadButton from 'atoms/FileuploadButton';
import ImageCropper from 'atoms/ImageCropper';
import { readAsBase64 } from 'shared/files';
import { Button, Icon } from 'semantic-ui-react';

const LogoPicker = ({ logo, onChange, content, placeholder, display }) => {
    const [logoBeingCropped, setLogoBeingCropped] = useState(null);
    const [crop, setCrop] = useState(null);

    const logoPicked = async logo => {
        const base64 = await readAsBase64(logo);
        setLogoBeingCropped(base64);
    };

    const handleSave = () => {
        if (!crop) {
            onChange(logoBeingCropped);
            setLogoBeingCropped(null);
            return;
        }
        onChange(crop);
        setLogoBeingCropped(null);
        setCrop(null);
    };

    const startCroppingAgain = () => {
        setLogoBeingCropped(logo);
    };

    if (logoBeingCropped) {
        return (
            <div style={{ textAlign: '' }}>
                <ImageCropper
                    image={logoBeingCropped}
                    onCrop={setCrop}
                />
                <br />
                <Button primary content='Save' onClick={handleSave} />
            </div>
        );
    }

    return (
        <div>
            <div style={{ marginBottom: '10px'}}>
                {
                    logo
                        ? <img src={logo} style={{ maxHeight: '150px', borderRadius: '4px' }} alt='logo' />
                        : (
                            <span>
                                <Icon name='image' size='large' />
                                <br />
                                {placeholder || 'No logo selected'}
                            </span>
                        )
                }
            </div>
            <div>
                <FileuploadButton
                    onChange={logoPicked}
                    content={`Choose ${content ||'logo'}`}
                    accept={['jpg', 'png', 'svg']}
                />
                {
                    logo &&
                    <Button
                        color='red'
                        onClick={() => onChange('')}
                        icon='trash'
                        content={`Remove ${content ||'logo'}`}
                    />
                }
                {
                    logo &&
                    <Button
                    color='olive'
                        onClick={startCroppingAgain}
                        content='Crop again'
                    />
                }
            </div>
        </div>
    );
};

export default LogoPicker;