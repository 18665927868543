import React from 'react';
import { Message, Button, List } from 'semantic-ui-react';
import modelEditor from 'http/modelEditor';
import ColoredText from 'atoms/ColoredText';
import withNavShell from '../../molecules/withNavShell';
import { getAppUrl } from '../../../shared/LinkHelper';

class ProductsMaster extends React.Component {

    constructor () {
        super();
        this.state = {
            busy: false,
            message: null,
            errorMsg: null,
            errDetails: {}
        };
    }

    async componentDidMount () {
        this.props.updateNavShell();
        this.generateAppLink();
    }

    handleExport = async () => {
        const { modelId } = this.props.match.params;
        this.setState({
            busy: true,
            message: null,
            errorMsg: null,
        });

        let validationResult;
        try {
            validationResult = await modelEditor.exportModel(modelId);
        } catch(e) {
            this.setState({
                busy: false,
                message: `Teknisk fejl: ${e.message}`,
                messageType: false,
            });
            return;
        }

        if (validationResult.success) {
            const warnings = validationResult.validationWarnings.map(({ message, link }) => {
                return (
                    <List.Item>
                        <ColoredText color='black' link={link} content={message} />
                    </List.Item>
                );
            });

            this.generateAppLink();
            this.setState({
                busy: false,
                message: '✔️ Modellen blev eksporteret' + (warnings.length > 0 ? ' med advarsler:' : '!'),
                messageType: true,
                errorMsg: warnings.length > 0 && (
                    <List bulleted>{warnings}</List>
                ),
            });
            return;
        }

        const { missingTags, unnecessaryTags, validationError } = validationResult || {};
        let errorMessages
        const message = '❌ Fejl under export:\n';
        if (validationError.list?.length > 0) {
            errorMessages = (
                <div>{ validationError.message} 
                    <ul>{
                        validationError.list.map(link => {
                            if (!link.url) {
                                return <li>{link.data}</li>        
                            }
                            return <li><a href={link.url}>{link.data}</a></li>;
                        })}
                    </ul> 
                </div>
            );
        }
        else {
            errorMessages = <div>{validationError.message}</div>
        }
        
        
        this.setState({
            busy: false,
            message: message,
            messageType: false,
            errorMsg: errorMessages,
            errDetails: {
                missingTags,
                unnecessaryTags
            }
        });
    };

    async generateAppLink () {
        const modelId = this.props.match.params.modelId;
        const liveUrl = getAppUrl(`/product/${modelId}/index`);
        this.setState({ liveUrl });
    }

    renderMessage() {
        const { message, messageType, errorMsg } = this.state;

        if (message !== null) {
            return (
                <Message warning={!messageType} success={messageType}>
                <Message.Header>{message}</Message.Header>
                {errorMsg}
                </Message>
            );
        } else {
            return null;
        }
    }

    render() {
        const { busy } = this.state;
        return (
            <div className="product-view">
                <div className="model">
                    <div className="editor">
                        <h1>Eksporter model</h1>
                        <a href={this.state.liveUrl} target="new">{this.state.liveUrl}</a>
                        <br/>
                        <br/>
                        <Button
                            primary
                            disabled={busy}
                            loading={busy}
                            onClick={this.handleExport}
                            content='Eksporter'
                            icon='cog'
                        />
                        <br/>
                        {this.renderMessage()}
                    </div>
                </div>
            </div>
        );
    }
}


export default withNavShell(ProductsMaster);
