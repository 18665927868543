const sanitizeWord = word => {
    return (
        [...word]
        .filter(c => /[0-9a-z]/gi.test(c))
        .join('')
    );
};

export const createSlug = (str, seperatorChar = '_') => {
    if (typeof str !== 'string') {
        throw new Error('argument must be of type string');
    }

    return (
        str
        .toLowerCase()
        .replaceAll('æ', 'ae')
        .replaceAll('ø', 'oe')
        .replaceAll('å', 'aa')
        .split(' ')
        .map(sanitizeWord)
        .filter(part => part)
        .join(seperatorChar)
    );
};