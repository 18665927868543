const NONE = 'none';
const BLOCK = 'block';
let clipboardTxtField;

function getClipboardTxtField () {
    if (!clipboardTxtField) {
        clipboardTxtField = document.createElement('input');
        document.body.appendChild(clipboardTxtField);
    }
    return clipboardTxtField;
}

function copyToClipboard (text) {
    if (navigator?.clipboard) {
        navigator.clipboard.writeText(text)
        return;
    }

    const txtField = getClipboardTxtField();
    txtField.style.display = BLOCK;
    txtField.value = text;
    txtField.select();
    txtField.setSelectionRange(0, text.length);
    document.execCommand('copy');
    txtField.style.display = NONE;
}

export default copyToClipboard;