export const collectSuggestions = (autocompleteItems, query) => {
    if (!autocompleteItems) {
        return [];
    }

    const queryLower = query.toLowerCase();

    return autocompleteItems.filter(item => {
        const label = item.label.toLowerCase();

        if (item.label === query) {
            return false;
        }

        return label.includes(queryLower);
    }).sort((a, b) => {
        const aLower = a.label.toLowerCase();
        const bLower = b.label.toLowerCase();

        const aStartsWithQuery = aLower.startsWith(queryLower);
        const bStartsWithQuery = bLower.startsWith(queryLower);
        if (aStartsWithQuery !== bStartsWithQuery) {
            return bStartsWithQuery - aStartsWithQuery;
        }

        if (aLower > bLower) {
            return 1;
        }

        if (aLower < bLower) {
            return -1;
        }

        return 0;
    }).slice(0, 5)
};