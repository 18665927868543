import React, { useState } from 'react';
import { Divider, Dropdown, Form } from 'semantic-ui-react';
import NodeDataChecklist from './NodeData/NodeDataChecklist';
import _ from 'lodash';

const NodeDataTypes = {
    CHECKLIST: 'Checklist'
    // TODO: Add new node data types here
};

const HintNodeData = props => {
    const { dataManager } = props;
    const { getCurrentField, updateField } = dataManager;

    const nodeDataType = _.get(getCurrentField(), 'hint.data.nodeDataType');

    const [selectedNodeDataType, setSelectedNodeDataType] = useState(nodeDataType);

    const prepareNodeTypeSelection = () => {
        const nodeTypeSelection = Object.entries(NodeDataTypes).map(([key, value]) => ({
            key,
            text: value,
            value
        }));
        return nodeTypeSelection;
    };

    const nodeDataTypeSelection = prepareNodeTypeSelection();
    const nodeTypeDropdown = (
        <Dropdown
            placeholder='Select node data type'
            fluid
            selection
            options={nodeDataTypeSelection}
            onChange={event => {
                const newNodeDataType = event.target.textContent;
                setSelectedNodeDataType(newNodeDataType);
                updateField('hint.data.nodeDataType', newNodeDataType);
            }}
            defaultValue={selectedNodeDataType}
        />
    );

    const renderNodeData = () => {
        const { dataManager } = props;

        const createNodeDataComponent = () => {
            let nodeDataComponent = null;
            switch(selectedNodeDataType) {
                case NodeDataTypes.CHECKLIST: {
                    nodeDataComponent = NodeDataChecklist;
                    break;
                }
                default: {
                    nodeDataComponent = null;
                }
            }

            return nodeDataComponent;
        };

        const NodeDataComponent = createNodeDataComponent();
        if(!NodeDataComponent) {
            return null;
        } else {
            return (
                <>
                    <Divider />
                    <NodeDataComponent dataManager={dataManager} />
                </>
            );    
        }
    };
    
    return (
        <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Node data type</label>
                    {nodeTypeDropdown}
                </Form.Field>
            </Form.Group>
            {renderNodeData()}
        </>
    );
};

export default HintNodeData;
