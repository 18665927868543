import React from 'react';

class NodeDataSumInputs extends React.Component {
    getData () {
        let flip = false;
        if (this.flip) {
            flip = this.flip.checked;
        }
        return {
            flip
        };
    }

    render () {
        const { flip } = this.props.node.data;
        return (
            <div>
                <label>
                    Vend fortegn 
                    <input type='checkbox' defaultChecked={flip} ref={flip => this.flip = flip}/>
                </label>
            </div>
        );

    }
}

export default NodeDataSumInputs;