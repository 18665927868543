import React, { Component } from 'react';
import {
    Message,
    Table,
    Checkbox,
    Button,
    Container,
    Loader,
    Header,
    Icon,
} from 'semantic-ui-react';
import productHttp from 'http/products';
import paymentHttp from 'http/payment';
import styles from './PaymentAdministration.module.css';

class ProductAccess extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: true,
            working: false,
            error: false,
            products: [],
            ownedProducts: {},
            upperTaxYear: new Date().getFullYear() + 2,
            taxYearsToShow: 8,
        };
    }

    async componentDidMount () {
        try {
            const [ products, ownedProducts ] = await Promise.all([
                this.fetchActiveProducts(),
                this.fetchUserOwnedProducts()
            ]);
            this.setState({
                products,
                ownedProducts
            });
        } catch {
            this.setState({
                error: true
            });
        } finally {
            this.setState({ loading: false });
        }
    }

    taxYearOwnageChanged = (taxYear, pid, owns) => {
        const { ownedProducts: op } = this.state;
        op[pid] = op[pid] || [];
        if (owns && !op[pid].includes(taxYear)) {
            op[pid].push(taxYear);
        } else {
            const idx = op[pid].indexOf(taxYear);
            if (idx !== -1) {
                op[pid].splice(idx, 1);
            }
        }
        this.setState({
            ownedProducts: op
        });
    };

    fetchActiveProducts = async () => {
        const resp = await productHttp.getActiveProducts();
        if (!resp.success) {
            throw new Error(resp.message);
        }
        return resp.data;
    };

    fetchUserOwnedProducts = async () => {
        const { uid, accountantID, clientID } = this.props;
        let data;
        if (accountantID && clientID) {
            data = await paymentHttp.getClientOwnedProducts(accountantID, clientID);
        } else if (uid) {
            data = await paymentHttp.getCustomerOwnedProducts(uid);
        }
        return data;
    };

    setUserOwnedProducts = async () => {
        this.setState({ working: true });
        try {
            const { uid, accountantID, clientID } = this.props;
            const { ownedProducts } = this.state;
            if (accountantID && clientID) {
                await paymentHttp.setClientOwnedProducts(accountantID, clientID, ownedProducts);
            } else {
                await paymentHttp.setCustomerOwnedProducts(uid, ownedProducts);
            }
        } catch {
            this.setState({ error: true });
        } finally {
            this.setState({ working: false });
        }
    };

    getViewableTaxYears = () => {
        const { upperTaxYear, taxYearsToShow } = this.state;
        const out = [];

        for (let taxYearOffset = 0; taxYearOffset < taxYearsToShow; taxYearOffset++) {
            const taxYear = 1 + upperTaxYear - taxYearsToShow + taxYearOffset;
            out.push(taxYear.toString());
        }

        return out;
    };

    renderTableHeader = () => {
        const taxYears = this.getViewableTaxYears();
        const { upperTaxYear } = this.state;
        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <Icon
                            name='chevron circle left'
                            link
                            onClick={() =>
                                this.setState({
                                    upperTaxYear: upperTaxYear - 1,
                                })
                            }
                        />
                        <span className='noselect'>Skatteår&nbsp;</span>
                        <Icon
                            name='chevron circle right'
                            link
                            onClick={() =>
                                this.setState({
                                    upperTaxYear: upperTaxYear + 1,
                                })
                            }
                        />
                    </Table.HeaderCell>
                    {taxYears.map((ty) => (
                        <Table.HeaderCell key={ty} textAlign='center'>
                            {ty}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
        );
    };

    renderProductCell = (taxYear, id) => {
        const { ownedProducts, working } = this.state;
        const op = ownedProducts[id];
        const ownsTaxYear = op && op.includes(taxYear);
        return (
            <Table.Cell key={taxYear + id} textAlign='center'>
                <Checkbox
                    toggle
                    disabled={working}
                    defaultChecked={ownsTaxYear}
                    onChange={(_, { checked }) => this.taxYearOwnageChanged(taxYear, id, checked)}
                />
            </Table.Cell>
        );
    };

    renderProductRow = ({ id, descriptiveName }) => {
        const taxYears = this.getViewableTaxYears();
        return (
            <Table.Row key={id}>
                <Table.Cell>
                    <Header size='small' title={id}>{descriptiveName}</Header>
                </Table.Cell>
                { taxYears.map(ty => this.renderProductCell(ty, id)) }
            </Table.Row>
        );
    };

    renderTableBody = () => {
        const { products } = this.state;
        return (
            <Table.Body>
                { products.map(this.renderProductRow) }
            </Table.Body>
        );
    };

    renderError = () => {
        if (!this.state.error) {
            return null;
        }
        return (
            <Message
                content='Produkter kunne ikke hentes'
                icon='warning'
                error
            />
        );
    };

    renderContent = () => {
        const { loading, working } = this.state;

        if (loading) {
            return null;
        }

        return <>
            <Table striped>
                { this.renderTableHeader() }
                { this.renderTableBody() }
            </Table>
            <div className={styles.rightAligned}>
                <Button
                    primary content='Gem'
                    onClick={this.setUserOwnedProducts}
                    disabled={loading || working}
                />
            </div>
        </>;
    };

    render () {
        const { loading, working } = this.state;
        return (
            <Container>
                <Loader active={loading || working} />
                {this.renderError()}
                {this.renderContent()}
            </Container>
        );
    }
}

export default ProductAccess;