import React, { Component } from 'react';
import { Header, Icon, Label, Loader, Segment } from 'semantic-ui-react';
import { confirm } from 'shared/globalModal';
import { formatUnix } from 'shared/dateTimeFormatting';
import payment from 'http/payment';
import ProductCard from './ProductCard';

class Subscriptions extends Component {
    state = {
        loading: true,
        working: false,
        subscriptions: [],
    };

    componentDidMount = async () => {
        await Promise.all([
            this.fetchSubscriptions(),
        ]);

        this.setState({
            loading: false,
        });
    };

    fetchSubscriptions = async () => {
        const { uid } = this.props;

        const resp = await payment.getCustomerSubscriptionsFull(uid);
        if (!resp.success) {
            throw new Error(resp.message);
        }

        this.setState({ subscriptions: resp.data });
    };
    doCancelSubscription = async ({ productID, productName }) => {
        // confirm deletion of the subscription
        const isSure = await confirm(`Er du sikker at du vil opsige brugerens abonnement på ${productName}?`);
        if (!isSure) {
            return;
        }

        // do unsubscribe
        this.setState({ working: true });
        await payment.cancelCustomerSubscription(this.props.uid, productID);
        await this.fetchSubscriptions();
        this.setState({ working: false });
    };

    renderCancelSubscription = subscription => {
        return <Icon
            onClick={() => this.doCancelSubscription(subscription)}
            name='x'
            color='black'
            circular
            link
        />;
    };

    renderSubscriptions = () => {
        const { subscriptions, working } = this.state;

        if (subscriptions.length === 0) {
            return (
                <Segment textAlign='center'>
                    <Icon name='dropbox' size='big' color='grey' />
                    <br />
                    <Header>
                        Brugeren har ingen aktive abonnementer
                    </Header>
                </Segment>
            );
        }

        return (
            <>
                {subscriptions.map(subscription => {
                    const {
                        productName,
                        productIcon,
                        productID,
                        createdAt,
                        invoicingMonth,
                        invoicingDay,
                    } = subscription;

                    const description = <span>
                        <Label basic circular>
                            <Icon name='pencil' />
                            Tegnet d. {formatUnix(createdAt)}
                        </Label>
                        <Label basic circular>
                            <Icon name='calendar alternate' />
                            Fornyes årligt d. {invoicingDay}/{invoicingMonth}
                        </Label>
                    </span>;

                    return (
                        <ProductCard
                            key={productID}
                            loading={working}
                            name={productName}
                            description={description}
                            icon={productIcon}
                            actions={[
                                this.renderCancelSubscription(subscription),
                            ]}
                        />
                    );
                })}
            </>
        );
    };

    render = () => {
        const { loading } = this.state;

        if (loading) {
            return <Loader
                inline='centered'
                size='huge'
                active
            />;
        }
        
        return this.renderSubscriptions();
    };
}

export default Subscriptions;