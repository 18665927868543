import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Loader, Segment } from 'semantic-ui-react';
import { listPipelines } from 'http/internalTasks';
import TaskManager from 'molecules/TaskManager';

const TasksMaster = () => {
    const [pipeline, setPipeline] = useState(null);
    const [pipelines, setPipelines] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const doFetchPipelines = async () => {
            const allPipelines = await listPipelines();
            
            setPipelines([...allPipelines]);
            setPipeline(allPipelines[0]);
            setLoading(false);
        };

        doFetchPipelines();
    }, []);

    const pipelineChanged = (_ , { value }) => {
        const pline = pipelines.find(pl => pl.id === value);
        setPipeline(pline);
    };

    if (loading) {
        return (
            <Container>
                <Segment>
                    <Loader inline='centered' size='huge' />
                </Segment>
            </Container>
        );
    }

    return (
        <Container>
            <Segment.Group>
                <Segment>
                    <Dropdown
                        selection
                        search
                        onChange={pipelineChanged}
                        defaultValue={pipeline?.id}
                        options={pipelines.map(({ id, title }) => ({
                            text: title,
                            value: id,
                        }))}
                    />
                </Segment>
                <Segment>
                    <TaskManager pipelineID={pipeline?.id} />
                </Segment>
            </Segment.Group>
        </Container>
    );
};

export default TasksMaster;