import { useState } from "react";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import useRestResource from "shared/hooks/useRestResource";
import email from 'http/email';

const CampaignSelector = ({ onClose, onCampaignSelected, selectedCampaignID }) => {
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const handleCampaignSelected = async () => {
        const fullCampaign = await email.getCampaign(selectedCampaign);
        onCampaignSelected(fullCampaign);
        onClose();
    };

    const { loading, data, error } = useRestResource({
        fetcher: email.getCampaigns,
    });

    return (
        <Modal open>
            <Modal.Header>
                Vælg en kampagne
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    selection
                    search
                    fluid
                    loading={loading}
                    error={error}
                    placeholder='Vælg en kampagne...'
                    onChange={(_, { value }) => setSelectedCampaign(value)}
                    options={(data || []).filter(({ id }) => id !== selectedCampaignID).map(({ name, id }) => {
                        return {
                            key: id,
                            value: id,
                            text: name,
                        };
                    })}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content='Annullér'
                    color='black'
                    onClick={onClose}
                />
                <Button
                    content='Indlæs målgruppe'
                    primary
                    onClick={handleCampaignSelected}
                    disabled={!selectedCampaign}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default CampaignSelector;