import React from 'react';
import { Dropdown, Form, Icon, List, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import modelEditor from 'http/modelEditor';

class NodeDataView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: {
                ok: false,
                tags: [],
                view: null
            },
            views: [],
            loading: true,
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.loadViewStatus(),
            this.loadViewList()
        ]);

        this.setState({ loading: false });
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.node?.id !== this.props.node?.id) {
            await this.loadViewStatus();
        }
    }


    async loadViewList() {
        const views = await modelEditor.getViews();
        this.setState({ views });
    }

    sortTags(data) {
        data.tags.sort((a, b) => {
            if (a.found !== b.found) {
                return b.found - a.found;
            }

            return b.tag.localeCompare(a.tag);
        });

        return data;
    }

    async loadViewStatus() {
        const { node, modelId } = this.props;
        try {
            const data = await modelEditor.getViewStatus(modelId, node.id);
            this.setState({
                status: this.sortTags(data),
            });
        } catch {
            this.setState({
                status: {
                    tags: [],
                },
            });
        }
    };

    getView() {
        let view = null;
        if (this.viewSelect) {
            view = this.viewSelect.state.value;
        }
        return view;
    }

    render() {
        const { modelId, node } = this.props;
        const { status, views, loading } = this.state;

        if (loading) {
            return <Loader inline='centered' active />;
        };

        return (
            <Form>
                <Form.Field>
                    <label style={{ color: 'white' }}>View</label>
                    <Dropdown
                        ref={r => this.viewSelect = r}
                        selection
                        search
                        clearable
                        defaultValue={node.viewId}
                        options={views.map(({ id, name, system }) => {
                            return {
                                value: id,
                                text: name,
                                description: system,
                            };
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{ color: 'white' }}>Status på tilknyttede tags:</label>
                    <List>
                        {status.tags.map(({ nodeId, tag, found }) => {
                            let link;
                            let icon;
                            if (found) {
                                link = <Link to={`/model-editor/${modelId}/node/${nodeId}`}>{tag}</Link>;
                                icon = <Icon name='check' color='green' />;
                            } else {
                                link = tag;
                                icon = <Icon name='question' color='orange' />;
                            }
        
                            return <List.Item content={link} icon={icon} />;
                        })}
                    </List>
                </Form.Field>
            </Form>
        );
    }
}

export default NodeDataView;