import React from 'react';
import style from './ErrorBoundary.module.css'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error(error);
    }

    render() {
        const { hasError, error } = this.state;
        if (hasError) {
            // You can render any custom fallback UI
            return <div className={style.error}>
                <h1 className={style.header}>Something went wrong.</h1>
                <pre className={style.errorMessage}>
                    {error.toString()}
                </pre>
                <pre className={style.stackTrace}>
                    {error.stack}
                </pre>
            </div>
        }

        return this.props.children;
    }
}