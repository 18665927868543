import React from 'react';
import { get } from 'lodash';
import TextInput from '../../../atoms/TextInput/TextInput';
import Checkbox from '../../../atoms/Checkbox';
import { dataTypes } from '../../nodeMetadata'

class NodeDataStaticValue extends React.Component {
    state = {
        isDate: false,
    };

    componentDidMount = () => {
        this.setState({
            isDate: get(this.props, 'node.data.isDate', false),
        });
    };

    getData = () => {
        const { isDate } = this.state;
        if (isDate) {
            if (!this.dateValue) {
                return {};
            }
            return {
                staticValue: this.dateValue.value,
                isDate: true
            };
        } else {
            if (!this.staticValue) {
                return {};
            }
            return {
                staticValue: this.staticValue.getValue(),
                isDate: false
            }
        }
    }

    getDatatype () {
        if (this.state.isDate) {
            return dataTypes.DATE;
        }

        if (!this.staticValue) {
            return dataTypes.STRING;
        }

        if(this.staticValue.getValue().includes('|')) {
            return dataTypes.STRING_LIST;
        }

        return isNaN(this.staticValue.getValue()) ? dataTypes.STRING : dataTypes.NUMBER;
    }

    setIsDate = e => {
        this.setState({ isDate: e.target.value });
    };

    renderInput = isDate => {
        const { staticValue } = this.props.node.data;
        if (isDate) {
            return (
                <input
                    type='date'
                    defaultValue={staticValue}
                    ref={data => this.dateValue = data}
                />
            );
        }
        
        return <TextInput ref={data => this.staticValue = data} defaultValue={staticValue}/>;
    };

    render () {
        const { isDate } = this.state;
        return (
            <section>
                <label>Statisk værdi</label>
                <br/>
                <Checkbox onChange={this.setIsDate} checked={isDate}/> Er dato?
                <br/>
                {this.renderInput(isDate)}
            </section>
        );
    }
}

export default NodeDataStaticValue;