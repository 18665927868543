import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const TABS = [
    {
        path: '/development/feature-flags',
        name: 'Feature flags',
        icon: 'flag',
    },
    {
        path: '/development/events',
        name: 'Aktivitet',
        icon: 'calendar alternate',
    },
    {
        path: '/development/sammenligningstal',
        name: 'Sammenligningstal',
        icon: 'list ol',
    },
    {
        path: '/development/reporting-period',
        name: 'Skatteår til regnskabsperiode',
        icon: 'arrows alternate horizontal',
    },
];

function Navigation ({ location, history }) {
    const { pathname } = location;
    return <Menu pointing>
        {TABS.map(({ path, name, icon }) => {
            const active = pathname.startsWith(path);
            return <Menu.Item
                key={path}
                content={name}
                icon={icon}
                active={active}
                link={!active}
                onClick={() => history.push(path)}
            />;
        })}
    </Menu>;
}

export default withRouter(Navigation);