import payment from 'http/payment';
import { roleMap } from './roles';

const injectPaymentInformation = async accounts => {
    const _accounts = accounts.map(({ id, roles }) => ({
        uid: id,
        isAccountant: roles.includes(roleMap.revisor.id)
    }));
    
    const paymentInformationBulked = await payment.getPaymentInformationBulked(_accounts);

    // inject payment information
    for(const pib of paymentInformationBulked) {
        const { uid, paymentInformation } = pib;
        const account = accounts.find(account => account.id === uid);
        const { userSubscriptions, ownedProducts, onetimePaymentsFull, accountantPurchaseStats } = paymentInformation;
        account.subscriptions = userSubscriptions.map(s => s.productID) || [];
        account.ownedProducts = ownedProducts || {};
        account.onetimePayments = onetimePaymentsFull || [];
        account.clientStats = accountantPurchaseStats || {};
    }
    return accounts;
};

export {
    injectPaymentInformation
};
