import React from 'react';
import { useState } from 'react';
import { Button, Container, Divider, Dropdown, Form, Header, Input, Segment } from 'semantic-ui-react';

import copyToClipboard from 'shared/copyToClipboard';
import validateEmail from 'shared/validation/email';
import accounts from 'http/accounts';

const Registrations = () => {
    const roles = [
        {
            name: 'Akademi',
            value: 'akademi',
            text: 'Akademi',
            icon: 'user'
        }
    ];
    
    const [working, setWorking] = useState(false);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(null);
    const [registrationLink, setRegistrationLink] = useState('');
    const [emailValid, setEmailValid] = useState(true);

    const runAsyncJob = async (f) => {
        setWorking(true);
        await f();
        setWorking(false);
    };

    const generateRegistrationLink = async (email, role) => {
        await runAsyncJob(async () => {
            const { link } = await accounts.generateRegistrationLink('akademi', email, role);
            setRegistrationLink(link);
        });
    };

    return (
        <Container>
            <Segment>
                <Header>Akademi</Header>
                <Form onSubmit={() => generateRegistrationLink(email, role)} style={{ display: 'inline' }}>
                    <div onBlur={() => setEmailValid(validateEmail(email))}>
                        <Form.Field
                            control={Input}
                            label='Email'
                            placeholder='username@domain.xyz'
                            onChange={(_, { value }) => {
                                setEmail(value);
                                setEmailValid(true);
                            }}
                            error={ !email || emailValid ? null : {
                                content: 'Indtast korrekt emailadresse',
                                pointing: 'above',
                            }}
                        />    
                    </div>                       
                    <Form.Field
                        label='Rolle'
                        control={Dropdown}
                        selection
                        options={roles}
                        onChange={(_, { value }) => setRole(value)}
                        defaultValue={null}
                        placeholder={'Vælg personens adgangsniveau'}
                    />
                    <Form.Field disabled={working}>
                        <Button
                            content={`Generer registrationlink`}
                            icon='linkify icon'
                            primary
                            fluid
                            loading={working}
                            disabled={!emailValid || !role || working}
                        />
                    </Form.Field>
                </Form>
                <Divider></Divider>
                <Form.Field style={{ display: 'grid' }}>
                    <label>Registration link</label>
                    <Input
                        value={registrationLink}
                        action={{
                            icon: 'copy',
                            onClick: () => registrationLink && copyToClipboard(registrationLink)
                        }}
                    />
                </Form.Field>
            </Segment>
        </Container>
    );
};

export default Registrations;