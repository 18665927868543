import { useEffect } from 'react';
import { createEventHub } from 'shared/eventHub';
import { localConfigSetter, localConfigHookGenerator } from 'shared/useLocalConfig';

const eventHub = createEventHub();
const namespace = 'reportTemplates';

const _useReportTemplatesConfig = localConfigHookGenerator(namespace);

export const useReportTemplatesConfig = (id, defaultValue) => {
    const [value, _setValue] = _useReportTemplatesConfig(id, defaultValue);

    const setValue = (newValue) => {
        eventHub.emitEvent(id, newValue);
    };

    useEffect(() => {
        const onEvent = incValue => {
            _setValue(incValue);
        };

        return eventHub.register(id, onEvent);
    }, [id, _setValue]);

    return [value, setValue];
};

export const setConfigValue = localConfigSetter(namespace);