import { useCallback, useEffect, useState } from 'react';

/**
 * @typedef {Object} RestResource
 * @property {boolean} loading Whether the resource is still being fetched
 * @property {Error} [error] Is set if the resource call returned an error
 * @property {*} [data] Data returned from the resource call
 * @property {() => boolean} hasChanges Checks if the data has changed since the initial fetch
 * @property {(data: *) => void} setLocalData Overwrite data locally
 * @property {() => void} refetch Call this to retrigger the resource call
 */

/**
 * Wrapper for handling retrieval of async data
 * @param {Object} props 
 * @param {() => *} props.fetcher - Handler for fetching the resource
 * @param {*[]} props.args
 * @returns {RestResource}
 */
const useRestResource = ({ fetcher, args = [] }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [dataVersion, setDataVersion] = useState(0);
    const [hasLocalChanges, setHasLocalChanges] = useState(false);

    const setLocalData = (newData) => {
        setData(newData);
        setHasLocalChanges(true);
    };

    // eslint-disable-next-line
    const callback = useCallback(() => fetcher(...args), args);

    useEffect(() => {
        const doFetch = async () => {
            setError(null);
            setLoading(true);
            try {
                const data = await callback();
                setData(data);
                setHasLocalChanges(false);
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        };

        doFetch();
    }, [dataVersion, callback]);

    return {
        loading,
        error,
        data,
        setLocalData: setLocalData,
        refetch: () => setDataVersion(dataVersion + 1),
        hasChanges: () => hasLocalChanges,
    };
};

export default useRestResource;