import React, { useState } from "react";
import { Button, Header, Icon, Input, Popup } from "semantic-ui-react";
import email from "http/email";
import MasterTable from "molecules/MasterTable";
import { localeDate, localeTime } from "shared/dateTimeFormatting";
import { withRouter } from "react-router-dom";

function NotificationsMaster({ history }) {
  const [working, setWorking] = useState(false);
  const [notificationName, setNotificationName] = useState("");

  const goToNotification = (id) => {
    history.push(`/notifications/notifications/${id}`);
  };

  const createNotification = async () => {
    if (!notificationName) {
      return;
    }

    setWorking(true);
    const notification = await email.createNotification(notificationName);
    goToNotification(notification._id);
  };

  const formatIsSent = (isSent, sendDate, timeSent) => {
    let text;
    let icon;
    let color;
    if (isSent) {
      text = "Afsendt";
      icon = "check";
      color = "green";
    } else if (sendDate) {
      text = "Planlagt";
      icon = "clock";
      color = "yellow";
    } else {
      text = "Ikke afsendt";
      icon = "ellipsis horizontal";
    }
    const iconJSX = (
      <span>
        <Icon name={icon} color={color} /> {text}
      </span>
    );

    if (isSent) {
      return (
        <Popup
          trigger={iconJSX}
          position="top center"
          content={`Sendt d. ${localeDate(timeSent)}`}
          inverted
        />
      );
    } else if (sendDate) {
      return (
        <Popup
          trigger={iconJSX}
          position="top center"
          content={`Afsendelse planlagt til d. ${localeDate(
            sendDate
          )} kl. ${localeTime(sendDate)}`}
          inverted
        />
      );
    } else {
      return iconJSX;
    }
  };

  const formatReceivers = (receivers) => {
    return (
      <span>
        <Icon name="envelope" color="black" /> Sendt til:{receivers}
      </span>
    );
  };

  const formatReaders = (readers, type, receivers) => {
    if (type === "default") {
      {
        return (
          <span>
            <Icon name="envelope open" color="black" /> Åbnet:{readers}
          </span>
        );
      }
    } else {
      return (
        <span>
          <Icon name="envelope open" color="black" /> Åbnet:{receivers}
        </span>
      );
    }
  };

  const createNotificationButton = (
    <Button
      onClick={createNotification}
      disabled={working || !notificationName}
      content="Opret notifikation"
      primary
    />
  );

  return (
    <div>
      <Header as="h2">
        <Icon name="comment" />
        <Header.Content>
          Notifikationer
          <Header.Subheader>
            Opret, se og redigér notifikationer
          </Header.Subheader>
        </Header.Content>
      </Header>
      <MasterTable
        textAlign="center"
        headers={[
          "Titel",
          "Oprettet d.",
          "Afsendt",
          "Antal sendt",
          "Antal læst",
        ]}
        sortProperties={["title", "timestamp", "isSent", "timeSent"]}
        defaultSort="timestamp"
        formatRow={({
          title,
          timestamp,
          isSent,
          timeSent,
          receivers,
          readers,
          sendDate,
          type,
        }) => [
          title,
          localeDate(timestamp),
          formatIsSent(isSent, sendDate, timeSent),
          formatReceivers(receivers),
          formatReaders(readers, type, receivers),
        ]}
        fetchResources={email.getNotificationsWithoutLoginID}
        onRowClicked={({ id }) => goToNotification(id)}
        deleteResource={({ id }) => email.deleteNotification(id)}
        deleteResourceMessage={({ id }) =>
          `Vil du slette Notifikationen med navn "${id}"?`
        }
        rowOptions={["delete"]}
        createForm={
          <Input
            onChange={(_, { value }) => setNotificationName(value)}
            onKeyDown={(e) => e.key === "Enter" && createNotification()}
            placeholder="Navn på Notifikation"
            labelPosition="right"
            label={createNotificationButton}
          />
        }
        filterBy="name"
        paginated
        sortable
      />
    </div>
  );
}

export default withRouter(NotificationsMaster);
