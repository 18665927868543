import React from 'react';
import { Icon, Table, Header, Checkbox, Segment, Menu } from 'semantic-ui-react';
import { getAllFlags, toggleFlag } from 'http/featureFlags';
import Spinner from 'model-editor/atoms/Spinner/Spinner';
import AutoStamp from 'atoms/AutoStamp/index';
import styles from './FeatureFlag.module.css';

export default class FlagMaster extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            success: false,
            flags: [],
            timeStamp: null,
            showingFeatureFlags: true,
        };
    }

    getTableData = async () => {
        this.setState({
            flags: await getAllFlags(),
            success: true,
            loading: false,
        });
    };

    componentDidMount = () => {
        this.getTableData();
    };

    toggleFlag = async (flag) => {
        await toggleFlag(flag.name);
        this.getTableData();
    };

    renderTable() {
        const { flags, success, showingFeatureFlags } = this.state;
        if (success) {
            const flagsToShow = flags.filter(flag => {
                return showingFeatureFlags === flag.isFeatureFlag;
            });

            return (
                <Table.Body>
                    {flagsToShow.map((f) => this.renderTableRow(f))}
                </Table.Body>
            );
        }
    }

    renderTableRow(flag) {
        if (flag.lastAccessed === '0001-01-01T00:00:00Z') {
            //time.Time's nil værdi
            this.timeStamp = <p>Flaget har ikke været aktiveret</p>;
        } else {
            this.timeStamp = <AutoStamp stamp={flag.lastAccessed} />;
        }

        return (
            <Table.Row>
                <Table.Cell>{flag.name}</Table.Cell>
                <Table.Cell>{flag.description}</Table.Cell>
                <Table.Cell>{this.timeStamp}</Table.Cell>
                <Table.Cell>
                    <Checkbox
                        toggle
                        checked={flag.value ? 'active' : ''}
                        onClick={(e) => this.toggleFlag(flag)}
                    ></Checkbox>
                </Table.Cell>
            </Table.Row>
        );
    }

    render() {
        const { loading, showingFeatureFlags } = this.state;

        if (loading) {
            return <Spinner size='320' />;
        }

        return (
            <Segment>
                <Header as='h2'>
                    <div className={styles.header}>
                        <Icon name='flag' size='big' />
                        <Header.Content className={styles.headerContent}>
                            Flags
                            <Header.Subheader>
                                Administer flags for features & system state
                            </Header.Subheader>
                        </Header.Content>
                    </div>
                </Header>
                <Menu secondary pointing>
                    <Menu.Item
                        content='Feature flags'
                        active={showingFeatureFlags}
                        onClick={() => this.setState({ showingFeatureFlags: true })}
                    />
                    <Menu.Item
                        content='System state flags'
                        active={!showingFeatureFlags}
                        onClick={() => this.setState({ showingFeatureFlags: false })}
                    />
                </Menu>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Beskrivelse</Table.HeaderCell>
                            <Table.HeaderCell>Sidst tilgået</Table.HeaderCell>
                            <Table.HeaderCell>Aktiv</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {this.renderTable()}
                </Table>
            </Segment>
        );
    }
}
