import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import InvoiceTable from './InvoiceTable';

function InvoicesMaster () {
    const [showPaid, setShowPaid] = useState(false);

    return (
        <Segment>
            <h1>Rådgiverfakturaer</h1>
            <Menu secondary pointing>
                <Menu.Item
                    content='Ikke betalte'
                    active={!showPaid}
                    onClick={() => setShowPaid(false)}
                    link
                />
                <Menu.Item
                    content='Betalte'
                    active={showPaid}
                    onClick={() => setShowPaid(true)}
                    link
                />
            </Menu>
            <InvoiceTable showPaid={showPaid} />
        </Segment>
    );
}

export default InvoicesMaster;