import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

const FilterLink = ({ text, onClick, active }) => {
    const [isHovering, setIsHovering] = useState(false);

    const icon = <Icon name={active ? 'x' : 'filter'} color='blue' link />;

    return (
        <span onMouseLeave={() => setIsHovering(false)}>
            {isHovering && (
                <span 
                    children={icon} 
                    title={active ? 'Remove filter' : `Filter on ${text}`} 
                    onClick={onClick}
                />
            )}
            <span onMouseEnter={() => setIsHovering(true)}>{text}</span>
        </span>
    );
};

export default FilterLink;