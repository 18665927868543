import React, { Component } from 'react';
import { Modal, Input, Button } from 'semantic-ui-react';

let isMounted = false;
let updateModal; // Is assigned a value when the global modal is mounted

const throwIfUnmounted = () => {
    if (!isMounted) {
        throw new Error('Global modal not mounted');
    }
};

const modalTypes = {
    ALERT: 'ALERT',
    CONFIRM: 'CONFIRM',
    PROMPT: 'PROMPT'
};

class GlobalModal extends Component {
    static initialState = {
        open: false,
        value: ''
    };

    constructor (props) {
        super(props);
        this.inputField = React.createRef();
        this.state = {
            ...GlobalModal.initialState
        };
        this.types = {
            [modalTypes.ALERT]: {
                onOk:      () => undefined
            },
            [modalTypes.CONFIRM]: {
                onOk:      () => true,
                onCancel:  () => false
            },
            [modalTypes.PROMPT]: {
                onOk:      () => this.state.value,
                onCancel:  () => null,
                Component: () => (
                    <Input
                        onChange={(_, { value }) => this.setState({ value })}
                        onKeyUp={e => e.keyCode === 13 && this.onConfirm()}
                        ref={this.inputField}
                        placeholder={this.state.placeholder || this.state.header || this.state.content}
                        defaultValue={this.state.value}
                        fluid
                    />
                )
            }
        };
    }
    
    getType = () => {
        const { type } = this.state;
        return this.types[type];
    };

    componentDidMount = () => {
        updateModal = this.updateModal;
        isMounted = true;
    };

    componentWillUnmount = () => isMounted = false;

    updateModal = (modalProps, resolve) => {
        console.log('setting state', modalProps);
        this.setState({ ...modalProps, resolve }, this.focusInput);
    };

    focusInput = () => {
        const field = this.inputField.current?.inputRef?.current;

        if (!field) {
            return;
        }

        field.focus();

        if (field.value) {
            field.setSelectionRange(0, field.value.length);
        }
    };

    respond = value => {
        const resolve = this.state.resolve;
        const blankState = {};
        for (let prop of Object.keys(this.state)) {
            blankState[prop] = undefined;
        }
        this.setState({ ...blankState, ...GlobalModal.initialState }, () => {
            resolve(value);
        });
    };

    onAction = actionFn => {
        let responseValue;
        if (actionFn) {
            responseValue = actionFn();
        }
        this.respond(responseValue);
    };

    onConfirm = () => {
        const { onOk } = this.getType();
        this.onAction(onOk);
    };

    onCancel = () => {
        const { onCancel } = this.getType();
        this.onAction(onCancel);
    };

    renderHeader = () => {
        const { header } = this.state;
        if (!header) {
            return;
        }
        return <Modal.Header>{header}</Modal.Header>;
    };

    renderContent = () => {
        const { content } = this.state;
        const { Component } = this.getType();
        if (!content && !Component) {
            return;
        }
        let contentTitle;
        if (content) {
            contentTitle = <label>{content}</label>;
        }
        let component;
        if (Component) {
            component = Component();
        }
        return <Modal.Content>
            {contentTitle}
            {component}
        </Modal.Content>;
    };

    renderActions = () => {
        const { confirmButton, cancelButton } = this.state;
        const { onOk, onCancel } = this.getType();
        const cancelBtn = (
            <Button
                onClick={this.onCancel}
                content={cancelButton || 'Annuller'}
            />
        );
        const okBtn = (
            <Button
                primary
                onClick={this.onConfirm}
                content={confirmButton || 'OK'}
            />
        );
        return <Modal.Actions>
            { onCancel && cancelBtn }
            { onOk && okBtn }
        </Modal.Actions>;
    };

    renderBody = () => {
        return <>
            {this.renderHeader()}
            {this.renderContent()}
            {this.renderActions()}
        </>;
    };

    render () {
        const { open } = this.state;
        if (!open) {
            return null;
        }
        return (
            <Modal open onClose={this.onCancel}>
                {this.renderBody()}
            </Modal>
        );
    }
}


const openModal = type => {
    /**
     * @param {string} opts 
     */
    return opts => {
        throwIfUnmounted();
        return new Promise(resolve => {
            let props = {};
            if (typeof opts === 'string') {
                props.header = opts;
            } else {
                props = opts;
            }
            updateModal({
                type,
                open: true,
                ...props
            }, resolve);
        });
    };
    
};

export const alert   = openModal(modalTypes.ALERT);
export const confirm = openModal(modalTypes.CONFIRM);
export const prompt  = openModal(modalTypes.PROMPT);

export default GlobalModal;