import tagDicts from './tagDicts';

export const latestTags = {
    xbrl: {
        common: new Set(tagDicts.xbrl.common),
        onlyA: new Set(tagDicts.xbrl.onlyA),
        onlyB: new Set(tagDicts.xbrl.onlyB),
    },
    revcode: {
        common: new Set(tagDicts.revcode.common),
        onlyA: new Set(tagDicts.revcode.onlyA),
        onlyB: new Set(tagDicts.revcode.onlyB),
    },
};

const tagInModel = (set, tag, isA) => {
    if (set.common.has(tag)) {
        return true;
    }

    const specificTagSet = isA ? set.onlyA : set.onlyB;
    
    return specificTagSet.has(tag);
};

export const xbrlInModel = (tag, isA) => tagInModel(latestTags.xbrl, tag, isA);
export const revCodeInModel = (tag, isA) => tagInModel(latestTags.revcode, tag, isA);

const getAllowedTags = (set, isA) => {
    const specificTagSet = isA ? set.onlyA : set.onlyB;

    return new Set([...specificTagSet, ...set.common]);
};

export const getAllowedXBRLTags = isA => getAllowedTags(tagDicts.xbrl, isA);
export const getAllowedRevCodes = isA => getAllowedTags(tagDicts.revcode, isA);

export const doFilterSortAccounts = ({ rawData, filters = {}, discludeChecked = false, onlyBadMappings = false, minCount = null, chosenXBRLTag = null, chosenAccountantCode = null }) => {
    const groupHasTag = (accountStats, tagProp, allTagsProp, tagToCheck) => {
        if (accountStats[tagProp] === tagToCheck) {
            return true;
        }

        const data = accountStats[allTagsProp]?.data || {};
        if (tagToCheck in data) {
            return true;
        }

        return false;
    };
    
    const filtered = rawData.filter(([accountKey, accountStats]) => {
        const parsedAccount = parseAccount(accountKey);

        for (let [key, value] of Object.entries(filters)) {
            if (!(parsedAccount[key].toLowerCase()).includes(value.toLowerCase())) {
                return false;
            }
        }

        
        if (onlyBadMappings) {
            const isA = parsedAccount.fiscalClass === 'A';
            
            if (xbrlInModel(accountStats.chosenXBRL, isA) && revCodeInModel(accountStats.chosenRevcode, isA)) {
                return false;
            }
        }
        
        if (discludeChecked && accountStats.checked) {
            return false;
        }
        
        if (minCount !== null && accountStats.count < minCount) {
            return false;
        }

        if (chosenXBRLTag && !groupHasTag(accountStats, 'chosenXBRL', 'mappedXBRL', chosenXBRLTag)) {
            return false;
        }

        if (chosenAccountantCode && !groupHasTag(accountStats, 'chosenRevcode', 'mappedRevcode', chosenAccountantCode)) {
            return false;
        }

        return true;
    });


    return filtered.sort((a, b) => {
        return b[1].count - a[1].count;
    });
};

export const accountPropertyDescriptions = {
    fiscalClass: 'A/B',
    group: 'Header',
    vatcode: 'Vat',
    accountType: 'Type',
    text: 'Text',
    erp: 'ERP',
};

export const parseAccount = id => {
    const [
        accountType,
        vatcode,
        erp,
        group,
        text,
        fiscalClass,
    ] = id.split('||');

    return {
        fiscalClass,
        group,
        vatcode,
        accountType,
        text,
        erp,
    };
};

export const makeAccountID = ({
    accountType,
    vatcode,
    erp,
    group,
    text,
    fiscalClass,
}) => {
    return [
        accountType,
        vatcode,
        erp,
        group,
        text,
        fiscalClass,
    ].join('||');
};

export const initTrainingData = (mappings) => {
    const trainingData = {};
    
    for (let mapping of mappings) {
        const accountID = makeAccountID({
            accountType: mapping.accountType,
            vatcode: mapping.vatcode,
            erp: mapping.erp,
            group: mapping.group,
            text: mapping.text,
            fiscalClass: mapping.fiscalClass,
        });

        if (trainingData[accountID]) {
            throw new Error(`${accountID} has more than one mapping!`);
        }

        trainingData[accountID] = {
            checked: true,
            chosenXBRL: mapping.XBRLTag,
            chosenRevcode: mapping.accountantCode,
            count: 0,
            mappedRevcode: { data: {} },
            mappedXBRL: { data: {} },
        };
    }

    return trainingData;
};

export const addAccountsToTrainingData = (trainingData, accounts) => {
    for (let account of accounts) {
        const accountID = makeAccountID({
            accountType: account.accountType,
            vatcode: account.vatcode,
            erp: account.erp,
            group: account.group,
            text: account.text,
            fiscalClass: account.fiscalClass,
        });

        if (!trainingData[accountID]) {
            trainingData[accountID] = {
                checked: false,
                chosenXBRL: null,
                chosenRevcode: null,
                count: 0,
                mappedXBRL: { data: {} },
                mappedRevcode: { data: {} },
            };
        }

        const toUpdate = trainingData[accountID];

        toUpdate.count++;

        // add XBRL & accountant code to stats
        [
            ['mappedXBRL', 'XBRLTag'],
            ['mappedRevcode', 'accountantCode'],
        ].forEach(([tagsProp, tag]) => {
            if (toUpdate[tagsProp].data[account[tag]] === undefined) {
                toUpdate[tagsProp].data[account[tag]] = 0;
            }
            toUpdate[tagsProp].data[account[tag]]++;
        });
    }
}

export const selectChosenTagsInTrainingData = trainingData => {
    for (let stats of Object.values(trainingData)) {
        if (stats.checked) {
            continue;
        }

        [
            ['chosenXBRL', 'mappedXBRL'],
            ['chosenRevcode', 'mappedRevcode'],
        ].forEach(([chosenProp, tagsProp]) => {
            const mostMapped = Object.entries(stats[tagsProp].data).sort((a, b) => {
                return b[1] - a[1];
            });

            stats[chosenProp] = mostMapped[0][0];
        });
    }
};

export const trunc = (value, maxChars = 40) => {
    if (value.length >= maxChars) {
        return value.substring(0, maxChars - 3) + '...';
    }

    return value;
};

export const distinctProperties = [
    'fiscalClass',
    'vatcode',
    'accountType',
    'erp',
];

export const buildStdAccountPlan = accountGroups => {
    const out = {};

    for (let [id, stats] of accountGroups) {
        if (!stats.checked) {
            continue;
        }

        out[id] = {
            xbrl: stats.chosenXBRL,
            accountantCode: stats.chosenRevcode,
        };
    }

    return out;
};

export const getMostMappedTag = tags => {
    const [[mostMappedTag]] = Object.entries(tags.data).sort((a, b) => {
        return b[1] - a[1];
    });

    return mostMappedTag;
};