import { dataTypes } from 'model-editor/nodeMetadata';
import interpret from '@digital-revisor/node-formula-interpreter';
import findAllReferences from '@digital-revisor/node-formula-interpreter/lib/findAllReferences';

export const ruleToCalculation = (rule, dataType) => {
    const {
        advisoryMessage,
        comparator,
        compareRefId,
        compareValue,
        isDynamic,
        refNode,
    } = rule;

    const formatValue = () => {
        if (isDynamic) {
            // echo ref node
            return `$${compareRefId}`;
        }

        switch (dataType) {
            case dataTypes.BOOLEAN:
                return Number(compareValue === 'true');
            case dataTypes.DATE:
                return new Date(compareValue).getTime();
            case dataTypes.ENUM_STRING:
            case dataTypes.STRING:
                return `"${compareValue}"`;
            case dataTypes.NUMBER:
                return Number(compareValue);
            default:
                throw new Error(`Can not convert value of data type '${dataType}' to formula value`);
        }
    };

    const expression = `$${refNode} ${comparator} ${formatValue()}`;
    const parsedExpression = interpret(expression);
    const requires = findAllReferences(parsedExpression);

    return {
        expression,
        parsedExpression: JSON.stringify(parsedExpression),
        advisoryMessage,
        requires,
    };
};