import React, { Component } from 'react';
import { Checkbox, Message, Loader } from 'semantic-ui-react';
import { get } from 'lodash';
import cns from 'shared/cns';
import readableID from 'shared/readableID';
import productHttp from '../../http/products';
import getRelevantTaxYears from '../../shared/getRelevantTaxYears';

import styles from './ProductTaxYearPicker.module.css';

class ProductTaxYearPicker extends Component {
    state = {
        loading: true,
        error: false,
        products: [],
        taxYears: getRelevantTaxYears(),
    };

    componentDidMount = async () => {
        try {
            const products = await this.fetchActiveProducts();
            this.setState({ products });
        } catch {
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    fetchActiveProducts = async () => {
        const resp = await productHttp.getActiveProducts();
        if (!resp.success) {
            throw new Error(resp.message);
        }
        return resp.data;
    };

    reportChangeHandler = (productID, taxYear) => {
        const { onChange } = this.props;
        return (_, { checked }) => {
            onChange && onChange(productID, taxYear, checked);
        };
    };

    handleRowCheckerClicked = (id, allChecked) => {
        this.setAll(id, !allChecked);
    };

    setAll = (productID, value) => {
        const { taxYears } = this.state;
        const { onChange } = this.props;

        if (!onChange) {
            return;
        }

        for (let taxYear of taxYears) {
            onChange(productID, taxYear, value);
        }
    };

    renderTableHeader = () => {
        const { taxYears } = this.state;
        return (
            <thead>
                <tr>
                    <td className={styles.nameCell} />
                    {taxYears.map(ty => {
                        return <td className={styles.tyCell} key={ty}>
                            {ty}
                        </td>;
                    })}
                </tr>
            </thead>
        );
    };

    renderProductCell = (taxYear, id) => {
        const { data, disabled } = this.props;
        const isChecked = get(data, `${id}.${taxYear}`, false);
        return (
            <td key={taxYear + id} className={styles.tyCell}>
                <Checkbox
                    checked={isChecked}
                    onChange={this.reportChangeHandler(id, taxYear)}
                    disabled={disabled}
                />
            </td>
        );
    };

    renderProductRow = ({ id }, i) => {
        const { taxYears } = this.state;
        const { data } = this.props;

        // unreven row => render w/ background color
        const unevenRow = i % 2 === 1;

        // check if all columns are checked
        let allChecked = true;
        for (let taxYear of taxYears) {
            const isChecked = get(data, `${id}.${taxYear}`, false);
            if (!isChecked) {
                allChecked = false;
                break;
            }
        }

        return (
            <tr key={id} className={unevenRow ? styles.stripeRow : styles.row}>
                <td className={styles.nameCell}>
                    <Checkbox
                        checked={allChecked}
                        onClick={() => this.handleRowCheckerClicked(id, allChecked)}
                    />
                    &nbsp;
                    {readableID(id)}
                </td>
                { taxYears.map(ty => this.renderProductCell(ty, id)) }
            </tr>
        );
    };

    renderTableBody = () => {
        const { products } = this.state;
        return (
            <tbody>
                { products.map(this.renderProductRow) }
            </tbody>
        );
    };

    renderAccessTable = () => {
        const cnames = [styles.picker];
        if (this.props.disabled) {
            cnames.push(styles.disabledPicker);
        }

        return (
            <table className={cns(...cnames)}>
                { this.renderTableHeader() }
                { this.renderTableBody() }
            </table>
        );
    };

    renderError = () => {
        if (!this.state.error) {
            return null;
        }
        return (
            <Message
                content='Produkter kunne ikke hentes'
                icon='warning'
                error
            />
        );
    };

    renderContent = () => {
        if (this.state.loading) {
            return null;
        }
        return this.renderAccessTable();
    };


    render = () => {
        return (
            <>
                <Loader active={this.state.loading} />
                {this.renderError()}
                {this.renderContent()}
            </>
        );
    };
}

export default ProductTaxYearPicker;