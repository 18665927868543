import React from 'react';
import { Table, Icon } from 'semantic-ui-react';
import { prepareDownloadLink } from '../../http/fileStorage'

const FileLink = ({ fileID, textAlign, iconSize}) => {
    const getLink = disposition => prepareDownloadLink(fileID, disposition);

    const fetchAttachment = async () => {
        const link = await getLink('attachment');
        window.location.href = link;
    };

    const fetchInline = async () => {
        const link = await getLink('inline');
        window.open(link);
    };

    return (
        <Table.Cell textAlign={textAlign}>
            <Icon
                link
                name='download'
                color='green'
                title='Download'
                size={iconSize}
                onClick={fetchAttachment}
            />
            <Icon
                link
                name='eye'
                color='blue'
                title='Se i browser'
                size={iconSize}
                onClick={fetchInline}
            />
        </Table.Cell>
    );
};

export default FileLink;