import MasterTable from 'molecules/MasterTable';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon, Input } from 'semantic-ui-react';
import { localeDate } from 'shared/dateTimeFormatting';
import email from '../../../../http/email';

const TemplatesMaster = ({ history }) => {
    const [templateName, setTemplateName] = useState('');
    const [working, setWorking] = useState(false);

    const goToTemplate = id => {
        history.push(`/emails/templates/${id}`);
    };

    const createTemplate = async () => {
        if (!templateName) {
            return;
        }

        setWorking(true);
        const template = await email.createTemplate(templateName);
        goToTemplate(template.id);
    };
    
    const createTemplateButton = <Button
        onClick={createTemplate}
        disabled={working || !templateName}
        content='Opret mailskabelon'
        primary
    />;

    return (
        <div>
            <Header as='h2'>
                <Icon name='file' />
                <Header.Content>
                    Mailskabeloner
                    <Header.Subheader>
                        Opret, slet og redigér mailskabeloner
                    </Header.Subheader>
                </Header.Content>
            </Header>
            <MasterTable
                headers={['Navn', 'Oprettet d.', 'Ændret d.', 'Forfatter']}
                sortProperties={['name', 'createdAt', 'updatedAt', 'creator']}
                defaultSort='createdAt'
                formatRow={({ name, createdAt, updatedAt, creator }) => [
                    name,
                    localeDate(createdAt),
                    localeDate(updatedAt),
                    creator,
                ]}
                fetchResources={email.getTemplates}
                deleteResource={({ id }) => email.deleteTemplate(id)}
                deleteResourceMessage={({ name }) => `Vil du slette skabelonen med navn "${name}"?`}
                copyResource={({ id }) => email.copyTemplate(id)}
                onRowClicked={({ id }) => goToTemplate(id)}
                rowOptions={['copy', 'delete']}
                disabled={working}
                createForm={
                    <Input
                        onChange={(_, { value }) => setTemplateName(value)}
                        onKeyDown={e => e.key === 'Enter' && createTemplate()}
                        placeholder='Navn på skabelon'
                        labelPosition='right'
                        label={createTemplateButton}
                    />
                }
                filterBy='name'
                paginated
                sortable
            />
        </div>
    );
};

export default withRouter(TemplatesMaster);