import lodash from 'lodash';
import { toast } from 'react-toastify';
import React, { Component, useState } from 'react';
import { Checkbox, List, Button, Label, Form, Loader, Header, Segment, Icon, Dropdown } from 'semantic-ui-react';
import Papa from 'papaparse';
import { readAsText } from 'shared/files';
import roles from 'shared/roles';
import businessCategories from 'shared/businessCategories';
import erpSystems from 'shared/erpSystems';
import cns from 'shared/cns';
import products from 'http/products';
import ProductTaxYearPicker from 'molecules/ProductTaxYearPicker';
import DatePicker from 'atoms/DatePicker';
import FileuploadButton from 'atoms/FileuploadButton';

import styles from './AudienceSelector.module.css';
import productEngine from 'http/productEngine';

function GroupHeader ({ header, onClick, open, className }) {
    return <Form.Field>
        <Header
            className={className}
            color={open ? 'black' : 'grey'}
            onClick={onClick}
            content={<span>
                <Icon name={open ? 'caret down' : 'caret right'} /> 
                {header
            }</span>}
            dividing={open}
        />
    </Form.Field>;
}

function Group ({ open: o, header, children }) {
    const [open, setOpen] = useState(o);
    return <>
        <GroupHeader
            className={styles.header}
            open={open}
            header={header}
            onClick={() => setOpen(!open)}
        />
        {open && children}
    </>;
}

class AudienceSelector extends Component {
    constructor (props) {
        super();
        this.state = {
            audience: props.defaultValue || {},
            products: [],
            loading: true,
        };
    }

    setAudience = audience => this.setState({ audience });
    
    componentDidMount = async () => {
        const resourceTemplate = await productEngine.getResourceTemplate("Bolig2");
        const housingTypeOptns = lodash.get(resourceTemplate, 'properties.2.options');
        const housingTypes = [];
        housingTypeOptns.forEach(hto => {
            const { value, label } = hto;
            housingTypes.push({
                id: value,
                name: label
            });
        });
        const { data } = await products.getActiveProducts();
        this.setState({
            loading: false,
            products: data,
            housingTypes,
        });
    };

    simplifyCSVHeader = header => {
        let out = '';

        // run through every char of header
        for (let c of [...header]) {
            c = c.toLowerCase();
            // disregard everything but letters
            if (!/[a-z]/.test(c)) {
                continue;
            }

            out += c;
        }

        return out;
    };

    onEmailCSVSelected = async csvFile => {
        // read .csv contents and parse
        const contents = await readAsText(csvFile);
        const { data } = Papa.parse(contents);
        const csvHeaders = data[0];    // first row
        const csvData = data.slice(1); // rest of the rows

        // find email header index
        const emailHeaderIndex = csvHeaders.findIndex(header => {
            // simplify
            header = this.simplifyCSVHeader(header);

            return header === 'email';
        });

        // fail if not found
        if (emailHeaderIndex === -1) {
            toast.error('Kunne ikke finde emailkolonne i CSV-fil');
            return;
        }

        // filter-map emails
        const emails = [];
        for (let row of csvData) {
            // confirm valid column value
            if (typeof row[emailHeaderIndex] !== 'string') {
                continue;
            }

            const email = row[emailHeaderIndex].trim();

            // skip falsy values
            if (!email) {
                continue;
            }

            emails.push(email);
        }

        // update audience
        const { audience } = this.state;

        lodash.set(audience, 'email.exclusionActive', true);
        lodash.set(audience, 'email.toExclude', emails);

        this.setState({ audience });
    };

    referrerChanged = (prefix, suffix) => {
        return (_, { value }) => {
            const { audience } = this.state;
            if (!lodash.get(audience, `${prefix}`)) {
                lodash.set(audience, `${prefix}`, {});
            }

            // set the picked value
            lodash.set(audience, `${prefix}`, value);
            lodash.set(audience, `referrer.${suffix}`, true);

            // update state
            this.setState({ audience }, () => {
                const { onChange } = this.props;
                onChange && onChange(audience);
            });
        };
    };

    dissolvedChanged = (prefix, suffix) => {
        return () => {
            const { audience } = this.state;
            if (!lodash.get(audience, `${prefix}`)) {
                lodash.set(audience, `${prefix}`, {});
            }
            const dataPath = `isDissolved.${suffix}`;
            const include = lodash.get(audience, `${dataPath}`)
            if (include === undefined) {
                lodash.set(audience, `${prefix}`, true);
                lodash.set(audience, `isDissolved.${suffix}`, true);
            } else {
                const newValue = include !== true;
                // set the picked value
                lodash.set(audience, `${prefix}`, newValue);
                lodash.set(audience, `isDissolved.${suffix}`, newValue);
            }

            this.setState({ audience }, () => {
                const { onChange } = this.props;
                onChange && onChange(audience);
            });
        };
    };

    productTaxYearChanged = prefix => {
        return (pid, ty, value) => {
            const { audience } = this.state;
            // ensure pid key is an object (if full path provided, lodash will make it an array)
            if (!lodash.get(audience, `${prefix}.${pid}`)) {
                lodash.set(audience, `${prefix}.${pid}`, {});
            }
    
            // set the picked value
            lodash.set(audience, `${prefix}.${pid}.${ty}`, value);
    
            this.setState({ audience }, () => {
                const { onChange } = this.props;
                onChange && onChange(audience);
            });
        };
    };
    
    setValue = (propPath, value) => {
        const { audience } = this.state;
        lodash.set(audience, propPath, value);
        this.setState({ audience }, () => {
            const { onChange } = this.props;
            onChange && onChange(audience);
        });
    };

    setHOF = (propPath, valExtractor) => {
        return (...args) => {
            const value = valExtractor(...args);
            this.setValue(propPath, value);
        };
    };

    setValueCheckbox = propPath => {
        return this.setHOF(propPath, (_, { checked }) => checked);
    };

    setValueDatePicker = propPath => {
        return this.setHOF(propPath, v => v);
    };

    renderReferrerSubSelector = (pathSuffix) => {
        const { audience } = this.state;
        const dataPath = `referrer.${pathSuffix}`;
        const data = lodash.get(audience, dataPath, []);
        const options = [
            { key: 'facebook', text: 'Facebook', value: 'facebook' },
            { key: 'google', text: 'Google', value: 'google' },
            { key: 'rigraad', text: 'Rigtig Rådgivning', value: 'rigraad' }
        ];
        const include = (pathSuffix === "toInclude") ? "inclusionActive" : "exclusionActive";
        
        return <Dropdown
            placeholder="Vælg Henvisere"
            options={options}
            onChange={this.referrerChanged(dataPath, include)}
            fluid
            multiple
            selection
            search
            value={data}
        />;
    };

    renderDatePicker = (propPath) => {
        const { audience } = this.state;

        let value = lodash.get(audience, propPath);
        let leftAttachment;
        if (value) {
            // convert value to unix stamp
            value = new Date(value).getTime();
            leftAttachment = <Button
                as={Label}
                onClick={() => this.setValue(propPath, undefined)}
                icon='x'
            />;
        }

        return (
            <DatePicker
                leftAttachment={leftAttachment}
                value={value}
                onChange={this.setValueDatePicker(propPath)}
                maxDate={Date.now()}
                popperPlacement='top'
            />
        );
    };

    renderSelectorList = (fullSet, selectorProp, label, listProp, activeProp) => {
        const { audience } = this.state;

        const isActivePath = `${selectorProp}.${activeProp}`;
        const isActive = lodash.get(audience, isActivePath) || false;

        const subsetPath = `${selectorProp}.${listProp}`
        const subset = lodash.get(audience, subsetPath) || [];

        const nameCount = {};
        fullSet.forEach(({ name }) => {
            nameCount[name] = nameCount[name] || 0;
            nameCount[name]++;
        });
        return <Form.Field>
            <Checkbox
                onChange={this.setValueCheckbox(isActivePath)}
                checked={isActive}
                label={label}
                slider
            />
            <List>
                {fullSet.map(({ name, id }) => {
                    let label = name;
                    if (nameCount[name] > 1) {
                        label += ` (${id})`;
                    }
                    return <Checkbox
                        onChange={() => {
                            if (!isActive) {
                                this.setValue(isActivePath, true);
                            }

                            if (subset.includes(id)) {
                                subset.splice(subset.indexOf(id), 1);
                            } else {
                                subset.push(id);
                            }
                            this.setValue(subsetPath, subset);
                        }}
                        as={List.Item}
                        label={label}
                        checked={subset.includes(id)}
                        key={id}
                    />;
                })}
            </List>
        </Form.Field>;
    };

    renderInclusionSelector = (fullSet, selectorProp) => {
        return this.renderSelectorList(
            fullSet,
            selectorProp,
            'Indkluder',
            'toInclude',
            'inclusionActive'
        );
    };

    renderExlusionSelector = (fullSet, selectorProp) => {
        return this.renderSelectorList(
            fullSet,
            selectorProp,
            'Ekskluder',
            'toExclude',
            'exclusionActive'
        );
    };

    renderSelector = (title, selectorProp, fullSet) => {
        return <Group header={title} open={this.props.defaultOpen}>
            {this.renderInclusionSelector(fullSet, selectorProp)}
            {this.renderExlusionSelector(fullSet, selectorProp)}
        </Group >;
    };

    renderRoleSelector = () => {
        return this.renderSelector('Roller', 'roles', roles);
    };

    renderProductSubSelector = (resourcePrefix, pathSuffix) => {
        const { audience } = this.state;
        const dataPath = `${resourcePrefix}.${pathSuffix}`;
        const data = lodash.get(audience, dataPath, {});
        return <ProductTaxYearPicker
            data={data}
            onChange={this.productTaxYearChanged(dataPath)}
        />;
    };

    renderProductTaxYearSelector = (title, resourcePrefix) => {
        return <Group header={title} open={this.props.defaultOpen}>
            <strong>Inklusion:</strong>
            {this.renderProductSubSelector(resourcePrefix, 'toInclude')}
            <br />
            <strong>Eksklusion:</strong>
            {this.renderProductSubSelector(resourcePrefix, 'toExclude')}
        </Group>;
    };

    renderOnetimePaymentSelector = () => {
        const { audience } = this.state;

        const isActivePath = `onetimePayment.inclusionActive`;
        const isActive = lodash.get(audience, isActivePath) || false;

        const isActivePathEx = `onetimePayment.exclusionActive`;
        const isActiveEx = lodash.get(audience, isActivePathEx) || false;

        return <Group header='Enkeltkøb' open={this.props.defaultOpen}>
            <Form.Field>
                {this.renderOnetimePaymentCheck(isActive, "toInclude")}
            </Form.Field>
            <Form.Field>
                {this.renderOnetimePaymentCheck(isActiveEx, "toExclude")}
            </Form.Field>
        </Group>;
    }

    renderAdsReferrerSubSelector = () => {
        const { audience } = this.state;

        const isActivePath = `adsreferrer.inclusionActive`;
        const isActive = lodash.get(audience, isActivePath) || false;

        const isActivePathEx = `adsreferrer.exclusionActive`;
        const isActiveEx = lodash.get(audience, isActivePathEx) || false;

        return <Group header='Ads Henviser' open={this.props.defaultOpen}>
            <Form.Field>
                {this.renderAdsReferrerCheck(isActive, "toInclude")}
            </Form.Field>
            <Form.Field>
                {this.renderAdsReferrerCheck(isActiveEx, "toExclude")}
            </Form.Field>
        </Group>;
    }

    renderDissolvedSubSelector = () => {
        const { audience } = this.state;

        const isActivePath = `isDissolved.inclusionActive`;
        const isActive = lodash.get(audience, isActivePath) || false;

        const isActivePathEx = `isDissolved.exclusionActive`;
        const isActiveEx = lodash.get(audience, isActivePathEx) || false;

        return <Group header='Ophørte Virksomheder' open={this.props.defaultOpen}>
            <Form.Field>
                {this.renderDissolvedCheck(isActive, "toInclude")}
            </Form.Field>
            <Form.Field>
                {this.renderDissolvedCheck(isActiveEx, "toExclude")}
            </Form.Field>
        </Group>;
    }

    onetimePaymentChanged = (prefix, suffix) => {
        return () => {
            const { audience } = this.state;
            if (!lodash.get(audience, `${prefix}`)) {
                lodash.set(audience, `${prefix}`, {});
            }
            const dataPath = `onetimePayment.${suffix}`;
            const include = lodash.get(audience, `${dataPath}`)
            if (include === undefined) {
                lodash.set(audience, `${prefix}`, ["OPHJBOLIG", "OPHJERHVERV"]);
                lodash.set(audience, `onetimePayment.${suffix}`, true);
                // update state
                this.setState({ audience });
            } else {
                const newCheckValue = include !== true
                // set the picked value
                lodash.set(audience, `${prefix}`, include === true ? [] : ["OPHJBOLIG", "OPHJERHVERV"]);
                lodash.set(audience, `onetimePayment.${suffix}`, newCheckValue);

                this.setState({ audience });
            }
        };
    };

    adsReferrerChanged = (prefix, suffix) => {
        return () => {
            const { audience } = this.state;
            if (!lodash.get(audience, `${prefix}`)) {
                lodash.set(audience, `${prefix}`, {});
            }
            const dataPath = `adsreferrer.${suffix}`;
            const include = lodash.get(audience, `${dataPath}`)
            if (include === undefined) {
                lodash.set(audience, `${prefix}`, ["Google Ads"]);
                lodash.set(audience, `adsreferrer.${suffix}`, true);
                this.setState({ audience });
            } else {
                const newCheckValue = ( include === true) ? false : true;
                // set the picked value
                lodash.set(audience, `${prefix}`, ( include === true) ? [] : ["Google Ads"]);
                lodash.set(audience, `adsreferrer.${suffix}`, newCheckValue);

                this.setState({ audience });
            }
        };
    };

    renderOnetimePaymentCheck = (isActive, pathSuffix) => {
        const dataPath = `onetimePayment.${pathSuffix}`;
        const include = (pathSuffix === "toInclude") ? "inclusionActive" : "exclusionActive";
        return <Checkbox
            label={(pathSuffix === "toInclude") ? "Indkluder Enkeltkøb" : "Ekskluder Enkeltkøb"}
            onChange={this.onetimePaymentChanged(dataPath, include)}
            checked={isActive}
    />;
    };

    renderAdsReferrerCheck = (isActive, pathSuffix) => {
        const dataPath = `adsreferrer.${pathSuffix}`;
        const include = (pathSuffix === "toInclude") ? "inclusionActive" : "exclusionActive";
        return <Checkbox
            label={(pathSuffix === "toInclude") ? "Indkluder Ads Henviser" : "Ekskluder Ads Henviser"}
            onChange={this.adsReferrerChanged(dataPath, include)}
            checked={isActive}
    />;
    };

    renderDissolvedCheck = (isActive, pathSuffix) => {
        const dataPath = `isDissolved.${pathSuffix}`;
        const include = (pathSuffix === "toInclude") ? "inclusionActive" : "exclusionActive";
        return <Checkbox
            label={(pathSuffix === "toInclude") ? "Indkluder Ophørte Virksomheder" : "Ekskluder Ophørte Virksomheder"}
            onChange={this.dissolvedChanged(dataPath, include)}
            checked={isActive}
    />;
    };

    renderOwnedProductsSelector = () => {
        return this.renderProductTaxYearSelector('Produktadgange', 'ownedProducts');
    };

    renderHousingTypeSelector = () => {
        return this.renderSelector('Bolig Type', 'housingType', this.state.housingTypes)
    }

    renderTaxReportedProductsSelector = () => {
        return this.renderProductTaxYearSelector('Indberetningsstatus', 'taxReportedProducts');
    };

    renderBusinessCategorySelector = () => {
        return this.renderSelector('Virksomhedskategori', 'businessCategory', businessCategories);
    };

    renderERPSystemSelector = () => {
        return this.renderSelector('ERP-system', 'erp', erpSystems);
    };

    renderOneTimePaymentSelector = () => {
        return this.renderOnetimePaymentSelector('Enkeltkøb', 'onetimePayment')
    }

    renderReferrerSelector = () => {
        const { audience } = this.state;

        const isActivePath = `referrer.inclusionActive`;
        const isActive = lodash.get(audience, isActivePath) || false;

        const isActivePathEx = `referrer.exclusionActive`;
        const isActiveEx = lodash.get(audience, isActivePathEx) || false;

        return <Group header='Henviser' open={this.props.defaultOpen}>
            <Form.Field>
                <Checkbox
                    onChange={this.setValueCheckbox(isActivePath)}
                    checked={isActive}
                    label="Indkluder"
                    slider
                />
                <label>Inkluder kun brugere der har følgende henvisere</label>
                {this.renderReferrerSubSelector('toInclude')}
            </Form.Field>
            <Form.Field>
                <Checkbox
                    onChange={this.setValueCheckbox(isActivePathEx)}
                    checked={isActiveEx}
                    label="Ekskluder"
                    slider
                />
                <label>Inkluder kun brugere der ikke har følgende henvisere</label>
                {this.renderReferrerSubSelector('toExclude')}
            </Form.Field>
        </Group>;
    }

    renderAdsReferrerSelector = () => {
        return this.renderAdsReferrerSubSelector('Ads Henviser', 'adsreferrer')
    }

    renderDissolvedSelector = () => {
        return this.renderDissolvedSubSelector()
    }
    
    renderCampaignRelatedSelectors = () => {
        if (!this.props.campaign) {
            return null;
        }

        return <Group header='Abonnentgruppe' open={this.props.defaultOpen}>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>Indkluder kun brugere der har takket ja til nyhedsbrev</label>
                    <Checkbox
                        onChange={(_, { checked }) => {
                            if(this.props.campaign) {
                                this.setValue('wantsNews', checked);
                                this.setValue('wantsStatusMails', !checked);
                            }
                        }}
                        checked={this.state.audience.wantsNews}
                        toggle
                    />
                </Form.Field>
                <Form.Field>
                    <label>Indkluder kun brugere der har takket ja til driftsmails</label>
                    <Checkbox
                        onChange={(_, { checked }) => {
                            if(this.props.campaign) {
                                this.setValue('wantsNews', !checked);
                                this.setValue('wantsStatusMails', checked);
                            }
                        }}
                        checked={this.state.audience.wantsStatusMails}
                        toggle
                    />
                </Form.Field>
            </Form.Group>
        </Group>;
    };

    renderCreationDateSelector = () => {
        return <Group header='Oprettelsesdato' open={this.props.defaultOpen}>
            <Form.Field>
                <label>Inkluder kun brugere der har oprettet sig på eller efter følgende dato</label>
                {this.renderDatePicker('createdAt.from')}
            </Form.Field>
            <Form.Field>
                <label>Inkluder kun brugere der har oprettet sig før følgende dato</label>
                {this.renderDatePicker('createdAt.to')}
            </Form.Field>
        </Group>;
    };

    renderLatestActivitySelector = () => {
        return <Group header='Seneste login' open={this.props.defaultOpen}>
            <Form.Field>
                <label>Inkluder kun brugere der sidst har været logget ind på eller efter følgende dato</label>
                {this.renderDatePicker('latestActivity.from')}
            </Form.Field>
            <Form.Field>
                <label>Inkluder kun brugere der sidst har været logget ind før følgende dato</label>
                {this.renderDatePicker('latestActivity.to')}
            </Form.Field>
        </Group>;
    };

    renderEmailExcluder = () => {
        const { audience } = this.state;
        const excludedEmails = lodash.get(audience, `email.toExclude`, []);

        return <Group header='Ekskluder emails' open={this.props.defaultOpen}>
            <Form.Field>
                <label>Upload en CSV-fil med de e-mails der ønskes ekskluderet</label>
                <FileuploadButton
                    content='Vælg CSV'
                    accept={['csv']}
                    onChange={this.onEmailCSVSelected}
                />
            </Form.Field>
            {
                excludedEmails.length > 0 &&
                <Form.Field>
                    <label>Disse emails bliver ekskluderet:</label>
                    <List divided>
                        {excludedEmails.map(email => (
                            <List.Item>{email}</List.Item>
                        ))}
                    </List>
                </Form.Field>
            }
        </Group>;
    };

    render = () => {
        const { disabled, campaign } = this.props;
        const { loading } = this.state;
        if (loading) {
            return <Loader inline='centered' active />;
        }

        const classNames = [styles.nopad];
        if (disabled) {
            classNames.push(styles.disabled);
        }

        return (
            <Segment basic className={cns(...classNames)} disabled={disabled}>
                <Form className={styles.form}>
                    {this.renderCampaignRelatedSelectors()}
                    {this.renderRoleSelector()}
                    {this.renderOwnedProductsSelector()}
                    {this.renderOneTimePaymentSelector()}
                    {this.renderTaxReportedProductsSelector()}
                    {this.renderBusinessCategorySelector()}
                    {this.renderERPSystemSelector()}
                    {this.renderEmailExcluder()}
                    {this.renderCreationDateSelector()}
                    {!campaign && <>
                        {this.renderLatestActivitySelector()}
                        {this.renderDissolvedSelector()}
                        {this.renderAdsReferrerSelector()}
                        {this.renderReferrerSelector()}
                        {this.renderHousingTypeSelector()}
                    </>}
                </Form>
            </Segment>
        );
    };
}

AudienceSelector.defaultProps = {
    defaultOpen: false,
    campaign: false,
};

export default AudienceSelector;