import React from 'react';
import { Table } from 'semantic-ui-react';

const SimpleMappingTable = ({ groups }) => {
    return (
        <Table basic='very'>
            <Table.Header>
                <Table.Row>
                    {[
                        'A/B',
                        'Vat',
                        'Type',
                        'ERP',
                        'Header',
                        'Text'
                    ].map(header => <Table.HeaderCell>{header}</Table.HeaderCell>)}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {groups.map(group => (
                    <Table.Row>
                        <Table.Cell>{group.fiscalClass}</Table.Cell>
                        <Table.Cell>{group.vatcode}</Table.Cell>
                        <Table.Cell>{group.accountType}</Table.Cell>
                        <Table.Cell>{group.erp}</Table.Cell>
                        <Table.Cell>{group.group}</Table.Cell>
                        <Table.Cell>{group.text}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

export default SimpleMappingTable;