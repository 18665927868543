import { parseXML } from 'shared/xml';

// TODO: should probably be moved to the taxonomy-parser service

const globalMetaNamespaces = {
    linkbase: 'http://www.xbrl.org/2003/linkbase',
    instance: 'http://www.xbrl.org/2003/instance',
};

const splitNamespaceAndTag = (namespaceAndTag = '') => {
    const parts = namespaceAndTag.split(':');
    if (parts.length === 1) {
        return ['', parts[0]];
    }
    return parts;
};

const getTag = namespacedTag => {
    return splitNamespaceAndTag(namespacedTag).at(-1);
};

const getXmlNodeChildrenAsMap = xmlNode => {
    const out = {};

    for (const child of xmlNode.children) {
        out[getTag(child.tagName)] = child.children[0];
    }

    return out;
};

const getNamespaceLabel = namespace => {
    return namespace.split('/').at(-1);
};

const parseContext = ({ attributes, children }, localToGlobalNamespace) => {
    const context = {
        id: attributes.id,
        entity: null,
        period: null,
        scenario: null,
    };

    for (const child of children) {
        const tag = getTag(child.tagName);

        const childData = getXmlNodeChildrenAsMap(child);

        if (tag === 'entity') {
            context.entity = childData.identifier;
            continue;
        }

        if (tag === 'period') {
            context.period = childData;
            continue;
        }

        const memberChild = child.children[0];
        const memberKind = getTag(memberChild.tagName);
        const [dimensionNamespace, dimensionTag] = splitNamespaceAndTag(memberChild.attributes.dimension);

        const data = {};
        if (memberKind === 'typedMember') {
            const [firstChild] = memberChild.children;
            const [memberNamespace, memberTag] = splitNamespaceAndTag(firstChild.tagName);
            data.memberNamespace = getNamespaceLabel(localToGlobalNamespace[memberNamespace]);
            data.memberTag = memberTag;
            const [value] = firstChild.children;
            data.value = value;
        } else {
            const [explicitMember] = memberChild.children;
            const [memberNamespace, memberTag] = splitNamespaceAndTag(explicitMember);
            data.memberNamespace = getNamespaceLabel(localToGlobalNamespace[memberNamespace]);
            data.memberTag = memberTag;
        }

        context.scenario = {
            memberKind,
            dimensionNamespace: getNamespaceLabel(localToGlobalNamespace[dimensionNamespace]),
            dimensionTag,
            data,
        };
    }
    
    return context;
};

const parseInstance = instanceXML => {
    const rawInstance = parseXML(instanceXML);
    
    const localToGlobalNamespace = {};
    Object.entries(rawInstance.attributes).forEach(([key, value]) => {
        const localNamespace = key.replace(/xmlns:?/, '');

        localToGlobalNamespace[localNamespace] = decodeURIComponent(value);
    });

    const schemaRef = rawInstance.children.find(child => {
        const [namespace, tag] = splitNamespaceAndTag(child.tagName);

        if (tag !== 'schemaRef') {
            return false;
        }

        if (localToGlobalNamespace[namespace] !== globalMetaNamespaces.linkbase) {
            return false;
        }

        return true;
    });

    if (!schemaRef) {
        throw new Error('Failed to find schema ref in instance');
    }

    const hrefParts = schemaRef.attributes['xlink:href'].split('/');
    const entrypointUsed = hrefParts.at(-1);
    const taxonomyID = hrefParts.at(-2);

    const instanceChildren = rawInstance.children.filter(child => {
        const [localNamespace] = splitNamespaceAndTag(child.tagName);
        return localToGlobalNamespace[localNamespace] === globalMetaNamespaces.instance;
    });

    const contexts = [];
    const units = [];

    for (const { tagName, attributes, children } of instanceChildren) {
        const kind = getTag(tagName);
        switch (kind) {
            case 'context': {
                const context = parseContext({ attributes, children }, localToGlobalNamespace);
                contexts.push(context);
                break;
            }

            case 'unit': {
                const [measure] = children;
                const [measureValue] = measure.children;

                units.push({
                    id: attributes.id,
                    measure: getTag(measureValue),
                });
                break;
            }

            default:
                throw new Error('Unknown kind from instance namespace: ' + kind);
        }
    }

    const fiscalData = [];

    for (const { tagName, attributes, children } of rawInstance.children) {
        const [namespace, tag] = splitNamespaceAndTag(tagName);
        const globaNamespace = localToGlobalNamespace[namespace];

        if (Object.values(globalMetaNamespaces).find(ns => ns === globaNamespace)) continue;

        const [firstChild] = children;
        const badAttributes = [];
        for (const key of Object.keys(attributes)) {
            if (key === 'contextRef') continue;
            if (key === 'decimals') continue;
            if (key === 'unitRef') continue;
            if (key === 'xml:lang') continue;
            if (getTag(key) === 'ancestor') continue;
            if (getTag(key) === 'version') continue;

            badAttributes.push(key);
        }

        if (badAttributes.length > 0) {
            throw new Error('Got some unknown fiscal attributes: ' + badAttributes.join(', '));
        }

        fiscalData.push({
            tag,
            namespace: getNamespaceLabel(globaNamespace),
            value: firstChild || '',
            ...attributes,
        });
    }

    return {
        entrypointUsed,
        taxonomyID,
        units,
        contexts,
        fiscalData,
    };
};

export default parseInstance;