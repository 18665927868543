import React from 'react';
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

class TextInput extends React.Component {

    constructor() {
        super();
        this.state = {value: undefined};
    }

    static propTypes = {
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        name: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        disabled: PropTypes.bool,
        placeholder: PropTypes.string,
        autoFocus: PropTypes.bool,
        min: PropTypes.bool,
        max: PropTypes.bool,
        type: PropTypes.oneOf(['text', 'password'])
    };

    static defaultProps = {
        type: 'text',
        name: undefined,
        onChange: undefined,
        autoFocus: false,
        min: undefined,
        max: undefined,
        defaultValue: '',
        placeholder: undefined,
        onBlur: undefined,
        disabled: false,
    };

    getValue() {
        return this.input.inputRef.current.value;
    }

    render() {
        return <Input
            fluid
            ref={r => this.input = r }
            {...this.props}
        />;
    }

}

export default TextInput;
