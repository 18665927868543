import client from './client';

const prefix = '/financial-dates';

function taxYearToReportingPeriod (body) {
    return client.postResource(`${prefix}/reporting-period/test`, body);
}

const financialDates = {
    taxYearToReportingPeriod,
};

export default financialDates;