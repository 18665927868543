import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import businessForms from 'shared/businessForms';

const options = businessForms.map(bf => {
    return {
        key: bf,
        text: bf,
        value: bf
    };
});

class AllowedBusinessFormsEditor extends Component {
    onSelectionChanged = (e, { value }) => {
        this.props.onChange({ target: { value } });
    };

    render () {
        const { defaultValue } = this.props;
        return (
            <Dropdown
                fluid
                multiple
                selection
                search
                options={options}
                defaultValue={defaultValue || []}
                onChange={this.onSelectionChanged}
            />
        );
    }
}

export default AllowedBusinessFormsEditor;