import React from 'react';
import PropTypes from 'prop-types';
import roles from '../../shared/roles';
import http from '../../http/accounts';
import './RoleEditor.css';
import { Grid, Checkbox, Icon, Segment, Header } from 'semantic-ui-react';

class RoleEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            roles: props.roles
        };
    }

    static propTypes = {
        /** ID of the user to update */
        userID: PropTypes.string.isRequired,
        /** CurrentRoles the users has */
        roles: PropTypes.array.isRequired
    };

    updateRole = async (id, shouldAdd) => {
        const { userID } = this.props;
        const add = [];
        const remove = [];

        if (shouldAdd) {
            add.push(id);
        } else {
            remove.push(id);
        }

        try {
            const { roles } = await http.updateRoles(userID, { add, remove });
            this.props.onChange && this.props.onChange(roles);
            this.setState({
                error: null,
                roles: roles
            });
        } catch (e) {
            this.setState({
                error: e
            });
        }
    };

    roleChangeHandler = role => {
        return (_, { checked }) => {
            this.updateRole(role, checked);
        };
    };

    renderRoleColumn = role => {
        const { roles } = this.state;
        const { name, id, icon, color, immutable } = role;
        const roleIsActive = roles.includes(id);
        const iconColor = roleIsActive ? color : 'grey';
        const textColor = roleIsActive ? 'black' : 'grey';
        return (
            <Grid.Column textAlign='center' key={id}>
                <Segment textAlign='center'>
                    <Icon name={icon} color={iconColor} size='large' />
                    <Header color={textColor} size='small'>{name}</Header>
                    <Checkbox
                        disabled={immutable}
                        defaultChecked={roleIsActive}
                        onChange={this.roleChangeHandler(id)}
                        toggle
                    />
                </Segment>
            </Grid.Column>
        );
    };

    render () {
        return (
            <Grid columns={3} padded>
                { roles.map(this.renderRoleColumn) }
            </Grid>
        );
    }
}

export default RoleEditor;
