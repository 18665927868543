import React, { Component } from 'react';
import { Loader, Header, Table } from 'semantic-ui-react';
import { getUserFiles } from 'http/fileStorage';
import AutoStamp from 'atoms/AutoStamp';
import FileLink from 'atoms/Table/TableCellFileLink';

class FileStorage extends Component {
    state = {
        files: [],
        loading: true,
    };

    componentDidMount = async () => {
        const { id } = this.props.user;
        let files = [];
        try {
            files = await getUserFiles(id);
        } catch (e) {
            console.warn(`getUserFiles failed at mount with error: ${e}`);
        }

        this.setState({
            files,
            loading: false,
        });
    };

    renderFile = ({ _id, name, mimetype, updatedAt }) => {
        return (
            <Table.Row key={_id}>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{mimetype}</Table.Cell>
                <Table.Cell>
                    <AutoStamp stamp={updatedAt}  />
                </Table.Cell>
                <FileLink fileID={_id} textAlign="center" iconSize="large" />
            </Table.Row>
        );
    };

    renderFiles = () => {
        const { files } = this.state;

        if (files.length === 0) {
            return <Header>Ingen filer fundet</Header>;
        }

        return (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Updated At</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                            Download
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{files.map(this.renderFile)}</Table.Body>
            </Table>
        );
    };

    render = () => {
        const { loading } = this.state;

        let content;
        if (loading) {
            content = <Loader active inline="centered" size="huge" />;
        } else {
            content = this.renderFiles();
        }

        return <div>{content}</div>;
    };
}

export default FileStorage;
