import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { MODEL_EDITOR_SUBDOMAIN_HOST } from './config';
import PublicModelEditor from './model-editor/PublicModelEditor';
import GlobalModal from 'shared/globalModal';
import onAppStartup from './shared/onAppStartup';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const publicModelEditorMode = window.location.host === MODEL_EDITOR_SUBDOMAIN_HOST;

onAppStartup(publicModelEditorMode);

ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
        {publicModelEditorMode ? <PublicModelEditor /> : <App />}
        <GlobalModal />
    </BrowserRouter>
);
