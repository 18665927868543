import { dataTypes } from 'model-editor/nodeMetadata';
import React from 'react';
import { useMemo } from 'react';
import { Dropdown, Table } from 'semantic-ui-react';

const AnnualRolloverEditor = ({ resource, questions, calculations, functionCalls, annualRolloverMapping, onChange }) => {
    const numberTagOptions = useMemo(() => {
        const options = [
            // questions
            ...questions.filter(question => {
                return question.type === dataTypes.NUMBER;
            }).map(({ tag }) => ({
                tag,
                icon: 'question circle',
            })),

            // calculations
            ...calculations.map(({ tag }) => ({
                tag,
                icon: 'calculator',
            })),

            // function calls
            ...functionCalls.flatMap(functionCall => {
                return Object.values(functionCall.outputMappings).map(tag => ({
                    tag,
                    icon: 'cog',
                }));
            }),
        ].map(({ tag, icon }) => ({
            key: tag,
            text: tag,
            value: tag,
            icon,
        }));

        options.sort((a, b) => {
            if (a.tag > b.tag) return 1;
            if (a.tag < b.tag) return -1;
            return 0;
        });

        return options;
    }, [questions, calculations, functionCalls]);

    if (!resource) {
        return null;
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Rulningsværdi</Table.HeaderCell>
                    <Table.HeaderCell>Tag fra ressourceknuden</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {resource?.annualRolloverValues?.map(({ id, tag }) => {
                    return (
                        <Table.Row key={id}>
                            <Table.Cell>
                                {tag}
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown
                                    clearable
                                    selection
                                    search
                                    fluid
                                    defaultValue={annualRolloverMapping[tag]}
                                    options={numberTagOptions}
                                    onChange={(_, { value }) => {
                                        onChange({
                                            ...annualRolloverMapping,
                                            [tag]: value,
                                        });
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default AnnualRolloverEditor;