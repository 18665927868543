/**
    DataManager is a class encapsulating all the methods needed to manage form data of the parent component from within a nested component.
*/
class DataManager {
    constructor(getCurrentField, updateField) {
        this.getCurrentField = getCurrentField;
        this.updateField = updateField;
    }
};

export default DataManager;
