import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dropdown, Form, Grid, Header, Icon, Input, Modal, Progress, Table } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { getMappings, getAccounts, countAccounts, upsertMappings } from 'http/trainingdata';
import { formatNumber } from 'shared/formatNumber';
import { localConfigHookGenerator } from 'shared/useLocalConfig';
import { confirm, prompt } from 'shared/globalModal';
import { TRAININGDATA_ACTIVE, SPLIT_OTHER_EXTERNAL_EXPENSES } from 'config';
import { ShortcutReel } from './Shortcut';
import { customXbrlTags } from './tagDicts';
import TagSearcher from './TagSearcher';
import MappingDropdownItem from './MappingDropdownItem';
import SimpleMappingTable from './SimpleMappingTable';
import StatDisplayer from './StatDisplayer';
import FilterLink from './FilterLink';
import Sticky from './Sticky';
import * as util from './mlUtil';

import styles from './MappingTable.module.css';

const useMappingConfig = localConfigHookGenerator('ml_mapping');

const pageSizeLo = 5;
const pageSizeHi = 50;

function MappingTable() {
    const [active] = useState(TRAININGDATA_ACTIVE);
    const [pageSize, setPageSize] = useMappingConfig('pageSize', 15);
    const [rawData, setRawData] = useState(null);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [error, setError] = useState('');
    const [dropdownID, setDropdownID] = useState('');
    const [loading, setloading] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [totalAccounts, setTotalAccounts] = useState(0);
    const [saving, setSaving] = useState(false);
    const [distinct, setDistinct] = useState({});
    const [filterModal, setFilterModal] = useState(false);
    const [changedGroups, setChangedGroups] = useState(new Set());
    const pageInputRef = useRef();
    
    // filtering
    const [filters, setFilters] = useMappingConfig('filters', {});
    const [discludeChecked, setDiscludeChecked] = useMappingConfig('discludeChecked', false);
    const [minCount, setMinCount] = useMappingConfig('minCount', null);
    const [onlyBadMappings, setOnlyBadMappings] = useMappingConfig('onlyBadMappings', false);
    const [chosenXBRLTag, setChosenXBRLTag] = useMappingConfig('chosenXBRLTag', null);
    const [chosenAccountantCode, setChosenAccountantCode] = useMappingConfig('chosenAccountantCode', null);

    // group statistics
    const totalPages = Math.ceil(data?.length / pageSize);
    const totalChecked = data.reduce((acc, cur) => acc += (cur[1].checked ? 1 : 0), 0);
    
    // account statistics
    const dataset = data.reduce((acc, cur) => acc += cur[1].count, 0);
    const effectivlyMapped = data.filter(x => x[1].checked).reduce((acc, cur) => acc += cur[1].count, 0);

    const canGoBack = page !== 0;
    const canGoForward = page + 1 !== totalPages;
    const canSave = changedGroups.size > 0 && !saving && !loading;

    const updatePage = useCallback(newPage => {
        if (isNaN(newPage)) {
            return;
        }

        if (newPage < 0) {
            return;
        }

        if (newPage >= totalPages) {
            return;
        }

        setPage(newPage);
        pageInputRef.current.inputRef.current.value = newPage + 1
    }, [totalPages]);

    const changePageSize = useCallback(newPageSize => {
        if (newPageSize === null) {
            return;
        }

        if (isNaN(newPageSize)) {
            return;
        }

        setPageSize(Math.min(Math.max(pageSizeLo, Number(newPageSize)), pageSizeHi));
    }, [setPageSize]);

    const updatePageSize = useCallback(async () => {
        const newPageSize = await prompt({
            header: `Input a new page size`,
            placeholder: `Input number between ${pageSizeLo} and ${pageSizeHi}...`,
            value: pageSize,
        });

        changePageSize(newPageSize);
    }, [pageSize, changePageSize]);

    const jumpUnchecked = useCallback((currentPage, direction) => {
        let i = (currentPage * pageSize) + direction * pageSize;
        let found = false;

        while (i >= 0 && i < data.length) {
            const account = data[i];

            if (!account[1].checked) {
                found = true;
                break;    
            }

            i += direction;
        }

        if (!found) {
            return;
        }

        updatePage(Math.floor(i / pageSize));
    }, [pageSize, updatePage, data]);

    const jumpNextUnchecked = useCallback(currentPage => {
        jumpUnchecked(currentPage, 1);
    }, [jumpUnchecked]);

    const jumpPrevUnchecked = useCallback(currentPage => {
        jumpUnchecked(currentPage, -1);
    }, [jumpUnchecked]);

    const onSave = useCallback(async () => {
        if (!canSave) {
            return;
        }
        setSaving(true);

        const allMappingGroups = new Map(rawData);
        const changedButUnchecked = [];
        const mappingsToUpsert = [];

        for (let changedGroup of changedGroups) {
            const group = allMappingGroups.get(changedGroup);

            if (!group.checked) {
                // changed groups that are unchecked will cause a warning to appear
                changedButUnchecked.push(changedGroup);
                continue;
            }

            const mapping = {
                ...util.parseAccount(changedGroup),
                XBRLTag: group.chosenXBRL || util.getMostMappedTag(group.mappedXBRL),
                accountantCode: group.chosenRevcode || util.getMostMappedTag(group.mappedRevcode),
            };

            mappingsToUpsert.push(mapping);
        }
        
        if (changedButUnchecked.length > 0) {
            const confirmContent = (
                <>
                    <Header>
                        <Icon name='warning sign' color='orange' />
                        <Header.Content>Unchecked mappings w/ changes</Header.Content>
                    </Header>
                    <div>
                        <SimpleMappingTable groups={changedButUnchecked.map(util.parseAccount)} />
                        These mapping changes <u>will not</u> be saved, unless you go back and check them off.
                        Do you want to continue saving anyway?
                    </div>
                </>
            );

            const continueAnyway = await confirm({
                content: confirmContent,
            });

            if (!continueAnyway) {
                setSaving(false);
                return;
            }
        }

        try {
            await upsertMappings(mappingsToUpsert);

            setChangedGroups(new Set(changedButUnchecked));
            toast.success('Mapping was saved!');
        } catch {
            toast.error('Failed to save mapping...');
        }

        setSaving(false);
    }, [rawData, changedGroups, canSave]);

    // handle keyboard shortcuts
    useEffect(() => {
        const onKeyDown = e => {
            const left = 37;
            const up = 38;
            const right = 39;
            const down = 40;

            // control & command
            if (e.ctrlKey || e.metaKey) {
                const shortcutHandler = {
                    [left]:             () => updatePage(page - 1),
                    [up]:               () => changePageSize(pageSize + 1),
                    [right]:            () => updatePage(page + 1),
                    [down]:             () => changePageSize(pageSize - 1),
                    ['F'.charCodeAt()]: () => setFilterModal(!filterModal),
                    ['S'.charCodeAt()]: () => onSave(),
                }[e.keyCode];

                if (!shortcutHandler) {
                    return;
                }

                e.preventDefault();
                shortcutHandler();
            }

            if (e.altKey) {
                e.preventDefault();
                switch (e.keyCode) {
                    case left:
                        jumpPrevUnchecked(page);
                        return;
                    case right:
                        jumpNextUnchecked(page);
                        return;
                    default:
                        return;
                }
            }
        };

        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [page, updatePage, onSave, filterModal, saving, jumpNextUnchecked, jumpPrevUnchecked, changePageSize, pageSize]);

    useEffect(() => {
        const doLoad = async () => {
            try {
                const accountTypes = ['Drift', 'Balance'];

                const [mappings, totalAccounts] = await Promise.all([
                    getMappings(),
                    countAccounts(accountTypes),
                ]);

                setTotalAccounts(totalAccounts);

                // prepare training data w/ mappings
                const trainingData = util.initTrainingData(mappings);

                // keep track of desired distinct account values
                const distinct = {};

                // fetch accounts using pagination
                const pageSize = 15000;
                let totalFetched = 0;
                let lastID;

                while (totalFetched < totalAccounts) {
                    // fetch accounts && add to training data
                    const accounts = await getAccounts(accountTypes, pageSize, lastID);
                    util.addAccountsToTrainingData(trainingData, accounts);

                    // collect distinct account properties
                    for (let account of accounts) {
                        util.distinctProperties.forEach(dp => {
                            distinct[dp] = distinct[dp] || new Set();
                            distinct[dp].add(account[dp]);
                        });
                    }

                    // update progress
                    totalFetched += accounts.length;
                    setLoadingProgress(totalFetched / totalAccounts * 100);

                    // update pagination state
                    lastID = accounts.at(-1).internalID;
                }
                
                setDistinct(distinct);
                setRawData(Object.entries(trainingData));
            } catch (e) {
                setError(e.message);
            }
            
            setloading(false);
        };

        active && doLoad();
    }, [active]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const updateData = (idx, { newXBRL, newRevcode }) => {
        const newData = [...data];
        const newChangedGroups = new Set(changedGroups);
        const [accountID, accountInQuestion] = newData[idx];

        [
            [newXBRL, 'chosenXBRL'],
            [newRevcode, 'chosenRevcode'],
        ].forEach(([newVal, chosenProp]) => {
            if (!newVal) {
                return;
            }

            // flag the account group as changed
            newChangedGroups.add(accountID);

            // update account
            accountInQuestion[chosenProp] = newVal;
        });

        setChangedGroups(newChangedGroups);
        setData(newData);
    };

    const updateFilters = (prop, value) => {
        const newFilters = { ...filters };
        newFilters[prop] = value;
        setFilters(newFilters);
    };

    const updateHeaderFilter = val => updateFilters('group', val);
    const updateTextFilter = val => updateFilters('text', val);

    const hasFilters = () => {
        return (
            Object.values(filters).some(x => !!x) ||
            discludeChecked ||
            onlyBadMappings ||
            minCount !== null
        );
    };

    const clearFilters = () => {
        setFilters({});
        setDiscludeChecked(false);
        setOnlyBadMappings(false);
        setMinCount(null);
    };

    const toggleChecked = idx => {
        const newData = [...data];
        const newChangedGroups = new Set(changedGroups);

        const [groupID, groupStats] = newData[idx];

        // flip "checked" flag on account
        groupStats.checked = !groupStats.checked;

        // flag the group as changed
        newChangedGroups.add(groupID);

        setChangedGroups(newChangedGroups);
        setData(newData);
    };

    useEffect(() => {
        if (!rawData) {
            return;
        } 

        const sortedAndFiltered = util.doFilterSortAccounts({
            rawData,
            filters,
            discludeChecked,
            onlyBadMappings,
            minCount,
            chosenXBRLTag,
            chosenAccountantCode,
        });

        if (Object.keys(filters).length > 0) {
            updatePage(0);
        }

        setData(sortedAndFiltered);
    }, [rawData, filters, discludeChecked, onlyBadMappings, minCount, chosenXBRLTag, chosenAccountantCode, updatePage]);

    const getEmptyRow = children => {
        return (
            <Table.Row textAlign='center'>
                <Table.Cell colSpan={10}>
                    {children}
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderPage = () => {
        if (!active) {
            const prodLink = `https://admin.digitalrevisor.nu${window.location.pathname}`
            return getEmptyRow(
                <span>
                    The trainingdata module is not available in this environment.
                    <br />
                    Try visiting the module in our <a href={prodLink}>production environment</a>
                </span>
            );
        }

        if (loading) {
            const accountsLoaded = Math.floor(totalAccounts / 100 * loadingProgress);
            return getEmptyRow(
                <div style={{ width: '50%', display: 'inline-block' }}>
                    <Progress
                        active
                        indicating
                        progress='percent'
                        percent={Math.floor(loadingProgress)}
                        content={`${formatNumber(accountsLoaded)} of ${formatNumber(totalAccounts)} accounts loaded...`}
                    />
                </div>
            );
        }
    
        if (error) {
            return (
                <Table.Row textAlign='center'>
                    <Table.Cell colSpan={10}>
                        <Icon name='warning sign' color='orange' size='big' circular />
                        <Header>An error occurred</Header>
                        <pre>Technical error: {error}</pre>
                    </Table.Cell>
                </Table.Row>
            );
        }

        const startIndex = page * pageSize;

        const out = [];

        const renderTagDropdown = ({ id, tags, defaultValue, onChange, tagValidator, allowedTags }) => {
            // sort tags by occurance frequency
            const sortedTags = Object.entries(tags).sort((a, b) => {
                return b[1] - a[1];
            });

            const [[mostFrequentTag]] = sortedTags;
            const selectedTag = defaultValue || mostFrequentTag;

            const otherTags = [];
            if (SPLIT_OTHER_EXTERNAL_EXPENSES && (selectedTag === 'OtherExternalExpenses' || mostFrequentTag === 'OtherExternalExpenses')) {
                if (!sortedTags.find(([tag]) => tag === 'PropertyCost')) {
                    otherTags.push(['PropertyCost', 0]);
                }
                otherTags.push(...customXbrlTags.map(tag => [tag, 0]));
            }

            const total = sortedTags.reduce((acc, cur) => acc += cur[1], 0);
            const onlyOne = Object.keys(tags).length === 1;
            const selectedTagValid = tagValidator(selectedTag);
            const mappingCount = tags[selectedTag] || 0;
            const selectedTagOccuranceFrequency = `${(mappingCount / total * 100).toFixed(2)} %`;
            
            const renderDropdownIcon = () => {
                if (!selectedTagValid) {
                    return <Icon name='warning sign' color='red' />;
                }

                if (onlyOne) {
                    return <Icon name='check circle' color='green' />;
                }

                return <Icon name='caret down' color='black' />;
            };

            const handleChange = value => {
                if (value === undefined) {
                    return;
                }

                onChange(value);
                setDropdownID('')
            };

            const handleBlur = e => {
                if (e.relatedTarget) {
                    return;
                }
                setDropdownID('');
            };

            const open = id === dropdownID;

            return (
                <div style={{ background: 'white' }} onBlur={handleBlur}>
                    <Dropdown
                        open={open}
                        className='icon basic right labeled'
                        pointing='top right'
                        onChange={(_, { value }) => handleChange(value)}
                        text={util.trunc(`${selectedTagOccuranceFrequency} ∙ ${selectedTag}`)}
                        icon={renderDropdownIcon()}
                        onBlur={handleBlur}
                        onOpen={() => setDropdownID(id)}
                        fluid
                        labeled
                        button
                    >
                        <Dropdown.Menu >
                            <Dropdown.Header icon='tag' content={`${sortedTags.length} used tag${sortedTags.length !== 1 ? 's' : ''}`}/>
                            <Dropdown.Divider />
                            {
                                [...sortedTags, ...otherTags].map(([tag, count]) => {
                                    const tagIsValid = tagValidator(tag);
                                    const pctMapped = (count / total * 100).toFixed(2);
                                    const tooltip = (
                                        <span>
                                            {formatNumber(count)} cases
                                            &nbsp;∙&nbsp;
                                            {pctMapped} %
                                            {
                                                !tagIsValid &&
                                                <span>&nbsp;∙&nbsp;Unused tag <Icon name='warning sign' color='red' /></span>
                                            }
                                        </span>
                                    );
                                    return (
                                        <MappingDropdownItem
                                            key={tag}
                                            text={tag}
                                            tooltip={tooltip}
                                            active={tag === selectedTag}
                                            disabled={!tagIsValid}
                                            onClick={() => onChange(tag)}
                                        />
                                    );
                                })
                            }
                            <Dropdown.Divider />
                            <Dropdown.Item className={styles.searchOption}>
                                <TagSearcher
                                    open={open}
                                    allowedTags={allowedTags}
                                    onPick={pick => handleChange(pick)}
                                />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            );
        };

        const renderFilterLink = (filterProp, text) => {
            const isActive = filters[filterProp]?.toLowerCase() === text?.toLowerCase();
            return <FilterLink
                text={text}
                active={isActive}
                onClick={() => updateFilters(filterProp, isActive ? '' : text)}
            />;
        };

        for (let i = startIndex; i < data.length; i++) {
            if (out.length === pageSize) {
                break;
            }

            const [id, { chosenXBRL, chosenRevcode, count, mappedXBRL, mappedRevcode, checked }] = data[i];

            const [selectedXBRL, selectedRevcode] = [
                [chosenXBRL, mappedXBRL],
                [chosenRevcode, mappedRevcode],
            ].map(([chosen, allTags]) => chosen || util.getMostMappedTag(allTags));

            const {
                fiscalClass,
                group,
                vatcode,
                accountType,
                text,
                erp,
            } = util.parseAccount(id);

            const isA = fiscalClass === 'A';

            // do row styling (based on correct mapping && if row checked)
            let positive;
            let error;

            if (!util.xbrlInModel(selectedXBRL, isA) || !util.revCodeInModel(selectedRevcode, isA)) {
                error = true;
            } else if (checked) {
                positive = true;
            }

            out.push(
                <Table.Row key={id} positive={positive} error={error} onClick={e => e.altKey && !error && toggleChecked(i)}>
                    <Table.Cell width={1} textAlign='left'>{renderFilterLink('fiscalClass', fiscalClass)}</Table.Cell>
                    <Table.Cell width={1} textAlign='left'>{renderFilterLink('vatcode', vatcode)}</Table.Cell>
                    <Table.Cell width={1} textAlign='left'>{renderFilterLink('accountType', accountType)}</Table.Cell>
                    <Table.Cell width={1} textAlign='left'>{renderFilterLink('erp', erp)}</Table.Cell>
                    <Table.Cell width={2} textAlign='left'>{renderFilterLink('group', group)}</Table.Cell>
                    <Table.Cell width={2} textAlign='left'>{renderFilterLink('text', text)}</Table.Cell>
                    <Table.Cell width={1} textAlign='center'>{formatNumber(count)}</Table.Cell>
                    <Table.Cell width={3} textAlign='center'>
                        {renderTagDropdown({
                            id: `xbrl.${id}`,
                            tags: mappedXBRL.data,
                            defaultValue: selectedXBRL, 
                            onChange: v => updateData(i, { newXBRL: v }),
                            tagValidator: tag => util.xbrlInModel(tag, isA),
                            allowedTags: util.getAllowedXBRLTags(isA),
                        })}
                    </Table.Cell>
                    <Table.Cell width={3} textAlign='center'>
                        {renderTagDropdown({
                            id: `rev.${id}`,
                            tags: mappedRevcode.data,
                            defaultValue: selectedRevcode, 
                            onChange: v => updateData(i, { newRevcode: v }),
                            tagValidator: tag => util.revCodeInModel(tag, isA),
                            allowedTags: util.getAllowedRevCodes(isA),
                        })}
                    </Table.Cell>
                    <Table.Cell width={1} textAlign='center'>
                        <Icon
                            name={error ? 'warning sign' : 'check'}
                            circular
                            style={{ opacity: !checked && 0.25 }}
                            color={checked ? 'green' : undefined}
                            link={!error}
                            onClick={() => !error && toggleChecked(i)}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        }

        return out;
    };

    return (
        <>
            <Sticky top style={{ paddingRight: '20px' }}>
                <Grid columns={2}>
                    <Grid.Column>
                        <Button
                            basic
                            disabled={loading}
                            size='small'
                            onClick={() => setFilterModal(true)}
                            content='Toggle filters'
                            color='blue'
                            icon='filter'
                            labelPosition='left'
                        />
                        <Button
                            basic
                            size='small'
                            icon='trash'
                            content='Clear all filters'
                            color='blue'
                            disabled={!hasFilters()}
                            onClick={clearFilters}
                            labelPosition='left'
                        />
                        <Button
                            color='blue'
                            size='small'
                            disabled={data.length === 0 || !canGoBack}
                            onClick={() => updatePage(page - 1)}
                            icon='arrow left'
                            style={{ margin: '0' }}
                        />
                        <Input
                            size='small'
                            key={loading}
                            ref={pageInputRef}
                            className={styles.pageInput}
                            defaultValue={page + 1}
                            onChange={(_, { value }) => updatePage(Number(value) - 1)}
                        />
                        <Button
                            color='blue'
                            size='small'
                            disabled={data.length === 0 || !canGoForward}
                            onClick={() => updatePage(page + 1)}
                            icon='arrow right'
                            style={{ margin: '0' }}
                        />
                        &nbsp;
                        <span>
                            {
                                data.length > 0 &&
                                <>
                                    &nbsp;{totalPages} page{totalPages !== 1 && 's'} in total
                                    &nbsp;∙&nbsp;
                                    Page size is <span className={styles.link} onClick={updatePageSize}>
                                        {pageSize} <Icon name='edit' />
                                    </span>                                    
                                </>
                            }
                        </span>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <ShortcutReel />
                        <Button
                            size='small'
                            disabled={!canSave}
                            onClick={onSave}
                            color='blue'
                            content='Save progress'
                            labelPosition='left'
                            icon={<Icon name={saving ? 'spinner' : 'save'} loading={saving} />}
                        />
                    </Grid.Column>
                </Grid>
            </Sticky>
            <Table striped style={{ margin: '0' }}>
                <Table.Header style={{ position: 'sticky', top: '48px', zIndex: 1 }}>
                    <Table.Row>
                        {[
                            'A/B',
                            'Vat',
                            'Type',
                            'ERP',
                            'Header',
                            'Text',
                            'Count',
                            'XBRL tag',
                            'Accountant code',
                            '',
                        ].map((header, idx) => (
                            <Table.HeaderCell
                                textAlign={idx <= 5 ? 'left' : 'center'}
                                content={header}
                            />
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {renderPage()}
                </Table.Body>
            </Table>
            {
                data.length ?
                <Sticky bottom>
                    <StatDisplayer stats={[
                        <span><strong>{formatNumber(dataset)}</strong> accounts total</span>,
                        <span><strong>{formatNumber(data.length)}</strong> groups total</span>,
                        <span><strong>{formatNumber(totalChecked)}</strong> groups mapped</span>,
                        <span><strong>{formatNumber(effectivlyMapped)}</strong> accounts mapped</span>,
                        <span><strong>{(totalChecked / data.length * 100).toFixed(2)} %</strong> of groups mapped</span>,
                        <span><strong>{(effectivlyMapped / dataset * 100).toFixed(2)} %</strong> of accounts mapped</span>
                    ]} />                  
                </Sticky> :
                undefined
            }
            
            <Modal open={filterModal} onClose={() => setFilterModal(false)}>
                <Modal.Header>
                    <Header icon='filter' content='Toggle filters' />
                </Modal.Header>
                <Modal.Content>
                    <Form style={{ width: '100%' }}>
                        {
                            util.distinctProperties.map((prop) => {
                                const description = util.accountPropertyDescriptions[prop];
                                return (
                                    <Form.Field key={prop + filters[prop]}>
                                        <label>
                                            {description}
                                        </label>
                                        <Dropdown
                                            selection
                                            clearable
                                            placeholder={`Filter by ${description}...`}
                                            defaultValue={filters[prop]}
                                            onChange={(_, { value }) => updateFilters(prop, value)}
                                            options={[...(distinct[prop] || new Set())].map(value => ({
                                                text: value,
                                                value,
                                            }))}
                                        />
                                    </Form.Field>
                                );
                            })
                        }
                        <Form.Field>
                            <label>XBRL tag</label>
                            <Input
                                defaultValue={chosenXBRLTag}
                                placeholder='Filter by chosen XBRL tag...'
                                icon={
                                    chosenXBRLTag ?
                                    <Icon name='x' link onClick={() => setChosenXBRLTag(null)} /> :
                                    undefined
                                }
                                onBlur={e => {
                                    setChosenXBRLTag(e.target.value);
                                }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Accountant code</label>
                            <Input
                                defaultValue={chosenAccountantCode}
                                placeholder='Filter by chosen accountant code...'
                                icon={
                                    chosenAccountantCode ?
                                    <Icon name='x' link onClick={() => setChosenAccountantCode(null)} /> :
                                    undefined
                                }
                                onBlur={e => {
                                    setChosenAccountantCode(e.target.value);
                                }}
                            />
                        </Form.Field>
                        {
                            <Form.Group widths='equal'>
                                {[
                                    ['Header', 'group', updateHeaderFilter],
                                    ['Text', 'text', updateTextFilter],
                                ].map(([label, prop, onChange], i) => {
                                    return (
                                        <Form.Field key={prop + filters[prop]}>
                                            <label>{util.accountPropertyDescriptions[prop]}</label>
                                            <Input
                                                placeholder={`Filter by ${label}...`}
                                                defaultValue={filters[prop]}
                                                onBlur={e => onChange(e.target.value)}
                                                icon={
                                                    filters[prop] ?
                                                    <Icon name='x' link onClick={() => onChange('')} /> :
                                                    undefined
                                                }
                                            />
                                            
                                        </Form.Field>
                                    );
                                })}
                            </Form.Group>
                        }
                        <Form.Field>
                            <label>Minimum count</label>
                            <Input
                                type='number'
                                defaultValue={minCount}
                                key={minCount}
                                icon={
                                    minCount !== null ?
                                    <Icon name='x' link onClick={() => setMinCount(null)} /> :
                                    undefined
                                }
                                onBlur={e => {
                                    let v = Number(e.target.value);
                                    if (isNaN(v)) {
                                        return;
                                    }

                                    if (!v) {
                                        return;
                                    }

                                    setMinCount(Math.max(v, 0));
                                }}
                            />
                        </Form.Field>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Hide checked groups?</label>
                                <Checkbox
                                    toggle
                                    checked={discludeChecked} 
                                    onClick={() => setDiscludeChecked(!discludeChecked)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Show only accounts w/ unused mappings?</label>
                                <Checkbox
                                    toggle
                                    checked={onlyBadMappings} 
                                    onClick={() => setOnlyBadMappings(!onlyBadMappings)}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button 
                        basic
                        icon='trash'
                        content='Clear all filters'
                        color='blue'
                        disabled={!hasFilters()}
                        onClick={clearFilters}
                    />
                    <Button
                        onClick={() => setFilterModal(false)}
                        content='Close'
                        icon='x'
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default MappingTable;
