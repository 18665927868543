import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container, Grid, Header, Icon, Loader, Segment, Modal, Form, Input, Table } from 'semantic-ui-react';
import { getFileIcon, formatFileSize } from 'shared/files';
import { getFileMetadata } from 'http/fileStorage';
import { getPipeline, getTask, moveTask, flagTaskAsSeen } from 'http/internalTasks';
import accounts from 'http/accounts';
import email from 'http/email';
import AutoStamp from 'atoms/AutoStamp';
import TextArea from 'atoms/TextArea/TextArea';
import { APP_LOCATION } from '../../config';
import FileLink from '../../atoms/Table/TableCellFileLink'


const FileTable = ({ files }) => {
    const renderTableHeader = () => {
        if (files.length === 0) {
            return null;
        }

        return (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Navn
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Størrelse
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Tidspunkt for upload
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
        );
    };

    const renderTableBody = () => {
        return <Table.Body>
            {files.map(file => (
                <Table.Row key={file.id}>
                    <Table.Cell>
                        <Icon name={getFileIcon(file, true)} />
                        {file.name}
                    </Table.Cell>
                    <Table.Cell>{formatFileSize(file)}</Table.Cell>
                    <Table.Cell>
                        <AutoStamp stamp={file.updatedAt} />
                    </Table.Cell>
                    <FileLink fileID={file.id} textAlign='right' />
                </Table.Row>
            ))}
        </Table.Body>;
    };

    return (
        <Table basic='very'>
            {renderTableHeader()}
            {renderTableBody()}
        </Table>
    );
};

const UserMessageModal = ({ open, onSend, onCancel }) => {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    
    const doSend = () => {
        if (!title) {
            return toast.warn('Indtast en titel');
        }

        if (!body) {
            return toast.warn('Indtast en besked');
        }

        onSend(title, body);
    };

    return (
        <Modal open={open} onClose={onCancel}>
            <Modal.Header>Send besked til bruger</Modal.Header>
            <Modal.Content>
                <Form style={{ width: '100%' }}>
                    <Form.Field>
                        <label>Titel</label>
                        <Input
                            defaultValue={title}
                            onChange={(_, { value }) => setTitle(value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Besked</label>
                        <TextArea
                            defaultValue={body}
                            onChange={e => setBody(e.target.value)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel}>Annuller</Button>
                <Button onClick={doSend} primary>Send</Button>
            </Modal.Actions>
        </Modal>
    );
};

const TasksDetail = () => {
    const [task, setTask] = useState(null);
    const [pipeline, setPipeline] = useState(null);
    const [user, setUser] = useState(null);
    const [files, setFiles] = useState([]);
    const [messageModal, setMessageModal] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        const doLoadTask = async () => {
            setLoading(true);

            const taskResp = await getTask(params.id);

            const [filesMeta, pipeline, user] = await Promise.all([
                // get file metadata,
                Promise.all(taskResp.files.map(fid => {
                    return getFileMetadata(fid);
                })),

                // get pipeline
                getPipeline(taskResp.pipelineID),

                // get user
                accounts.getAccountByID(taskResp.user),

                // flag task as seen
                flagTaskAsSeen(params.id),
            ]);

            setUser(user);
            setPipeline(pipeline);
            setFiles(filesMeta);
            setTask(taskResp);
            setLoading(false);
        };
        doLoadTask();
    }, [params.id]);

    const markTaskAsDone = async () => {
        const { swimlanes } = pipeline;
        const lastSwimlane = swimlanes[swimlanes.length - 1];

        // move task to last swimlane
        await moveTask(params.id, lastSwimlane.id, 0);

        // update UI
        task.done = true;
        setTask({ ...task });
    };

    const sendMessageToUser = async (title, body) => {
        setMessageModal(false);
        setSendingMessage(true);

        // fetch user
        const user = await accounts.getAccountByID(task.user);
      
        // send e-mail
        await email.sendServiceEmail({
            receiver: user.email,
            subject: title,
            body: body,
            link: {
                text: 'Gå til Digital Revisor',
                href: `${APP_LOCATION}${task.appPath}`,
            },
        });

        setSendingMessage(false);
        toast.success('Beskeden blev sendt');
    };

    const renderHeader = () => {
        const { done, appPath } = task;

        const impersonationLink = `${APP_LOCATION}/impersonation?impuid=${task.user}&redirect=${encodeURIComponent(appPath)}`;

        return (
            <Segment style={{ position: 'sticky', top: '0', zIndex: '1' }} raised>
                <Grid columns={2} verticalAlign='middle'>
                    <Grid.Column>
                        <Header>{pipeline.title} ∙ {user.displayName}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        {/* "Go to app" button  */}
                        <Button
                            onClick={() => window.open(impersonationLink, '_blank')}
                            content='Gå til app'
                            icon='external'
                        />

                        {/* "Send message" button */}
                        <Button
                            onClick={() => setMessageModal(true)}
                            content={sendingMessage ? 'Sender... ' : 'Send en besked'}
                            icon={
                                <Icon
                                    name={sendingMessage ? 'spinner' : 'mail'}
                                    loading={sendingMessage}
                                />
                            }
                            disabled={sendingMessage}
                        />

                        {/* "Mark as done" button */}
                        <Button
                            primary
                            onClick={markTaskAsDone}
                            disabled={done}
                            content={done ? 'Opgave udført' : 'Markér som udført'}
                            icon={done ? 'check circle' : undefined}
                        />
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    };

    const renderDescription = () => {
        if (!task.description) {
            return null;
        }

        return <Segment>{task.description}</Segment>;
    };

    const renderFiles = () => {
        if (files.length === 0) {
            return null;
        }

        return (
            <Segment>
                <Header>Vedhæftede filer</Header>
                <FileTable files={files} />
            </Segment>
        );
    };

    const renderContent = () => {
        if (loading) {
            return <Loader inline='centered' active />;
        }

        return (
            <div>
                {renderHeader()}
                {renderDescription()}
            	{renderFiles()}
            </div>
        );
    };

    return (
        <Container>
            {renderContent()}
            <UserMessageModal
                open={messageModal}
                onCancel={() => setMessageModal(false)}
                onSend={sendMessageToUser}
            />
        </Container>
    );
};

export default TasksDetail;