import React, { Component } from 'react';
import { Input, Label, Icon } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';

import styles from './DateTimePicker.module.css';

class DateTimePicker extends Component {
    datepickerRef = React.createRef();

    constructor(props) {
        super(props);
    }

    compareDateTimes = (dateTime1, dateTime2) => {
        return [
            'FullYear',
            'Month',
            'Date',
            'Hours',
            'Minutes'
        ].map(f => {
            const fn = `get${f}`;
            return dateTime1[fn]() === dateTime2[fn]()
        }).every(x => x);
    };

    onChange = dateTime => {
        const { onChange } = this.props;

        const unixDateTime = dateTime ? dateTime.getTime() : null;
        onChange(unixDateTime);
    };

    dateTimeIconClicked = () => this.datepickerRef.current?.input?.select();

    renderInputField = () => {
        const { leftAttachment, fluid } = this.props;
        return (
            <Input labelPosition='right' fluid={fluid}>
                { Boolean(leftAttachment) && leftAttachment }
                <input />
                <Label onClick={this.dateTimeIconClicked}>
                    <Icon name='calendar alternate' fitted />
                </Label>
            </Input>
        );
    };

    getValue = () => {
        const { value } = this.props;
        if(!value) {
            return undefined;
        }

        const date = new Date(value);
        // Weird case: check if `value` is Jan 1st, 1970 in which case treat it as not a valid date
        if(date.getTime() === 0) {
            return undefined;
        }

        return date;
    };

    render () {
        const { timeIntervals, disabled, isClearable = false, minDate, maxDate, placeholder } = this.props;
        const value = this.getValue();
        return (
            <ReactDatePicker
                customInput={this.renderInputField()}
                onChange={this.onChange}
                wrapperClassName={styles.wrapper}
                ref={this.datepickerRef}
                selected={value && value}
                disabled={disabled}
                minDate={minDate}
                maxDate={maxDate}
                placeholderText={placeholder}
                dropdownMode='select'
                dateFormat='dd/MM/yyyy hh:mm aa'
                popperPlacement='bottom'
                disabledKeyboardNavigation
                showYearDropdown
                fixedHeight
                showTimeSelect={true}
                timeIntervals={timeIntervals}
                isClearable={!disabled && isClearable}
                clearButtonClassName={styles['react-datepicker__clear-button']}
            />
        );
    }
}

export default DateTimePicker;
