import React from 'react';
import uuid from 'uuid/v4';
import { useState } from 'react';
import { Button, Checkbox, Grid, Icon, Input, Menu, Segment } from 'semantic-ui-react';
import TextSwitchCase from './TextSwitchCase';
import InputSection, { InputSectionRow } from './InputSection'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { consumeQueryParam } from 'shared/queryParams';

const TextVariationsEditor = ({ metadata, suggestions, calculationFunctions, textVariations, setTextVariations }) => {
    const [selectedIdx, setSelectedIdx] = useState(() => {
        const preselectedTag = consumeQueryParam('tag');
        if (!preselectedTag) return 0;

        return textVariations.findIndex(variation => variation.tag === preselectedTag);
    });
    const [justMounted, setJustMounted] = useState(true);
    const [query, setQuery] = useState('');

    const mutateSelectedVariation = mutator => {
        const variationsCopy = [...textVariations];
        mutator(variationsCopy[selectedIdx]);
        setTextVariations(variationsCopy);
    };

    useEffect(() => {
        setJustMounted(false);
    }, []);

    const variationsToShow = useMemo(() => {
        const withOriginalIndex = textVariations.map((variation, idx) => ({
            ...variation,
            idx,
        }));

        return withOriginalIndex.filter(textVariation => {
            if (!query) {
                return true;
            }

            const lcQuery = query.toLowerCase();

            return textVariation.tag.toLowerCase().includes(lcQuery);
        });
    }, [query, textVariations]);

    const renderSelectedVariation = () => {
        const selectedVariation = textVariations[selectedIdx];
        if (!selectedVariation) {
            return null;
        }

        return (
            <>
                <Segment key={selectedVariation.tag + selectedIdx}>
                    <Checkbox
                        defaultChecked={selectedVariation.isEnumeration}
                        onChange={(_, { checked }) => mutateSelectedVariation(v => v.isEnumeration = checked)}
                        label='Is enumeration?'
                        style={{ marginRight: '1em' }}
                    />
                    <Checkbox
                        defaultChecked={selectedVariation.capitalize}
                        onChange={(_, { checked }) => mutateSelectedVariation(v => v.capitalize = checked)}
                        label='Capitalize result?'
                    />
                </Segment>
                <TextSwitchCase
                    suggestions={suggestions}
                    calculationFunctions={calculationFunctions}
                    supportedLanguages={metadata.supportedLanguages}
                    cases={selectedVariation.cases}
                    onChange={cases => mutateSelectedVariation(v => v.cases = cases)}
                />
            </>
        );
    };

    return (
        <InputSection>
            <InputSectionRow>
                <Grid columns={2}>
                    <Grid.Column width={3}>
                        <Input icon='search' fluid iconPosition='left' placeholder='Filter...' onChange={(_, { value }) => setQuery(value)} />             
                        <Menu vertical fluid>
                            {variationsToShow.map(({ id, tag, idx }) => {
                                const active = idx === selectedIdx;
                                return <Menu.Item
                                    key={id}
                                    link={!active}
                                    active={active}
                                    onClick={() => setSelectedIdx(idx)}
                                    content={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <input
                                                    autoFocus={!justMounted}
                                                    disabled={!active}
                                                    defaultValue={tag}
                                                    placeholder='Type custom tag here...'
                                                    onChange={e => mutateSelectedVariation(v => v.tag = e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                        background: 'transparent',
                                                        border: 'none',
                                                        outline: 'none',
                                                        pointerEvents: active ? 'initial' : 'none',
                                                        fontStyle: tag ? 'initial' : 'italic',
                                                    }}
                                                />
                                            </div>
                                            {active && <Icon
                                                name='x'
                                                color='red'
                                                link
                                                onClick={() => setTextVariations(textVariations.filter(tv => tv.id !== id))}
                                            />}
                                        </div>
                                    }
                                />;
                            })}
                            <Menu.Item>
                                <Button
                                    fluid
                                    basic
                                    content='New'
                                    icon='plus'
                                    onClick={() => {
                                        setSelectedIdx(textVariations.length);
                                        setTextVariations([
                                            ...textVariations,
                                            {
                                                id: uuid(),
                                                tag: '',
                                                cases: [],
                                            },
                                        ]);
                                    }}
                                />
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        {renderSelectedVariation()}
                    </Grid.Column>
                </Grid>
            </InputSectionRow>
        </InputSection>
    );
};

export default TextVariationsEditor;