import React from 'react';
import NodeDataView from "./NodeDataView";

class NodeDataProductView extends React.Component {
    
    getView() {
        let view = null;
        if (this.data && this.data.getView) {
            view = this.data.getView();
        }
        return view;
    }


    render() {
        const {modelId, node} = this.props;
        return (<NodeDataView ref={data => this.data = data} node={node} modelId={modelId}/>);
    }

}

export default NodeDataProductView;