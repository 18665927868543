import _ from 'lodash';
import { ACTIVITY_TYPES } from 'pages/Accounts/ReportingEntities/detail/ActivityList/activityTypes';
import { daysBetween } from './dateTimeFormatting';
import { roleMap } from './roles';

class User {
    constructor (userData) {
        if (userData) {
            Object.assign(this, userData);
        }
    }

    /**
     * Unmarshals user data into an instance of User
     * @param {*} userData
     * @returns {User}
     */
    static unmarshalUser (userData) {
        return Object.assign(new User(), userData);
    }

    /**
     * Unmarshals a list of user data into a list of instances of User
     * @param {*[]} userList 
     * @returns {User[]}
     */
    static unmarshalUserList (userList) {
        return userList.map(User.unmarshalUser);
    }

    getID () {
        return this._id || this.id
    }

    hasRole (roleToCheck) {
        return this.roles && this.roles.includes(roleToCheck);
    }
    
    isAdmin () {
        return this.hasRole('admin');
    }
    
    isPrivat () {
        return this.hasRole('privat');
    }
    
    isErhverv () {
        return this.hasRole('erhverv');
    }

    isAccountant () {
        return this.hasRole('revisor');
    }

    isAcademy() {
        return this.hasRole('akademi');
    }
    
    isClient () {
        return !!this.creator;
    }

    getIcon = () => {
        const { creator } = this;

        if (this.firstname === 'Anonym') {
            return 'spy';
        }

        if (creator) {
            return 'user';
        }

        if (this.isAcademy()) {
            return roleMap.akademi.icon;
        }

        if (this.isAccountant()) {
            return roleMap.revisor.icon;
        }

        if (this.isErhverv()) {
            return roleMap.erhverv.icon;
        }

        if (this.isPrivat()) {
            return 'home';
        }

        return 'question';
    };

    getTitle = () => {
        const { creator } = this;

        if (creator) {
            return (
                this.isPrivat() ?
                'Boligudlejningsklient' :
                'Erhvervsklient'
            );
        }

        if (this.isAcademy()) {
            return 'Academy user';
        }

        if (this.isPrivat()) {
            return 'Boligudlejer';
        }

        if (this.isAccountant()) {
            return 'Bogholder';
        }

        if (this.isErhverv()) {
            return this.businessForm;
        }

        return null;
    };

    getLatestLogin () {
        let latestActivity = this.createdAt;
        if (this.activities && this.activities.length > 0) {
            const latestAccessToken = this.activities.find(a => {
                const { activityType } = a;
                return activityType === ACTIVITY_TYPES.accessToken.id;
            });
            if (latestAccessToken) {
                latestActivity = latestAccessToken.timestamp;   
            }
        }
        return new Date(latestActivity).valueOf();
    }

    getDisplayName = () => {
        let displayName;
        if(this.isErhverv()) {
            displayName = this.displayName || this.companyName;
        } else if(this.isAcademy()) {
            displayName = this.className;
        } else {
            displayName = [this.firstname, this.lastname].map(x => _.capitalize(x)).join(' ');
        }
        return displayName;
    }

    isTestUser () {
        return this.isAdmin();
    }

    isNew () {
        const { createdAt } = this;
        const now = Date.now();
        return daysBetween(createdAt, now) <= 1;
    }
}

export default User;