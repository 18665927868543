import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Icon, Header, Segment, Table, Pagination, Popup, List, Button, Loader, Divider } from 'semantic-ui-react';
import { injectPaymentInformation } from 'shared/payment';
import { roleMap } from 'shared/roles';
import uuid from 'uuid/v4';
import accounts from 'http/accounts';
import ColoredText from 'atoms/ColoredText';
import getRelevantTaxYears from 'shared/getRelevantTaxYears';

const segments = {
    Boligudlejere:     'private',
    Rådgivere:         'accountant',
    Virksomheder:      'classA',
    Selskaber:         'classB',
    Holdingselskaber:  'holdingCompanies',
    Interessentskaber: 'interessentskaber',
};

const Dot = () => <span>&nbsp;∙</span>;

const FilterItemDropdown = ({ defaultValue, options, onChange, placeholder, disabled, ...otherProps }) => {
    const selectedOption = options.find(option => option.value === defaultValue);
    const textToDisplay = selectedOption?.text || placeholder || '...';

    const trigger = (
        <ColoredText
            link
            lineThrough={disabled}
            bold={!!selectedOption}
            italic={!selectedOption}
            underlined={false}
            content={textToDisplay}
            color={selectedOption ? 'black' : 'grey'}
        />
    );

    return (
        <Dropdown
            defaultOpen
            icon={null}
            defaultValue={defaultValue}
            selectOnBlur={false}
            trigger={trigger}
            onChange={(_, { value }) => onChange(value)}
            options={options}
            disabled={disabled}
            {...otherProps}
        />
    );
};

const TaxYearPicker = ({ state, setState }) => {
    const update = (updator = {}) => {
        const cpy = { ...state };
        cpy.filterOptions = { ...cpy.filterOptions, ...updator };
        setState(cpy);
    };

    const options = state.filterOptions || {};
    const taxYears = options.taxYears || [];

    const components = [];
    const taxYearOptions = getRelevantTaxYears().filter(x => !taxYears.includes(x));

    for (let i = 0; i < taxYears.length + 1; i++) {
        const currentSelectedTaxYear = taxYears[i];

        const isLast = i === taxYears.length - 1;
        if ((taxYears.length === 1 || isLast) && i > 0) {
            components.push(
                <FilterItemDropdown
                    placeholder='(og/eller)'
                    disabled={!state.isActive}
                    defaultOpen={false}
                    key={`${i}:op:${options.operator}`}
                    options={[
                        { text: 'og', value: 'and' },
                        { text: 'eller', value: 'or' },
                    ]}
                    defaultValue={options.operator}
                    onChange={operator => update({ operator })}
                />
            );

            if (!options.operator) break;
        }

        const taxYearsToShow = [...taxYearOptions];
        if (currentSelectedTaxYear) taxYearsToShow.push(currentSelectedTaxYear);

        const renderedOptions = taxYearsToShow.sort().map(year => ({ text: year, value: year }));
        if (currentSelectedTaxYear) renderedOptions.push({ text: <span style={{ color: 'red' }}>Slet</span>, value: 'slet' });

        components.push(
            <FilterItemDropdown
                disabled={!state.isActive}
                defaultOpen={false}
                key={`${i}:${currentSelectedTaxYear}`}
                options={renderedOptions}
                defaultValue={currentSelectedTaxYear}
                placeholder='(skatteår)'
                onChange={newSelection => {
                    const newTaxYears = [...taxYears];
                    const propsToUpdate = { taxYears: newTaxYears };

                    if (newSelection === 'slet') {
                        newTaxYears.splice(newTaxYears.indexOf(currentSelectedTaxYear), 1);
                        if (newTaxYears.length <= 1) {  
                            propsToUpdate.operator = '';
                        }
                    } else {
                        newTaxYears[i] = newSelection;
                        newTaxYears.sort();
                    }

                    update(propsToUpdate);
                }}
            />
        );

        if (i === 0 && !currentSelectedTaxYear) {
            break;
        }
    }

    // seperate by dots
    return components.map((component, i) => {
        if (i !== component.length - 1) {
            return <><Dot /> {component}</>;
        }

        return component;
    });
};

const EmailPicker = ({ state, setState }) => {
    const amountPerPage = 10;
    const [amountToShow, setAmountToShow] = useState(amountPerPage);

    const update = (updator = {}) => {
        const cpy = { ...state };
        cpy.filterOptions = { ...cpy.filterOptions, ...updator };
        setState(cpy);
    };

    const emails = state.filterOptions.emails;

    const onFilePicked = file => {
        const reader = new FileReader();

        reader.onload = () => {
            const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
            const emails = [...new Set(reader.result.match(emailRegex) || [])]
            update({ emails });
        };

        reader.readAsText(file);
    };

    const content = (
        <div>
            <FileUploadButton onChange={onFilePicked} />
            {emails && (
                <>
                    <Divider />

                    <b>{emails.length} emails fundet</b>

                    {emails.slice(0, amountToShow).map(email => <div>{email}</div>)}

                    {emails.length > amountToShow && (
                        <ColoredText
                            link
                            underlined={false}
                            icon='arrow down'
                            iconPosition='left'
                            content='Vis flere...'
                            onClick={() => setAmountToShow(amountToShow + amountPerPage)}
                        />
                    )}
                </>
            )}
        </div>
    );
    
    return (
        <Popup
            position='top center'
            on='click'
            content={content}
            trigger={
                <ColoredText link underlined={false} color='blue'>
                    <Dot /> <Icon name='mail' /> {emails?.length ?? 0} valgt
                </ColoredText>
            }
        />
    );
};

const FilterItem = ({ state, setState, onDelete, onCopy, validationError, filterChoices }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const renderChoices = (prop, choices) => {
        return (
            <FilterItemDropdown
                disabled={!state.isActive}
                options={choices}
                defaultValue={state[prop]}
                defaultOpen={!state[prop] && state.isActive}
                placeholder='Vælg...'
                onChange={value => {
                    const newState = { ...state };
                    if (prop === 'filterKind') {
                        newState.filterOptions = {};
                    }
                    newState[prop] = value;
                    setState(newState);
                }}
            />
        );
    };

    const renderFilterSubComponent = () => {
        const chosenFilterKind = filterChoices.find(x => x.value === state.filterKind);
        if (!chosenFilterKind) {
            return null;
        }

        const { Component } = chosenFilterKind;

        if (!Component) {
            return null;
        }

        return <Component state={state} setState={setState} />;
    };

    let tooltip;
    let icon;
    let color;

    if (validationError) {
        icon = 'info circle';
        color = 'grey';
        tooltip = (
            <div>
                <b>Filter ikke klar:</b>
                <br />
                {validationError}
            </div>
        );
    } else {
        color = 'green';
    }

    return (
        <Segment
            style={{
                border: '1px solid lightgray',
                padding: '1em',
                borderRadius: '0.5em',
                margin: 0,
            }}
            color={color}
        >
            <span style={{ pointerEvents: state.isActive ? undefined : 'none', opacity: state.isActive ? 1 : 0.75 }}>
                {renderChoices('includeOrExclude', [
                    { text: 'Inkluder modtagere', value: 'include' },
                    { text: 'Ekskluder modtagere', value: 'exclude' },
                ])}

                {state.includeOrExclude && (
                    <>
                        <Dot/>{' '}
                        {renderChoices('filterKind', filterChoices.map(x => ({ text: x.text, value: x.value })))}
                        {renderFilterSubComponent()}
                    </>
                )}
            </span>

            <div style={{ float: 'right' }}>
                {tooltip && (
                    <Popup
                        basic
                        position='top center'
                        trigger={<Icon name={icon} color={color} />}
                        content={tooltip}
                    />
                )}
                <Popup
                    basic
                    onClose={() => setMenuOpen(false)}
                    onOpen={() => setMenuOpen(true)}
                    open={menuOpen}
                    on='click'
                    position='top center'
                    trigger={<Icon name='ellipsis vertical' link />}
                    content={
                        <List style={{ cursor: 'pointer', selection: 'none' }} onClick={() => setMenuOpen(false)}>
                            <List.Item onClick={() => setState({ ...state, isActive: !state.isActive })}>
                                <Icon name={state.isActive ? 'eye slash' : 'eye'} /> {state.isActive ? 'Deaktivér' : 'Aktivér'}
                            </List.Item>
                            <List.Item onClick={onCopy}>
                                <Icon name='copy' /> Kopier
                            </List.Item>
                            <List.Item onClick={onDelete}>
                                <Icon name='trash' color='red' /> Slet
                            </List.Item>
                        </List>
                    }
                />
            </div>
        </Segment>
    );
};

const makeFilterItem = () => ({
    id: uuid(),
    isActive: true,
    includeOrExclude: '',
    filterKind: '',
    filterOptions: {},
});

const UsersOverview = ({ uids }) => {
    const [open, setOpen] = useState(false);
    const [fullUsers, setFullUsers] = useState(null);

    useEffect(() => {
        if (!open) return;
        if (fullUsers) return;
        if (!uids) return;
        if (uids.length === 0) return;

        const fetchUsers = async () => {
            const fullUsers = await Promise.all(uids.map(uid => {
                return accounts.getAccountByID(uid);
            }));

            await injectPaymentInformation(fullUsers);

            setFullUsers(fullUsers);
        };

        fetchUsers();
    }, [uids, open, fullUsers]);

    const renderTrigger = () => {
        return (
            <span style={{ cursor: 'pointer' }}>
                <Icon name={uids.length > 1 ? 'users' : 'user'} />{' '}
                {uids.length} bruger{uids.length !== 1 && 'e'}
            </span>
        );
    };

    const renderContent = () => {
        if (!fullUsers) {
            return <Loader inline='centered' active />;
        }

        return (
            <Table basic='very' style={{ width: '500px' }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>Roller</Table.HeaderCell>
                        <Table.HeaderCell textAlign='right'>Skatteår købt</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {fullUsers.map(user => {
                        const allOwnedTaxYears = new Set();
                        for (const taxYears of Object.values(user.ownedProducts || {})) {
                            taxYears.forEach(taxYear => allOwnedTaxYears.add(taxYear));
                        }
                        const sortedTaxYears = [...allOwnedTaxYears].sort();

                        const rolesToShow = user.roles.filter(role => {
                            if (role === roleMap.privat.id) return false;
                            if (role === roleMap.erhverv.id) return false;
                            if (!(role in roleMap)) return false;
                            return true;
                        });

                        const formattedRoles = [user.getTitle(), ...rolesToShow.map(role => roleMap[role].name)];

                        return (
                            <Table.Row>
                                <Table.Cell><Icon name={user.getIcon()} /> {user.displayName}</Table.Cell>
                                <Table.Cell>{formattedRoles.join(', ')}</Table.Cell>
                                <Table.Cell textAlign='right'>{sortedTaxYears.join(', ') || <>-</>}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );
    };

    return (
        <Popup
            position='top right'
            on='click'
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={renderTrigger()}
            content={renderContent()}
        />
    );
};

const AudiencePreview = ({ theSearch }) => {
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [page, setPage] = useState(0);
    const pageSize = 5;

    const doSearch = async () => {
        if (!theSearch.canSearch) return;

        setLoading(true);

        const searchResult = await accounts.searchNewletterRecipients(theSearch);
        // await injectPaymentInformation(searchResult);

        setPage(0);
        setResult(searchResult);
        setLoading(false);
    };

    const colSpan = '3';
    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Brugere</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Sidst aktiv</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {result?.slice(pageSize * page, (pageSize * page) + pageSize)?.map(({ loginEmail, latestLogin, uids }) => {
                    return (
                        <Table.Row key={loginEmail}>
                            <Table.Cell>
                                <div style={{ width: '180px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {loginEmail}
                                </div>
                            </Table.Cell>
                            <Table.Cell style={{ width: '120px' }}>
                                <UsersOverview uids={uids} />
                            </Table.Cell>
                            <Table.Cell textAlign='right'>{new Date(latestLogin).toLocaleDateString()}</Table.Cell>
                        </Table.Row>
                    );
                })}
                {!result && !loading && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center'>
                            <i>Ingen søgning foretaget...</i>
                        </Table.Cell>
                    </Table.Row>
                )}
                {!result && loading && (
                    <Table.Row>
                        <Table.Cell colSpan={colSpan} textAlign='center'>
                            Søger...
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
            <Table.Footer>
                {result?.length > 0 && (
                    <Table.Row>
                        <Table.HeaderCell colSpan={colSpan} textAlign='center'>
                            <Pagination
                                activePage={page + 1}
                                totalPages={Math.ceil(result.length / pageSize)}
                                siblingRange={0}
                                onPageChange={(_, { activePage }) => setPage(activePage - 1)}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                )}
                <Table.Row>
                    <Table.HeaderCell colSpan={colSpan} verticalAlign='middle'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                {result && (
                                    <span>
                                        <Icon name='users' />
                                        Antal modtagere fundet i alt:{' '}
                                        {
                                            loading
                                                ? <Loader size='tiny' inline active />
                                                : <b>{new Intl.NumberFormat().format(result.length)}</b>
                                        }
                                    </span>
                                )}
                            </div>
                            <div>
                                <Button
                                    basic
                                    color='green'
                                    loading={loading}
                                    floated='right'
                                    content='Søg'
                                    icon='search'
                                    disabled={!theSearch.canSearch || loading}
                                    onClick={doSearch}
                                />
                            </div>
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
};

const FileUploadButton = ({ onChange }) => {
    const fileRef = useRef();

    return (
        <>
            <ColoredText
                link
                underlined={false}
                content='Vælg en fil'
                iconPosition='left'
                icon='upload'
                onClick={() => fileRef.current.click()}
            />
            <input
                type='file'
                ref={fileRef}
                style={{ display: 'none' }}
                onChange={e => {
                    const [file] = e.target.files;
                    onChange(file);
                    e.target.files = null;
                }}
            />
        </>
    );
};

const filterChoiceTypes = {
    boughtTaxYears: 'boughtTaxYears',
    emailAddresses: 'emailAddresses',
};

const allFilterChoices = [
    {
        value: filterChoiceTypes.boughtTaxYears,
        text: 'der har købt',
        segments: [segments.Boligudlejere, segments.Selskaber, segments.Virksomheder, segments.Interessentskaber, segments.Holdingselskaber],
        Component: TaxYearPicker,
    },
    {
        value: filterChoiceTypes.emailAddresses,
        text: 'med bestemte e-mailaddresser',
        segments: [...Object.values(segments)],
        Component: EmailPicker,
    },
];

const RecipientSelector = ({ recipientSearchConfiguration, onChange }) => {
    const update = (updator = {}) => {
        const filterItems = recipientSearchConfiguration?.filterItems || [];
        const relevantSegments = recipientSearchConfiguration?.relevantSegments || [];

        onChange({
            filterItems,
            relevantSegments,
            ...updator,
        });
    };

    const setFilterItems = filterItems => update({ filterItems });
    const setRelevantSegments = relevantSegments => update({ relevantSegments });
    
    const getActiveFilterChoices = useCallback(() => {    
        const relevantSegments = recipientSearchConfiguration?.relevantSegments || [];
        
        return allFilterChoices.filter(choice => {
            if (relevantSegments.length === 0) {
                return false;
            }
    
            return relevantSegments.every(relevantSegment => {
                return choice.segments.includes(relevantSegment);
            });
        })
    }, [recipientSearchConfiguration]);

    const validateFilterItem = useCallback(filterItem => {
        if (!filterItem.includeOrExclude) {
            return 'Vælg mellem inklusion og eksklusion';
        }

        if (!filterItem.filterKind) {
            return 'Vælg type af filter';
        }

        const currentActiveFilterChoices = getActiveFilterChoices();
        if (!currentActiveFilterChoices.some(choice => choice.value === filterItem.filterKind)) {
            return 'Dette filter er ikke længere validt givet valgte segment(er)';
        }

        if (filterItem.filterKind === filterChoiceTypes.boughtTaxYears) {
            const { taxYears, operator } = filterItem.filterOptions || {};
            if (operator && taxYears.length <= 1) {
                return 'Vælg mere en ét skatteår';
            }
        }

        if (filterItem.filterKind === filterChoiceTypes.emailAddresses) {
            const emails = filterItem.filterOptions?.emails || [];
            if (emails.length === 0) {
                return 'Du har ikke valgt nogen mailadresser';
            }
        }

        // filter item is valid
        return null;
    }, [getActiveFilterChoices]);

    const theSearch = useMemo(() => {
        const filterItems = recipientSearchConfiguration?.filterItems || [];
        const relevantSegments = recipientSearchConfiguration?.relevantSegments || [];
        
        const activeFilterItems = filterItems.filter(item => {
            if (!item.isActive) {
                return false;   
            }

            const validationError = validateFilterItem(item);
            if (validationError) {
                return false;
            }

            return true;
        });

        return {
            canSearch: relevantSegments.length > 0,
            filterItems: activeFilterItems,
            relevantSegments,
        };
    }, [recipientSearchConfiguration, validateFilterItem]);

    const renderFilterItems = () => {
        const filterItems = recipientSearchConfiguration?.filterItems || [];
        const relevantSegments = recipientSearchConfiguration?.relevantSegments || [];

        const renderFilterItem = item => {
            return (
                <FilterItem
                    key={item.id}
                    state={item}
                    relevantSegments={relevantSegments}
                    filterChoices={getActiveFilterChoices()}
                    validationError={validateFilterItem(item)}
                    onDelete={() => setFilterItems(filterItems.filter(filterItem => filterItem.id !== item.id))}
                    onCopy={() => setFilterItems(filterItems.flatMap(filterItem => {
                        if (filterItem.id === item.id) {
                            const copy = structuredClone(filterItem);
                            copy.id = uuid();
                            return [filterItem, copy];
                        }

                        return [filterItem];
                    }))}
                    setState={newItem => setFilterItems(
                        filterItems.map(filterItem => {
                            if (filterItem.id === item.id) {
                                return newItem;
                            }

                            return filterItem;
                        })
                    )}
                />
            );
        };

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                {filterItems.map(renderFilterItem)}
                <div
                    style={{
                        border: '1px dashed lightgray',
                        padding: '1em',
                        borderRadius: '0.5em',
                        cursor: 'pointer',
                    }}
                    onClick={() => setFilterItems([...filterItems, makeFilterItem()])}
                >
                    <Icon name='plus circle' /> Nyt filter
                </div>
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', gap: '2em', marginTop: '2em' }}>
            <div style={{ flex: 1 }}>
                <Header>Vælg modtagergruppe(r)</Header>
                <Dropdown
                    selection
                    multiple
                    fluid
                    placeholder='Vælg modtagergruppe(r)'
                    defaultValue={recipientSearchConfiguration?.relevantSegments}
                    onChange={(_, { value }) => setRelevantSegments([...value])}
                    options={Object.entries(segments).map(([text, value]) => {
                        return { text, value };
                    })}
                />
                
                <Header>Yderligere filtrering</Header>
                {renderFilterItems()}
            </div>
            <div style={{ width: '450px' }}>
                <Header>Søgeresultat</Header>
                <AudiencePreview theSearch={theSearch} />
            </div>
        </div>
    );
};

export default RecipientSelector;
