import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';

import AutoStamp from 'atoms/AutoStamp';

import styles from './Pipeline.module.css';

const Task = ({ id, index, title, createdAt, unseenChanges }) => {
    let cardCname;
    let linkCname;
    let linkIcon;
    if (unseenChanges) {
        linkIcon = <Icon name='exclamation circle' />;
    } else {
        linkCname = styles.unimportantLink;
        cardCname = styles.unimportantTask;
    }

    return (
        <Draggable key={id} draggableId={id} index={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className={styles.taskWrapper}>
                        <Segment raised={snapshot.isDragging} className={cardCname}>
                            <Link to={`/tasks/${id}`} className={linkCname}>
                                {linkIcon}
                                {title}
                            </Link>
                            <br />
                            <AutoStamp stamp={createdAt} />
                        </Segment>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const Swimlane = ({ title, id, tasks }) => {
    const [tasksToShow, setTasksToShow] = useState(10);

    const renderShowMore = () => {
        if (tasksToShow >= tasks.length) {
            return null;
        }

        return (
            <span
                className={styles.showMore}
                onClick={() => setTasksToShow(tasksToShow + 10)}
                children='Vis flere...'
            />
        );
    };

    // render tasks based on "tasksToShow" and amount of tasks
    const visibleTasks = [];

    for (let i = 0; i < Math.min(tasksToShow, tasks.length); i++) {
        visibleTasks.push(tasks[i]);
    }

    return (
        <Droppable droppableId={id}>
            {(propvided, snapshot) => (
                <div
                    className='column'
                    ref={propvided.innerRef}
                    children={
                        <Segment secondary={!snapshot.isDraggingOver}>
                            <Grid columns={2}>
                                <Grid.Column>
                                    <Header content={title}  />
                                </Grid.Column>
                                <Grid.Column textAlign='right'>
                                    <Icon name='clone outline' />
                                    <span>
                                        {Math.min(tasksToShow, tasks.length)} / {tasks.length}
                                    </span>
                                </Grid.Column>
                            </Grid>
                            <Divider />
                            {visibleTasks.map((task, i) => <Task {...task} index={i} />)}
                            {renderShowMore()}
                        </Segment>
                    }
                />
            )}
        </Droppable>
    );
};

const Swimlanes = ({ swimlanes }) => {
    return swimlanes.map(({ id, title, tasks }) => {
        return (
            <Swimlane
                id={id}
                title={title}
                tasks={tasks}
            />
        );
    });
};

const Pipeline = ({ title, swimlanes, onChange }) => {
    const onDragEnd = ({ draggableId, source: s, destination: d }) => {
        // dragged outside swimlanes
        if (!d) {
            return;
        }

        // element wasn't moved
        if (s.droppableId === d.droppableId && s.index === d.index) {
            return;
        }

        onChange({
            task: draggableId,
            from: {
                swimlane: s.droppableId,
                index: s.index,
            },
            to: {
                swimlane: d.droppableId,
                index: d.index,
            },
        });
    };

    return (
        <div>
            <Header>
                {title}
            </Header>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid columns={swimlanes.length} unstackable>
                    <Swimlanes swimlanes={swimlanes} />
                </Grid>
            </DragDropContext>
        </div>
    );
};

export default Pipeline;