import React, { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';

function FoldableSegment ({ defaultOpen = false, children, header, icon }) {
    const [open, setOpen] = useState(defaultOpen);
    return <Segment secondary={!open}>
        <Header
            content={header}
            icon={icon}
            color={open ? 'black' : 'grey'}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
        />
        {open && children}
    </Segment>;
}

export default FoldableSegment;