import React from 'react';
import { Segment, Button, Container, Header, Message, Icon } from 'semantic-ui-react';
import modelEditor from "../../../http/modelEditor";
import Spinner from '../../atoms/Spinner/Spinner';
import { DragAndDropZone, DraggableItem } from '../../atoms/DragAndDrop';
import withNavShell from '../../molecules/withNavShell';
import { confirm } from '../../../shared/globalModal';
import { move } from '../../../shared/array';
import styles from './ProductSectionMaster.module.css';

const DROPPABLE_ID = 'dnd_sections';

class ProductsSectionMaster extends React.Component {
    constructor() {
        super();
        this.state = {
            sections: [],
            selectedIndexes: [],
            loading: true,
            working: false
        }
    }

    componentDidMount () {
        this.getSectionData();
    }

    async getSectionData () {
        const modelId = this.props.match.params.modelId;
        const data = await modelEditor.getSections(modelId);
        this.props.updateNavShell();
        this.setState({
            sections: data,
            loading: false
        });
    }

    moveSection = (a, b) => {
        const { sections } = this.state;
        const idA = sections[a].id;
        const idB = sections[b].id;
        move(sections, a, b);
        this.setState({ working: true, sections }, async () => {
            const { modelId } = this.props.match.params;
            const data = await modelEditor.swapSections(modelId, idA, idB);
            this.setState({
                sections: data,
                working: false,
            });
        });
    };

    newSection = () => {
        const { modelId } = this.props.match.params;
        this.props.history.push(`/model-editor/${modelId}/create-section`);
    };

    editSection = slug => {
        const { modelId } = this.props.match.params;
        this.props.history.push(`/model-editor/${modelId}/sections/${slug}`);
    };

    onDragEnd = async result => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }
        const idxA = result.source.index;
        const idxB = result.destination.index;
        await this.moveSection(idxA, idxB);
    };

    deleteSection = async slug => {
        const { title } = this.state.sections.find(s => s.slug === slug);
        const confirmDelete = await confirm({
            header: 'Slet sektion',
            content: `Er du sikker på at du vil slette sektionen "${title}"?`,
            confirmButton: 'Slet'
        });
        if (!confirmDelete) {
            return;
        }
        const { modelId } = this.props.match.params;
        this.setState({
            working: true
        });
        const data = await modelEditor.deleteSection(modelId, slug);
        this.setState({
            working: false,
            sections: data,
        });
    };

    renderSection = ({ id, slug, title, icon }, i) => {
        const { working } = this.state;
        const cname = working ? styles.disabled : undefined;
        return (
            <DraggableItem
                id={id}
                key={id} onChange={this.onChange}
                index={i}
            >
                <Message icon onDoubleClick={() => this.editSection(slug)} className={cname}>
                    <Icon name={icon} style={{ width: '50px' }} />
                    <Message.Content>
                        <Message.Header>{title}</Message.Header>
                        {slug}
                    </Message.Content>
                    <Icon
                        disabled={working}
                        onClick={() => this.editSection(slug)} className={cname}
                        name='edit'
                        size='tiny'
                        link
                    />
                    <Icon
                        disabled={working}
                        onClick={() => this.deleteSection(slug)}
                        name='x'
                        size='small'
                        color='red'
                        link
                    />
                </Message>
            </DraggableItem>
        );
    };
    
    render () {
        const { sections, loading, working } = this.state;
        if (loading) {
            return (
                <Spinner size='320'/>
            );
        }
        return (
            <Container>
                <Segment>
                    <Header>Sektioner</Header>
                    <DragAndDropZone droppableId={DROPPABLE_ID} onDragEnd={this.onDragEnd}>
                        {sections.map(this.renderSection)}
                    </DragAndDropZone>
                    <br />
                    <div className={styles.right}>
                        <Button
                            positive
                            content='Opret sektion'
                            labelPosition='left'
                            icon='plus'
                            onClick={this.newSection}
                            disabled={working}
                        />
                    </div>
                </Segment>
            </Container>
        );
    }
}

export default withNavShell(ProductsSectionMaster);
