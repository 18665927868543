import React from 'react';
import erpSystems from 'shared/erpSystems';
import HintVideos from './_abstract_/HintVideos';

const HintVideosErp = props => {
    const idColumn = {
        header: 'ERP system',
        dropdownPlaceholder: 'Vælg ERP system'
    };

    const erpOptions = erpSystems.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name
    }));

    const hintVideosErp = (
        <HintVideos {...props} idColumn={idColumn} options={erpOptions} />
    );
    return hintVideosErp;
};

export default HintVideosErp;
