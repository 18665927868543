import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/document-generator`);

class DocumentGeneratorClient {
    buildPDFFromDocumentComponents = ({ documentComponents, overrideValues, watermarkText }) => {
        return client.postResource('/generator/document-components', {
            documentComponents,
            overrideValues,
            watermarkText,
        });
    };
}

const documentGenerator = new DocumentGeneratorClient();

export default documentGenerator;