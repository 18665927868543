import React, { Component } from 'react';
import { withStore } from '../../../store';
import NavItem from '../../../atoms/NavItem/NavItem';

function withNavShell (WrappedComponent) {
    const injectorHOC = class NavShellInjector extends Component {
        getPrefix = () => {
            const modelId = this.props.match.params.modelId;
            return `/model-editor/${modelId}`;
        };

        getUrlNavPath = () => {
            const { pathname } = this.props.location;
            const urlParts = pathname.split('/');
            return urlParts[urlParts.length - 1];
        };

        shouldBeActive = postfix => {
            const fromUrl = this.getUrlNavPath();
            return fromUrl === postfix;
        };

        navItem = (postfix, label, icon) => {
            const prefix = this.getPrefix();
            const to = `${prefix}/${postfix}`;
            const isActive = this.shouldBeActive(postfix);
            return <NavItem
                to={to}
                label={label}
                name={icon}
                key={icon}
                active={isActive}
            />;
        };

        getMenuItems = () => {
            return [
                this.navItem('nodes',    'Noder',     'list'),
                this.navItem('',         'Oversigt',  'map'),
                this.navItem('metadata', 'Metadata',  'dna'),
                this.navItem('sections', 'Sektioner', 'file alternate'),
                this.navItem('export',   'Eksporter', 'cloud upload')
            ];
        };

        updateNavShell = () => {            
            const { updateMenu } = this.props.store;
            updateMenu(this.getMenuItems());
        };

        render () {
            return (
                <WrappedComponent
                    updateNavShell={this.updateNavShell}
                    {...this.props}
                />
            );
        }
    };
    return withStore(injectorHOC);
}

export default withNavShell;