import React, { Component } from 'react';
import { Grid, Icon, Input, Loader, Message, Pagination, Popup, Table } from 'semantic-ui-react';
import lodash from 'lodash';
import cns from 'shared/cns';
import { confirm } from 'shared/globalModal';
import styles from './MasterTable.module.css';

const OptionIcon = ({ tooltip, ...props }) => {
    const icon = (
        <Icon
            {...props}
            link
        />
    );
    return <Popup
        inverted
        position='right center'
        content={tooltip}
        trigger={icon}
    />;
};

class MasterTable extends Component {
    constructor(props) {
        super(props);
        this.sortAscending = 'ascending';
        this.sortDescending = 'descending';

        let defaultSort;
        if (props.defaultSort) {
            defaultSort = props.defaultSort;
        } else if (props.sortProperties) {
            defaultSort = props.sortProperties[0];
        }

        this.state = {
            loading: true,
            working: false,
            error: false,
            resources: [],

            // pagination
            resourcesPerPage: 15,
            pageIndex: 0,

            // sorting
            sortBy: props.sortProperties?.findIndex(prop => prop === defaultSort),
            sortDirection: this.sortDescending,

            // search
            query: '',
        };
    }

    isWorking = () => {
        return this.state.working || this.props.disabled;
    };

    getDeletionMessage = resource => {
        const { deleteResourceMessage } = this.props;
        if (typeof deleteResourceMessage === 'function') {
            return deleteResourceMessage(resource);
        }
        return deleteResourceMessage;
    };

    componentDidMount = async () => {
        try {
            await this.fetchResources();
        } catch (e) {
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    };

    applyFiltering = resources => {
        const { query } = this.state;
        const { filterBy } = this.props;

        if (!query || !filterBy) {
            return resources;
        }

        return resources.filter(resource => {
            const val = lodash.get(resource, filterBy, '').toLowerCase();
            return val.includes(query);
        });
    };

    applySorting = resources => {
        if (!this.props.sortable) {
            return resources;
        }

        if (!Array.isArray(this.props.sortProperties)) {
            return resources;
        }

        // find property to sort by
        const sortProp = this.props.sortProperties[this.state.sortBy];

        if (!sortProp) {
            return resources;
        }

        const sorted = lodash.sortBy(resources, [sortProp]);

        if (this.state.sortDirection === this.sortDescending) {
            return sorted.reverse();
        }

        return sorted;
    };

    applyPagination = resources => {
        if (!this.props.paginated) {
            return resources;
        }

        const { pageIndex, resourcesPerPage } = this.state;

        const out = [];
        let resourceIndex = pageIndex * resourcesPerPage;
        while (out.length < resourcesPerPage && resourceIndex < resources.length) {
            out.push(resources[resourceIndex++]);
        }

        return out;
    };

    formatResources = () => {
        const pipeline = [
            this.applyFiltering,
            this.applySorting,
            this.applyPagination,
        ];

        let resources = this.state.resources;

        pipeline.forEach(formatter => resources = formatter(resources));

        return resources;
    };

    fetchResources = async () => {
        const resources = await this.props.fetchResources();

        this.setState({ resources });
    };

    deleteResource = async resource => {
        const deleteConfirmed = await confirm(this.getDeletionMessage(resource));
        if (!deleteConfirmed) {
            return;
        }

        this.setState({ working: true });
        await this.props.deleteResource(resource);
        await this.fetchResources();
        this.setState({ working: false });
    };

    copyResource = async resource => {
        this.setState({ working: true });
        await this.props.copyResource(resource);
        await this.fetchResources();
        this.setState({ working: false });
    };

    rowClicked = rowData => {
        const { onRowClicked } = this.props;
        if (typeof onRowClicked === 'function') {
            onRowClicked(rowData);
        }
    };

    calculateColspan = () => {
        const{ headers, rowOptions } = this.props;

        // calculate footer colspan
        let colspan = 0;
        colspan += headers.length;
        colspan += rowOptions ? 1 : 0;

        return colspan;
    };

    updateSearchQuery = (_, { value }) => {
        this.setState({
            query: value,
            pageIndex: 0,
        });
    };

    renderRowOptions = rowData => {
        const { rowOptions } = this.props;
        if (!rowOptions) {
            return null;
        }

        const supportedOptions = {
            delete: {
                color: 'red',
                name: 'x',
                tooltip: 'Slet',
                onClick: () => this.deleteResource(rowData),
            },
            copy: {
                name: 'copy',
                tooltip: 'Kopier',
                onClick: () => this.copyResource(rowData),
            },
        };

        const options = [];
        rowOptions.forEach(ro => {
            const props = supportedOptions[ro];
            if (props) {
                options.push(<OptionIcon key={ro} {...props} />);
            }
        });

        return <Table.Cell textAlign='right'>
            {options}
        </Table.Cell>;
    };

    renderRow = rowData => {
        const { formatRow, onRowClicked, rowKey, alignments } = this.props;

        const classNames = [];
        if (onRowClicked) {
            classNames.push(styles.clickableRow);
        }

        // when clicked, perform the provided "onRowClicked" function
        const LinkCell = ({ children, textAlign, width }) => {
            return <Table.Cell
                children={children}
                onClick={() => this.rowClicked(rowData)}
                textAlign={textAlign}
                width={width}
            />;
        };

        const columns = formatRow(rowData);
        const key = rowKey(rowData);

        return (
            <Table.Row className={cns(classNames)} disabled={this.isWorking()} key={key}>
                {columns.map((c, i) => {
                    const alignment = alignments[i] || 'left';
                    return <LinkCell
                        key={`${key}-${i}`}
                        children={c}
                        textAlign={alignment}
                        width={i === 0 ? 8 : undefined}
                    />;
                })}
                {this.renderRowOptions(rowData)}
            </Table.Row>
        );
    };

    renderTableHeader = () => {
        const { sortBy, sortDirection } = this.state;
        const { headers, rowOptions, alignments, sortable } = this.props;
        if (headers.length === 0) {
            return null;
        }

        return (
            <Table.Header>
                <Table.Row>
                    {headers.map((h, i) => {
                        const alignment = alignments[i] || 'left';

                        let sorted;
                        if (sortable && sortBy === i) {
                            sorted = sortDirection;
                        }

                        return <Table.HeaderCell
                            sorted={sorted}
                            key={h}
                            content={h}
                            textAlign={alignment}
                            onClick={() => {
                                if (sortBy === i) {
                                    this.setState({
                                        sortDirection: (
                                            sortDirection === this.sortAscending ?
                                            this.sortDescending :
                                            this.sortAscending
                                        )
                                    });
                                    return;
                                }

                                this.setState({
                                    sortBy: i,
                                    sortDirection: this.sortDescending,
                                });
                            }}
                        />;
                    })}
                    {!!rowOptions && <Table.HeaderCell />}
                </Table.Row>
            </Table.Header>
        );
    };

    renderSearchRow = () => {
        const { filterBy, createForm } = this.props;

        if (!filterBy) {
            return;
        }

        return (
            <Table.Row>
                <Table.Cell colSpan={this.calculateColspan()} className={styles.searchRow}>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Input
                                iconPosition='left'
                                icon='search'
                                placeholder='Søg...'
                                onChange={lodash.debounce(this.updateSearchQuery, 200)}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign='right'>
                            {createForm}
                        </Grid.Column>
                    </Grid>
                    
                </Table.Cell>
            </Table.Row>
        );
    };

    renderTableBody = () => {
        return <Table.Body>
            {this.formatResources().map(this.renderRow)}
        </Table.Body>;
    };

    renderPagination = () => {
        if (!this.props.paginated) {
            return null;
        }

        const resourcesAfterFiltering = this.applyFiltering(this.state.resources).length;
        const totalPages = Math.ceil(resourcesAfterFiltering / this.state.resourcesPerPage);

        return (
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan={this.calculateColspan()} textAlign='center'>
                        <Pagination
                            totalPages={totalPages}
                            activePage={this.state.pageIndex + 1}
                            onPageChange={(_, { activePage }) => this.setState({ pageIndex: activePage - 1 })}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        ); 
    };

    renderTable = () => {
        return <Table {...this.props.tableProps} sortable={this.props.sortable}>
            {this.renderTableHeader()}
            {this.renderSearchRow()}
            {this.renderTableBody()}
            {this.renderPagination()}
        </Table>;
    };

    renderError = () => {
        return <Message
            error
            content='Der opstod en fejl'
        />;
    };

    renderLoading = () => {
        return this.props.loader;
    };

    render = () => {
        const { loading, error } = this.state;

        if (error || this.props.error) {
            return this.renderError();
        }

        if (loading) {
            return this.renderLoading();
        }

        return this.renderTable();
    };
}

MasterTable.defaultProps = {
    tableProps: {},
    headers: [],
    alignments: [],
    loader: <Loader inline='centered' active />,
    disabled: false,
    error: false,
    paginated: false,
    sortable: false,
    deleteResourceMessage: 'Er du sikker?',
    rowKey: (...args) => JSON.stringify(args),
};

export default MasterTable;