import { renderDataAsList } from './renderDataAsList';

export const ACTIVITY_TYPES = {
    accessToken: {
        id: 'accessToken',
        title: 'Login',
        icon: 'sign-in'
    },
    impersonationToken: {
        id: 'impersonationToken',
        title: 'Impersonering',
        icon: 'spy',
        renderData: renderDataAsList([
            {id: 'impersonated', title: 'Impersonering af' }
        ])
    },
    emailSent: {
        id: 'emailSent',
        title: 'Email tilsendt',
        icon: 'mail outline',
        renderData: renderDataAsList([
            { id: 'subject', title: 'Titel på email' }
        ])
    },
    resetRequest: {
        id: 'resetRequest',
        title: 'Nulstil adgangskode',
        icon: 'unlock'
    },
    resetExecute: {
        id: 'resetExecute',
        title: 'Skift af adgangskode',
        icon: 'lock'
    },
    fetchedAccounts: {
        id: 'fetchedAccounts',
        title: 'Hentet tal',
        icon: 'cloud download',
        renderData: renderDataAsList([
            { id: 'year', title: 'Årstal' }
        ])
    },
    signatureFlowStarted: {
        id: 'signatureFlowStarted',
        title: 'Signaturflow påbegyndt',
        icon: 'signup',
        renderData: renderDataAsList([
            {
                id: 'product',
                title: 'Produktnavn'
            },
            {
                id: 'year',
                title: 'Årstal'
            },
            {
                id: 'signingSystem',
                title: 'Signeringssystem'
            }
        ])
    },
    signatureFlowEnded: {
        id: 'signatureFlowEnded',
        title: 'Signaturflow afsluttet',
        icon: 'signup',
        renderData: renderDataAsList([
            {
                id: 'product',
                title: 'Produktnavn'
            },
        ])
    },
    signatureFlowFailed: {
        id: 'signatureFlowFailed',
        title: 'Signaturflow fejlet',
        icon: 'signup',
        renderData: renderDataAsList([
            {
                id: 'product',
                title: 'Produktnavn'
            },
        ])
    },
    erstReportAttempt: {
        id: 'erstReportAttempt',
        title: 'Indberetningsforsøg til ERST',
        icon: 'paper plane outline',
        renderData: renderDataAsList([
            {
                id: 'product',
                title: 'Produktnavn'
            },
            {
                id: 'year',
                title: 'Årstal'
            },
            {
                id: 'status',
                title: 'Status'
            }
        ])
    },
    createdClient: {
        id: 'createdClient',
        title: 'Klient oprettet',
        icon: 'user plus',
        renderData: renderDataAsList([
            {
                id: 'clientName',
                title: 'Navn'
            },
            {
                id: 'clientEmail',
                title: 'E-mail'
            }
        ])
    }
};

export const translateActivity = activity => {
    const { activityType, timestamp, data } = activity;
    let activityData = ACTIVITY_TYPES[activityType];
    if (!activityData) {
        // Default
        activityData =  {
            title: activityType,
            icon: 'clock',
            renderData: null
        };
    }
    return {
        data,
        timestamp: new Date(timestamp).valueOf(),
        ...activityData
    };
};