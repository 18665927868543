import React from 'react';
import { Loader, Icon } from 'semantic-ui-react';
import ReactDOMServer from 'react-dom/server';
import tinycolor from "tinycolor2";
import {withRouter} from 'react-router-dom';
import NodeMetadata from '../../nodeMetadata';
import Cytoscape from '../../atoms/Cytoscape/Cytoscape';
import { alert } from '../../../shared/globalModal';
import modelEditor from 'http/modelEditor';

const layout = {
    name: 'dagre',
    rankDir: 'rl',
    nodeSep: 50,
    rankSep: 100,
    edgeSep: 50,
    ranker: 'network-simplex',
};

const stylesheet = [ // the stylesheet for the graph
    {
        selector: 'node',
        style: {
            'text-valign': 'center',
            'text-margin-y': '3px',
            'color': 'black',
            'background-color': 'data(color)',
            'width': 'label',
            'height': 'label',
            'shape': 'roundrectangle',
            'label': 'data(name)',
            'padding': '5px',
        }
    },
    {
        selector: 'edge',
        style: {
            'curve-style': 'bezier',
            'target-arrow-shape': 'triangle-backcurve',
            'target-arrow-color': 'data(color)',
            'arrow-scale': 2,
            'width': 4,
            'line-color': "data(color)",
            'source-endpoint': '-49% 0%',
            'target-endpoint': '49% 0%'
        }
    },
    {
        selector: '.eh-handle',
        style: {
            'background-color': 'red',
            'opacity': .8,
            'width': 8,
            'height': 8,
            'shape': 'diamond',
            'overlay-opacity': 0,
            'border-opacity': 0,
            'border-width': 8, // makes the handle easier to hit
        }
    },
    {
        selector: '.eh-hover',
        style: {
            'background-color': 'red'
        }
    },
    {
        selector: '.eh-source',
        style: {
            'border-width': 2,
            'border-color': 'red'
        }
    },
    {
        selector: '.eh-target',
        style: {
            'border-width': 2,
            'border-color': 'red'
        }
    },
    {
        selector: '.eh-preview, .eh-ghost-edge',
        style: {
            'background-color': 'red',
            'line-color': 'red',
            'target-arrow-color': 'red',
            'source-arrow-color': 'red'
        }
    },
    {
        selector: '.eh-ghost-edge.eh-preview-active',
        style: {
            'opacity': 0
        }
    }
];

const ctxMenuNode = {
    selector: 'node',
    activePadding: 10,
    indicatorSize: 12,
    menuRadius: 70,
    minSpotlightRadius: 18,
    maxSpotlightRadius: 18,
    atMouse: true,
    commands: [
        {
            content: ReactDOMServer.renderToStaticMarkup(<Icon name='copy' />),
            select: element => {element.cy().emit('nodeClone', element.id())}
        },
        {
            content: ReactDOMServer.renderToStaticMarkup(<Icon name='trash' />),
            fillColor: 'rgba(200, 30, 30, 0.75)',
            select: element => {element.cy().emit('nodeDelete', element.id())}
        }
    ]
};

const ctxMenuEdge = {
    selector: 'edge',
    activePadding: 10,
    indicatorSize: 12,
    menuRadius: 70,
    minSpotlightRadius: 18,
    maxSpotlightRadius: 18,
    atMouse: true,
    activeFillColor: 'rgba(200, 30, 30, 0.75)',
    commands: [
        {
            content: ReactDOMServer.renderToStaticMarkup(<Icon name="delete"/>),
            select: element => {element.cy().emit('edgeDelete', element.id())}
        }
    ]
};

const edgehandler = {
    handlePosition: function( node ){
        return 'left middle'; // sets the position of the handle in the format of "X-AXIS Y-AXIS" such as "left top", "middle top"
    },
    snap: true,
};

class GraphView extends React.Component {
    constructor(props) {
        super(props);
        this.onTapNode = this.onTapNode.bind(this);
        this.onCloneNode = this.onCloneNode.bind(this);
        this.onDeleteNode = this.onDeleteNode.bind(this);
        this.onDeleteEdge = this.onDeleteEdge.bind(this);
        this.onAddEdge = this.onAddEdge.bind(this);
        this.nodeById = this.nodeById.bind(this);
        this.convertNode = this.convertNode.bind(this);
        this.convertEdge = this.convertEdge.bind(this);
        this.antiScrollPopup = React.createRef();
        this.graphContainer = React.createRef();
    }

    nodeById(node_id) {
        for (let node of this.props.graph.nodes) {
            if (node.id === node_id) {
                return node
            }
        }
        return null
    }

    convertNode(node) {
        const color = NodeMetadata(node.type).color;
        const c2u = this.props.highlightedNode === node.id ? '#2bfbff' : color;
        return {
            data: {
                id: node.id,
                name: node.name,
                color: c2u,
                borderColor: tinycolor(c2u).darken().toString()
            }
        }
    }

    convertEdge(edge) {
        const color = NodeMetadata(this.nodeById(edge.from).type).color;
        return {
            data: {
                source: edge.from,
                target: edge.to,
                color: color
            }
        }
    }

    onCloneNode(_, id) {
        const { modelId } = this.props.match.params;
        modelEditor.cloneNode(modelId, id)
            .then(newNodeID => {
                this.props.history.push(`/model-editor/${modelId}/node/${newNodeID}`)
            });
    }

    async onDeleteNode(event, id) {
        const {modelId} = this.props.match.params;
        const isConfirmed = window.confirm("Er du sikker på at du vil slette noden?\nSletning af noden sletter også felter i sektioner der er tilknyttet noden.");
        if (!isConfirmed) return;
        await modelEditor.deleteNode(modelId, id);
    }

    onTapNode(event) {
        const {modelId} = this.props.match.params;
        this.props.history.push(`/model-editor/${modelId}/node/${event.target.id()}`)
    }

    onDeleteEdge(event, id) {
        const edge = this.cy.elements(`#${id}`);
        const sourceEdges = this.cy.elements(`edge[source="${edge.source().id()}"]`);
        const targets = sourceEdges.targets()
            .filter(x => x.data('name') !== undefined)
            .filter(x => x.id() !== edge.target().id())
            .map(x => x.id());

        const { modelId } = this.props.match.params;

        modelEditor.setNodeLinks(modelId, edge.source().id(), targets)
            .then(() => {
                this.cy.remove(edge);
            });
    }

    onAddEdge(events, sourceNode, targetNode, addedEles) {
        const edges = this.cy.elements(`edge[source="${sourceNode.id()}"]`);
        const targets = edges.targets().filter(x => x.data('name') !== undefined).map(x => x.id());

        this.cy.remove(addedEles);

        const alreadyConnetedNodes = sourceNode.connectedEdges().connectedNodes().map(x => x.id());
        if (alreadyConnetedNodes.includes(targetNode.id())){
            alert("Nodes are already connected");
            return;
        }

        const { modelId } = this.props.match.params;
        modelEditor.setNodeLinks(modelId, sourceNode.id(), targets)
            .then(() => {
                this.cy.add({
                    group: "edges",
                    data: {source: sourceNode.id(), target: targetNode.id(), color: sourceNode.data('color')}
                });
            });
    }

    renderLoadingPlaceholder = () => {
        return (
            <div
                style={{
                    background: 'white',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                children={<Loader inline='centered' active size='large' />}
            />
        );
    };

    render = () => {
        const { editor, style, working } = this.props;
        const { nodes, edges } = this.props.graph;
        const elements = {
            nodes: nodes.map(this.convertNode),
            edges: edges.map(this.convertEdge)
        };

        const ref = (cy) => {
            this.cy = cy;
            if (editor){
                cy.cxtmenu(ctxMenuNode);
                cy.cxtmenu(ctxMenuEdge);
                cy.edgehandles(edgehandler);
            }
            cy.on('nodeClone', this.onCloneNode);
            cy.on('nodeDelete', this.onDeleteNode);
            cy.on('edgeDelete', this.onDeleteEdge);
            cy.on('tap', 'node', this.onTapNode);
            cy.on('ehcomplete', this.onAddEdge);
        };

        return (
            <div style={style}>
                {
                    working ?
                    this.renderLoadingPlaceholder() :
                    <Cytoscape 
                        containerID="cy"
                        elements={elements}
                        cyRef={ref}
                        style={stylesheet}
                        layout={layout}
                        editor={editor}
                    />
                }
            </div>
        );
    }
}

export default withRouter(GraphView)

