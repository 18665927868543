import React, { Component } from 'react';
import { Segment, Button, Loader } from 'semantic-ui-react';
import http from 'http/onetimePayments';
import { prompt } from 'shared/globalModal';
import OnetimePaymentForm from './OnetimePaymentForm';

export default class OnetimePaymentsDetail extends Component {
    state = {
        loading: true,
        otp: {},
        activityTriggers: []
    };

    async componentDidMount () {
        const { otpid } = this.props.match.params;
        const [onetimePaymentReq, activityTriggersReq] = await Promise.all([
            http.getOnetimePayment(otpid),
            http.getActivityTriggers()
        ]);

        if (onetimePaymentReq.success && activityTriggersReq.success) {
            this.setState({
                loading: false,
                otp: onetimePaymentReq.data,
                activityTriggers: activityTriggersReq.data
            });
        }
    }

    save = async () => {
        const otp = this.state.otp;

        const { success } = await http.updateOnetimePayment(otp.id, otp);
        if (success) {
            this.props.history.push('/payment/onetime-payments/');
        }
    };

    delete = async () => {
        const confirmDelete = await prompt('Indtast enkeltkøbets navn for at slette det');
        if (!confirmDelete) return;
        if (confirmDelete !== this.state.otp.name) return;

        const { success } = await http.deleteOnetimePayment(this.state.otp.id);
        if (success) {
            this.props.history.push('/payment/onetime-payments/');
        }
    };

    updateOnetimePayment = (e, property) => {
        this.setState({
            ...this.state,
            otp: {
                ...this.state.otp,
                [property]: e.target.value
            }
        });
    };

    render () {
        const { loading, otp, activityTriggers } = this.state;

        if (loading) {
            return <Loader
                active
                inline='centered'
                size='huge'
            />;
        }

        return (
            <>
                <h1>Rediger enkeltkøb</h1>
                <OnetimePaymentForm
                    {...otp}
                    updateFormData={this.updateOnetimePayment}
                    activityTriggers={activityTriggers}
                    idDisabled
                />
                <Segment basic>
                    <Button color='green' floated='right' onClick={this.save}>Gem</Button>
                    <Button basic color='red' floated='left' onClick={this.delete}>Slet</Button>
                    <br />
                </Segment>
            </>
        );
    }
}