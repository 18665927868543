import React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import ChatHistoryViewer from 'molecules/ChatHistoryViewer';
import useRestResource from 'shared/hooks/useRestResource';
import accounts from 'http/accounts';

const TawkToHistory = ({ uid }) => {
    const { data, loading, error } = useRestResource({
        fetcher: accounts.getChatEntries,
        args: [uid],
    });

    if (loading) {
        return <Loader inline='centered' active />;
    }

    if (error) {
        return <Message color='orange' content='Kunne ikke indlæse chathistorik...' />;
    }

    return <ChatHistoryViewer chatEntries={data} />;
};

export default TawkToHistory;