import React from 'react';
import PropTypes from "prop-types";
import {Redirect} from 'react-router-dom';
import LoginForm from "../molecules/LoginForm/LoginForm";
import {withUser} from "../shared/LoginManager";

class Login extends React.Component {

    static propTypes = {
        /** The User Object **/
        user: PropTypes.object
    };

    render() {
        const { user } = this.props;
        if (user.loggedIn) {
            if (user.redirectUrl) {
                return null;
            } else {
                return <Redirect to='/'/>
            }
        } else {
            return <LoginForm/>
        }
    }
}

export default withUser(Login);
