import { Client } from './client';
import { API_LOCATION } from '../config';

const erpBrokerClinet = new Client(`${API_LOCATION}/erp-broker/api`);

export function mapAccountPlan(accounts, klasseA, klasseB) {
    return erpBrokerClinet.postResource('/accounts/apply-mapping', {
        accounts,
        company: {
            klasseA,
            klasseB,
        },
    });
}