import React from 'react';
import DatePicker from 'atoms/DatePicker';
import SubSection from './SubSection';

const DateRule = props => {
    const { disabled, rule, updateRule } = props;

    const unix2iso = unix => {
        return new Date(unix).toISOString().substr(0, 10);
    };

    const inputField = (
        <DatePicker
            placeholder='Vælg en dato'
            disabled={disabled}
            value={rule?.compareValue}
            onChange={value => updateRule({ compareValue: unix2iso(value) })}
        />
    );

    return (
        <SubSection
            input={inputField}
            {...props}
        />
    );
};

export default DateRule;