import React from 'react';
import { Grid, Header, Icon, Segment } from 'semantic-ui-react';

const ProductCard = ({ name, description, icon, actions, loading }) => {
    return (
        <Segment loading={loading}>
            <Grid columns={3} verticalAlign='middle'>
                <Grid.Column width={1} textAlign='left'>
                    <Icon
                        name={icon}
                        circular
                        size='large'
                        color='green'
                    />
                </Grid.Column>
                <Grid.Column width={14}>
                    <Header>{name}</Header>
                    {description}
                </Grid.Column>
                <Grid.Column width={1} textAlign='right'>
                    {actions}
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default ProductCard;