import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';

export default class Checkboxn extends Component {
    onChange = () => {
        if (this.props.onChange) {
            const checked = !!this.props.checked;
            this.props.onChange({ target: { value: !checked } });
        }
    };

    render () {
        const checked = !!this.props.checked;
        return <Checkbox {...this.props} checked={checked} onChange={this.onChange}/>;
    }
}