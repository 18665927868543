import React, { Component } from 'react';
import { Form, Header, Button, Label } from 'semantic-ui-react';
import crc32 from '../../../../shared/crc32';
import http from '../../../../http/productEngine';

const DT_NUM  = 'Number';
const DT_STR  = 'String';
const DT_ENUM = 'EnumString';
const DT_DATE = 'Date';
const DT_BOOL = 'Boolean';

const styles = {
    inputForm: {
        width: '100%',
        padding: '0px',
        borderSpacing: '0px',
    }
};

export default class CalcTester extends Component {
    state = {
        open: true,
        references: {}
    };

    getConnectedNodes = () => {
        const { nodes, edges } = this.props;
        const out = [];
        for (let node of nodes) {
            if (edges.includes(node.id)) {
                out.push(node);
            }
        }
        return out;
    };

    setFormValue = (propName, dataType) => {
        let format;
        switch (dataType) {
            case DT_NUM:
                format = v => Number(v);
                break;
            case DT_DATE:
                format = v => Date.parse(v) / 1000;
                break;
            case DT_ENUM:
            case DT_STR:
                format = v => crc32(v);
                break;
            case DT_BOOL:
                format = v => Number(v);
                break;
            default:
                format = v => v;
        }
        return e => {
            const prop = e.target.getAttribute('prop');
            this.setState({ 
                references: {
                    ...this.state.references,
                    [propName]: format(e.target[prop])
                },
                result: undefined
            })
        };
    };

    getInputComponent = (id, dataType) => {
        let props;
        // prop = which property to get the value of the input field from
        switch (dataType) {
            case DT_NUM:
                props = { type: 'number', prop: 'value' };
                break;
            case DT_DATE:
                props = { type: 'date', prop: 'value' };
                break;
            case DT_BOOL:
                props = { type: 'checkbox', prop: 'checked' };
                break;
            default:
                props = { prop: 'value' };
        }
        return <input 
            onChange={this.setFormValue(id, dataType)}
            {...props}
        />;
    };

    renderTestInput = () => {
        const connectedNodes = this.getConnectedNodes();
        return connectedNodes.map(({ id, name, dataType }) => {
            const InputComponent = this.getInputComponent(id, dataType);
            return (
                <Form.Field>
                    <label>{name}</label>
                    {InputComponent}
                </Form.Field>
            );
        });
    };

    renderForm = () => {
        if (!this.state.open) {
            return null;
        }
        return (
            <Form style={styles.inputForm}>
                {this.renderTestInput()}
                <Form.Field>
                    <Button
                        primary
                        basic
                        onClick={this.performTest}
                        content='Kør'
                    />
                </Form.Field>
                {this.renderTestResult()}
                {this.renderError()}
            </Form>
        );
    }; 

    toggleOpen = () => {
        this.setState({
            open: !this.state.open
        });
    };

    performTest = async () => {
        try {
            const pTokens = JSON.parse(this.props.tokens);
            const connectedNodes = this.getConnectedNodes();
            const references = this.state.references;
            for (let { id } of connectedNodes) {
                if (references[id] === undefined) {
                    references[id] = 0;
                }
            }
            const result = await http.testCalculation(pTokens, references);
            this.setState({
                result,
                error: null
            });
        } catch (e) {
            this.setState({
                result: undefined,
                error: 'Udregning fejlede: ' + e
            });
        }
    };

    renderTestResult = () => {
        if (this.state.result === undefined) {
            return null;
        }
        return (
            <Form.Field>
                <Header size='small'>Resultat: <u>{this.state.result}</u></Header>
            </Form.Field>
        );
    };

    renderError = () => {
        if (!this.state.error) {
            return null;
        }
        return (
            <Form.Field>
                <Label
                    basic
                    pointing='above'
                    color='red'
                    icon='warning'
                    content={this.state.error}
                />
            </Form.Field>
        );
    };

    render () {
        return this.renderForm();
    }
}