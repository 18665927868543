import React from 'react';
import { List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function ClientList ({ clients, root }) {
    const clientItems = clients.map(client => {
        const displayName = client.getDisplayName();
        return <List.Item>
            <Link
                to={`${root}/${client.id}`}
                children={displayName}
            />
        </List.Item>;
    });

    return <List divided>
        {clientItems}
    </List>;
}

export default ClientList;