/**
 * @param {Element} element
 * @param {WindowEventMap} handlers
 * @returns {() => void} a function to unregister all registered events handlers
 */
 export const eventListenerHub = (element, handlers) => {
    for (const [event, handler] of Object.entries(handlers)) {
        element.addEventListener(event, handler);
    }

    return () => {
        for (const [event, handler] of Object.entries(handlers)) {
            element.removeEventListener(event, handler);
        }
    };
};