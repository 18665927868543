import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const TABS = [
    {
        path: '/emails/campaigns',
        name: 'Kampagner',
        icon: 'send',
    },
    {
        path: '/emails/templates',
        name: 'Mailskabeloner',
        icon: 'file',
    },
    {
        path: '/emails/onboarding',
        name: 'Onboarding',
        icon: 'plane',
    },
];

function Navigation ({ location, history }) {
    const { pathname } = location;
    return <Menu pointing>
        {TABS.map(({ path, name, icon }) => {
            const active = pathname.startsWith(path);
            return <Menu.Item
                key={path}
                content={name}
                icon={icon}
                active={active}
                link={!active}
                onClick={() => history.push(path)}
            />;
        })}
    </Menu>;
}

export default withRouter(Navigation);