import { collectSuggestions } from './collectSuggestions';

const makeColorizer = ({ autocompleteItems }, colors) => {
    if (autocompleteItems) {
        const validItems = new Set(autocompleteItems.map(item => item.label));
        return subMatch => {
            if (validItems.has(subMatch)) {
                return colors.active;
            }

            return colors.inactive;
        };
    }

    return () => colors.standard;
};

export const analyzeText = ({
    text,
    colors,
    caretIndex,
    syntaxConfiguration = [],
}) => {
    const occupiedRanges = [];
    const colorChangeIndices = {};
    const autocomplete = {
        suggestions: [],
        fullMatch: '',
        query: '',
    };


    for (const syntax of syntaxConfiguration) {
        const { pattern, autocompleteItems, colorOverwrites } = syntax;
        const syntaxColors = {
            ...colors,
            ...(colorOverwrites || {})
        };
        const exp = new RegExp(pattern);
        const colorizer = makeColorizer(syntax, syntaxColors);

        let match;
        while ((match = exp.exec(text))) {
            const [fullMatch, subMatch] = match;
            const start = match.index;
            const subMatchStart = start + (fullMatch.length - subMatch.length);
            const end = start + fullMatch.length;

            if (occupiedRanges.some(range => start >= range.start && end <= range.end)) {
                continue;
            }

            occupiedRanges.push({ start, end });

            colorChangeIndices[start] = syntaxColors.identifier;
            colorChangeIndices[subMatchStart] = colorizer(subMatch);

            if (!colorChangeIndices[end]) {
                colorChangeIndices[end] = colors.standard;
            }

            if (autocompleteItems && caretIndex >= start && caretIndex <= end) {
                autocomplete.query = subMatch;
                autocomplete.suggestions = collectSuggestions(autocompleteItems, subMatch).map(suggestion => ({
                    ...suggestion,
                    subMatchStart,
                    end,
                }));
            }
        }
    }

    return {
        colorChangeIndices,
        autocomplete,
    };
};