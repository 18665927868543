import React from 'react';
import { Dropdown, Icon } from "semantic-ui-react";
import { withUser } from "../../shared/LoginManager";
import styles from './Header.module.css';

const Header = ({ user, openMenu }) => {
    return <header className={styles.header}>
        <div>
            <span className={styles.menuToggle} onClick={openMenu}><Icon name='bars' /></span>
            <h1 className={styles.title}>Digital Revisor Administrationsapp</h1>
        </div>
        {
            user?.user &&
            <div>
                <Dropdown className={`${styles.dropdown}`} text={user.user.displayName} pointing={'top right'}>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Log ud' icon='sign-out' onClick={user.logout} />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        }
    </header>
};

export default withUser(Header);
