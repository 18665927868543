import { Client } from './client';
import { API_LOCATION } from '../config';
import { makeQueryParams } from 'shared/queryParams';

const client = new Client(`${API_LOCATION}/data-warehouse`);

export const countSaasEvents = (fromIsodate, toIsodate, segment, kind) => {
    const query = makeQueryParams({ segment, kind });

    return client.getResource(`/saas-events/from/${fromIsodate}/to/${toIsodate}/count${query}`);
};

export const getSaasEvents = (fromIsodate, toIsodate, segment, kind) => {
    const query = makeQueryParams({ segment, kind });

    return client.getResource(`/saas-events/from/${fromIsodate}/to/${toIsodate}${query}`);
};