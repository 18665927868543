import lodash from 'lodash';
import React, { Component } from 'react';
import { Dropdown, Header, Icon, List, Loader, Menu, Segment, Table } from 'semantic-ui-react';
import { dataTypeDescriptions, dataTypes, nodeTypes } from 'model-editor/nodeMetadata';
import { ruleToCalculation } from 'model-editor/util/rule';
import modelEditor from 'http/modelEditor';
import productEngine from 'http/productEngine';
import interpret from '@digital-revisor/node-formula-interpreter';
import copyToClipboard from 'shared/copyToClipboard';
import findAllReferences from '@digital-revisor/node-formula-interpreter/lib/findAllReferences';
import InputsEditor from './InputsEditor';
import QuestionEditor from './QuestionEditor';
import CalculationEditor from './CalculationEditor';
import FunctionCallsEditor from './FunctionCallsEditor';
import FieldTemplateEditor from './FieldTemplateEditor';
import DeactivationRuleEdtior from './DeactivationRulesEditor';
import AnnualRolloverEditor from './AnnualRolloverEditor';

import styles from './NodeDataMultipleResources.module.css';

const ResourceDescription = ({ properties, annualRolloverValues = [] }) => {
    return (
        <>
            <h3>Ressourceegenskaber</h3>
            <Table basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Navn</Table.HeaderCell>
                        <Table.HeaderCell>Tag</Table.HeaderCell>
                        <Table.HeaderCell>Datatype</Table.HeaderCell>
                        <Table.HeaderCell>Påkrævet?</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {properties.map(({ name, tag, dataType, required }) => {
                        return (
                            <Table.Row key={tag}>
                                <Table.Cell>{name}</Table.Cell>
                                <Table.Cell>
                                    <Icon
                                        link
                                        name='copy'
                                        onClick={() => copyToClipboard(tag)}
                                    />
                                    {tag}
                                </Table.Cell>
                                <Table.Cell>
                                    {dataTypeDescriptions[dataType]}
                                </Table.Cell>
                                <Table.Cell>
                                    {required ? 'Ja' : 'Nej'}
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {annualRolloverValues?.length > 0 && (
                <>
                    <h3>Værdier til årsrulning</h3>
                    <List bulleted>
                        {annualRolloverValues.map(({ id, tag }) => (
                            <List.Item key={id}>{tag}</List.Item>
                        ))}
                    </List>
                </>
            )}
        </>
    );
};

class NodeDataMultipleResources extends Component {
    static lastSelectedTabStorageKey = 'lastSelectedResourceTab';

    constructor(props) {
        super(props);

        this.tabs = [
            {
                id: 'resource',
                icon: 'cog',
                name: 'Ressource',
                render: this.renderResourceTab,
            },
            {
                id: 'inputs',
                icon: 'sign in',
                name: 'Inputs',
                render: this.renderInputsTab,
            },
            {
                id: 'questions',
                icon: 'question circle',
                name: 'Spørgsmål',
                render: this.renderQuestionsTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                icon: 'calculator',
                id: 'calculations',
                name: 'Udregninger',
                render: this.renderCalculationsTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                icon: 'cogs',
                id: 'functions',
                name: 'Funktioner',
                render: this.renderFunctionsTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                id: 'field_templates',
                icon: 'file alternate',
                name: 'Feltskabeloner',
                render: this.renderFieldTemplatesTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                id: 'annual_rollover',
                icon: 'chart bar',
                name: 'Årsrul',
                render: this.renderAnnualRolloverTab,
                isDisabled: () => !this.state.data.resource,
            },
            {
                id: 'deactivation_rules',
                icon: 'power',
                name: 'Regler for deaktivering',
                render: this.renderDeactivationRulesTab,
                isDisabled: () => !this.state.data.resource,
            },
        ];

        // extract node specific data from props
        const data = lodash.get(props, 'node.data', {});

        // default find tab
        let defaultTabIdx = 0;

        const lastSelectedTab = localStorage[NodeDataMultipleResources.lastSelectedTabStorageKey];
        if (lastSelectedTab) {
            const idx = this.tabs.findIndex(t => t.id === lastSelectedTab);
            if (idx !== -1) defaultTabIdx = idx; 
        }

        this.state = {
            activeTabID: this.tabs[defaultTabIdx].id,
            resourceTypes: [],
            signatures: [],
            resourceFunctions: [],
            loading: true,

            // saved server side
            data: {
                resource: data?.resource || '',
                questions: data?.questions || [],
                calculations: data?.calculations || [],
                functionCalls: data?.functionCalls || [],
                fieldTemplates: data?.fieldTemplates || [],
                deactivationRules: data?.deactivationRules || [],
                inputTagsSettings: data?.inputTagsSettings || {},
                annualRolloverMapping: data?.annualRolloverMapping || {},
            },
        };
    }

    componentDidMount = async () => {
        await Promise.all([
            this.loadResourceTypes(),
            this.loadFunctionSignatures(),
            this.loadResourceFunctions(),
        ]);

        this.setState({
            loading: false,
        });
    };

    loadFunctionSignatures = async () => {
        const signatures = await modelEditor.getCalculationFunctions();

        this.setState({ signatures });
    };

    loadResourceTypes = async () => {
        const { data } = this.state;

        const resourceTypes = await productEngine.getResourceTemplates();

        this.setState({
            resourceTypes,
            data,
        });
    };

    loadResourceFunctions = async () => {
        const resourceFunctions = await productEngine.getResourceFunctions();

        this.setState({ resourceFunctions });
    };

    ruleToCalculation = (field, rule, tagMap, isVisibilityRule, isDeactivationRule) => {
        const ruleFieldTag = tagMap[rule.refNode];
        const ruleDataType = ruleFieldTag?.dataType;
        const convertedRule = ruleToCalculation(rule, ruleDataType);
        
        // inject node ID && field ID && type
        convertedRule.nodeID = field.nodeId;
        convertedRule.fieldID = field.id;
        convertedRule.isVisibilityRule = isVisibilityRule;
        convertedRule.isDeactivationRule = isDeactivationRule;

        return convertedRule;
    };

    visibilityRuleToCalculation = (field, rule, tagMap) => {
        return this.ruleToCalculation(field, rule, tagMap, true, false);
    };

    validationRuleToCalculation = (field, rule, tagMap) => {
        return this.ruleToCalculation(field, rule, tagMap, false, false);
    };

    deactivationRuleToCalculation = (rule, tagMap) => {
        return this.ruleToCalculation({}, rule, tagMap, false, true);
    };

    getData = () => {
        const { data } = this.state;
        const tagMap = this.prepareTagMap();

        // prepare calculations
        const parsedCalculations = data.calculations.map(calculation => {
            const parsedExpression = interpret(calculation.expression);
            const refs = findAllReferences(parsedExpression);

            return {
                ...calculation,
                parsedExpression: JSON.stringify(parsedExpression),
                requiredTags: refs,
            };
        });

        // parse rules from field templates
        const parsedRules = [];
        for (let fieldTemplate of data.fieldTemplates) {
            for (let field of fieldTemplate.fields) {
                const {
                    visibilityRules,
                    validationRules,
                } = field;

                visibilityRules && parsedRules.push(...visibilityRules.map(rule => {
                    return this.visibilityRuleToCalculation(field, rule, tagMap);
                }));

                validationRules && parsedRules.push(...validationRules.map(rule => {
                    return this.validationRuleToCalculation(field, rule, tagMap);
                }));
            }
        }

        // parse deactivation rules
        data.deactivationRules.forEach(rule => {
            parsedRules.push(this.deactivationRuleToCalculation(rule, tagMap));
        });

        return {
            ...data,
            calculations: parsedCalculations,
            rules: parsedRules,
        };
    };

    updateData = mutator => {
        const { data } = this.state;

        for (let [key, value] of Object.entries(mutator)) {
            data[key] = value;
        }

        this.setState({ data });
    };

    getActiveTab = () => {
        return this.tabs.find(tab => tab.id === this.state.activeTabID);
    };

    getChosenResource = () => {
        const { resourceTypes, data } = this.state;

        if (!data.resource) {
            return null;
        }

        return resourceTypes.find(type => {
            return type.slug === data.resource;
        });
    };

    getDependencyNodes = () => {
        const edges = this.props.node?.edges || [];
        const nodes = this.props.nodes;

        return edges.map(edge => {
            return nodes.find(node => node.id === edge);
        });
    };

    prepareFieldTag = ({ id, name, dataType, nodeType }) => {
        return {
            id,
            name,
            dataType,
            type: nodeType,
        };
    };

    prepareFieldTags = () => {
        const { resource, calculations, questions, functionCalls } = this.state.data;
        const dependencyNodes = this.getDependencyNodes();

        // prepare properties from selected resource
        const resourceTags = [];

        if (resource) {
            const { properties, annualRolloverValues } = this.getChosenResource();

            resourceTags.push(...(properties || []).map(prop => {
                return this.prepareFieldTag({
                    id: prop.tag,
                    name: prop.name,
                    dataType: prop.dataType,
                    nodeType: nodeTypes.StaticValue,
                });
            }));

            resourceTags.push(...(annualRolloverValues || []).map(({ tag }) => {
                return this.prepareFieldTag({
                    id: tag,
                    name: tag,
                    dataType: dataTypes.NUMBER,
                    nodeType: nodeTypes.StaticValue,
                });
            }));
        }

        calculations.forEach(({ tag }) => {
            resourceTags.push(this.prepareFieldTag({
                name: tag,
                id: tag,
                dataType: dataTypes.NUMBER,
                nodeType: nodeTypes.Calculation,
            }));
        });
        
        questions.forEach(({ tag, type }) => {
            resourceTags.push(this.prepareFieldTag({
                name: tag,
                id: tag,
                dataType: type,
                nodeType: nodeTypes.Question,
            }));
        });

        functionCalls.forEach(call => {
            const resourceFunction = this.state.resourceFunctions.find(({ functionID }) => functionID === call.functionID);
            if (!resourceFunction) {
                return;
            }

            for (const [id, tag] of Object.entries(call.outputMappings)) {
                if (!tag) {
                    continue;
                }
                
                const functionOutput = resourceFunction.outputDefinitions.find(output => output.id === id);
                if (!functionOutput) {
                    continue;
                }

                resourceTags.push({
                    name: tag,
                    id: tag,
                    dataType: functionOutput.dataType,
                    nodeType: nodeTypes.StaticValue, 
                });
            }
        });

        dependencyNodes.forEach(node => {
            if (!node.tag) {
                return;
            }

            resourceTags.push(this.prepareFieldTag({
                name: node.name,
                id: node.tag,
                dataType: node.dataType,
                nodeType: nodeTypes.StaticValue,
            }));
        });

        return resourceTags;
    };

    prepareTagMap = () => {
        const fieldTags = this.prepareFieldTags();
        const tagMap = {};
        for (let fieldTag of fieldTags) {
            tagMap[fieldTag.id] = fieldTag;
        }
        return tagMap;
    };

    renderActiveTab = () => {
        const activeTab = this.getActiveTab();

        return activeTab.render();
    };

    setActiveTab = id => {
        localStorage[NodeDataMultipleResources.lastSelectedTabStorageKey] = id;
        this.setState({ activeTabID: id });
    };

    renderTab = ({ id, name, icon, isDisabled }, activeTabID) => {
        let tabIsDisabled;

        // decide whether the tab is active
        if (isDisabled instanceof Function) {
            tabIsDisabled = isDisabled();
        } else {
            tabIsDisabled = false;
        }

        return (
            <Menu.Item        
                content={name}
                icon={icon}
                active={id === activeTabID}
                onClick={() => this.setActiveTab(id)}
                disabled={tabIsDisabled}
            />
        );
    };

    renderTabsMenu = () => {
        const activeTab = this.getActiveTab();
        const activeTabID = activeTab.id;

        return (
            <Menu secondary pointing>
                {this.tabs.map(tab => this.renderTab(tab, activeTabID))}
            </Menu>
        );
    };

    renderResourceTab = () => {
        const chosenResourceID = this.state.data.resource;
        const options = this.state.resourceTypes.map(({ slug, name }) => ({
            text: name,
            value: slug,
        }));

        // render details about resource, if picked
        let resourceDetails;
        if (chosenResourceID) {
            const chosenResource = this.getChosenResource();

            resourceDetails = (
                <ResourceDescription {...chosenResource} />
            );
        }

        return (
            <div>
                <Header>Vælg ressource:</Header>
                <Dropdown
                    search
                    selection
                    fluid
                    defaultValue={chosenResourceID}
                    options={options}
                    onChange={(_, { value }) => {
                        this.updateData({ resource: value });
                    }}
                />
                {resourceDetails}
            </div>
        );
    };

    renderInputsTab = () => {
        return (
            <InputsEditor
                inputNodes={this.getDependencyNodes()}
                inputTagsSettings={this.state.data.inputTagsSettings}
                onChange={inputTagsSettings => this.updateData({ inputTagsSettings })}
            />
        );
    };

    renderQuestionsTab = () => {
        return (
            <QuestionEditor
                questions={this.state.data.questions}
                onChange={questions => this.updateData({ questions })}
            />
        );
    };

    renderCalculationsTab = () => {
        const { calculations } = this.state.data;
        const fieldIds = this.prepareFieldTags();

        return (
            <CalculationEditor
                calculations={calculations}
                fieldIds={fieldIds}
                signatures={this.state.signatures}
                onChange={calculations => this.updateData({ calculations })}
            />
        );
    };

    renderFunctionsTab = () => {
        return (
            <FunctionCallsEditor
                fieldIds={this.prepareFieldTags()}
                functionCalls={this.state.data.functionCalls}
                onChange={functionCalls => this.updateData({ functionCalls })}
                resourceFunctions={this.state.resourceFunctions}
            />
        );
    };

    renderFieldTemplatesTab = () => {
        // expose resource properties, questions and calculations to the field manager
        const fieldIds = this.prepareFieldTags();

        const radioOptionsGetter = tag => {
            const question = this.state.data.questions.find(question => question.tag === tag);
            if (question) {
                return (question.options || []).map(({ optionText, optionValue }) => {
                    return {
                        text: optionText,
                        value: optionValue,
                        key: optionValue,
                    };
                });
            }
            
            const resourceProp = this.getChosenResource()?.properties?.find(property => property.tag === tag);
            if (resourceProp) {
                return (resourceProp.options || []).map(({ label, value }) => {
                    return {
                        text: label,
                        value: value,
                        key: value,
                    };
                })
            }

            return [];
        };
        
        return (
            <FieldTemplateEditor
                fieldIds={fieldIds}
                fieldTemplates={this.state.data.fieldTemplates}
                onChange={fieldTemplates => this.updateData({ fieldTemplates })}
                radioOptionsGetter={radioOptionsGetter}
            />
        );
    };

    renderDeactivationRulesTab = () => {
        const fieldIds = this.prepareFieldTags();

        return (
            <DeactivationRuleEdtior
                fieldIds={fieldIds}
                deactivationRules={this.state.data.deactivationRules}
                onChange={deactivationRules => this.updateData({ deactivationRules })}
            />
        );
    };

    renderAnnualRolloverTab = () => {
        const resource = this.getChosenResource();
        const { questions, calculations, annualRolloverMapping, functionCalls } = this.state.data;

        return (
            <AnnualRolloverEditor
                resource={resource}
                questions={questions}
                calculations={calculations}
                functionCalls={functionCalls}
                annualRolloverMapping={annualRolloverMapping}
                onChange={annualRolloverMapping => this.updateData({ annualRolloverMapping })}
            />
        );
    };

    render = () => {
        const { loading } = this.state;

        if (loading) {
            return (
                <Loader inline='centered' active />
            );
        }

        return (
            <Segment className={styles.container}>
                {this.renderTabsMenu()}
                {this.renderActiveTab()}
            </Segment>
        );
    };
}

export default NodeDataMultipleResources;