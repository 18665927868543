import React from 'react';
import { Input } from 'semantic-ui-react';
import { taxonomyDatalistID } from 'model-editor/atoms/TaxonomyDataList';

const TaxonomyTagInput = props => {
    return (
        <Input
            list={taxonomyDatalistID}
            {...props}
        />
    );
};

export default TaxonomyTagInput;