import React from 'react';
import { Grid, Header, Icon, Menu } from 'semantic-ui-react';

const SideMenu = ({ menuItems, activeItemID, content, onMenuItemClicked }) => {
    const activeItem = menuItems.find(item => item?.id === activeItemID);

    return (
        <Grid stackable>
            <Grid.Column width={4}>
                <Menu vertical pointing secondary fluid>
                    {menuItems.map(item => {
                        if (!item) {
                            return null;
                        }

                        const { id, title, icon } = item;

                        return (
                            <Menu.Item
                                key={id}
                                active={id === activeItemID}
                                content={title}
                                icon={icon}
                                onClick={() => onMenuItemClicked(id)}
                            />
                        );
                    })}
                </Menu>
            </Grid.Column>
            <Grid.Column width={12}>
                {activeItem?.title && activeItem.subtitle && (
                    <Header>
                        <Icon name={activeItem.icon} />
                        <Header.Content>
                            {activeItem.title}
                            {
                                activeItem.subtitle &&
                                <Header.Subheader>
                                    {activeItem.subtitle}
                                </Header.Subheader>
                            }
                        </Header.Content>
                    </Header>
                )}
                {content}
            </Grid.Column>
        </Grid>
    );
};

export default SideMenu;