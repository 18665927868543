import React from 'react';
import { Link } from 'react-router-dom';
import {
    Header,
    Segment,
    Form,
    Input,
    Icon,
    List,
    Message,
    Loader,
    Label,
} from 'semantic-ui-react';

import { APP_LOCATION, STRIPE_DASHBOARD_LOCATION } from 'config';
import accountHttp                   from 'http/accounts';
import paymentHttp                   from 'http/payment';
import RoleEditor                    from 'molecules/RoleEditor';
import CopyToClipboard               from 'atoms/CopyToClipboard';
import { formatUnix }                from 'shared/dateTimeFormatting';
import { confirm }                   from 'shared/globalModal';
import ActionItem                    from 'pages/Accounts/ActionItem';
import SideMenu                      from 'pages/Accounts/SideMenu';
import ActivityList                  from './ActivityList';
import PaymentAdministration         from './PaymentAdministration';
import PricesControlPanel            from './PricesControlPanel';
import ClientList                    from './ClientList';
import ProductStates                 from './ProductStates';
import ChatHistory                   from './ChatHistory';
import FileStorage                   from './FileStorage';
import InvoiceListing                from './InvoiceListing';
import Credits                       from './Credits';
import Logins                        from './Logins';
import TawkToHistory                 from './TawkToHistory';
import AddonProducts                 from './PaymentAdministration/AddonProducts';
import styles                        from './AccountDetail.module.css';

function TruthyField ({ children, value }) {
    if (!value) {
        return null;
    }
    return <Form.Field>
        {children}
    </Form.Field>;
}

class AccountsDetail extends React.Component {
    constructor() {
        super();
        this.tabs = [
            {
                id: 'TAB_ACCOUNT_DETAILS',
                title: 'Kontodetaljer',
                subtitle: 'Oversigt over kontoen',
                icon: 'dna',
                render: this.renderAccountDetails,
            },
            {
                id: 'TAB_ROLES',
                title: 'Roller',
                subtitle: 'Tilføj eller fjern roller for kontoen',
                icon: 'address card',
                render: this.renderRoleManagerView,
            },
            {
                id: 'TAB_LOGINS',
                title: 'Logins',
                subtitle: 'Oversigt over logins knyttet til brugeren',
                icon: 'users',
                shouldRender: user => !user.isClient(),
                render: this.renderLoginsView,
            },
            {
                id: 'TAB_PAYMENT_ADMINISTRATION',
                title: 'Betalingsadministration',
                subtitle: 'Administrer produktadgange, abonnementer og enkeltkøb',
                icon: 'unlock alternate',
                shouldRender: user => !user.isAccountant(),
                render: this.renderPaymentAdministrationView,
            },
            {
                id: 'TAB_ACCOUNTANT_COURSE',
                title: 'Rådgiverkursus',
                subtitle: 'Administrer adgange til rådgiverkursus',
                icon: 'university',
                shouldRender: user => user.isAccountant(),
                render: this.renderAccountantCourse,
            },
            {
                id: 'TAB_USER_ACTIVITY',
                title: 'Aktivitet',
                subtitle: 'Følg brugerens aktiviteter i systemet',
                icon: 'chart bar outline',
                render: this.renderActivityView,
            },
            {
                id: 'TAB_PRODUCT_STATES',
                title: 'Produktstatuser',
                subtitle: 'Tjek brugerens fremgang gennem produkterne',
                icon: 'line graph',
                render: this.renderProductStatesView,
                shouldRender: user => !user.isAccountant(),
            },
            {
                id: 'TAB_PRICE_LIST',
                title: 'Prisaftale',
                subtitle: 'Opdater bogholderens priser for de forskellige produkter og skatteår',
                icon: 'list ol',
                shouldRender: user => user && user.roles && user.roles.includes('revisor'),
                render: this.renderPricesControlPanel,
            },
            {
                id: 'TAB_CHAT_HISTORY',
                title: 'Chathistorik',
                subtitle: 'Tjek brugerens chathistorik',
                icon: 'talk',
                shouldRender: user => !user.isClient(),
                render: this.renderChatHistory,
            },
            {
                id: 'TAB_TAWK_TO_HISTORY',
                title: 'Tawk.to beskedhistorik',
                subtitle: 'Tjek brugerens beskedhistorik',
                icon: 'chat',
                shouldRender: user => !user.isClient(),
                render: this.renderTawkToHistory,
            },
            {
                id: 'TAB_FILE_STORAGE',
                title: 'Filer',
                subtitle: 'Tjek brugerens uploadede filer',
                icon: 'file',
                render: this.renderFileStorage,
                shouldRender: user => !user.isAccountant(),
            },
            {
                id: 'TAB_INVOICE_LISTING',
                title: 'Købshistorik',
                subtitle: 'Lister alle bogholderens køb',
                icon: 'file alternate outline',
                render: this.renderInvoiceList,
                shouldRender: user => user && user.roles && user.roles.includes('revisor'),
            },
            {
                id: 'TAB_CREDITS',
                title: 'Credits',
                subtitle: null,
                icon: 'dollar',
                render: this.renderCredits,
                shouldRender: user => user && user.roles && user.roles.includes('revisor'),
            },
        ];
        this.state = {
            ready: false,
            error: null,
            account: {},
            productStates: {},
            subscriptions: [],
            clients: null,
            activeMenuItem: this.tabs[0].id
        };
    }

    componentDidMount () {
        this.readData();

        // set default tab based on hash
        const hash = window.location.hash;
        if (hash) {
            const hashValue = hash.substring(1);

            // search for tab with ID from hash
            const tab = this.tabs.find(t => t.id === hashValue);
            if (tab) {
                this.setState({ activeMenuItem: tab.id });
            }
        }
    }

    componentDidUpdate = ({ match }) => {
        const { id, email } = this.props.match.params;

        const paramsChanged = (
            match.params.id !== id ||
            match.params.email !== email
        );

        // if id or email change, refetch account data
        if (paramsChanged) {
            this.readData();
        }
    };

    readAccount = () => {
        const { id, email } = this.props.match.params;
        
        if (id) {
            return accountHttp.getAccountByID(id);
        }

        if (email) {
            return accountHttp.getAccount(email);
        }

        throw new Error('Neither uid or email provided');
    };

    readClients = account => {
        if (!account.isAccountant()) {
            return null;
        }

        return accountHttp.getAccountantClients(account.id);
    };

    readCreator = account => {
        if (!account.isClient()) {
            return null;
        }

        return accountHttp.getAccountByID(account.creator);
    };

    readStripeID = async account => {
        const resp = await paymentHttp.getCustomerStripeID(account.id);
        return resp.data
    };

    readData = async () => {
        this.setState({
            ready: false,
            error: null,
        });

        try {
            // read account by ID or email
            const account = await this.readAccount();

            // read relevant information about the account
            const [clients, creator, stripeID] = await Promise.all([
                this.readClients(account),
                this.readCreator(account),
                this.readStripeID(account),
            ]);
            
            this.setState({
                ready: true,
                account,
                clients,
                creator,
                stripeID,
                activeMenuItem: this.tabs[0].id,
            });
        } catch (e) {
            this.setState({
                ready: true,
                error: e.message,
            });
        }
    };

    refetchAccount = async () => {
        const { email } = this.props.match.params;
        const { account } = await accountHttp.getAccount(email);
        this.setState({ account });
    };

    toggleAccountSuspension = async () => {
        const { id, suspended } = this.state.account;
        let confirmMsg;
        if (suspended) {
            confirmMsg = 'Er du sikker på at du vil låse kontoen op?';
        } else {
            confirmMsg = 'Er du sikker på du vil suspendere kontoen?';
        }
        const sure = await confirm(confirmMsg);
        if (!sure) {
            return;
        }
        await accountHttp.setSuspension(id, !suspended);
        await this.refetchAccount();
    };

    getRouteRoot = () => {
        const { path } = this.props.match;
        const parts = path.split('/').filter(v => v);

        // remove last part of path if it's a parameter
        if (parts.at(-1)[0] === ':') {
            parts.splice(parts.length -1, 1);
        }

        return '/' + parts.join('/');
    };

    renderSuspensionToggler = () => {
        const { suspended } = this.state.account;

        let props;
        if (suspended) {
            props = {
                content: 'Lås konto op',
                icon: 'lock open'
            };
        } else {
            props = {
                content: 'Suspender konto',
                icon: 'lock'
            };
        }
        
        return <ActionItem
            onClick={this.toggleAccountSuspension}
            {...props}
        />;
    };

    renderImpersonationLink = () => {
        const { id } = this.state.account;
        const link = `${APP_LOCATION}/impersonation?impuid=${id}`;
        return <ActionItem
            content='Impersoner'
            icon='spy'
            onClick={() => window.open(link, '_blank')}
        />;
    };

    renderFormValueString = (value, cpy = false) => {
        let copyBtn;
        if (value && cpy) {
            copyBtn = <CopyToClipboard text={value}>
                <Label
                    basic
                    className={styles.copy}
                    content={<Icon name='copy' fitted />}
                />
            </CopyToClipboard>;
        }

        return <Input
            className={styles.valueDisplayer}
            defaultValue={value}
            label={copyBtn}
            labelPosition={copyBtn ? 'right' : undefined}
            disabled
        />;
    };

    renderFormValueStringArray = value => {
        let toDisplay;
        if (value && value.length === 0) {
            toDisplay = '<ingen>'
        } else {
            toDisplay = value.join(', ');
        }
        return this.renderFormValueString(toDisplay);
    };

    renderFormValueBool = value => {
        return this.renderFormValueString(value ? 'Ja' : 'Nej');
    };

    renderFormValueUnix = value => {
        return this.renderFormValueString(formatUnix(value));
    };

    renderAccountDetails = () => {
        const { account: a } = this.state;
        return (
            <Form className={styles.accountInfoForm}>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Navn</label>
                        {this.renderFormValueString(a.displayName, true)}
                    </Form.Field>
                    <Form.Field>
                        <label>Faktureringsmail</label>
                        {this.renderFormValueString(a.email, true)}
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <label>Internt ID</label>
                    {this.renderFormValueString(a.id, true)}
                </Form.Field>
                <TruthyField value={a.cvr}>
                    <label>CVR-nummer</label>
                    {this.renderFormValueString(a.cvr, true)}
                </TruthyField>
                <TruthyField value={a.businessForm}>
                    <label>Virksomhedsform</label>
                    {this.renderFormValueString(a.businessForm)}
                </TruthyField>
                <TruthyField value={a.erp}>
                    <label>ERP-system</label>
                    {this.renderFormValueString(a.erp)}
                </TruthyField>
                <TruthyField value={a.referrer}>
                    <label>Henviser</label>
                    {this.renderFormValueString(a.referrer)}
                </TruthyField>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Oprettet d.</label>
                        {this.renderFormValueUnix(a.createdAt)}
                    </Form.Field>
                    <Form.Field>
                        <label>Sidst aktiv d.</label>
                        {this.renderFormValueUnix(a.latestActivity)}
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <label>Suspenderet</label>
                    {this.renderFormValueBool(a.suspended)}
                </Form.Field>
                {this.renderAccountantClients()}
                {this.renderAccountActions()}
            </Form>
        );
    };

    renderCVRLink = () => {
        const { cvr } = this.state.account;

        return (
            cvr &&
            <ActionItem
                content='Se på CVR'
                icon='external'
                onClick={() => window.open(`https://datacvr.virk.dk/enhed/virksomhed/${cvr}`, '_blank')}
            />
        );
    };

    renderAccountActions = () => {
        return <Form.Field>
            <label>Handlinger</label>
            <List>
                {this.renderImpersonationLink()}
                {this.renderCVRLink()}
                {this.renderStripeLink()}
                {this.renderSuspensionToggler()}
            </List>
        </Form.Field>;
    };

    renderAccountantClients = () => {
        const { clients } = this.state;
        if (clients) {
            return <Form.Field>
                <label>Klienter</label>
                <ClientList root={this.getRouteRoot()} clients={clients} />
            </Form.Field>;
        }
    };

    renderCreatorLink = () => {
        const { creator } = this.state;
        if (creator) {
            return <Link
                children={creator.getDisplayName()}
                to={`${this.getRouteRoot()}/${creator.id}`}
            />;
        }
    };

    renderStripeLink = () => {
        const { stripeID } = this.state;
        if (stripeID) {
            const stripeLink = `${STRIPE_DASHBOARD_LOCATION}/customers/${stripeID}`;
            return <ActionItem
                content='Se kunde på Stripe Dashboard'
                icon='arrow circle right'
                onClick={() => window.open(stripeLink, '_blank')}
            />;
        }
    };

    rolesUpdated = roles => {
        const { account } = this.state;
        account.roles = roles;
        this.setState({ account });
    };

    renderRoleManagerView = () => {
        const { roles, id } = this.state.account;
        return <RoleEditor roles={roles} userID={id} onChange={this.rolesUpdated} />;
    };

    renderLoginsView = () => {
        return <Logins uid={this.state.account.id} />;
    };

    renderActivityView = () => {
        const { activities } = this.state.account;
        return <ActivityList activities={activities} />;
    };

    renderPaymentAdministrationView = () => {
        const { account } = this.state;
        let props;
        if (account.isClient()) {
            props = {
                accountantID: account.creator,
                clientID: account.id
            };
        } else {
            props = {
                uid: account.id,
            };
        }
        return <PaymentAdministration {...props} account={account} />;
    };

    renderAccountantCourse = () => {
        return <AddonProducts account={this.state.account} />;
    };

    renderProductStatesView = () => {
        const { account } = this.state;

        return <ProductStates userID={account.id} roles={account.roles} />;
    };

    renderPricesControlPanel = () => {
        const { id } = this.state.account;
        return <PricesControlPanel uid={id} />;
    };

    renderChatHistory = () => {
        return <ChatHistory user={this.state.account} />;
    };

    renderTawkToHistory = () => {
        return <TawkToHistory uid={this.state.account.id} />;
    };

    renderFileStorage = () => {
        return <FileStorage user={this.state.account} />;
    };

    renderInvoiceList = () => {
        return <InvoiceListing user={this.state.account} />;
    };

    renderCredits = () => {
        return <Credits user={this.state.account} />;
    };

    changeMenuItem = activeMenuItem => {
        return this.setState({ activeMenuItem });
    };

    renderActiveTab = () => {
        if (!this.state.ready) {
            return (
                <>
                    <Loader inline active />
                    <br />
                    Indlæser...
                </>
            );
        }

        const activeTab = this.tabs.find(t => t.id === this.state.activeMenuItem);
        return activeTab.render();
    };

    renderMainHeader = () => {
        const { ready, account } = this.state;

        let icon;
        let title;
        if (ready) {
            icon = account.getIcon();
            title = account.getTitle();
        } else {
            icon = 'spinner';
        }

        return (
            <Header as='h3'>
                <Icon
                    name={icon}
                    circular
                    loading={!ready}
                />
                <Header.Content>
                    {this.state.account?.displayName}
                    {
                        title &&
                        <Header.Subheader>
                            {title}
                            {
                                account.isClient() &&
                                <span>
                                    &nbsp;oprettet af {this.renderCreatorLink()}
                                </span>
                            }
                        </Header.Subheader>
                    }
                </Header.Content>
            </Header>
        );
    };

    render () {
        const { error } = this.state;

        if (error) {
            return (
                <Segment>
                    <Message
                        color='red'
                        icon='warning circle'
                        header='Der opstod en fejl'
                        content={error}
                    />
                </Segment>
            );
        }

        
        return (
            <div>
                {this.renderMainHeader()}
                <Segment>
                    <SideMenu
                        menuItems={this.tabs.map(({ id, title, subtitle, icon, shouldRender }) => {
                            if (typeof shouldRender === 'function') {
                                if (!this.state.ready) {
                                    return null;
                                }

                                if (!shouldRender(this.state.account)) {
                                    return null;
                                }
                            }

                            return {
                                id,
                                title,
                                subtitle,
                                icon,
                            };
                        })}
                        activeItemID={this.state.activeMenuItem}
                        onMenuItemClicked={this.changeMenuItem}
                        content={this.renderActiveTab()}
                    />
                </Segment>
            </div>
        );
    }

}

export default AccountsDetail;
