import React, { useCallback, useEffect, useState } from 'react';
import reportEditor from 'http/reportEditor';
import documentGenerator from 'http/documentGenerator';
import { useReportTemplatesConfig } from '../config';
import * as blockService from '../blockService';
import model from '../model';
import ValidationError from '../ValidationError';
import TestPDFViewer from './TestPDFViewer';

const executeTheTest = async (variables, userContext, template) => {
    let documentComponents;
    try {
        documentComponents = await reportEditor.testTemplate({ variables, userContext, template });
    } catch (e) {
        return {
            validationError: true,
            data: e.data,
        };
    }

    let document, metadata;
    try {
        ({ document, metadata } = await documentGenerator.buildPDFFromDocumentComponents({
            documentComponents,
        }));
    } catch (e) {
        return {
            validationError: true,
            data: { message: e.message },
        };
    } 
    
    return {
        validationError: false,
        data: [document, metadata, documentComponents],
    };
};

const collectTestVariables = inputDefinitions => {
    const variables = {
        [model.variableNamespaces.activeYear.id]: {},
        [model.variableNamespaces.lastYear.id]: {},
        terms: {},
    };

    inputDefinitions.forEach(({ tag, testValue, testValueLastYear, terms, dataType }) => {
        variables[model.variableNamespaces.activeYear.id][tag] = testValue || '';
        variables[model.variableNamespaces.lastYear.id][tag] = testValueLastYear || '';
        
        if (dataType === model.definitionDataTypes.Number) {
            variables.terms[tag] = terms || [];
        }
    });

    return variables;
};

const TestEditor = ({ metadata, pdfTemplate, inputDefinitions, userContext, textVariations, setPdfTemplate, setTab }) => {
    const [selectedBlockID] = useReportTemplatesConfig('selectedField', null);
    const [pdf, setPdf] = useState('');
    const [sections, setSections] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [renderedBlocks, setRenderedBlocks] = useState([]);

    const doRunTest = useCallback(async () => {
        setLoading(true);
        setSections(null);
        setError(null);

        const variables = collectTestVariables(inputDefinitions);

        const template = {
            metadata,
            inputDefinitions,
            pdfTemplate,
            textVariations,
        };

        const { validationError, data } = await executeTheTest(variables, userContext, template);
        if (validationError) {
            setError(data);
            setLoading(false);
            return;
        }
        
        const [result, docMetadata, renderedBlocks] = data;

        if (docMetadata) {
            const blocks = pdfTemplate?.blocks || [];
            const sections = [];
            const sectionToPageNumber = docMetadata.sectionToPageNumber;
            const ancestor = blockService.findEldestAncestor(blocks, selectedBlockID);

            for (let block of blocks) {
                if (!(block.id in sectionToPageNumber)) {
                    continue;
                }

                sections.push({
                    sectionTitle: blockService.getLabel(block, inputDefinitions, userContext.language),
                    pageNumber: sectionToPageNumber[block.id],
                    defaultActive: block.id === ancestor?.id,
                });
            }

            setSections(sections);
        }

        setPdf(result);
        setRenderedBlocks(renderedBlocks);
        setLoading(false);
    }, [pdfTemplate, selectedBlockID, inputDefinitions, userContext, metadata, textVariations]);

    useEffect(() => {
        doRunTest();
    }, [doRunTest]);

    return (
        <TestPDFViewer
            pdfURL={(
                loading
                    ? null
                    : `data:application/pdf;base64,${pdf}`
            )}
            blocks={renderedBlocks}
            sections={sections}
            error={
                error && <ValidationError
                    error={error}
                    pdfTemplate={pdfTemplate}
                    setPdfTemplate={setPdfTemplate}
                    inputDefinitions={inputDefinitions}
                    userContext={userContext}
                    textVariations={textVariations}
                    setTab={setTab}
                />
            }
        />
    );
};

export default TestEditor;