import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { eventListenerHub } from 'shared/eventListenerHub';
import cns from 'shared/cns';

import styles from './ImageCropper.module.css';

const DRAG_BOUNDING_BOX_SIZE = 8;

const ImageCropper = ({ image, onCrop }) => {
    const [cropBoxScale, setCropBoxScale] = useState([
        [0.0, 0.0],
        [1, 1],
    ]);
    const [loaded, setLoaded] = useState(false);
    const [temp, setTemp] = useState([0, 0]);
    const [clickPoint, setClickPoint] = useState(null);
    const [boxSize, setBoxSize] = useState(null);
    const imgRef = useRef();

    const clamp = v => {
        return Math.max(0, Math.min(1, v));
    };

    useLayoutEffect(() => {
        setLoaded(false);
    }, [image]);

    useLayoutEffect(() => {
        const { width, height } = imgRef.current.getBoundingClientRect();
        setBoxSize([width, height]);
    }, [cropBoxScale, loaded]);

    const getAbsoluteCropBoxPosition = useCallback(() => {
        const activeBorderIndices = new Set(clickPoint && clickPoint.at(-1));
        
        const [x1, y1, x2, y2] = cropBoxScale.flat().map((factor, i) => {
            return boxSize[i % 2] * clamp(factor + (
                activeBorderIndices.size > 0 && !activeBorderIndices.has(i)
                    ? 0
                    : temp[i % 2]
            ));
        });

        const [[hx, lx], [hy, ly]] = [[x1, x2], [y1, y2]].map(coordinates => {
            return coordinates.sort((a, b) => b - a);
        });

        return [lx, ly, hx, hy];
    }, [cropBoxScale, clickPoint, temp, boxSize]);

    useLayoutEffect(() => {
        if (!clickPoint) {
            return;
        }

        const [width, height] = boxSize;

        const handleMouseUp = () => {
            const [p1x, p1y, p2x, p2y] = getAbsoluteCropBoxPosition().map((point, i) => {
                return point / [width, height][i % 2];
            });

            const fullImage = new Image();
            fullImage.src = image

            const imgWidth = fullImage.width;
            const imgHeight = fullImage.height;

            const canvas = document.createElement('canvas');
            canvas.width = (imgWidth * p2x) - (imgWidth * p1x);
            canvas.height = (imgHeight * p2y) - (imgHeight * p1y);
            const context = canvas.getContext('2d');

            context.drawImage(imgRef.current, -(imgWidth * p1x), -(imgHeight * p1y));
            onCrop(canvas.toDataURL('image/jpeg'));

            setCropBoxScale([[p1x, p1y], [p2x, p2y]]);
            setClickPoint(null);
            setTemp([0, 0]);
        };
    
        return eventListenerHub(window, {
            mouseup: handleMouseUp,
            blur: handleMouseUp,
            mousemove: e => {
                const { left, top } = imgRef.current.getBoundingClientRect();
                const mx = e.clientX - left;
                const my = e.clientY - top;
                const [cx, cy] = clickPoint;
                const dx = (mx - cx) / width;
                const dy = (my - cy) / height;
                setTemp([dx, dy]);
            },
        });
    }, [onCrop, image, clickPoint, temp, boxSize, getAbsoluteCropBoxPosition]);

    const handleImageClick = e => {
        const { left, top } = imgRef.current.getBoundingClientRect();

        const [p1x, p1y, p2x, p2y] = getAbsoluteCropBoxPosition();

        const x = e.clientX - left;
        const y = e.clientY - top;

        const borders = [
            [p1x, x],
            [p1y, y],
            [p2x, x],
            [p2y, y],
        ];

        const seenNumberTypes = new Set();
        const selectedBorders = new Set();
        
        borders.forEach(([pointA, pointB], idx) => {
            const diff = Math.abs(pointA - pointB);
            const numberType = idx % 2;
            if (diff <= DRAG_BOUNDING_BOX_SIZE && !seenNumberTypes.has(numberType)) {
                seenNumberTypes.add(numberType);
                selectedBorders.add(idx);
            }
        });
        
        setClickPoint([x, y, selectedBorders]);
    };

    const renderCropOverlay = () => {
        if (!loaded) {
            return null;
        }

        const [lx, ly, hx, hy] = getAbsoluteCropBoxPosition();

        const corners = [
            [lx, ly],
            [hx, ly],
            [hx, hy],
            [lx, hy],
        ];
        
        const [width, height] = boxSize;
        const grayBorders = [
            [[0, 0], [lx, height]],
            [[hx, 0], [width - hx, height]],
            [[lx, 0], [hx - lx, ly]],
            [[lx, hy], [hx - lx, height - hy]],
        ];
        
        const cropZoneWidth = hx - lx;
        const cropZoneHeight = hy - ly;

        return (
            <>
                <div
                    style={{
                        width: `${cropZoneWidth}px`,
                        height: `${cropZoneHeight}px`,
                        left: `${lx}px`,
                        top: `${ly}px`,
                    }}
                    className={styles.overlay}
                />
                {grayBorders.map(([[left, top], [width, height]]) => {
                    return (
                        <div
                            style={{
                                width: `${width}px`,
                                height: `${height}px`,
                                left: `${left}px`,
                                top: `${top}px`,
                                background: 'gray',
                                opacity: 0.5,
                            }}
                            className={styles.overlayShade}
                        />
                    );
                })}
                {corners.map(([x, y]) => {
                    return <div
                        style={{
                            width: `${DRAG_BOUNDING_BOX_SIZE}px`,
                            height: `${DRAG_BOUNDING_BOX_SIZE}px`,
                            left: `${x - (DRAG_BOUNDING_BOX_SIZE / 2)}px`,
                            top: `${y - (DRAG_BOUNDING_BOX_SIZE / 2)}px`,
                            background: 'white',
                            border: '1px solid black'
                        }}
                        className={styles.overlayShade}
                    />
                })}
            </>
        );
    };

    return (
        <div
            className={cns('noselect', styles.wrapper)}
            onMouseDown={handleImageClick}
        >
            <img
                onLoad={() => setLoaded(true)}
                alt='logo'
                ref={imgRef}
                draggable={false}
                className={styles.image}
                src={image}
            />
            {renderCropOverlay()}
        </div>
    );
};

export default ImageCropper;