import React, { Component } from 'react';
import { Loader, Table } from 'semantic-ui-react';
import { formatNumber } from 'shared/formatNumber';

class InternalBookingOverview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tables: [
                {
                    title: 'Sælg i måned',
                    accountGroups: [
                        ['Årsrapport', 'Forældrekøb', 'Opstartshjælp, private'],
                        ['Bogholder årsrapport', 'Opstartshjælp, erhverv'],
                        ['Solceller', 'Værelsesudlejning'],
                        ['Stripe gebyr']
                    ]
                },
                {
                    title: 'Periodiseringposter',
                    accountGroups: [
                        ['Periodeafgrænsning boligudlejning', 'Periodeafgrænsning årsrapport', 'Periodeafgrænsning bogholder årsrapport']
                    ]
                },
            ]
        }
    }

    calculateTotalPerAccount = accountName => {
        const { charges, fetching } = this.props;
        if(!charges) {
            return !fetching ? '-' : <Loader inline active size='tiny'></Loader>;
        }

        let total = 0;

        charges.forEach(charge => {
            charge.bookings.forEach(booking => {
                const lines = booking.lines.filter(line => line.accountName === accountName);
                total += lines.reduce((acc, line) => acc + line.amount, 0);
            });
        });

        return formatNumber(total.toFixed(2)) + ' kr.';
    };

    calculateTotalOverall = accountGroups => {
        const { charges, fetching } = this.props;
        if(!charges) {
            return !fetching ? '-' : <Loader inline active size='tiny'></Loader>;
        }
        const accountNames = accountGroups.reduce((acc, val) => [...acc, ...val], []);
        let total = 0;
        charges.forEach(charge => {
            charge.bookings.forEach(booking => {
                const lines = booking.lines.filter(line => accountNames.includes(line.accountName))
                total += lines.reduce((acc, line) => acc + line.amount, 0)
            });
        });
        
        return formatNumber(total.toFixed(2)) + ' kr.';
    }

    renderTable = (table) => {
        return <>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='2' textAlign='center'>{table.title}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Konto navn</Table.HeaderCell>
                    <Table.HeaderCell>Beløb</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    table.accountGroups.map((ag, i) => {
                        const accountLines = ag.map(name => {
                            return <Table.Row>
                                <Table.Cell>{name}</Table.Cell>
                                <Table.Cell textAlign='center'>{this.calculateTotalPerAccount(name)}</Table.Cell>
                            </Table.Row>
                        });
                        const space = i !== table.accountGroups.length - 1 ? <Table.Row><Table.Cell colSpan='2'></Table.Cell></Table.Row> : null;
                        return <>
                            {accountLines}
                            {space}
                        </>;
                    })
                }
                <Table.Row>
                    <Table.Cell textAlign='right'><b>I alt</b></Table.Cell>
                    <Table.Cell textAlign='center'><b>{this.calculateTotalOverall(table.accountGroups)}</b></Table.Cell>
                </Table.Row>
            </Table.Body>
        </>
    };

    render = () => {
        const { tables } = this.state;
        return <Table celled unstackable>
            {
                tables.map(table => this.renderTable(table))
            }
        </Table>;
    };
}

export default InternalBookingOverview;