import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import Invoice from './Invoice';

function InvoiceDetail () {
    return (
        <Segment padded>
            <Header as='h1'>Fakturadetaljer</Header>
            <Invoice />
        </Segment>
    );
}

export default InvoiceDetail;