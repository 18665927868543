import React from 'react';
import RuleManager from 'model-editor/molecules/RuleManager/RuleManager';
import { Form } from 'semantic-ui-react';

const DeactivationRulesEditor = ({ deactivationRules, fieldIds, onChange }) => {
    return <Form style={{ width: '100%' }}>
        <RuleManager
            nodes={fieldIds}
            rules={deactivationRules}
            modelId={null}
            getOptionsByID
            deliverData={onChange}
        />
    </Form>;
};

export default DeactivationRulesEditor;