import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import Registrations from './Registrations';
import LoginsMaster from './Logins/master';
import LoginsDetail from './Logins/detail';
import ReportingEntitiesMaster from './ReportingEntities/master';
import ReportingEntitiesDetail from './ReportingEntities/detail';


const Accounts = () => {
    const location = useLocation();
    const history = useHistory();

    return (
        <Container>
            <Menu>
                <Menu.Item
                    content='Indberetningsenheder'
                    icon='users'
                    active={location.pathname.startsWith('/accounts/reporting-entities')}
                    onClick={() => history.push('/accounts/reporting-entities')}
                />
                <Menu.Item
                    content='Logins'
                    icon='signup'
                    active={location.pathname.startsWith('/accounts/logins')}
                    onClick={() => history.push('/accounts/logins')}
                />
                <Menu.Item
                    content='Registrations'
                    icon='user'
                    active={location.pathname.startsWith('/accounts/registrations')}
                    onClick={() => history.push('/accounts/registrations')}
                />
            </Menu>
            <Switch>
                {/* reporting entity (users) routes */}
                <Route path='/accounts/reporting-entities/email/:email' component={ReportingEntitiesDetail} />
                <Route path='/accounts/reporting-entities/:id' component={ReportingEntitiesDetail} />
                <Route path='/accounts/reporting-entities' component={ReportingEntitiesMaster} />

                {/* login related routes */}
                <Route path='/accounts/logins/:id' component={LoginsDetail} />
                <Route path='/accounts/logins/email/:email' component={LoginsDetail} />
                <Route path='/accounts/logins' component={LoginsMaster} />

                {/* invitations related routes */}
                <Route path='/accounts/registrations' component={Registrations} />

                {/* handle legacy links to accounts */}
                <Redirect from='/accounts/:email' to='/accounts/reporting-entities/email/:email' />
                <Redirect exact path='/accounts' to='/accounts/reporting-entities' />
            </Switch>
        </Container>
    );
};

export default Accounts;