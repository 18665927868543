import client from './client';

const PREFIX = '/payments/products';

function getActiveProducts() {
    return client.get(PREFIX);
}

const products = {
    getActiveProducts
};

export default products;
