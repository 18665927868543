import { dataTypes } from 'model-editor/nodeMetadata';
import React from 'react';
import { Dropdown, Table } from 'semantic-ui-react';

const INPUT_PROCESSING_STRATEGIES = {
    USE_TOTAL_SUM: 'USE_TOTAL_SUM',
    USE_RESOURCE_SUM: 'USE_RESOURCE_SUM',
};

const InputsEditor = ({ inputNodes, inputTagsSettings, onChange }) => {
    const configurableInputNodes = inputNodes.filter(({ tag, dataType }) => {
        if (!tag?.trim()) {
            return false;
        }

        if (dataType !== dataTypes.NUMBER) {
            return false;
        }

        return true;
    });

    console.log(inputTagsSettings);

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Nodens tag</Table.HeaderCell>
                    <Table.HeaderCell>Brugstype</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {configurableInputNodes.map(({ tag }) => {
                    const nodeProcessingStrategy = inputTagsSettings[tag]?.processingStrategy || INPUT_PROCESSING_STRATEGIES.USE_TOTAL_SUM;

                    return (
                        <Table.Row>
                            <Table.Cell width={8}>
                                {tag}
                            </Table.Cell>
                            <Table.Cell width={8}>
                                <Dropdown
                                    selection
                                    fluid
                                    defaultValue={nodeProcessingStrategy}
                                    options={[
                                        {
                                            text: 'Bruges nodens totale sum',
                                            key: INPUT_PROCESSING_STRATEGIES.USE_TOTAL_SUM,
                                            value: INPUT_PROCESSING_STRATEGIES.USE_TOTAL_SUM,
                                        },
                                        {
                                            text: 'Brug ressourcespecifik sum',
                                            key: INPUT_PROCESSING_STRATEGIES.USE_RESOURCE_SUM,
                                            value: INPUT_PROCESSING_STRATEGIES.USE_RESOURCE_SUM,
                                        },
                                    ]}
                                    onChange={(_ , { value }) => {
                                        const inputTagsSettingsCopy = { ...inputTagsSettings };
                                        inputTagsSettingsCopy[tag] ||= {};
                                        inputTagsSettingsCopy[tag].processingStrategy = value;
                                        onChange(inputTagsSettingsCopy);
                                    }}
                                />
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export default InputsEditor;