import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';


export default class NodeSelect extends Component {
    onChange = (_, { value }) => {
        this.props.onChange(value);
    };

    render () {
        const { nodes, defaultValue } = this.props;
        return (
            <Dropdown
                search
                selection
                fluid
                placeholder='Vælg en referencenode'
                defaultValue={defaultValue}
                onChange={this.onChange}
                options={nodes.map(({ id, name }) => ({
                    key: id,
                    value: id,
                    text: name,
                }))}
            />
        );
    }
}
