import React, { Component } from 'react';

class NodeDataHasRole extends Component {
    getData () {
        if (!this.role) {
            return {};
        }
        return {
            role: this.role.value
        };
    }

    render () {
        const {role} = this.props.node.data;
        return (
            <select ref={r => this.role = r} defaultValue={role}>
                <option value=''>-</option>
                <option value='admin'>Administrator</option>
                <option value='revisor'>Revisor</option>
                <option value='holding'>Holding</option>
                <option value='akademi'>Akademi</option>
            </select>
        );
    }
}

export default NodeDataHasRole;