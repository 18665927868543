import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Icon, List, Loader, Segment } from 'semantic-ui-react';
import modelEditor from '../../../http/modelEditor'

const NodeFieldViewer = ({ modelID, nodeID, history, beforeLink }) => {
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const doGetFields = async () => {
            let fieldsResp = await modelEditor.getFieldsByNodeID(modelID, nodeID);
            fieldsResp = fieldsResp.sort((a, b) => {
                if (a.sectionName > b.sectionName) {
                    return 1;
                }
                if (a.sectionName < b.sectionName) {
                    return -1;
                }
                return 0;
            });
            setFields([ ...fieldsResp ]);
            setLoading(false);
        };
        doGetFields();
    }, [modelID, nodeID]);

    const goto = async link => {
        if (beforeLink) {
            await beforeLink();
        }
        history.push(link);
    };

    if (loading) {
        return <Loader inline='centered' active size='huge' />;
    }

    if (fields.length === 0) {
        return null;
    }

    return <Segment inverted>
        <Header size='small'>Felter</Header>
        <List inline>
            {fields.map(({ sectionName, fieldLabel, link }) => {
                return <List.Item onClick={() => goto(link)} style={{ cursor: 'pointer' }}>
                    {sectionName} <Icon name='arrow right' />{fieldLabel}
                </List.Item>;
            })}
        </List>
    </Segment>;
};

export default withRouter(NodeFieldViewer);