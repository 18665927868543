import React from 'react';
import PropTypes from "prop-types";
import {Link, withRouter} from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import styles from './NavItem.module.css';

class NavItem extends React.Component {

    static propTypes = {
        /* A react router link for internal pages */
        to: PropTypes.string,
        /* A function to call instead of link */
        onClick: PropTypes.func,
        /* Text to put on the link */
        label: PropTypes.string.isRequired,
        /* Extra css classes to add */
        className: PropTypes.string,
        /* The name of the icon from the material icons font(must use underscore instead of space) */
        name: PropTypes.string.isRequired,
    };

    static defaultProps = {
        to: '',
        className: '',
    };

    render() {
        const { to, onClick, label, name, className, location, active } = this.props;

        let itemActive;

        if (active !== undefined) {
            itemActive = active;
        } else if (to === '/') {
            itemActive = location.pathname === '/';
        } else {
            itemActive = location.pathname.startsWith(to);
        }


        if (to === '') {
            return (
                <span className={ `${styles.spanItem} ${itemActive ? styles.active : null} ${ className }` } onClick={ onClick }>
                    <Icon name={ name }/>
                    &nbsp;<span>{ label }</span>
                </span>
            );
        } else {
            return (
                <Link className={ `${styles.linkItem} ${itemActive ? styles.active : null} ${ className }` } onClick={ onClick } to={to}>
                    <Icon name={ name }/>
                    &nbsp;<span>{ label }</span>
                </Link>
            );
        }
    }

}

export default withRouter(NavItem);