import { Container, Header, Progress, Segment } from 'semantic-ui-react';
import { useState } from 'react';
import { readAsText } from 'shared/files';
import FileuploadButton from 'atoms/FileuploadButton';
import { toast } from 'react-toastify';
import { mapAccountPlan } from 'http/erpBroker';
import { downloadArrayAsCSV, parseCSV } from 'shared/CSV';

const MLTest = () => {
    const [working, setWorking] = useState(false);
    const [progress, setProgess] = useState(0);

    const performTest = async csvFile => {
        const textContent = await readAsText(csvFile);
        const parsedAccountPlan = parseCSV(textContent);

        const fileName = csvFile.name;
        const fileNameFirstLetter = fileName[0].toUpperCase();
        const classA = fileNameFirstLetter === 'A';
        const classB = fileNameFirstLetter === 'B';

        if (!(classA || classB)) {
            throw new Error('Filnavn skal starte med enten A eller B for at indikere regnskabsklassen');
        }
        
        const accountplanToMap = parsedAccountPlan.map(account => {
            return {
                number: Number(account.number),
                vatcode: account.vatcode,
                accountType: account.accountType,
                text: account.text,
                amount: Number(account.currentYearAmount),
            };
        });

        const mappedAccounts = await mapAccountPlan(accountplanToMap, classA, classB);

        let amountOfErrors = 0;

        const fullAccountPlan = parsedAccountPlan.map((account, idx) => {
            const { taxonomy, accountantCode } = mappedAccounts[idx];

            const expectedXbrlTag = account.xbrlTag;
            const mappedXbrlTag = taxonomy.value;
            const xbrlIsCorrect = expectedXbrlTag === mappedXbrlTag;

            const expectedAccountantCode = account.accountantCode;
            const mappedAccountantCode = accountantCode.value;
            const accountantCodeIsCorrect = expectedAccountantCode === mappedAccountantCode;

            const testPassed = xbrlIsCorrect && accountantCodeIsCorrect;

            if (!testPassed) {
                amountOfErrors++;
            }

            return {
                number: account.number,
                text: account.text,
                accountType: account.accountType,
                vatcode: account.vatcode,
                amount: Number(account.currentYearAmount),

                expectedXbrlTag,
                mappedXbrlTag,

                expectedAccountantCode,
                mappedAccountantCode,

                testPassed,
            };
        });

        return {
            fullAccountPlan,
            amountOfErrors,
            fileName,
        };
    };


    const performTests = async csvFiles => {
        setWorking(true);
        setProgess(0);

        const results = [];

        for (let fileIdx = 0; fileIdx < csvFiles.length; fileIdx++) {
            const csvFile = csvFiles[fileIdx];
            try {
                const testResult = await performTest(csvFiles[fileIdx]);
                results.push(testResult);
                setProgess(Math.floor((fileIdx + 1) / csvFiles.length * 100));
            } catch (e) {
                toast.error(`Fejl i ${csvFile.name}: ${e.message}`);
                setWorking(false);
                return;
            }
        }

        for (const { fileName, fullAccountPlan, amountOfErrors } of results) {
            const resultFileName = `${fileName}_${amountOfErrors}_fejl`;
            downloadArrayAsCSV(fullAccountPlan, resultFileName);
        }

        setWorking(false);
    };

    return (
        <Container>
            <Segment style={{ marginTop: '1em' }}>
                <Header>Test maskinlæringsalgoritme</Header>
                <div style={{ marginTop: '1em' }}>
                    <FileuploadButton
                        primary
                        multiple
                        icon='cloud upload'
                        content='Upload kontoplan'
                        accept={['csv']}
                        loading={working}
                        disabled={working}
                        onChange={files => performTests(files)}
                    />
                </div>
                {(working || progress === 100)  && (
                    <Progress
                        percent={progress}
                        indicating
                        progress
                        style={{ marginBottom: 0, marginTop: '1em' }}
                    />
                )}
            </Segment>
        </Container>
    );
};

export default MLTest;