import React from 'react';
import { Checkbox, List } from 'semantic-ui-react';
import roles from '../../../shared/roles';

function RoleManager ({ onChange, selectedRoles }) {
    const rolesSet = new Set(selectedRoles);
    return (
        <List>
            {roles.map(({ name, id }) => {
                return <List.Item>
                    <Checkbox
                        onChange={(_, { checked }) => onChange(id, checked)}
                        checked={rolesSet.has(id)}
                        label={name}
                    />
                </List.Item>;
            })}
        </List>
    );
}

export default RoleManager;