import React, { forwardRef } from 'react';

const InputSection = ({ children }) => {
    const borderRadius = '4px';

    if (!Array.isArray(children)) {
        children = [children];
    }
    
    return children.map((child, cidx) => {
        const style = {};

        const isFirst = cidx === 0;
        const isLast = cidx === children.length - 1;

        if (isFirst) {
            style.borderTopRightRadius = borderRadius;
        } else {
            style.marginTop = '-1px';
        }

        if (isLast) {
            style.borderBottomLeftRadius = borderRadius;
            style.borderBottomRightRadius = borderRadius;
        }

        return (
            <div key={cidx} children={child} style={style} />
        );
    });
};

export const InputSectionRow = forwardRef(({ style, children, ...props }, ref) => {
    const defaultStyle = {
        background: '#fafafa',
        border: '1px solid lightgray',
        padding: '1em',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
    };

    return (
        <div style={{ ...defaultStyle, ...style }} ref={ref} {...props}>
            {children}
        </div>
    );
});

export default InputSection;