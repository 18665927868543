import { Client } from './client';
import { API_LOCATION } from '../config';

const client = new Client(`${API_LOCATION}/file-storage`);

export function getFile(fileID) {
    return client.getResource(`/files/${fileID}`);
}

export function getFileDataAsBase64(fileID) {
    return client.getResource(`/files/${fileID}/data?base64=true`);
}

export function getFileMetadata(fileID) {
    return client.getResource(`/files/${fileID}/metadata`);
}

export function getUserFiles(UID) {
    return client.getResource(`/admin/files/uid/${UID}`);
}

/**
 * Prepares a download link for the given file
 * @param {string} fileID - ID of the file
 * @param {("inline"|"attachment")} disposition - "inline" or "attachment". Defaults to "inline"
 */
export async function prepareDownloadLink (fileID, disposition = 'inline') {
    const { link } = await getFile(fileID);
    return `${API_LOCATION}/file-storage/files/download/${link}?disposition=${disposition}`;
}