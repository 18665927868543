import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

const TABS = [
    {
        path: '/payment/overview',
        name: 'Oversigt',
        icon: 'eye', 
    },
    {
        path: '/payment/campaigns',
        name: 'Rabatkampagner',
        icon: 'tag',
    },
    {
        path: '/payment/invoices',
        name: 'Rådgiverfakturaer',
        icon: 'dollar',
    },
    {
        path: '/payment/onetime-payments',
        name: 'Enkeltkøb',
        icon: 'cart',
    },
    {
        path: '/payment/bookkeeper-scoreboard',
        name: 'Rådgiverscoreboard',
        icon: 'list ol',
    },
    {
        path: '/payment/internal-booking',
        name: 'Intern bogføring',
        icon: 'book',
    },
];

function Navigation ({ location, history }) {
    const { pathname } = location;
    return <Menu pointing>
        {TABS.map(({ path, name, icon }) => {
            const active = pathname.startsWith(path);
            return <Menu.Item
                key={path}
                content={name}
                icon={icon}
                active={active}
                link={!active}
                onClick={() => history.push(path)}
            />;
        })}
    </Menu>;
}

export default withRouter(Navigation);