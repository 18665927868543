import React, { Component } from 'react';
import { Form, Input, TextArea, Checkbox, Dropdown, List } from 'semantic-ui-react';
import roles from 'shared/roles';

const simEvent = data => ({ target: { value: data } })

export default class OnetimePaymentForm extends Component {
    onRoleChanged = (_, { id, checked }) => {
        const reqRoles = new Set(this.props.requiredRoles);
        if (checked) {
            reqRoles.add(id);
        } else {
            reqRoles.delete(id);
        }
        this.props.updateFormData(simEvent([...reqRoles]), 'requiredRoles');
    };

    renderFormRow = (label, formProp, defaultValue, Field = Input, disabled = false) => {
        return (
            <Form.Field>
                <label>{label}</label>
                <Field
                    disabled={disabled}
                    placeholder={label}
                    defaultValue={defaultValue}
                    onChange={e => this.props.updateFormData(e, formProp)}
                />
            </Form.Field>
        );
    };

    renderCheckbox = (label, formProp, defaultValue) => {
        return (
            <Form.Field>
                <label>{label}</label>
                <Checkbox
                    toggle
                    placeholder={label}
                    checked={defaultValue}
                    onChange={() => this.props.updateFormData(simEvent(!defaultValue), formProp)}
                />
            </Form.Field>
        );
    };

    renderDropDown = (label, formProp, defaultValue) => {
        const { activityTriggers } = this.props;

        if (!activityTriggers) {
            return null;
        }
        
        const options = activityTriggers.map(activityTrigger => {
            return {
                key: activityTrigger.id,
                value: activityTrigger.id,
                text: activityTrigger.displayName
            };
        });

        options.unshift({});

        return (
            <Form.Field>
                <label>{label}</label>
                <Dropdown
                    defaultValue={defaultValue}
                    search
                    selection
                    options={options}
                    onChange={(_, selection) => this.props.updateFormData(simEvent(selection.value), formProp)}
                />
            </Form.Field>
        );
    };

    renderRoleList = () => {
        const reqRoles = new Set(this.props.requiredRoles);

        const roleListJSX = roles.map(({ name, id }) => {
            return <List.Item>
                <Checkbox
                    id={id}
                    label={name}
                    checked={reqRoles.has(id)}
                    onChange={this.onRoleChanged}
                />
            </List.Item>;
        });

        return <Form.Field>
            <label>Krævede roller</label>
            <List>
                {roleListJSX}
            </List>
        </Form.Field>;
    };

    render () {
        const {
            name,
            price,
            id,
            description,
            isActive,
            activityTrigger,
            idDisabled,
        } = this.props;

        return <Form style={{width: '100%'}}>
            {this.renderFormRow('Navn', 'name', name)}    
            {this.renderFormRow('ID', 'id', id, Input, idDisabled)}
            {this.renderFormRow('Pris', 'price', price)}
            {this.renderFormRow('Eventuel beskrivelse', 'description', description, TextArea)}
            {this.renderDropDown('Aktivitet ved køb', 'activityTrigger', activityTrigger)}
            {this.renderCheckbox('Er aktivt', 'isActive', isActive)}
            {this.renderRoleList()}
        </Form>;
    }
}