import email from 'http/email';
import React, { useEffect, useState } from 'react';
import { Button, Segment, Table } from 'semantic-ui-react';
import copyToClipboard from 'shared/copyToClipboard';

function VariableTable () {
    const [showVarHelp, setShowVarHelp] = useState(false);
    const [variables, setVariables] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const doLoad = async () => {
            const vars = await email.getTemplateVariables();
            setVariables(vars);
            setLoading(false);
        };
        doLoad();
    }, []);

    const toggleBtn = (
        <p>
            <Button
                size='small'
                labelPosition='right'
                color='black'
                icon={showVarHelp ? 'eye slash' : 'eye'}
                content={showVarHelp ? 'Skjul variabler' : 'Vis variabler'}
                onClick={() => setShowVarHelp(!showVarHelp)}
                loading={loading}
                basic
            />
        </p>
    );
    if (!showVarHelp || loading) {
        return <Segment secondary>{toggleBtn}</Segment>;
    }

    const entries = Object.entries(variables);
    const out = entries.map(([ varname, { name, testVal } ]) => {
        return (
            <Table.Row key={varname}>
                <Table.Cell>{varname}</Table.Cell>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>{testVal}</Table.Cell>
                <Table.Cell textAlign='right'>
                    <Button
                        icon='copy'
                        color='teal'
                        onClick={() => copyToClipboard(`{{${varname}}}`)}
                    />
                </Table.Cell>
            </Table.Row>
        );
    });

    return <Segment secondary>
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Variabelnavn</Table.HeaderCell>
                    <Table.HeaderCell>Beskrivelse</Table.HeaderCell>
                    <Table.HeaderCell>Eksempel</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Kopier</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {out}
            </Table.Body>
        </Table>
        <div style={{ textAlign: 'right' }}>
            {toggleBtn}
        </div>
    </Segment>;
}

export default VariableTable;