import React, { Component } from 'react';
import { Container, Segment, Header, Button, Form, Checkbox, Input, Divider, Dropdown, Message, List } from 'semantic-ui-react';
import financialDates from 'http/financialDates';
import DatePicker from 'atoms/DatePicker';

function date2iso (date) {
    return new Date(date + 1000 * 60 * 60 * 2).toISOString().substring(0, 10)
}

function iso2date (iso) {
    if (!iso) return undefined;
    return new Date(iso);
}

function pad (x) {
    x = x.toString();
    if (x.length <= 9) {
        return '0' + x;
    }
    return x;
}

class ReportingPeriodTest extends Component {
    state = {
        working: false,
        result: null,
        error: false,
        fiction: false,
        // Form values
        cvr: '',
        startingPoint: date2iso(new Date()),
        taxYear: '',
        apsd: '',
        apsm: '',
        aped: '',
        apem: '',
        frpsd: '',
        frped: '',
    };

    convert = async () => {
        const {
            fiction,
            startingPoint,
            cvr,
            taxYear,
            apsd,
            apsm,
            aped,
            apem,
            frpsd,
            frped,
        } = this.state;
        try {
            this.setState({ working: true, error: false, result: null });
            const result = await financialDates.taxYearToReportingPeriod({
                cvr: fiction ? undefined : cvr.trim(),
                startingPoint,
                taxYear: taxYear.trim(),
                apsd: pad(apsd),
                apsm: pad(apsm),
                aped: pad(aped),
                apem: pad(apem),
                frpsd,
                frped,
            });
            this.setState({ result });
        } catch (e) {
            console.error(e);
            this.setState({ error: true });
        } finally {
            this.setState({ working: false });
        }
    };

    renderFormFieldText = (stateID, label, width) => (
        <Form.Field>
            <label>{label}</label>
            <Input
                style={{ width }}
                onChange={(_, { value }) => this.setState({ [stateID]: value })}
                defaultValue={this.state[stateID]}
            />
        </Form.Field>
    );
    
    renderFormFieldDate = (stateID, label) => {
        return <Form.Field>
            <label>{label}</label>
            <DatePicker
                onChange={value => this.setState({ [stateID]: date2iso(value) })}
                value={iso2date(this.state[stateID])}
            />
        </Form.Field>
    };

    renderFormFieldDropDown = (stateID, label, amount) => {
        const options = [];
        for (let i = 1; i <= amount; i++) {
            options.push({
                text: '' + i,
                key: '' + i,
                value: '' + i,
            });
        }
        return <Form.Field>
            <label>{label}</label>
            <Dropdown
                options={options}
                scrolling
                selection
                search
                defaultValue={this.state[stateID]}
                onChange={(_, { value }) => this.setState({ [stateID]: value })}
            />
        </Form.Field>
    };

    renderCVRFormFields = () => {
        return this.renderFormFieldText('cvr', 'CVR');
    };

    renderFictionFormFields = () => (
        <>
            {this.renderFormFieldDropDown('apsd', 'Regnskabsperiode startdag', 31)}
            {this.renderFormFieldDropDown('apsm', 'Regnskabsperiode startmåned', 12)}
            {this.renderFormFieldDropDown('aped', 'Regnskabsperiode slutdag', 31)}
            {this.renderFormFieldDropDown('apem', 'Regnskabsperiode slutmåned', 12)}
            {this.renderFormFieldDate('frpsd', 'Første regnskabsperiode start')}
            {this.renderFormFieldDate('frped', 'Første regnskabsperiode slut')}
        </>
    );

    renderResult = () => {
        const { result } = this.state;
        if (!result) {
            return;
        }

        const {
            // tax year & reporting period
            isBeforeFirstYear,
            isFirstYear,
            startDate,
            endDate,
            // tax year & invoicing
            isHighlighted,
            isFutureYear,
            isTeaser,
            invoicingPeriodStart,
            invoicingPeriodEnd,
        } = result;

        const janej = v => v ? <span><b>Ja</b></span> : <span><b>Nej</b></span>;
        
        return <>
            <Header>Skatteåret ift. virksomhedens regnskabsperiode</Header>
            <List divided>
                <List.Item>Skatteår ligger før virksomhedens første skatteår: {janej(isBeforeFirstYear)}</List.Item>
                <List.Item>Skatteår er virksomhedens første skatteår: {janej(isFirstYear)}</List.Item>
                <List.Item>Regnskabsperiode start: <b>{startDate}</b></List.Item>
                <List.Item>Regnskabsperiode slut: <b>{endDate}</b></List.Item>
            </List>

            <Header>Skatteåret ift. fakturering og visning i appen</Header>
            <List divided>
                <List.Item>Skatteår burde være highlighted i appen: {janej(isHighlighted)}</List.Item>
                <List.Item>Skatteår er først relevant i fremtiden: {janej(isFutureYear)}</List.Item>
                <List.Item>Skatteår er teaser: {janej(isTeaser)}</List.Item>
                <List.Item>Abonnementsperiode start: <b>{invoicingPeriodStart}</b></List.Item>
                <List.Item>Abonnementsperiode slut: <b>{invoicingPeriodEnd}</b></List.Item>
            </List>
        </>; 
    };

    renderContent = () => {
        const { working, fiction, error } = this.state;
        return <>
            <Form style={{ width: '100%' }}>
                <Form.Field>
                    <label>Fiktivt selskab?</label>
                    <Checkbox
                        toggle
                        onChange={(_, { checked }) => this.setState({ fiction: checked })}
                    />
                </Form.Field>
                {this.renderFormFieldDate('startingPoint', 'Udgangspunkt')}
                {this.renderFormFieldText('taxYear', 'Skatteår')}
                <Form.Field>
                    <Divider />
                </Form.Field>
                {fiction ? this.renderFictionFormFields() : this.renderCVRFormFields()}
                <Form.Field>
                    <Button
                        primary
                        content='Kør'
                        icon='cog'
                        onClick={this.convert}
                        disabled={working}
                        loading={working}
                    />
                    {this.renderResult()}
                </Form.Field>
            </Form>
            {error && <Message
                icon='warning circle'
                content='Der opstod en fejl'
                error
            />}
        </>;
    };

    render = () => {
        return <Container>
            <Segment>
                <Header>Skatteår til regnskabsperiode</Header>
                {this.renderContent()}
            </Segment>
        </Container>
    };
}

export default ReportingPeriodTest;