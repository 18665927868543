import React from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';

import model from './model';

const FormulaHelp = () => {
    return (
        <Popup
            trigger={
                <span>
                    <Icon name='question circle outline' color='blue' />
                    Formula help
                </span>
            }
            content={
                <Grid columns={2} style={{ width: '400px' }}>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Operator</b>
                        </Grid.Column>
                        <Grid.Column>
                            <b>Usage</b>
                        </Grid.Column>
                    </Grid.Row>
                    {Object.values(model.variableNamespaces).map((namespace) => {
                        return (
                            <>
                                <Grid.Column verticalAlign='middle'>
                                    {namespace.operatorPrefix}
                                </Grid.Column>
                                <Grid.Column>
                                    {namespace.description}
                                </Grid.Column>
                            </>
                        );
                    })}
                </Grid>
            }
        />
    );
};

export default FormulaHelp;