export const stylesheet = [ // the stylesheet for the graph
    {
        selector: 'node',
        style: {
            'text-valign': 'center',
            'text-margin-y': '3px',
            'color': 'black',
            'background-color': 'data(color)',
            'width': 'label',
            'height': 'label',
            'shape': 'roundrectangle',
            'label': 'data(name)',
            'padding': '10px',
        }
    },
    {
        selector: 'edge',
        style: {
            'curve-style': 'bezier',
            'width': 4,
            'line-color': 'data(color)',
            'source-endpoint': '-49% 0%',
            'target-endpoint': '49% 0%'
        }
    }
];

export const layout = {
    name: 'dagre',
    rankDir: 'rl',
    nodeSep: 50,
    rankSep: 100,
    edgeSep: 50,
    ranker: 'network-simplex',
};

export const options = {};