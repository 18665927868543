import React, {Component} from 'react';
import { Dropdown, Loader } from 'semantic-ui-react';
import { dataTypes, dataTypeIsPrimitive } from 'model-editor/nodeMetadata';
import http from 'http/modelEditor';

class NodeDataPrimoValue extends Component {
    constructor (props) {
        super(props);
        const data = props.node?.data;
        this.state = {
            loading: true,
            previousNodes: [],
            previousModelNode: data?.previousModelNode,
            thisModelNode: data?.thisModelNode,
        };
    }

    getData = () => {
        const { previousModelNode, thisModelNode } = this.state;
        return { previousModelNode, thisModelNode };
    };

    getDatatype = () => {
        const { previousNodes, previousModelNode } = this.state;
        
        // find previous model node
        const prevNode = previousNodes.find(node => node.id === previousModelNode);

        // default to number, if node not found
        return prevNode?.dataType || dataTypes.NUMBER;
    };

    fetchModel = async () => {
        const { modelId } = this.props;
        return http.getModelMetadata(modelId);
    };

    fetchNodesFromModel = async modelID => {
        const nodes = await http.getModelNodes(modelID);
        return nodes.filter(n => dataTypeIsPrimitive(n.dataType));
    };

    async componentDidMount () {
        const model = await this.fetchModel();

        // list previous model nodes
        let previousNodes;
        if (model.previousModel) {
            previousNodes = await this.fetchNodesFromModel(model.previousModel);
        }

        this.setState({ previousNodes, loading: false });
    }



    renderNodesDropdown = ({ label, nodes, stateKey }) => {
        return (
            <>
                <label>{label}</label>
                <br />
                <Dropdown
                    selection
                    search
                    defaultValue={this.state[stateKey]}
                    onChange={(_, { value }) => this.setState({ [stateKey]: value })}
                    options={nodes.map(({ name, id }) => ({
                        text: name,
                        value: id,
                    }))}
                />
            </>
        );
    };

    renderPreviousModelNodesDropdown = () => {
        const { previousNodes } = this.state;

        return this.renderNodesDropdown({
            label: 'Node fra sidste års model',
            stateKey: 'previousModelNode',
            nodes: previousNodes,
        });
    };

    renderThisModelNodesDropdown = () => {
        const { nodes } = this.props;

        return this.renderNodesDropdown({
            label: 'Node fra denne model',
            stateKey: 'thisModelNode',
            nodes,
        });
    };

    render () {
        const { loading } = this.state;
        if (loading) {
            return <Loader active inline/>
        }

        return (
            <div>
                {this.renderPreviousModelNodesDropdown()}
                <br />
                {this.renderThisModelNodesDropdown()}
            </div>
        );
    }
}

export default NodeDataPrimoValue;