import client from './client';
import User from '../shared/User';
import { makeQueryParams } from '../shared/queryParams';

/**
 * @returns {Promise<User[]>}
 */
async function getAccounts() {
    const resp = await client.get('/accounts');
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return User.unmarshalUserList(resp.accounts);
}

/**
 * @returns {Promise<User[]>}
 */
async function getAccountsPaginated(page = 0, query = {}) {
    const qparams = makeQueryParams({ page, ...query }, true);
    const resp = await client.get(`/accounts/paginated${qparams}`, false);
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return User.unmarshalUserList(resp.data);
}

/**
 * @param {string} email
 * @returns {Promise<User>}
 */
async function getAccount(email) {
    const resp = await client.get(`/accounts/email/${email}`);
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return new User(resp.account);
}

/**
 * @param {string} id
 * @returns {Promise<User>}
 */
async function getAccountByID(id) {
    const resp = await client.get(`/accounts/${id}`);
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return new User(resp.account);
}

async function getAccountsFiltered (sortBy, direction, filter) {
    const qparams = makeQueryParams({
        sortBy,
        direction,
        filter,
    });
    const resp = await client.get(`/accounts/filtered${qparams}`);
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return User.unmarshalUserList(resp.data);
}

async function getAccountLogins(id) {
    return client.getResource(`/accounts/${id}/logins`);
}

function performUserSearch ({ audience = {}, performCount = false, projections = null, limit = null } = {}) {
    const body = {
        audience,
        performCount,
        projections,
        limit,
    };
    return client.postResource('/accounts/search', body);
}

function countAudienceTargets({ audienceQuery = {}, wantsNews, wantsStatusMails }) {
    return client.postResource('/accounts/search-audience', {
        audienceQuery,
        wantsNews,
        wantsStatusMails,
        doCount: true,
    });
}

function searchNewletterRecipients({ relevantSegments, filterItems }) {
    return client.postResource('/accounts/search-newletter-recipients', {
        relevantSegments,
        filterItems,
    });
};

/**
 * @returns {Promise<number>}
 */
function countAccounts() {
    return client.getResource('/accounts/count');
}

/**
 * @returns {Promise<User[]>}
 */
async function getAccountantClients(id) {
    const clients = await client.getResource(`/accounts/${id}/clients`);
    const users = User.unmarshalUserList(clients);
    return users.filter(u => u.isClient() && u.creator === id);
}

function getProductStates(email) {
    return client.get(`/accounts/email/${email}/states`);
}

function getProductStats() {
    return client.get(`/accounts/product-stats`);
}

function purgeUser(email) {
    return client.post(`/accounts/purge`, {
        email: email
    });
}

function updateRoles(id, roles) {
    return client.patch(`/accounts/${id}/roles`, {
        add: roles.add,
        remove: roles.remove
    });
}

function setSuspension (id, value) {
    return client.putResource(`/accounts/${id}/suspended`, { value });
}

function getEmail (uid) {
    return client.getResource(`/accounts/${uid}/email`);
}

function getChatEntries(uid) {
    return client.getResource(`/accounts/${uid}/chat-entries`);
}

async function getClientSignUpAndPaymentData(){
    const clientData = await client.get(`/accounts/client-data`);
    return clientData.data;
}

function getLogins(pagination) {
    return client.getResource('/accounts/admin/logins' + (
        pagination ?
        makeQueryParams(pagination) :
        ''
    ));
}

function countLogins() {
    return client.getResource('/accounts/admin/logins/count');
}

function getLoginByID(loginID) {
    return client.getResource(`/accounts/admin/logins/${loginID}`);
}

function getLoginReportingEntities(loginID) {
    return client.getResource(`/accounts/admin/logins/${loginID}/reporting-entities`);
}

function toggleLoginAdmin(loginID) {
    return client.postResource(`/accounts/admin/logins/${loginID}/toggle-admin`);
}

async function generateRegistrationLink(segment, email, role) {
    return client.getResource(`/accounts/academy/generate-registration-link?segment=${segment}&email=${encodeURIComponent(email)}&role=${role}`);
}

const accounts = {
    getAccounts,
    getAccountsPaginated,
    getAccountsFiltered,
    getAccount,
    getAccountByID,
    getAccountLogins,
    countAccounts,
    countAudienceTargets,
    purgeUser,
    updateRoles,
    getProductStates,
    getProductStats,
    setSuspension,
    getAccountantClients,
    getEmail,
    getChatEntries,
    getClientSignUpAndPaymentData,
    performUserSearch,
    getLogins,
    getLoginByID,
    countLogins,
    getLoginReportingEntities,
    toggleLoginAdmin,
    generateRegistrationLink,
    searchNewletterRecipients,
};

export default accounts;
