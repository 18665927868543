import client from './client';
import Accountant from '../shared/Accountant';
import { makeQueryParams } from '../shared/queryParams';
import { base64ToBlob } from '../shared/files';


const PREFIX = '/payments/admin';

function countSubscriptions() {
    return client.getResource(`${PREFIX}/subscriptions/count`);
}

function countSubscriptionsDistinct() {
    return client.getResource(`${PREFIX}/subscriptions/count/distinct`);
}

function getRenewalCalendar() {
    return client.getResource(`${PREFIX}/subscriptions/renewals/calendar`);
}

function calculateGrossVolume(fromDate) {
    return client.get(`${PREFIX}/grossvolume/${fromDate}`);
}

function getCustomerStripeID (uid) {
    return client.get(`${PREFIX}/customer/${uid}/stripeid`);
}

function getCustomerSubscriptions(uid) {
    return client.get(`${PREFIX}/customer/${uid}/subscriptions`);
}

function getCustomerSubscriptionsFull(uid) {
    return client.get(`${PREFIX}/customer/${uid}/subscriptions/full`);
}

function cancelCustomerSubscription(uid, productID) {
    return client.deleteResource(`${PREFIX}/customer/${uid}/subscriptions/${productID}`);
}

function getCustomerOnetimePayments(uid) {
    return client.get(`${PREFIX}/customer/${uid}/onetime-payments`);
}

function getCustomerOnetimePaymentsFull(uid) {
    return client.getResource(`${PREFIX}/customer/${uid}/onetime-payments/full`);
}

function unregisterOnetimePaymentOwnage(uid, onetimePaymentID) {
    return client.deleteResource(`${PREFIX}/customer/${uid}/onetime-payments/${onetimePaymentID}`);
}

function registerOnetimePaymentOwnage(uid, onetimePaymentID) {
    return client.postResource(`${PREFIX}/customer/${uid}/onetime-payments`, {
        onetimePaymentID,
    });
}

function getAllCustomerPurchasedAddonProducts(uid) {
    return client.getResource(`${PREFIX}/customer/${uid}/addon-products`);
}

function registerAddonProductOwnership(uid, addonProductID, data) {
    return client.postResource(`${PREFIX}/customer/${uid}/addon-products/${addonProductID}`, data);
}

function putAddonProductData(uid, addonProductID, id, data) {
    return client.putResource(`${PREFIX}/customer/${uid}/addon-products/${addonProductID}/purchases/${id}/data`, data);
}

function deleteAddonProductPurchase(uid, addonProductID, id) {
    return client.deleteResource(`${PREFIX}/customer/${uid}/addon-products/${addonProductID}/purchases/${id}`);
}

function getCustomerOwnedProducts(uid) {
    return client.getResource(`${PREFIX}/customer/${uid}/owned-products`);
}

function setCustomerOwnedProducts(uid, ownedProducts) {
    return client.postResource(`${PREFIX}/customer/${uid}/owned-products`, ownedProducts);
}

function getUnpaidAccountantInvoices() {
    return getAccountantInvoices({ paid: 0 });
}

function getAccountantInvoices(qparams = {}) {
    const params = makeQueryParams(qparams);
    return client.getResource(`${PREFIX}/accountant/invoices${params}`);
}

function getAccountantInvoice(invoiceID) {
    return client.getResource(`${PREFIX}/accountant/invoices/${invoiceID}`);
}

async function getAccountantInvoicePDFAsB64(invoiceID) {
    const url = `${PREFIX}/accountant/invoices/${invoiceID}/pdf`;
    const { bytes, filename } = await client.getResource(url);;
    return { bytes, filename };
}

async function getAccountantInvoicePDF(invoiceID) {
    const { bytes, filename } = await getAccountantInvoicePDFAsB64(invoiceID);
    const blob = base64ToBlob(bytes, 'application/pdf');
    return { blob, filename };
}

function updateAccountantInvoice(invoiceID, prop, value) {
    return client.putResource(`${PREFIX}/accountant/invoices/${invoiceID}/${prop}`, value);
}

function updateAccountantInvoicePaid(invoiceID, paid) {
    return updateAccountantInvoice(invoiceID, 'paid', { value: paid });
}

function updateAccountantInvoiceComments(invoiceID, addedComments, deletedComments) {
    return client.postResource(`${PREFIX}/accountant/invoices/update-comments`, { invoiceID, addedComments, deletedComments });
}

async function getPriceList(uid) {
    const resp = await client.get(`${PREFIX}/user/${uid}/pricelist`);
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return resp.data;
}

async function upsertPricelist(uid, priceList) {
    const resp = await client.put(`${PREFIX}/user/${uid}/pricelist`, { priceList });
    if (!resp.success) {
        throw new Error(resp.message);
    }
    return resp.data;
}

function getClientOwnedProducts(aid, cid) {
    return client.getResource(`${PREFIX}/accountant/${aid}/clients/${cid}/owned-products`);
}

function setClientOwnedProducts(aid, cid, ownedProducts) {
    return client.postResource(`${PREFIX}/accountant/${aid}/clients/${cid}/owned-products`, ownedProducts);
}

async function getAccountants () {
    const accountants = await client.getResource(`${PREFIX}/accountant`);
    return Accountant.unmarshalAccountants(accountants);
}

async function getBookKeepingScoreboard () {
    const bookkeepingScoreboard = await client.getResource(`${PREFIX}/accountant/bookkeeping-scoreboard`);
    return Accountant.unmarshalAccountants(bookkeepingScoreboard);
} 

function getAccountantRevenue (year, month) {
    return client.getResource(`${PREFIX}/accountant/revenue/${year}/${month}`);
}

function getPaymentLogs (queryParams = {}) {
    return client.getResource('/payments/payment-log' + makeQueryParams(queryParams));
}

function getAccountantPriceDeal (uid) {
    return client.getResource(`${PREFIX}/accountant/pricedeal/${uid}`);
}

function createAccountantPriceDeal (uid) {
    return client.postResource(`${PREFIX}/accountant/pricedeal`, { uid });
}

function updateAccountantPriceDeal (uid, deal) {
    return client.putResource(`${PREFIX}/accountant/pricedeal/${uid}`, deal);
}

function getAccountantPurchaseStats (uid) {
    return client.getResource(`${PREFIX}/accountant/${uid}/purchase-stats`);
}

function getSAASProducts() {
    return client.getResource('/payments/products');
}

function getPaymentInformationBulked(accounts) {
    return client.postResource(`${PREFIX}/user/payment-information`, accounts);
}

function getAccountantPurchasesInRange(start, end, limit) {
    const query = makeQueryParams({
        start: new Date(start).toISOString(),
        end: new Date(end).toISOString(),
        limit,
    });
    return client.getResource(`${PREFIX}/accountant/purchase-overview${query}`); 
}


function getAccountantBillEntries(aid) {
    return client.getResource(`${PREFIX}/accountant/bill/${aid}`);
}

function updateAccountantBill(invoiceid) {
    return client.deleteResource(`${PREFIX}/accountant/bill/${invoiceid}`);
}

function getAllDiscountCampaigns() {
    return client.getResource(`${PREFIX}/discount-campaigns`);
}

function createDiscountCampaign(discountCampaign) {
    return client.postResource(`${PREFIX}/discount-campaigns`, discountCampaign);
}

function updateDiscountCampaign(campaignID, discountCampaign) {
    return client.putResource(`${PREFIX}/discount-campaigns/${campaignID}`, discountCampaign);
}

const payment = {
    countSubscriptions,
    countSubscriptionsDistinct,
    getRenewalCalendar,
    getCustomerStripeID,
    getCustomerSubscriptions,
    getCustomerSubscriptionsFull,
    cancelCustomerSubscription,
    getCustomerOnetimePayments,
    getCustomerOnetimePaymentsFull,
    registerOnetimePaymentOwnage,
    unregisterOnetimePaymentOwnage,
    getAllCustomerPurchasedAddonProducts,
    registerAddonProductOwnership,
    putAddonProductData,
    deleteAddonProductPurchase,
    getCustomerOwnedProducts,
    setCustomerOwnedProducts,
    calculateGrossVolume,
    getPriceList,
    upsertPricelist,
    getUnpaidAccountantInvoices,
    getAccountantInvoice,
    getAccountantInvoices,
    getAccountantInvoicePDF,
    getAccountantInvoicePDFAsB64,
    updateAccountantInvoicePaid,
    updateAccountantInvoiceComments,
    getClientOwnedProducts,
    setClientOwnedProducts,
    getAccountants,
    getBookKeepingScoreboard,
    getAccountantRevenue,
    getPaymentLogs,
    getAccountantPriceDeal,
    createAccountantPriceDeal,
    updateAccountantPriceDeal,
    getAccountantPurchaseStats,
    getSAASProducts,
    getPaymentInformationBulked,
    getAccountantPurchasesInRange,

    getAccountantBillEntries,
    updateAccountantBill,

    getAllDiscountCampaigns,
    createDiscountCampaign,
    updateDiscountCampaign,
};

export default payment;