import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import Navigation from './navigation';

// subpages
import Overview from './Overview';
import DiscountCampaigns from './DiscountCampaigns';
import InvoicesDetail from './Invoices/detail';
import InvoicesMaster from './Invoices/master';
import OnetimePayments from './OnetimePayments';
import BookkeeperScoreboard from './BookkeeperScoreboard';
import InternalBooking from './InternalBooking';

function Payment () {
    return (
        <Container>
            <Navigation />
            <Switch>
                <Route path='/payment/overview' component={Overview} />
                <Route path='/payment/campaigns' component={DiscountCampaigns} />
                <Route path='/payment/invoices/:invoiceid' component={InvoicesDetail} />
                <Route path='/payment/invoices' component={InvoicesMaster} />
                <Route path='/payment/onetime-payments' component={OnetimePayments} />
                <Route path='/payment/bookkeeper-scoreboard' component={BookkeeperScoreboard} />
                <Route path='/payment/internal-booking' component={InternalBooking} />

                <Redirect from='/payment' to='/payment/overview' exact />
            </Switch>
        </Container>
    );
}

export default Payment;