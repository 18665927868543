import React from 'react';
import { Button, Grid, Form, Header, Icon, Segment as SemSeg, Loader } from 'semantic-ui-react'
import cns from '../../../shared/cns';
import { alert, confirm } from '../../../shared/globalModal';
import otpHttp from '../../../http/onetimePayments';
import modelEditor from '../../../http/modelEditor';
import Spinner from '../../atoms/Spinner/Spinner';
import Checkbox from '../../../atoms/Checkbox';
import TextInput from "../../../atoms/TextInput/TextInput";
import BasicInfoEditor from './BasicInfoEditor';
import VariationEditor from './VariationEditor';
import AllowedBusinessFormsEditor from './AllowedBusinessFormsEditor';
import OnetimePaymentEditor from './OnetimePaymentEditor';
import styles from './ProductDetail.module.css';

function Segment ({ className, ...props }) {
    return <SemSeg
        className={cns(styles.fullHeight, className)}
        {...props}
    />;
}

export default class ProductDetail extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            product: {},
            models: [],
            onetimePayments: [],
            loading: true,
            error: '',
            selectedVariation: null,
            selectedVariationIndex: -1,
            working: false,
        };
        this.productId = this.props.match.params.productId;
    }

    async componentDidMount () {
        const [product, models, onetimePayments] = await Promise.all([
            modelEditor.getProduct(this.productId),
            modelEditor.getModels(),
            otpHttp.getAllOnetimePayments(),
        ]);

        this.setState({
            product,
            models,
            onetimePayments,
            selectedVariation: product.variations[0],
            loading: false
        });
    }

    save = () => {
        return modelEditor.updateProduct(this.productId, this.state.product);
    };

    async saveProduct () {
        try {
            await this.save();
            this.props.history.push('/model-editor');
        } catch {
            this.setState({
                error: 'Produktet kunne ikke gemmes...'
            });
        }
    }

    async publishProduct () {
        const { productName } = this.state.product;

        const isSure = await confirm({
            header: 'Bekræft publicering',
            content: `Er du sikker på at du vil publicere produktet "${productName}"?`,
            confirmButton: 'Publicér',
        });
        if (!isSure) {
            return;
        }
        this.setState({ woring: true });

        try {
            await this.save(); // save product before publishing
        } catch {
            this.setState({
                error: 'Produktet kunne ikke gemmes...',
                woring: false,
            });
            return;
        }

        await modelEditor.publishProduct(this.productId);
        alert('Modellen blev publiceret!'); // TODO: show in UI
        this.setState({ woring: false });
    }

    exportAndPublish = async () => {
        const {
            variations,
            productId,
            baseId,
            productName,
        } = this.state.product;

        const isSure = await confirm({
            header: 'Bekræft publicering',
            content: `Er du sikker på at du vil publicere produktet "${productName}" og alle tilhørende modeller?`,
            confirmButton: 'Publicér',
        });
        if (!isSure) {
            return;
        }

        try {
            this.setState({ working: true });

            await this.save();

            const baseResult = await modelEditor.exportModel(baseId);
            if (!baseResult.success) {
                throw new Error('Opretmodel kunne ikke eksporteres');
            }

            const results = await Promise.all(variations.map(v => {
                return modelEditor.exportModel(v.modelId);
            }));

            if (results.some(x => !x.success)) {
                throw new Error('Modellerne kunne ikke eksporteres...');
            }

            await modelEditor.publishProduct(productId);
            alert('Modellerne og produktet blev publiceret');
        } catch (e) {
            alert(e.message);
        } finally {
            this.setState({ working: false });
        }
    };

    updateProduct (attr, e) {
        const { product } = this.state;
        product[attr] = e.target.value;
        this.setState({
            product
        });
    }

    createBasicInfoLine = () => {
        const { basicInformation } = this.state.product;
        basicInformation.push({
            description: '',
            tag: ''
        });
        this.setState({
            product: this.state.product
        });
    };

    updateBasicInfoLine = (attr, index, e) => {
        const { basicInformation } = this.state.product;
        basicInformation[index][attr] = e.target.value;
        this.setState({
            product: this.state.product
        });
    };

    createVariation () {
        new Promise((resolve) => {
            // create variation
            const variation = {
                modelId: this.state.models[0].id
            };
            this.state.product.variations.push(variation);
            this.setState(this.state);
            
            resolve(true);
        })
        .then(() => {
            // update index
            let idx = this.state.product.variations.length - 1;
            this.chosenVariationChanged(idx);
        });
    }

    updateSelectedVariation = (attr, e) => {
        const { selectedVariation } = this.state;
        selectedVariation[attr] = e.target.value;
        this.setState({
            selectedVariation
        });
    };

    deleteVariation = (index) => {
        let { variations } = this.state.product;
        variations.splice(index, 1);
        this.chosenVariationChanged(index - 1);
        this.setState({
            product: this.state.product,
        });
    };

    chosenVariationChanged (index) {
        const { variations } = this.state.product;
        this.refs.variations.selectedIndex = index;
        this.setState({
            selectedVariation: variations[index],
            selectedVariationIndex: index
        });
    }

    deleteBasicInformation = (idx) => {
        const { product } = this.state;
        product.basicInformation.splice(idx, 1);
        this.setState({ product });
    }   

    addOnetimePayment = otp => {
        const { onetimePayments } = this.state.product;
        onetimePayments.push(otp);
        this.setState({ product: this.state.product });
    };

    removeOnetimePayment = otp => {
        const { onetimePayments } = this.state.product;
        const index = onetimePayments.indexOf(otp);
        if (index !== -1) {
            onetimePayments.splice(index, 1);
            this.setState({ product: this.state.product });
        }
    };

    renderVariationOptions = ({ modelId, year, type }) => {
        let label;
        if (type && year) {
            label = `${type} ${year}`;
        } else {
            const { name } = this.state.models.find(m => m.id === modelId);
            label = name || modelId;
        }
        
        return <option
            children={label}
            value={modelId}
            key={modelId}
        />;
    };

    render () {
        const { loading, product, models, selectedVariation, selectedVariationIndex, onetimePayments } = this.state;
        const { productId, productName, productType, stripeId, baseId, variations, requiresBusinessForm } = product;
        const pickedOnetimePayments = product.onetimePayments;

        if (loading) {
            return (
                <Spinner size={300}/>
            );
        }

        const renderBasicInfoEditor = () => {
            const { basicInformation } = product;

            return <BasicInfoEditor
                updateBasicInfoLine={this.updateBasicInfoLine}
                deleteBasicInformation={this.deleteBasicInformation}
                createBasicInfoLine={this.createBasicInfoLine}
                basicInformation={basicInformation}
            />;
        };

        const renderVariationSection = () => {
            if (!selectedVariation) {
                return null;
            }
            return <VariationEditor
                updateSelectedVariation={this.updateSelectedVariation}
                deleteVariation={this.deleteVariation}
                selectedVariation={selectedVariation}
                selectedVariationIndex={selectedVariationIndex}
                models={models}
            />;
        };

        const renderAllowedBusinessforms = () => {
            if (!requiresBusinessForm) return null;

            return (
                <Form.Field>
                    <label>Tilladte virksomhedstyper</label>
                    <AllowedBusinessFormsEditor
                        defaultValue={product.allowedBusinessForms}
                        onChange={e => this.updateProduct('allowedBusinessForms', e)}
                    />
                </Form.Field>
            );
        };

        return (
            <div className={styles.wrapper}>
                <Header as='h2'>
                    <Icon name='settings' />
                    <Header.Content>
                        Produkt
                        <Header.Subheader>Opdater indstillinger for "{productName || productId}"</Header.Subheader>
                    </Header.Content>
                </Header>
                
                <Form className={styles.productForm}>
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Header>Produktindstillinger</Header>
                                <Form.Field>
                                    <label>Navn</label>
                                    <TextInput defaultValue={productName} onChange={e => this.updateProduct('productName', e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>ID</label>
                                    <TextInput defaultValue={productId} disabled/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Stripe ID</label>
                                    <TextInput defaultValue={stripeId} onChange={e => this.updateProduct('stripeId', e)}/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Opret-model</label>
                                    <select defaultValue={baseId} onChange={e => this.updateProduct('baseId', e)}>
                                        <option value=''>-</option>
                                        {
                                            models.map(m => <option value={m.id} key={m.id}>{m.name || m.id}</option>)
                                        }
                                    </select>
                                </Form.Field>
                                <Form.Field>
                                    <label>Produkttype</label>
                                    <select defaultValue={productType} onChange={e => this.updateProduct('productType', e)}>
                                        <option value=''>-</option>
                                        <option value='privat'>Privat</option>
                                        <option value='erhverv'>Erhverv</option>
                                    </select>
                                </Form.Field>
                                <Form.Field>
                                    <label>Kræver virksomhedstype?</label>
                                    <Checkbox
                                        slider
                                        checked={requiresBusinessForm}
                                        onChange={e => this.updateProduct('requiresBusinessForm', e)}
                                    />
                                </Form.Field>
                                { renderAllowedBusinessforms() }
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <div>
                                    <Header>Grundlæggende oplysninger</Header>
                                    { renderBasicInfoEditor() }
                                </div>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Header>Variationer</Header>
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <select
                                                size={10}
                                                ref='variations'
                                                className={styles.fullHeight}
                                                children={variations.map(this.renderVariationOptions)}
                                                onChange={e => this.chosenVariationChanged(e.target.selectedIndex)}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            { renderVariationSection() }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <br />
                                <Button
                                    icon='plus'
                                    color='green'
                                    content='Opret ny variation'
                                    onClick={() => this.createVariation()}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Header>Tilknyttede enkeltkøb</Header>
                                <OnetimePaymentEditor 
                                    onetimePayments={onetimePayments}
                                    pickedPayments={pickedOnetimePayments || []}
                                    addOnetimePayment={this.addOnetimePayment}
                                    removeOnetimePayment={this.removeOnetimePayment}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br/>
                <Segment>
                    <Button
                        disabled={this.state.working}
                        color='blue'
                        onClick={() => this.saveProduct()}
                        content='Gem produkt'
                        icon='save'
                        labelPosition='right'
                    />
                    <Button
                        disabled={this.state.working}
                        primary
                        onClick={() => this.publishProduct()}
                        content='Offentliggør produkt'
                        icon='cog'
                        labelPosition='right'
                    />
                    <Button
                        disabled={this.state.working}
                        primary
                        onClick={this.exportAndPublish}
                        content='Eksporter modeller og offentliggør'
                        icon='cogs'
                        labelPosition='right'
                    />
                    <Loader inline active={this.state.working} />
                </Segment>
                </Form>
            </div>
        );
    }
}