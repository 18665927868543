import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const MappingDropdownItem = ({ key, active, text, tooltip, disabled, onClick }) => {
    return (
        <Dropdown.Item
            disabled={disabled}
            onClick={onClick}
            active={active}
            key={key}
            content={
                <div>
                    {text} <br />
                    <i>{tooltip}</i>
                </div>
            }
        />
    );
};

export default MappingDropdownItem;