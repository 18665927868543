import React, { useState } from 'react';
import { Button, Header, Icon, Input } from 'semantic-ui-react';
import email from 'http/email';
import MasterTable from 'molecules/MasterTable';
import { localeDate } from 'shared/dateTimeFormatting';
import { withRouter } from 'react-router-dom';

function CampaignsOnboardingMaster ({ history }) {
    const [working, setWorking] = useState(false);
    const [campaignName, setCampaignName] = useState('');

    const goToCampaign = id => {
        history.push(`/emails/onboarding/${id}`);
    };

    const createCampaign = async () => {
        if (!campaignName) {
            return;
        }

        setWorking(true);
        const campaign = await email.createCampaign(campaignName, true);
        goToCampaign(campaign.id);
    };

    const createCampaignButton = <Button
        onClick={createCampaign}
        disabled={working || !campaignName}
        content='Opret kampagne'
        primary
    />;

    return <div>
        <Header as='h2'>
            <Icon name='plane' />
            <Header.Content>
                Onboarding
                <Header.Subheader>
                    Opret, se og redigér onboardingkampagner
                </Header.Subheader>
            </Header.Content>
        </Header>
        <MasterTable
            headers={['Navn', 'Oprettet d.', 'Oprettet af']}

            formatRow={({ name, createdAt, creator }) => [
                name,
                localeDate(createdAt),
                creator,
            ]}
            fetchResources={email.getOnboardingCampaigns}
            onRowClicked={({ id }) => goToCampaign(id)}
            deleteResource={({ id }) => email.deleteCampaign(id)}
            deleteResourceMessage={({ name }) => `Vil du slette kampagnen med navn "${name}"?`}
            rowOptions={['delete']}
            createForm={
                <Input
                    onChange={(_, { value }) => setCampaignName(value)}
                    onKeyDown={e => e.key === 'Enter' && createCampaign()}
                    placeholder='Navn på kampagne'
                    labelPosition='right'
                    label={createCampaignButton}
                />
            }
            filterBy='name'
        />
    </div>;
}

export default withRouter(CampaignsOnboardingMaster);