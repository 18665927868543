import React from 'react';

class MultilineQuestion extends React.Component {

    constructor (props) {
        super(props);
        const { isMultiline, allowImportingLastYearValue } = this.props.node.data;
        this.state = {
            isMultiline,
            allowImportingLastYearValue,
        };
    }

    getData () {
        const { isMultiline, allowImportingLastYearValue } = this.state;
        
        return {
            isMultiline: isMultiline || false,
            allowImportingLastYearValue: allowImportingLastYearValue || false,
        };
    }

    render () {
        const { isMultiline, allowImportingLastYearValue } = this.state;
        return (
            <>
                <section>
                    <label>Flere linier?</label>
                    <input
                        type='checkbox'
                        checked={isMultiline}
                        onChange={e => this.setState({ isMultiline: e.target.checked })}
                    />
                </section>
                <section>
                    <label>Tillad import af sidste års værdi?</label>
                    <input
                        type='checkbox'
                        checked={allowImportingLastYearValue}
                        onChange={e => this.setState({ allowImportingLastYearValue: e.target.checked })}
                    />
                </section>
            </>
        );
    }
}

export default MultilineQuestion;