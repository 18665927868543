import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import AugmentationLayer from './AugmentationLayer';

import styles from './AugmentedTextarea.module.css';

const defaultStyle = {
    width: '100%',
    position: 'relative',
    resize: 'vertical',
    overflowY: 'auto',
    fontFamily: 'monospace',
    fontSize: '1em',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    caretColor: 'black',
    minHeight: '40px',
    height: '150px',
    lineHeight: '1.5em',
    spellCheck: false,
};

const AugmentedTextarea = ({ onChange, defaultValue, syntaxConfiguration, placeholder, style = defaultStyle, containerStyle = {} }) => {
    const [text, setText] = useState(defaultValue || '');
    const [caretIndex, setCaretIndex] = useState(-1);
    const [menuOpen, setMenuOpen] = useState(false);
    const [containerHeight, setContainerHeight] = useState(0);
    const containerRef = useRef();
    const typingLayer = useRef();
    const colorLayer = useRef();
    const caretRef = useRef();

    const combinedStyle = {
        ...defaultStyle,
        ...(style || {}),
    };

    const {
        width,
        position,
        resize,
        overflowY,
        fontFamily,
        fontSize,
        whiteSpace,
        wordWrap,
        caretColor,
        height,
        minHeight,
        lineHeight,
        spellCheck,
    } = combinedStyle;

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (containerRef.current) {
                setContainerHeight(containerRef.current.offsetHeight);
            }
        });

        observer.observe(containerRef.current);
        return () => {
            observer.disconnect();
        };
    }, []);

    useLayoutEffect(() => {
        const colorLayerHeight = colorLayer.current.offsetHeight;
        const typingLayerHeight = Math.max(colorLayerHeight, containerHeight);
        typingLayer.current.style.height = typingLayerHeight + 'px';
    }, [text, height, containerHeight]);

    const layerStyle = {
        fontFamily,
        fontSize,
        whiteSpace,
        wordWrap,
        lineHeight,

        overflowY: 'hidden',
        position: 'absolute',
        width: '100%',
        outline: 'none',
        padding: '11px 14px',
        border: 'none',
        top: 0,
        left: 0,
    };

    const setNewText = newText => {
        setText(newText);
        setCaretIndex(typingLayer.current.selectionStart);
        onChange && onChange(newText);
    };
    
    const handleTextInput = e => {
        setMenuOpen(true);
        setNewText(e.target.value);
    };

    const handleAutocomplete = ({ label, subMatchStart, end }) => {
        const textBefore = text.slice(0, subMatchStart);
        const textAfter = text.slice(end);
        
        const newText = textBefore + label + textAfter;
        const endIdx = subMatchStart + label.length;
        
        typingLayer.current.value = newText;
        typingLayer.current.selectionStart = endIdx;
        typingLayer.current.selectionEnd = endIdx;

        setMenuOpen(false);
        setNewText(newText);
    };

    const handleKeyDown = e => {
        if (e.code === 'Tab') {
            e.preventDefault();

            const caretPosition = typingLayer.current.selectionEnd;
            handleAutocomplete({
                label: '  ',
                subMatchStart: caretPosition,
                end: caretPosition,
            });
            return;
        }

        if (!menuOpen) {
            return;
        }
        
        const keysToPrevent = new Set([
            'ArrowUp',
            'ArrowDown',
            'Enter',
        ]);

        if (keysToPrevent.has(e.code)) {
            e.preventDefault();
        }
    };

    const handleClick = () => setMenuOpen(false);

    const handleBlur = () => setMenuOpen(false);

    return (
        <div
            ref={containerRef}
            style={{
                cursor: 'text',
                margin: '1px',
                outline: '1px solid lightgray',
                borderRadius: '4px',
                borderTopLeftRadius: '0px',
                width,
                position,
                resize,
                height,
                minHeight,
                overflowY,
                ...containerStyle,
            }}
        >
            <div
                ref={colorLayer}
                style={{ ...layerStyle }}
                children={
                    <AugmentationLayer
                        text={text}
                        caretIndex={caretIndex}
                        ref={caretRef}
                        syntaxConfiguration={syntaxConfiguration}
                        menuOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        onAutocomplete={handleAutocomplete}
                    />
                }
            />
            <textarea
                ref={typingLayer}
                className={styles.typingLayer}
                onInput={handleTextInput}
                onKeyDown={handleKeyDown}
                onClick={handleClick}
                onBlur={handleBlur}
                defaultValue={defaultValue}
                spellCheck={spellCheck}
                placeholder={placeholder}
                style={{
                    background: 'transparent',
                    color: 'transparent',
                    resize: 'none',
                    minHeight: 'initial',
                    maxHeight: 'initial',
                    caretColor,
                    ...layerStyle,
                }}
            />
        </div>
    );
};

export default AugmentedTextarea;