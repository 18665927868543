import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Message, Icon, Grid } from 'semantic-ui-react';
import { DragAndDropZone, DraggableItem } from 'model-editor/atoms/DragAndDrop';
import SpaceSpan from 'model-editor/atoms/SpaceSpan';
import uiFieldTypes, { uiTypes } from './uiFieldTypes';
import { nodeCategories, nodeMetadata } from '../../nodeMetadata';
import { TYPE_NODE, TYPE_RESOURCE } from '.';
import './FieldList.css';

const DROPPABLE_ID = 'dnd_section_fields';

class FieldList extends Component {
    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        this.props.swap(result.source.index, result.destination.index);
    };

    renderDraggableFields = () => {
        const { fields, onChange } = this.props;
        let indentation = 0;
        return fields.map((field, index) => {
            if (field.type === uiTypes.GROUP_CLOSED || field.type === uiTypes.FREEBIE_GROUP_CLOSE) {
                indentation--;
            }

            const out = (
                <DraggableItem id={field.id} key={field.id} index={index} onChange={onChange}>
                    {this.renderField(field, index, indentation)}
                </DraggableItem>
            );

            if (field.type === uiTypes.GROUP_OPEN || field.type === uiTypes.FREEBIE_GROUP_OPEN) {
                indentation++;
            }
            return out;
        });
    };

    getResourceText = field => {
        if (!field.fieldTemplate) {
            return 'Ny ressourcenode';
        }

        return `Feltskabelon: ${field.fieldTemplate}`;
    };

    getFieldText = field => {
        // resource field: show chosen node + field template
        if (field.type === TYPE_RESOURCE) {
            return this.getResourceText(field);
        }

        // node field: show label
        if (field.type === TYPE_NODE) {
            return <span>{field.label}</span>;
        }

        // ui field
        const {
            text,
            color,
            hasLabel,
        } = uiFieldTypes.find(uft => uft.value === field.type);

        let label;
        if (hasLabel) {
            label = `${field.label} `;
        }
        
        const typeLabel = (
            <span>
                {hasLabel && '∙ '}
                <span style={{ color }}>{text}</span>
            </span>
        );
        return (
            <span>
                {label}{typeLabel}
            </span>
        );
    };    
    
    getNodeIcon = nodeID => {
        let type;
        const field = this.props.fieldIDs.find(f => nodeID === f.id);
        if (field && field.type) {
            type = field.type;
        } else {
            return 'circle outline';
        }

        const elm = Object.values(nodeMetadata).find(nmd => type === nmd.type);
        if (elm && elm.category) {
            return nodeCategories[elm.category].icon;
        }
        
        return 'circle';
    }

    getIcon = field => {
        if (field.type === uiTypes.GROUP_OPEN || field.type === uiTypes.FREEBIE_GROUP_OPEN) {
            return 'caret square up outline';
        }

        if (field.type === uiTypes.GROUP_CLOSED || field.type === uiTypes.FREEBIE_GROUP_CLOSE) {
            return 'caret square down outline';
        }

        if (field.type === uiTypes.GROUP_TOGGLER) {
            return 'folder open outline';
        }

        if (field.type === TYPE_NODE) {
            return this.getNodeIcon(field.nodeId);
        }

        if (field.type === TYPE_RESOURCE) {
            return 'cog';
        }

        // assume UI field
        return 'paint brush';
    };

    getOverviewIcons = field => {
        const { hasVisibilityRules, hasValidationRules, printOptions, youtubeID, link, roles, isAssociatedWithHint } = field;
        const shouldPrintField = get(printOptions, 'shouldPrint', false);
        const sectionPrintable = this.props.printable;
        const shouldPrint = sectionPrintable && shouldPrintField;
        const hasRoles = roles?.length > 0;
        const showHintIcon = this.props.HINT_SECTION && isAssociatedWithHint;

        const defaultIconColor = 'grey';

        return (
            <React.Fragment>
                { hasVisibilityRules   && <Icon name='eye' color={defaultIconColor} /> }
                { hasValidationRules   && <Icon name='flag checkered' color={defaultIconColor} /> }
                { shouldPrint          && <Icon name='print' color={defaultIconColor} /> }
                { youtubeID            && <Icon name='video' color={defaultIconColor} /> }
                { link                 && <Icon name='attach' color={defaultIconColor} /> }
                { hasRoles             && <Icon name='users' color={defaultIconColor} /> }
                { showHintIcon         && <Icon name='question' color={defaultIconColor} /> }
            </React.Fragment>
        );
    };

    renderField = (field, index, indentation) => {
        const isActive     = index === this.props.index;
        const icon         = this.getIcon(field);
        const ruleIcons    = this.getOverviewIcons(field);
        const fieldText    = this.getFieldText(field);
        const onDismiss    = () => this.props.deleteField(index);
        const onClick      = () => this.props.onChange(index);

        let colorProp = {};
        if (isActive) {
            colorProp = { color: 'blue' };
        }

        return (
            <React.Fragment>
                <Message {...colorProp} onClick={onClick} size='tiny' id={field.id}>
                    <Message.Header>
                        <Grid verticalAlign='middle'>
                            <Grid.Row>
                                <Grid.Column width={13}>
                                    <div className='fieldListFieldName' >
                                        <SpaceSpan amount={indentation} sizeInSpaces={8}  />
                                        <Icon name={icon} />
                                        { isActive ? <u>{fieldText}</u> : fieldText }
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={3} textAlign='right'>
                                    <div>
                                        {ruleIcons}
                                        <span className='fieldListClickableIcon' onClick={onDismiss} >
                                            <Icon name='close'/>
                                        </span>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Message.Header>
                </Message>
            </React.Fragment>
        );
    };

    render () {
        return (
            <DragAndDropZone onDragEnd={this.onDragEnd} droppableId={DROPPABLE_ID}>
                {this.renderDraggableFields()}
            </DragAndDropZone>
        );
    }
}

export default withRouter(FieldList);