function getQueryParams(search = window.location.search) {
    if (!search) {
        return {};
    }

    const splitted = search.substring(1, search.length).split('&');
    const params = {};
    splitted.forEach((str) => {
        const arr = str.split('=');
        params[decodeURIComponent(arr[0])] = decodeURIComponent(arr[1]);
    });
    return params;
};


/**
 * Formats provided object as query params
 * @param {{[index: string]: string}} params - object<string, string> of query params
 * @param {boolean} urlEncode - if true, url encode each key-value pair of "params". default = false
 */
export function makeQueryParams (params = {}, urlEncode = false) {
    let pairs = [];
    for (let [key, value] of Object.entries(params)) {
        if (value === undefined) {
            continue;
        }

        if (urlEncode) {
            key = encodeURIComponent(key);
            value = encodeURIComponent(value.toString());
        }
        pairs.push(`${key}=${value}`);
    }
    return '?' + pairs.join('&');
}

/**
 * Sets the query params of the browser without causing a reload
 * @param {*} params 
 */
function setQueryParams (params = {}) {
    if (!window.history) {
        return;
    }
    const { protocol, host, pathname } = window.location;
    const base = `${protocol}//${host}${pathname}`;
    const queryParams = makeQueryParams(params);
    const newFullURL = base + (queryParams.length === 1 ? '' : queryParams);
    window.history.pushState({ path: newFullURL }, '', newFullURL);
}

export function getParam(key) {
    const params = getQueryParams();
    return params[key];
}

export function setQueryParam(key, value) {
    return setQueryParams({
        ...getQueryParams(),
        [key]: value,
    });
}

export function unsetQueryParam(key) {
    const params = getQueryParams();
    delete params[key];
    setQueryParams(params);
}

/**
 * Returns and unsets the query param
 * @param {string} key 
 */
export function consumeQueryParam(key) {
    const value = getParam(key);
    unsetQueryParam(key);
    return value || '';
}