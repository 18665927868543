import React from 'react';
import JwtDecode from 'jwt-decode';
import {Redirect, withRouter} from 'react-router-dom';
import { API_LOCATION } from '../config';
import { toast } from 'react-toastify';
import { refreshAccessToken } from 'http/client';

const URL_LOGIN = '/login';

export const UserContext = React.createContext({
  loggedIn: false,
  loginFail: false,
  login: () => {
  },
  logout: () => {
  },
  user: null
});


class LoginManager extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.stateFromLocalStorage();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async login(email, password) {
    let init = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',
      credentials: 'include',
      body: JSON.stringify({email, password})
    };
    const path = `${API_LOCATION}/token`;
    const response = await fetch(path, init);
    if (response.status >= 400) {
      toast.error('Kunne ikke logge dig ind');
      return;
    }

    if (response.ok) {
      const json = await response.json();
      localStorage.setItem("token", json.data.token);
      await refreshAccessToken();
      this.setState(this.stateFromLocalStorage());
    } else {
      toast.error('Kunne ikke logge dig ind');

			this.setState(prevState => {
				return {
					loginFail: true
				};
			});
			this.logout();
    }
	}

  stateFromLocalStorage() {
    const accessToken = localStorage.getItem('accessToken'),
      token = localStorage.getItem('token');
    return {
      loggedIn: token != null,
      user: accessToken != null ? JwtDecode(accessToken) : null
    };
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('accessToken');
    this.setState(prevState => {
      return {
        loggedIn: false,
        user: null
      };
    }, () => {
      const {history} = this.props;
      history.push(URL_LOGIN);
    });
  }

  render() {
    const {loggedIn, loginFail, user} = this.state;
    return (<UserContext.Provider value={{
      loggedIn: loggedIn,
      loginFail: loginFail,
      login: this.login,
      logout: this.logout,
      user: user
    }}>
      {this.props.children}
    </UserContext.Provider>);
  }
}

class EnsureLoggedInComponent extends React.Component {

  render() {
    const { user, requiredRole } = this.props;

    if (!user.loggedIn) {
      return <Redirect to={URL_LOGIN} />;
    }

    if (requiredRole && !user.user?.roles?.includes(requiredRole)) {
      user.logout();
      toast.error('Du er blevet logged ud, da du ikke har rettighed til at se siden');
      return null;
    }

    return this.props.children;
  }
}


// Higher Order Component allowing to expose the user object
function withUserHOC(Component) {
  // ...and returns another component...
  return function UserComponent(props) {

    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <UserContext.Consumer>
        {user => <Component {...props} user={user}/>}
      </UserContext.Consumer>
    );
  };
}


export const Manager = withRouter(LoginManager),
  withUser = withUserHOC,
  EnsureLoggedIn = withUserHOC(withRouter(EnsureLoggedInComponent));
