export const uiTypes = {
    HEADER: 'header',
    DIVIDER: 'divider',
    LINK: 'link',
    GROUP_OPEN: 'group-open',
    GROUP_CLOSED: 'group-close',
    GROUP_TOGGLER: 'group-toggler',
    SEGMENT: 'segment',
    FREEBIE_GROUP_OPEN: 'freebie-group-open',
    FREEBIE_GROUP_CLOSE: 'freebie-group-close',
    AUTOMATED_BOOKING: 'automated-booking',
    TEXT: 'text',
    EMBEDDED_VIDEO: 'embedded-video',
    BOOKING_DISCREPANCY_CHECKER: 'booking-discrepancy-checker',
};

const uiFieldTypes = [
    {
        value: uiTypes.HEADER,
        text: 'Overskrift',
        color: '#81a7e3',
        hasLabel: true,
    },
    {
        value: uiTypes.DIVIDER,
        text: 'Divider',
        color: '#67bf4d',
        hasLabel: false,
    },
    {
        value: uiTypes.LINK,
        text: 'Link',
        color: '#fcba03',
        hasLabel: true,
    },
    {
        value: uiTypes.GROUP_OPEN,
        text: 'Feltgruppe - start',
        color: '#d481e3',
        hasLabel: true,
    },
    {
        value: uiTypes.GROUP_CLOSED,
        text: 'Feltgruppe - slut',
        color: '#d481e3',
        hasLabel: false,
    },
    {
        value: uiTypes.GROUP_TOGGLER,
        text: 'Feltgruppe - åbn & luk',
        color: '#d481e3',
        hasLabel: false,
    },
    {
        value: uiTypes.SEGMENT,
        text: 'Segment',
        color: '#f116f5',
        hasLabel: false,
    },
    {
        value: uiTypes.FREEBIE_GROUP_OPEN,
        text: 'Freebiegruppe - start',
        color: '#68a343',
        hasLabel: true,
    },
    {
        value: uiTypes.FREEBIE_GROUP_CLOSE,
        text: 'Freebiegruppe - slut',
        color: '#68a343',
        hasLabel: false,
    },
    {
        value: uiTypes.AUTOMATED_BOOKING,
        text: 'Automatiseret bogføring',
        color: '#27ba16',
        hasLabel: false,
    },
    {
        value: uiTypes.TEXT,
        text: 'Tekst',
        color: '#27ba16',
        hasLabel: true,
    },
    {
        value: uiTypes.EMBEDDED_VIDEO,
        text: 'Indlejret video',
        color: '#8116ba',
        hasLabel: false,
    },
    {
        value: uiTypes.BOOKING_DISCREPANCY_CHECKER,
        text: 'Bogføringstjek',
        color: '#8116ba',
        hasLabel: false,
    },
];

export default uiFieldTypes;