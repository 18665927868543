import React from 'react';
import { TextArea as SemTextArea } from 'semantic-ui-react';
import cns from 'shared/cns';

import styles from './TextArea.module.css';

/**
 * 
 * @param {object} props 
 * @param {'none'|'vertical'|'horizontal'} props.resize
 * @returns 
 */
const TextArea = ({ defaultValue, value, placeholder, onChange, fluid, resize, className }) => {
    const style = {};

    if (fluid) {
        style.width = '100%';
    }

    if (resize) {
        style.resize = resize;
    }

    return <SemTextArea
        className={cns(styles.txa, className)}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        style={style}
    />;
};

export default TextArea;