import React from 'react';
import { HintBasic, HintNodeData, HintVideo, HintVideosErp } from './hints';

export const HintTypes = {
    BASIC: 'Basic',
    NODE_DATA: 'NodeData',
    VIDEO: 'Video',
    VIDEOS_ERP: 'VideosErp'
};

const Hint = props => {
    const { hintType, dataManager } = props;

    const createHintComponent = hintType => {
        let hintComponent = null;
        switch(hintType) {
            case HintTypes.BASIC: {
                hintComponent = HintBasic;
                break;
            }
            case HintTypes.NODE_DATA: {
                hintComponent = HintNodeData;
                break;
            }
            case HintTypes.VIDEO: {
                hintComponent = HintVideo;
                break;
            }
            case HintTypes.VIDEOS_ERP: {
                hintComponent = HintVideosErp;
                break;
            }
            default: {
                console.error(`Could not create hint of type '${hintType}'`);
            }
        }

        return hintComponent;
    };

    const HintComponent = createHintComponent(hintType);
    return (
        <HintComponent dataManager={dataManager} />
    );
};

export default Hint;
