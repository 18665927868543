import React, { useState } from 'react';
import {
    Dropdown,
    Form,
    Icon,
    List,
    Loader,
    Message,
    Popup,
    Table,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { dataTypes, dataTypeDescriptions } from 'model-editor/nodeMetadata';
import useRestResource from 'shared/hooks/useRestResource';
import modelEditor from 'http/modelEditor';
import productEngine from 'http/productEngine';
import InputSection, { InputSectionRow } from './InputSection';
import { useEffect } from 'react';

const ModelConnectionEditor = ({ inputDefinitions, metadata, setMetadata }) => {
    const [modelData, setModelData] = useState(null);

    const models = useRestResource({
        fetcher: modelEditor.getModels,
    });

    const syncronizeModel = async (modelID) => {
        if (!modelID) {
            setModelData(null);
            return;
        }

        const values = {};
        const product = await productEngine.getProduct(modelID);

        const productFacts = Object.values(product.facts);

        const yearReportDataFact = productFacts.find(fact => fact.dataType === 'yearReportData');
        if (!yearReportDataFact) {
            return toast.error('Chosen model does not contain a YearReportData node');
        }

        const yearReportDataAction = Object.values(product.actions).find(action => {
            return action.supply?.includes(yearReportDataFact.id);
        });
        
        if (!yearReportDataAction) {
            return toast.error('Failed to find YearReportData action');
        }

        // maps from product-engine data types to model-editor data types
        const productDataTypes = {
            number: {
                dataType: dataTypes.NUMBER,
                convert: (val) => ({
                    active: Number(val.number),
                    lastYear: Number(val.lastyear),
                }),
            },
            string: {
                dataType: dataTypes.STRING,
                convert: (val) => ({ active: val.string }),
            },
            bool: {
                dataType: dataTypes.BOOLEAN,
                convert: (val) => ({ active: val.bool }),
            },
            date: {
                dataType: dataTypes.DATE,
                convert: (val) => ({ active: val.date }),
            },
            enumString: {
                dataType: dataTypes.STRING,
                convert: (val) => ({ active: val.enumString }),
            },
            stringList: {
                dataType: dataTypes.STRING_LIST,
                convert: (val) => ({ active: val.stringList }),
            },
        };
        
        const relevantFacts = [];
        for (let factID of yearReportDataAction.requiresFacts) {
            const fact = product.facts[factID];
            if (!fact) {
                return toast.error('Failed to find fact by id: ' + factID);
            }

            const { id, tag, dataType } = fact;

            if (!tag.trim()) {
                continue;
            }

            if (!(dataType in productDataTypes)) {
                continue;
            }

            const typeMapping = productDataTypes[dataType];

            const toPush = {
                tag: tag,
                dataType: typeMapping.dataType,
                data: null,
            };

            const factValue = values[id];
            if (factValue && !factValue.hidden) {
                toPush.data = typeMapping.convert(factValue.value);
            }

            relevantFacts.push(toPush);
        }        

        setModelData(relevantFacts);
    };

    useEffect(() => {
        syncronizeModel(metadata.connectedModelID);
    }, [metadata.connectedModelID]);

    const renderModelData = () => {
        if (!modelData) {
            return null;
        }

        const checkOrCross = (bool, tooltip) => {
            const icon = (
                <Icon
                    name={bool ? 'check' : 'x'}
                    color={bool ? 'green' : 'red'}
                />
            );

            if (tooltip) {
                return (
                    <Popup
                        trigger={icon}
                        content={tooltip}
                        position='top center'
                    />
                );
            }

            return icon;
        };

        const templateTagsToDataType = {};
        inputDefinitions.forEach(definition => {
            templateTagsToDataType[definition.tag] = definition.dataType;
        });

        const rows = [...modelData].map(({ tag, dataType, data }) => {
            return {
                tag,
                dataType,
                activeYear: data?.active,
                lastYear: data?.lastYear,
                tagInTemplate: tag in templateTagsToDataType,
                templateDataType: templateTagsToDataType[tag],
            };
        }).sort((a, b) => {
            if (a.tagInTemplate !== b.tagInTemplate) {
                return b.tagInTemplate - a.tagInTemplate;
            }

            if (a.tag > b.tag) {
                return 1;
            }

            if (a.tag < b.tag) {
                return -1;
            }

            return 0;
        });

        const productTags = new Set(modelData.map(({ tag }) => tag));
        const unrecognizedTemplateDefinitions = inputDefinitions.filter(definition => {
            return !productTags.has(definition.tag);
        });

        const productTagsTable = (
            <Table>
                <Table.Header style={{ position: 'sticky', top: '50px' }}>
                    <Table.Row>
                        <Table.HeaderCell>Tag from model</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Tag exists in template</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Data types match</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map(({
                        tag,
                        dataType,
                        tagInTemplate,
                        templateDataType,
                    }) => {
                        const dataTypesMatch = templateDataType === dataType;
                        const dataTypeMismatchTooltip = (
                            !dataTypesMatch &&
                            templateDataType && (
                                <span>
                                    <div>
                                        <b>Template:</b>
                                        <br />
                                        {dataTypeDescriptions[templateDataType]}
                                    </div>

                                    <div style={{ marginTop: '4px' }}>
                                        <b>Model:</b>
                                        <br />
                                        {dataTypeDescriptions[dataType]}
                                    </div>
                                </span>
                            )
                        );

                        return (
                            <Table.Row error={!dataTypesMatch}>
                                <Table.Cell>{tag}</Table.Cell>
                                <Table.Cell textAlign='center'>{checkOrCross(tagInTemplate)}</Table.Cell>
                                <Table.Cell textAlign='center'>{checkOrCross(dataTypesMatch, dataTypeMismatchTooltip)}</Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        );

        const unrecognizedTemplateDefinitionsTable = (
            <div>
                <span>
                    The following tags are included in the template as inputs while not
                    being connected to YearReportData in <strong>{metadata.connectedModelID}</strong>:
                </span>
                <List >
                    {unrecognizedTemplateDefinitions
                        .map((definition) => definition.tag)
                        .sort()
                        .map((tag) => (
                            <List.Item icon='x'>{tag}</List.Item>
                        ))}
                </List>
            </div>
        );

        return (
            <>
                {productTagsTable}
                {unrecognizedTemplateDefinitionsTable}
            </>
        );
    };

    const renderContent = () => {
        if (models.loading) {
            return <Loader inline='centered' size='big' active />;
        }

        if (models.error) {
            return (
                <Message
                    error
                    icon='warning triangle'
                    header='An error occured'
                    content={modelEditor.error.message}
                />
            );
        }

        return (
            <>
                <Form style={{ width: '100%', borderSpacing: '0px' }}>
                    <Form.Field>
                        <label>Select a model</label>
                        <Dropdown
                            selection
                            search
                            defaultValue={metadata.connectedModelID}
                            onChange={(_, { value }) =>
                                setMetadata({
                                    ...metadata,
                                    connectedModelID: value,
                                })
                            }
                            options={models.data
                                ?.reverse()
                                .map(({ id, name }) => ({
                                    key: id,
                                    value: id,
                                    text: name,
                                }))}
                        />
                    </Form.Field>
                </Form>
                {renderModelData()}
            </>
        );
    };

    return (
        <InputSection>
            <InputSectionRow>{renderContent()}</InputSectionRow>
        </InputSection>
    );
};

export default ModelConnectionEditor;
