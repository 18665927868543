import { inputDataTypes } from './model';

/**
 * Builds suggestions data for the AugmentedTextarea component from
 * the given list of input definitions
 */
const buildSuggestionsFromDefinitions = inputDefinitions => {
    return inputDefinitions.map(definition => {
        const dataType = inputDataTypes.find(dt => dt.id === definition.dataType);
        return {
            label: definition?.tag,
            icon: dataType?.icon,
            dataType: dataType?.id,
        };
    });
};

export default buildSuggestionsFromDefinitions;