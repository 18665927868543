import { useState, useRef } from 'react';
import { Container, Segment, Button, Form, Dropdown, Progress } from 'semantic-ui-react';
import Papa from 'papaparse';
import client from '../../http/client';

const Hubspot = () => {
    const [file, setFile] = useState(null);
    const [jsonData, setJsonData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('boligudlejer');
    const [progress, setProgress] = useState(0); // For tracking progress
    const [alreadyExistsCount, setAlreadyExistsCount] = useState(0); // For items that already exist
    const [newItemCount, setNewItemCount] = useState(0); // For new items
    const [isSending, setIsSending] = useState(false);
    const [stopRequested, setStopRequested] = useState(false);
    const stopRequestedRef = useRef(false); // Using a ref for stopRequested

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const options = [
        { key: 'boligudlejer', text: 'Boligudlejere', value: 'private' },
        { key: 'virksomhed', text: 'Virksomheder', value: 'business' },
        { key: 'revisor', text: 'Revisoere', value: 'accountant' },
    ];

    const parseAndSendCSV = () => {
        //setStopRequested(false); // Reset stop request flag
        stopRequestedRef.current = false;
        if (!file) return;
        setIsSending(true); // Start sending
        
        Papa.parse(file, {
            complete: async (result) => {
                const simplifiedData = result.data.map(item => ({
                    name: item["First name"] + (item["Last name"] ? ` ${item["Last name"]}` : ''),
                    phoneNumber: item["Phone number"],
                    email: item["Email"],
                    ip: item["IP"],
                    webinar: item["Webinar"],
                    session: item["Session"],
                    event: item["Event"],
                    registrationDate: item["Registration date"],
                    attendedLive: item["Attended live"],
                    gdprIP: item["GDPR IP"],
                })).filter(item => item.name.trim() && item.email.trim()); // Filtering logic (the header sometimes gets processed as real data);

                for (let index = 0; index < simplifiedData.length; index++) {
                    if (stopRequestedRef.current) break;

                    const dataToSend = {
                        productID: 300003,
                        taxYear: 2020,
                        data: simplifiedData[index],
                    };
                    try {
                        const response = await client.postResource(`/hubspot/webinar?option=${selectedOption}`, dataToSend);
                        setJsonData(prev => [...prev, response]);
                        //setProgress((index + 1) / simplifiedData.length * 100);
                        setProgress((index + 1) / simplifiedData.length * 100);

                        // Update counts based on the response
                        if (response.alreadyExists) {
                            setAlreadyExistsCount(prevCount => prevCount + 1);
                        } else {
                            setNewItemCount(prevCount => prevCount + 1);
                        }
                    } catch (error) {
                        console.error("Error posting JSON data:", error);
                    }
                }
            },
            header: true,
        });
    };

    const renderJSONData = () => {
        // Filter out items that already exist
        const itemsThatDoNotExist = jsonData.filter(item => !item.alreadyExists);

        if (itemsThatDoNotExist.length === 0) return null; // If no new items, don't render anything

        return (
            <div>
            <h3>Nye kontakter:</h3>
            {itemsThatDoNotExist.map((item, index) => (
                <Segment inverted key={index}>
                    <p><strong>Navn:</strong> {item.name}</p>
                    <p><strong>Telefonnummer:</strong> {item.phoneNumber}</p>
                    <p><strong>Email:</strong> {item.email}</p>
                    <p><strong>Webinar:</strong> {item.webinar}</p>
                    <p><strong>Session:</strong> {item.session}</p>
                    <p><strong>Begivenhed:</strong> {item.event}</p>
                    <p><strong>Tilmeldingsdato:</strong> {item.registrationDate}</p>
                    <p><strong>Deltog Live:</strong> {item.attendedLive}</p>
                </Segment>
            ))}
        </div>
        );
    };

    const handleStopSending = () => {
        stopRequestedRef.current = true;
        setIsSending(false)
    };

    return (
        <Container>
            <Segment style={{ marginTop: '1em' }}>
                <Form>
                    <Form.Field>
                        <label>Upload CSV</label>
                        <input type="file" accept=".csv" onChange={handleFileChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Hvilken gruppe høre dette webinar til?</label>
                        <Dropdown
                            placeholder='Select Option'
                            fluid
                            selection
                            options={options}
                            value={selectedOption}
                            onChange={(e, { value }) => setSelectedOption(value)}
                        />
                    </Form.Field>
                    <Button onClick={parseAndSendCSV} disabled={!file || isSending}>Parse and Send CSV</Button>
                    {isSending && <Button onClick={handleStopSending}>Stop</Button>}
                </Form>
                {jsonData.length > 0 && (
                    <>
                        <Progress percent={progress} indicating color="blue" />
                        <Segment inverted color="blue" padded raised style={{ maxWidth: '400px', margin: '20px 0',  }}>
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Er allerede i hubspot: {alreadyExistsCount}</p>
                            <p style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Nye kontakter: {newItemCount}</p>
                        </Segment>
                    </>
                )}
                {renderJSONData()}
            </Segment>
        </Container>
    );
};

export default Hubspot;