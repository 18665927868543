import React, { useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import ColoredText from 'atoms/ColoredText';
import model from '../model';

const CreateBlockButton = ({ label, composite, onCreate }) => {
    const [open, setOpen] = useState(false);

    const popupContent = (
        <div>
            {
                Object.values(model.blockTypes)
                    .filter(blockType => blockType.composite === composite)
                    .filter(blockType => blockType !== model.blockTypes.groupCloser)
                    .map(blockType => {
                        return (
                            <div onClick={() => {
                                setOpen(false);
                                onCreate(blockType.id);
                            }}>
                                <ColoredText
                                    iconPosition='left'
                                    icon={blockType.icon}
                                    content={blockType.label}
                                    underlined={false}
                                    link
                                />
                            </div>
                        );
                    })
            }
        </div>
    );

    return (
        <Popup
            basic
            on='click'
            position='center'
            size='large'
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            content={popupContent}
            trigger={
                <Button
                    basic={open}
                    color={open ? 'grey' : undefined}
                    content={`Create ${label}`}
                />
            }
        />
    );
};

export default CreateBlockButton;