import { useState } from 'react';

const configStorageKey = 'config';

const storageKey = (namespace, id) => {
    return [configStorageKey, namespace, id].join('.')
};

const parse = toParse => {
    if (toParse === 'undefined') {
        return undefined;
    }

    return JSON.parse(toParse);
};

const loadConfigValue = (namespace, id, defaultValue) => {
    const key = storageKey(namespace, id);
    if (localStorage[key] === undefined) {
        return defaultValue;
    }

    return parse(localStorage[storageKey(namespace, id)]);
};

const saveConfigValue = (namespace, id, value) => {
    const key = storageKey(namespace, id);
    if (value === undefined) {
        delete localStorage[key];
        return;
    }

    localStorage[storageKey(namespace, id)] = JSON.stringify(value);
};

const useLocalConfig = (namespace, id, defaultValue) => {
    const [value, _setValue] = useState(loadConfigValue(namespace, id, defaultValue));

    const setValue = newValue => {
        saveConfigValue(namespace, id, newValue);
        _setValue(newValue);
    };

    return [value, setValue];
};

export const localConfigHookGenerator = namespace => {
    return (id, defaultValue) => {
        return useLocalConfig(namespace, id, defaultValue);
    };
};

export const localConfigSetter = namespace => {
    return (id, value) => {
        saveConfigValue(namespace, id, value);
    };
};


export default useLocalConfig;