import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';
import NotificationDetail from './Detail';
import NotificationMaster from './Master';
import Navigation from './navigation';

function Notification () {
    return (
        <Container>
            <Navigation />
            <Segment>
                <Switch>
                    <Route path='/notifications/notifications/:notification' component={NotificationDetail} />
                    <Route path='/notifications/notifications' component={NotificationMaster} />
                    <Redirect from='/notifications' to='/notifications/notifications' exact />
                </Switch>
            </Segment>
        </Container>
    );
}

export default Notification;