import React, { useEffect, useState } from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import { getPipeline, moveTask } from 'http/internalTasks';
import Pipeline from 'atoms/Pipeline';
import * as array from 'shared/array';

const TaskManager = ({ pipelineID }) => {
    const [pipeline, setPipeline] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchPipeline = async (pipelineID) => {
        if (!pipelineID) {
            setLoading(false);
            return;
        }
        
        setLoading(true);
        const resp = await getPipeline(pipelineID);
        setPipeline(resp);
        setLoading(false);
    };
    
    // fetch pipeline on pipelineID change
    useEffect(() => {
        fetchPipeline(pipelineID);
    }, [pipelineID]);

    const onTaskMoved = async ({ from, to, task }) => {        
        // move task client side
        if (from.swimlane !== to.swimlane) {
            // task is moved to different swimlane
            const fromTasks = pipeline.swimlanes.find(sl => sl.id === from.swimlane).tasks;
            const toTasks = pipeline.swimlanes.find(sl => sl.id === to.swimlane).tasks;
            array.insert(toTasks, fromTasks[from.index], to.index);
            array.deleteAt(fromTasks, from.index);
        } else {
            // task index changed in swinlane
            const tasks = pipeline.swimlanes.find(sl => sl.id === to.swimlane).tasks;
            array.move(tasks, from.index, to.index);
        }

        setPipeline({ ...pipeline });
        
        // move task serverside
        await moveTask(task, to.swimlane, to.index);
    };

    if (loading) {
        return <Segment>
            <Loader inline='centered' size='huge' />
        </Segment>;
    }

    return <Pipeline
        swimlanes={pipeline.swimlanes}
        onChange={onTaskMoved}
    />;
};

export default TaskManager;