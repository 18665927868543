import React from 'react';
import { Button, Checkbox, Form, Segment, Container, Dropdown } from 'semantic-ui-react';
import { dataTypeIsPrimitive, dataTypes } from 'model-editor/nodeMetadata';
import NumberRule from './RuleSubsection/NumberRule';
import BooleanRule from './RuleSubsection/BooleanRule';
import DateRule from './RuleSubsection/DateRule';
import StringRule from './RuleSubsection/StringRule';
import EnumRule from './RuleSubsection/EnumRule';

import styles from './RuleManager.module.css';

class RuleManager extends React.Component {
    constructor (props) {
        super(props);
        const rules = this.props.rules || [];
        this.state = {
            rules,
        };
    }

    updateRule = (index, updator) => {
        const { rules } = this.state;

        for (let [toUpdate, value] of Object.entries(updator)) {
            if (toUpdate === 'refNode' && !value) {
                rules[index] = {};
            } else {
                rules[index][toUpdate] = value;
            }
        }

        this.props.deliverData(rules);
    };

    removeRule (index) {
        const { rules } = this.state;
        rules.splice(index, 1);
        this.props.deliverData(rules);
    }

    getNodeDataType = nodeID => {
        const node = this.props.nodes.find(n => n.id === nodeID);
        return node?.dataType;
    };

    getComponentByDataType = dataType => ({
        [dataTypes.NUMBER]: NumberRule,
        [dataTypes.BOOLEAN]: BooleanRule,
        [dataTypes.DATE]: DateRule,
        [dataTypes.STRING]: StringRule,
        [dataTypes.ENUM_STRING]: EnumRule,
    }[dataType]);

    createEnumOptionsGetter = nodeID => {
        const { getOptionsByID } = this.props;

        return () => {
            return getOptionsByID instanceof Function
                ? getOptionsByID(nodeID)
                : [];
        };
    };

    getRuleSubSection = (rule, index) => {
        const { refNode, isDynamic } = rule;
        const { disabled, nodes } = this.props;
        
        // lookup sub-component based on data type
        const dataType = this.getNodeDataType(refNode);
        const Component = this.getComponentByDataType(dataType);
        if (!Component) {
            return null;
        }

        // find relevant comparion nodes w/ same data type
        const relevantNodes = nodes.filter(n => n.dataType === dataType);

        // wrapper for updating current rule
        const ruleUpdator = updator => this.updateRule(index, updator);

        // special case: provide options for enum types
        let getOptions;
        if (dataType === dataTypes.ENUM_STRING) {
            getOptions = this.createEnumOptionsGetter(refNode);
        }

        return <Component
            key={index}
            rule={rule}
            index={index}
            refNode={refNode}
            disabled={disabled}
            nodes={relevantNodes}
            isDynamic={isDynamic}
            updateRule={ruleUpdator}
            getOptions={getOptions}
        />;
    }

    createEmptyRule = () => {
        const { rules } = this.state;
        rules.push({});
        this.setState({
            rules,
        });
    };

    getValidRuleNodes = () => {
        const { nodes } = this.props;

        // nodes w/ primitive data types are valid
        return nodes.filter(node => dataTypeIsPrimitive(node.dataType));
    };

    render () {
        const { rules } = this.state;
        const { disabled, showAdvisoryMessage, deliverData, fieldID } = this.props;
        const nodes = this.getValidRuleNodes();

        const renderAdvisoryMessageSection = (i, defaultValue) => {
            if (!showAdvisoryMessage) return null;

            return (
                <Form.Field>
                    <label>
                        Vejledende besked ved brud på regel:
                    </label>
                    <input
                        defaultValue={defaultValue}
                        disabled={disabled}
                        onBlur={e => this.updateRule(i, {
                            advisoryMessage: e.target.value,
                        })}
                    />
                </Form.Field>
            );
        };

        return (
            <Container onBlur={() => deliverData(rules)} fluid>
                {
                    rules.map((rule, i) => {
                        const { refNode, advisoryMessage, isDynamic } = rule;
                        return (
                            <Segment key={`${fieldID}:${i}`} padded>
                                <Form.Field>
                                    <label>Regel:</label>
                                    <Dropdown
                                        search
                                        selection
                                        defaultValue={refNode}
                                        disabled={disabled}
                                        onChange={(_, { value }) => this.updateRule(i, { refNode: value })}
                                        options={nodes.map(({ id, name }) => ({
                                            key: id,
                                            value: id,
                                            text: name,
                                        }))}
                                    />
                                </Form.Field>
                                
                                
                                {this.getRuleSubSection(rule, i)}
                                {renderAdvisoryMessageSection(i, advisoryMessage)}
                                <Checkbox
                                    toggle
                                    label='Dynamisk?'
                                    checked={isDynamic}
                                    onChange={(_, { checked }) => this.updateRule(i, {
                                        isDynamic: checked,
                                    })}
                                />
                                <Button
                                    onClick={() => this.removeRule(i)}
                                    floated='right'
                                    size='small'
                                    content='Fjern'
                                    icon='delete'
                                    negative
                                />
                            </Segment>
                        );
                    })
                }
                <Button
                    className={styles.createContainer}
                    onClick={this.createEmptyRule}
                    content='Tilføj ny regel'
                    icon='plus'
                    size='big'
                    fluid
                />
            </Container>
        );
    }
}

export default RuleManager;
