import React from 'react';
import { insert } from 'shared/array';

export const createLineBuffer = (startColor) => {
    let textBuffer = '';
    let currentColor = startColor;
    let caretElement = null;
    let caretIndex = null;
    let tokens = [];

    const tokensToElements = () => {
        if (tokens.length === 0) {
            return [<br />];
        }

        return tokens.map(({ color, text }) => (
            <span
                style={{ color }}
                children={text}
            />
        ));
    };
    
    const hasCaret = () => (
        caretElement !== null &&
        caretIndex !== null
    );

    return {
        write(toWrite) {
            textBuffer += toWrite;
        },
        writeCaretMarker(caret) {
            this.flushBuffer();

            caretIndex = tokens.length;
            caretElement = caret;
        },
        flushBuffer() {
            if (!textBuffer) {
                return;
            }
    
            tokens.push({
                text: textBuffer,
                color: currentColor,
            });
            textBuffer = '';
        },
        changeColor(newColor) {
            this.flushBuffer();
            currentColor = newColor;
        },
        toElement() {
            this.flushBuffer();
    
            const children = tokensToElements();
            if (hasCaret()) {
                insert(children, caretElement, caretIndex);
            }
    
            return <div children={children} />;
        },
    };
};